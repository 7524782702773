import * as React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { AvatarImagesJSON } from "src/utils/avatar";
import { ReactComponent as Avatar1 } from "../../assets/dist/avatar/avatar_1.svg";
import { ReactComponent as Avatar2 } from "../../assets/dist/avatar/avatar_2.svg";
import { ReactComponent as Avatar3 } from "../../assets/dist/avatar/avatar_3.svg";
import { ReactComponent as Avatar4 } from "../../assets/dist/avatar/avatar_4.svg";
import { ReactComponent as Avatar5 } from "../../assets/dist/avatar/avatar_5.svg";
import { ReactComponent as Avatar6 } from "../../assets/dist/avatar/avatar_6.svg";
import { ReactComponent as Avatar7 } from "../../assets/dist/avatar/avatar_7.svg";
import { ReactComponent as Avatar8 } from "../../assets/dist/avatar/avatar_8.svg";
import { ReactComponent as Avatar9 } from "../../assets/dist/avatar/avatar_9.svg";
import { ReactComponent as Avatar10 } from "../../assets/dist/avatar/avatar_10.svg";
import { ReactComponent as Avatar11 } from "../../assets/dist/avatar/avatar_11.svg";
import { ReactComponent as Avatar12 } from "../../assets/dist/avatar/avatar_12.svg";
import { ReactComponent as Avatar13 } from "../../assets/dist/avatar/avatar_13.svg";
import { ReactComponent as Avatar14 } from "../../assets/dist/avatar/avatar_14.svg";
import { ReactComponent as Avatar15 } from "../../assets/dist/avatar/avatar_15.svg";
import { ReactComponent as Avatar16 } from "../../assets/dist/avatar/avatar_16.svg";

interface AvatarSelectionModalProps {
  avatarId: string;
  isActive?: boolean;
  key?: string;
  className?: string;
  isHoverable?: boolean;
}

const AvatarImage = ({
  avatarId,
  isActive = false,
  className,
  isHoverable = false,
}: AvatarSelectionModalProps) => {
  const avatar =
    AvatarImagesJSON.find((av) => av?.id === avatarId) || AvatarImagesJSON[0];
  const clsxClassname = clsx([
    styles.imgAvatar,
    isActive && styles.imgAvatar_active,
    isHoverable && styles.imgAvatar_hoverable,
    className,
  ]);

  if (Number(avatarId) >= 1 && Number(avatarId) <= 16)
    return (
      <>
        {avatarId === "1" && <Avatar1 className={clsxClassname} />}
        {avatarId === "2" && <Avatar2 className={clsxClassname} />}
        {avatarId === "3" && <Avatar3 className={clsxClassname} />}
        {avatarId === "4" && <Avatar4 className={clsxClassname} />}
        {avatarId === "5" && <Avatar5 className={clsxClassname} />}
        {avatarId === "6" && <Avatar6 className={clsxClassname} />}
        {avatarId === "7" && <Avatar7 className={clsxClassname} />}
        {avatarId === "8" && <Avatar8 className={clsxClassname} />}
        {avatarId === "9" && <Avatar9 className={clsxClassname} />}
        {avatarId === "10" && <Avatar10 className={clsxClassname} />}
        {avatarId === "11" && <Avatar11 className={clsxClassname} />}
        {avatarId === "12" && <Avatar12 className={clsxClassname} />}
        {avatarId === "13" && <Avatar13 className={clsxClassname} />}
        {avatarId === "14" && <Avatar14 className={clsxClassname} />}
        {avatarId === "15" && <Avatar15 className={clsxClassname} />}
        {avatarId === "16" && <Avatar16 className={clsxClassname} />}
      </>
    );

  return (
    <img
      className={clsxClassname}
      src={`https://images.blacknut.com/avatars/${avatar?.category}/${avatar?.filename}`}
    />
  );
};

export default AvatarImage;
