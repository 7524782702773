import {
  NotificationType,
  State,
  addNotification,
} from "@blacknut/react-client-core/lib";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "src/theme/ThemeProvider";
import { ReactComponent as ID } from "../../assets/dist/ic_id.svg";
import { ReactComponent as Copy } from "../../assets/dist/ic_copy.svg";

import styles from "./styles.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  Focusable,
  FocusableSection,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { Button } from "@blacknut/react-sdk";
import { useTranslation } from "react-i18next";
import { useHeader } from "src/components/Header/HeaderProvider";
import HeaderBackButton from "src/components/Header/HeaderBackButton";
import { TertiaryButton } from "src/components/Button/V2Button";
import { ReactComponent as Arrow } from "../../assets/main/ic_arrow.svg";
import { useHistory } from "react-router";
const AccountID = () => {
  const { theme } = useTheme();
  const { user, config, organization } = useSelector(
    (state: State) => state.globalState,
  );
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toggleVisibility = useCallback(() => {
    setVisible((b) => !b);
  }, []);
  const copyToClipboard = useCallback(
    (e: any) => {
      navigator.clipboard.writeText(user?.uuid || "");
      addNotification({
        time: new Date().getTime(),
        type: NotificationType.SUCCESS,
        message: "accountId.notification",
      })(dispatch);
    },
    [dispatch, user?.uuid],
  );

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  useEffect(() => {
    setHeaderTitle(t("buttons.accountId", { appName: process.env.REACT_APP_APP_NAME }));
    setHeaderLeft(
      <HeaderBackButton
        title={t("buttons.accountId", { appName: process.env.REACT_APP_APP_NAME })}
      />,
    );
    setHeaderRight(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t]);

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);
  const history = useHistory();

  const onSupportClick = useCallback(() => {
    if (organization?.supportUrl) {
      window.open(organization?.supportUrl);
    } else {
      history.push("/account/support");
    }
  }, [history, organization?.supportUrl]);

  return (
    <FocusableSection
      className={styles.container}
      focusKey="settings"
      config={{ restrict: "self-first" }}
    >
      <div className={styles.content}>
        <div className={styles.subtitle}>
          {t("accountId.subtitle", { appName: process.env.REACT_APP_APP_NAME })}
        </div>
        <div className={styles.inputCard}>
          <ID className={styles.id} />
          <span className={styles.input}>
            {visible ? user?.uuid : `****${(user?.uuid || "").slice(-4)}`}
          </span>
          <Focusable className={styles.eye} onPressEnter={toggleVisibility}>
            {visible && theme.images.Visibility && (
              <theme.images.Visibility onClick={toggleVisibility} />
            )}
            {!visible && theme.images.VisibilityOff && (
              <theme.images.VisibilityOff onClick={toggleVisibility} />
            )}
          </Focusable>
        </div>

        <Button variant="primary" onClick={copyToClipboard} className={styles.copy}>
          <Copy />
          <span>{t("accountId.copy")}</span>
        </Button>
      </div>

      {config?.features?.support !== false && (
        <div className={styles.footer}>
          <TertiaryButton className={styles.support} onClick={onSupportClick}>
            {t("buttons.support")}
            <Arrow />
          </TertiaryButton>
        </div>
      )}
    </FocusableSection>
  );
};
export default AccountID;
