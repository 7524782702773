import { getImageByThemeName } from "@blacknut/react-client-core/lib/models/Image";
import { MenuItem } from "@blacknut/react-client-core/lib";
import React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import styles from "./styles.module.scss";

const FeaturedTileActiveState = css`
  transform: ${(props) =>
    props.theme.browseCardItemCardStyle.scaleFactor
      ? `scale(${props.theme.browseCardItemCardStyle.scaleFactor})`
      : undefined};
  box-shadow: 0 0 1.2rem
    ${(props) => props.theme.browseCardItemCardStyle.activeBackgroundColor};
  background-color: ${(props) =>
    props.theme.browseCardItemCardStyle.scaleFactor
      ? undefined
      : props.theme.browseCardItemCardStyle.activeBackgroundColor};
  z-index: 1;

  img {
    opacity: ${(props) => (props.theme.browseCardItemCardStyle.scaleFactor ? 1 : 0)};
  }
`;
const FeaturedTile = styled(Focusable)<{
  theme: Theme;
  spatialNavigationActive: boolean;
}>`
  flex: 1;
  height: 10rem;
  border-radius: ${(props) => props.theme.cardStyle.radius}rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s; /* Animation */

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    height: 16rem;
  }

  @media (hover: hover) {
    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : FeaturedTileActiveState)}
    }
  }

  &:focus {
    ${(props) => (props.spatialNavigationActive ? FeaturedTileActiveState : "")}
  }
`;

const FeaturedTileTitle = styled.h2`
  color: #fff;
  text-align: center;
  flex: 1;
  cursor: pointer;

  font-size: ${(props) => props.theme.browseCardItemCardStyle.fontSize};
  font-weight: ${(props) => props.theme.browseCardItemCardStyle.fontWeight};
  font-family: ${(props) => props.theme.browseCardItemCardStyle.fontFamily};
`;

const FeaturedImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const FeaturedTitleTileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

interface FeaturedTileProps {
  item: MenuItem;
}
const FeaturedTileProps = ({ item }: FeaturedTileProps) => {
  const history = useHistory();
  const { theme } = useTheme();
  const onClick = () => {
    history.push(`/page/${item.uuid}`);
  };
  const backgroundImg = getImageByThemeName(theme.name, item.imgBackground);
  const { active: spatialNavigationActive } = useSpatialNavigation();
  return (
    <FeaturedTile
      onPressEnter={onClick}
      key={item.uuid}
      theme={theme}
      onClick={onClick}
      role="listitem"
      spatialNavigationActive={spatialNavigationActive}
      className={styles.featuredTile}
    >
      {backgroundImg && <FeaturedImage src={backgroundImg.url} />}
      <FeaturedTitleTileContainer>
        <FeaturedTileTitle theme={theme}>{item.title}</FeaturedTileTitle>
      </FeaturedTitleTileContainer>
    </FeaturedTile>
  );
};
export default FeaturedTileProps;
