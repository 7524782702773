import {
  AppLayout,
  RemoteNotificationFormat,
  RemoteNotificationTrigger,
  State,
  useAnalytics,
  useHomePage,
  useLayout,
  usePrevious,
} from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  NavigationFailedEvent,
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import Catalog from "../../components/Catalog/Catalog";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import { useHeader } from "../../components/Header/HeaderProvider";
import MessageModal from "../../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import { useSubscriptionRedirect } from "../../components/SubscriptionRedirect/SubscriptionRedirect";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import { AccountHeaderRight } from "./AccountHeaderRight";
import BannerNotification from "./BannerNotification";
import styles from "./styles.module.scss";

const HomePage = () => {
  const {
    page,
    loading,
    dismissLoadingError,
    loadingError,
    paginating,
    paginate,
    remoteNotifications,
    markRemoteNotificationAsRead,
  } = useHomePage({
    limit: 10,
    tilesLimit: 20,
  });
  const layout = useLayout();
  const { resume: resumeSpatialNav, active: spatialNavActive } = useSpatialNavigation();
  const { push: modalPush } = useModal();
  const { profile } = useSelector((state: State) => state.profilesState);

  const notification =
    remoteNotifications &&
    remoteNotifications.find(
      (n) => n.format === RemoteNotificationFormat.BANNER && n.isNew,
    );

  const markAsRead = useCallback(() => {
    if (notification) {
      markRemoteNotificationAsRead(notification);
    }
  }, [markRemoteNotificationAsRead, notification]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  useErrorHandling({
    error: loadingError,
    clearError: dismissLoadingError,
    callback: reload,
  });

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();

  React.useEffect(() => {
    setHeaderLeft(undefined);
    setHeaderRight(<AccountHeaderRight />);
    setHeaderTitle(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle]);

  const { trackPageView } = useAnalytics();
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      trackPageView("home");
    }
  }, [trackPageView]);

  const prevPage = usePrevious(page);
  useEffect(() => {
    if (!prevPage && page && page.lists.length > 0) {
      setTimeout(() => {
        resumeSpatialNav();
        if (spatialNavActive) {
          focusSectionWithPath(`/${page.uuid}/${page.lists[0].uuid}`);
        }
      }, 300);
    }
  }, [page, prevPage, resumeSpatialNav, spatialNavActive]);

  const onNavigationFailed = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "up") {
      document.documentElement.scroll({ top: 0 });
    } else if (e.detail.direction === "down") {
      document.documentElement.scroll({ top: document.body.scrollHeight });
    }
  }, []);

  const { redirectToSubscription } = useSubscriptionRedirect();
  const modalSubscription = useRef<ModalSubscription>();
  const { t } = useTranslation();
  const closeErrorModal = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
    markAsRead();
  }, [markAsRead]);
  const _redirectToSubscription = useCallback(() => {
    markAsRead();
    redirectToSubscription();
  }, [markAsRead, redirectToSubscription]);
  useEffect(() => {
    if (
      notification?.format === RemoteNotificationFormat.BANNER &&
      layout === AppLayout.TV &&
      notification.trigger === RemoteNotificationTrigger.INVALID_SUBSCRIPTION &&
      !modalSubscription.current
    ) {
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          message={notification.description}
          title={notification.title}
          onClose={closeErrorModal}
          buttons={
            profile?.isMaster && [
              spatialNavActive ? (
                <SecondaryButton key="ok" onClick={_redirectToSubscription}>
                  {t("buttons.ok")}
                </SecondaryButton>
              ) : (
                <PrimaryButton key="ok" onClick={_redirectToSubscription}>
                  {t("buttons.ok")}
                </PrimaryButton>
              ),
              <SecondaryButton key="cancel" onClick={closeErrorModal}>
                {t("buttons.cancel")}
              </SecondaryButton>,
            ]
          }
        />
      ));
    }
  }, [
    layout,
    notification?.format,
    notification,
    modalPush,
    closeErrorModal,
    profile?.isMaster,
    _redirectToSubscription,
    t,
    spatialNavActive,
  ]);

  return (
    <FocusableSection
      className={styles.container}
      focusKey={page?.uuid || "xx"}
      onNavigationFailed={onNavigationFailed}
    >
      {layout !== AppLayout.TV &&
        notification &&
        notification.format === RemoteNotificationFormat.BANNER && (
          <BannerNotification notification={notification} onClose={markAsRead} />
        )}
      {loading && <CircularProgress className={styles.loading} size={50} />}
      {page && <Catalog page={page} paginating={paginating} onPaginate={paginate} />}
    </FocusableSection>
  );
};
export default HomePage;
