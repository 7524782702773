import { apiService } from "@blacknut/javascript-sdk/dist";
import { NativeModule } from "./NativeModule";
import electronService from "../../services/ElectronService";

export class NativeBridge {
  private _module?: NativeModule;
  public get module(): NativeModule | undefined {
    return this._module;
  }

  public init() {
    if ("ReactNativeWebView" in window) {
      return import("./AndroidNativeModule").then((mod) => {
        this._module = new mod.AndroidNativeModule();
      });
    } else if (apiService.userAgent.os === "Tizen" && window.parent) {
      return import("./TizenNativeModule").then((mod) => {
        this._module = new mod.TizenNativeModule();
      });
    } else if (electronService.isAvailable()) {
      return import("./ElectronNativeModule").then((mod) => {
        this._module = new mod.ElectronNativeModule();
      });
    }
    return Promise.resolve();
  }

  tearDown() {
    this._module?.tearDown?.();
  }
}
