import { List } from "@blacknut/react-client-core/lib";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Validate } from "../../assets/dist/ic_check_filled.svg";
import { ReactComponent as ValidateActive } from "../../assets/dist/ic_check_filled_active.svg";
import { ReactComponent as Delete } from "../../assets/dist/ic_delete_filled.svg";
import { ReactComponent as DeleteActive } from "../../assets/dist/ic_delete_filled_active.svg";
import { ReactComponent as Sort } from "../../assets/dist/ic_sort_filled.svg";
import { ReactComponent as SortActive } from "../../assets/dist/ic_sort_filled_active.svg";
import IconButton from "../../components/Button/IconButton";
import { PrimaryButton } from "../../components/Button/V2Button";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";

const Header = styled(FocusableSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    justify-content: flex-start;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LastPlayed = styled(PrimaryButton)<{ theme: Theme; isActive: boolean }>`
  margin-right: ${dimens.margins.Green}rem;
  flex: 1;

  button {
    border-width: 0;
    background-color: ${({ theme }) => theme.toggleButton.tintColor};
    color: ${({ isActive, theme }) =>
      isActive ? theme.toggleButton.activeTintColor : theme.toggleButton.textColor};
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    flex: 0;
    min-width: 25rem;
  }
`;

const Favorite = styled(PrimaryButton)<{ isActive: boolean }>`
  flex: 1;

  button {
    border-width: 0;
    background-color: ${({ theme }) => theme.toggleButton.tintColor};
    color: ${({ isActive, theme }) =>
      isActive ? theme.toggleButton.activeTintColor : theme.toggleButton.textColor};
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    flex: 0;
    min-width: 25rem;
  }
`;

const ButtonsContainer = styled.div`
  margin-left: auto;
  flex-direction: row;
  display: none;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    display: flex;
  }
`;

const HeaderButton = styled(IconButton)`
  cursor: pointer;
  margin-left: ${dimens.margins.DarkRed}rem;
  display: inline-flex;
  svg:last-child {
    display: none;
  }
  svg {
    color: ${(props: { theme: Theme }) =>
      props.theme.primaryButton.activeBackgroundColor};
    width: 3.6rem;
    height: 3.6rem;
  }
  &:hover,
  &:focus {
    svg:first-child {
      display: none;
    }
    svg:last-child {
      display: block;
    }
  }
`;

interface MyListPageHeaderProps {
  currentIndex: number;
  list: List;
  onClickLastPlayed: () => void;
  onClickFavorite: () => void;
  editMode: boolean;
  openSortModal: () => void;
  onToggleEdit: () => void;
  className?: string;
  isTV?: boolean;
}
// eslint-disable-next-line react/display-name
const MyListPageHeader = React.forwardRef<HTMLDivElement, MyListPageHeaderProps>(
  (props, ref) => {
    const {
      currentIndex,
      list,
      onClickLastPlayed,
      onClickFavorite,
      editMode,
      openSortModal,
      onToggleEdit,
      className,
    } = props;
    const { t } = useTranslation();
    const { theme } = useTheme();
    return (
      <Container className={className} ref={ref}>
        <Header focusKey="header">
          <LastPlayed
            buttonStyle={
              currentIndex === 0 ? theme.primaryButton : theme.secondaryButton
            }
            isActive={currentIndex === 0}
            onClick={onClickLastPlayed}
            theme={theme}
          >
            {t("myList.lastPlayed")}
          </LastPlayed>

          <Favorite
            buttonStyle={
              currentIndex === 1 ? theme.primaryButton : theme.secondaryButton
            }
            isActive={currentIndex === 1}
            onClick={onClickFavorite}
            theme={theme}
          >
            {t("myList.favorites")}
          </Favorite>

          {list.total > 0 && (
            <ButtonsContainer>
              <HeaderButton onClick={openSortModal} theme={theme} testID="sort">
                <Sort />
                <SortActive />
              </HeaderButton>

              {editMode && (
                <HeaderButton onClick={onToggleEdit} theme={theme} testID="delete">
                  <Validate />
                  <ValidateActive />
                </HeaderButton>
              )}
              {!editMode && (
                <HeaderButton onClick={onToggleEdit} theme={theme} testID="edit">
                  <>
                    <Delete />
                    <DeleteActive />
                  </>
                </HeaderButton>
              )}
            </ButtonsContainer>
          )}
        </Header>
      </Container>
    );
  },
);

export default MyListPageHeader;
