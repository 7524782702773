import { logW } from "@blacknut/logging/dist";
import {
  apiService,
  Route,
  RouteNames,
  WithRouterProps,
} from "@blacknut/react-client-core/lib";
import * as H from "history";
import { useHistory, useLocation, useParams } from "react-router-dom";
import getEnv from "./getEnv";
import { clearHistory, isAISBuild, isDreiBuild, LOGGING_TAG } from "./Utils";
import { useMemo } from "react";

/** @deprecated */
export const enhanceOnboardingURL = (
  url: string,
  opts: {
    viewport?: { width: number; height: number };
    theme: string;
  },
) => {
  const { viewport, theme } = opts;
  const uri = new URL(url);
  if (viewport) {
    const { width, height } = viewport;
    uri.searchParams.append("viewport", `${Math.floor(width)}*${Math.floor(height)}`);
  }
  uri.searchParams.append("device", apiService.userAgent.deviceType.toLowerCase());
  uri.searchParams.append("env", getEnv(apiService.endpoint));
  apiService.userAgent.brand &&
    uri.searchParams.append("brand", apiService.userAgent.brand.toLowerCase());
  apiService.userAgent.model &&
    uri.searchParams.append("model", apiService.userAgent.model.toLowerCase());
  uri.searchParams.append("theme", `${theme}`);
  const res = uri.toString().replace(/\/\?/g, "?");
  return res;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const navigate = (history: H.History, r: Route | string, state?: unknown) => {
  if (typeof r === "string") {
    history.push(r, state);
  } else {
    const route = r as Route;
    switch (route.name) {
      case RouteNames.HOME:
        history.push("/catalog", state);
        break;
      case RouteNames.STREAM_RECOVERY:
        history.replace("/streams/recover");
        break;
      case RouteNames.PAGE:
        history.push(`/page/${route.pageId}`, state);
        break;
      case RouteNames.SUBSCRIPTION: {
        history.push("/welcome");
        break;
      }
      case RouteNames.PAYMENT_SELECTION:
        logW(LOGGING_TAG, "deprecated PAYMENT_SELECTION");
        if (route.clearHistory) {
          clearHistory(history);
        }
        if (isDreiBuild() || isAISBuild()) {
          history.push("/welcome");
        } else {
          history.push("/checkout");
        }
        break;
      case RouteNames.GAME:
        history.push(`/game/${route.gameId}`, state);
        break;
      case RouteNames.SELECT_PROFILE:
        if (route.clearHistory) {
          clearHistory(history);
        }
        if (route.fromRegister) {
          history.push("/profiles?fromRegister");
        } else {
          history.push("/profiles");
        }
        break;
      case RouteNames.PLAY:
        if (route.clearHistory) {
          clearHistory(history);
        }
        const qs: string[] = [`origin=${route.origin}`];
        if (route.streamId) {
          qs.push(`streamId=${route.streamId}`);
        }
        history.push(`/game/${route.gameId}/play?${qs.join("&")}`, state);
        break;
      case RouteNames.PROFILE_UNLOCK:
        if (route.returnUrl) {
          history.push(`/profiles/${route.id}/pin?returnUrl=${route.returnUrl}`, state);
        } else {
          history.push(`/profiles/${route.id}/pin`, state);
        }
        break;
      case RouteNames.FORGOT_PASSWORD:
        history.push(`/password-reset`);
        break;
      case RouteNames.ADD_PROFILE:
        history.push(`/account/profiles/new`);
        break;
    }
  }
};

export const useReactRouter = <T extends unknown>() => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams();
  return {
    back: () => {
      history.goBack();
    },
    isScreenForeground: true,
    navigate: (r) => navigate(history, r),
    routeParams: params,
  } as WithRouterProps<T>;
};
