import React, { FunctionComponent } from "react";

export const Gauge: FunctionComponent = () => {
    return (
        <div>
            <svg width="263" height="132" viewBox="0 0 263 132" fill="none">
                <defs>
                    <linearGradient
                        id="Gradient-0"
                        x1="260"
                        y1="128.578"
                        x2="11.001"
                        y2="124.055"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#7ac70c" />
                        <stop offset=".484" stopColor="#f0da11" />
                        <stop offset=".995" stopColor="#ee220c" />
                    </linearGradient>
                </defs>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40 132.365c.02-50.645 40.978-91.694 91.5-91.694c50.522 0 91.48 41.049 91.5 91.694h33.506c3.735 0 6.677-3.15 6.485-6.89c-1.701-33.142-15.6-63.049-37.278-85.309l-0.001 .001c-23.909-24.553-57.282-39.802-94.212-39.802c-36.868 0-70.191 15.198-94.092 39.678c-21.747 22.273-35.695 52.228-37.399 85.432c-0.192 3.739 2.75 6.89 6.485 6.89h33.506Z"
                    fill="url(#Gradient-0)"
                    transform="translate(0,-0.365)"
                />
            </svg>
        </div>
    );
};
