import { StorageKey } from "@blacknut/react-client-core/lib";
import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";

let localeFromLocalStorage = localStorage.getItem(StorageKey.LOCALE);
if (localeFromLocalStorage) {
  localeFromLocalStorage = JSON.parse(localeFromLocalStorage);
}

let lng =
  Cookies.get("lang") ||
  localeFromLocalStorage ||
  navigator.language ||
  navigator["userLanguage"];
if (lng) {
  lng = lng.replace("_", "-");
}

const options = {
  // your settings here
  fallbackLng: "en",
  ns: ["app"],
  defaultNS: "app",
  debug: true,
  lng,
  resources: {
    fr: {
      app: require("@blacknut/react-client-core/assets/i18n/global.fr-FR.json"),
    },
    en: {
      app: require("@blacknut/react-client-core/assets/i18n/global.en-US.json"),
    },
    de: {
      app: require("@blacknut/react-client-core/assets/i18n/global.de-DE.json"),
    },
    th: {
      app: require("@blacknut/react-client-core/assets/i18n/global.th-TH.json"),
    },
    pt: {
      app: require("@blacknut/react-client-core/assets/i18n/global.pt-PT.json"),
    },
    es: {
      app: require("@blacknut/react-client-core/assets/i18n/global.es-ES.json"),
    },
    pl: {
      app: require("@blacknut/react-client-core/assets/i18n/global.pl-PL.json"),
    },
    "zh-TW": {
      app: require("@blacknut/react-client-core/assets/i18n/global.zh-HANT.json"),
    },
    "zh-HK": {
      app: require("@blacknut/react-client-core/assets/i18n/global.zh-HANT.json"),
    },
    "zh-HANT": {
      app: require("@blacknut/react-client-core/assets/i18n/global.zh-HANT.json"),
    },
    "zh-CN": {
      app: require("@blacknut/react-client-core/assets/i18n/global.zh-HANS.json"),
    },
    "zh-SG": {
      app: require("@blacknut/react-client-core/assets/i18n/global.zh-HANS.json"),
    },
    "zh-HANS": {
      app: require("@blacknut/react-client-core/assets/i18n/global.zh-HANS.json"),
    },
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
};

const instance = i18n.isInitialized ? i18n.cloneInstance(options) : i18n;
const instanceInited = instance.use(initReactI18next).init(
  options, // We must provide a function as second parameter, otherwise i18next errors
  (err, t) => {
    if (err) return console.log(err);
  },
);

export default instanceInited;
