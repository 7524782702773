import { logE } from "@blacknut/logging/dist";
import { deriveToken, State, StorageKey } from "@blacknut/react-client-core/lib";
import { useForceFocusOnMount } from "@blacknut/spatialnav-sdk/dist";
import { QRCodeSVG } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { firstValueFrom } from "rxjs";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { LOGGING_TAG } from "../../../utils/Utils";
import styles from "./styles.module.scss";

const AccountManage = () => {
  const { t } = useTranslation();
  const { accountUrl, userToken, familyToken } = useSelector(
    (state: State) => state.globalState,
  );
  useForceFocusOnMount("/");
  const theme = localStorage.getItem(StorageKey.THEME_BW) || "";
  const [uri, setUri] = useState<string>();
  useEffect(() => {
    const init = async () => {
      try {
        const derived = await firstValueFrom(deriveToken("profile"));
        const _uri = `${accountUrl}?fat=${derived.familyToken.accessToken}&frt=${derived.familyToken.refreshToken}&uat=${derived.userToken?.accessToken}&urt=${derived.userToken?.refreshToken}&theme=${theme}&frompwa=true`;
        setUri(_uri);
      } catch (e) {
        logE(LOGGING_TAG, "Caught error on token derivation", e);
        const _uri = `${accountUrl}?fat=${familyToken?.accessToken}&frt=${familyToken?.refreshToken}&uat=${userToken?.accessToken}&urt=${userToken?.refreshToken}&theme=${theme}&frompwa=true`;
        setUri(_uri);
      }
    };
    init();
  }, [accountUrl, familyToken, theme, userToken]);

  useForceFocusOnMount("/");
  return (
    <div className={styles.container}>
      <PageTitle title="accountManage" />
      <h2
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: t("account.itemstv.goWeb") }}
      />
      {uri && <QRCodeSVG value={uri} size={384} />}
    </div>
  );
};
export default AccountManage;
