import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import throttle from "lodash/throttle";

export declare type OrientationType = "PORTRAIT" | "LANDSCAPE";

export const isPortrait = () => {
  return window.innerHeight >= window.innerWidth;
};

export const OrientationContext = React.createContext<{
    orientation: OrientationType;
}>({
    orientation: isPortrait() ? "PORTRAIT" : "LANDSCAPE",
});

export const OrientationContextProvider = ({ children }: PropsWithChildren<unknown>) => {
    const [orientation, setOrientation] = useState<OrientationType>(isPortrait() ? "PORTRAIT" : "LANDSCAPE");
    const callback = useCallback(() => {
        setTimeout(() => {
            setOrientation(isPortrait() ? "PORTRAIT" : "LANDSCAPE");
        }, 200);
    }, []);
    useEffect(() => {
        const listener = throttle(callback, 200, {
            trailing: true,
        });
        window.addEventListener("resize", listener);
        return () => {
            window.removeEventListener("resize", listener);
        };
    }, [callback]);

    return (
        <OrientationContext.Provider
            value={{
                orientation,
            }}
        >
            {children}
        </OrientationContext.Provider>
    );
};

export const useOrientation = () => useContext(OrientationContext);
