import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import dimens from "src/theme/dimens";
import { Theme } from "src/theme/Theme";
import styled, { css } from "styled-components";

export const Container = styled.div<{ theme: Theme }>`
  padding: ${dimens.margins.DarkRed}rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 13rem;

  svg,
  img {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    padding: ${dimens.margins.Green}rem;

    svg,
    img {
      width: 9rem;
      height: 9rem;
    }
  }
`;

const ActiveState = css`
  background-color: ${(props) => props.theme.profileCardStyle.activeBackgroundColor};
  color: ${(props) => props.theme.profileCardStyle.activeTextColor};
  transform: ${(props) =>
    props.theme.profileListItemCardStyle.scaleFactor
      ? `scale(${props.theme.profileListItemCardStyle.scaleFactor})`
      : undefined};
  box-shadow: 0 0 1.2rem ${(props) => props.theme.profileCardStyle.activeBackgroundColor};
`;

// Required since ripple has an overflow hidden style
export const Hover = styled(Focusable)<{
  theme: Theme;
  spatialNavigationActive: boolean;
  $isActive?: boolean;
}>`
  border: 0.2rem solid
    ${({ theme, $isActive }) =>
      $isActive
        ? theme.profileCardStyle.activeBorderColor
        : theme.profileCardStyle.inactiveBorderColor};
  border-radius: ${(props) => props.theme.profileCardStyle.radius}rem;
  color: ${(props) => props.theme.profileCardStyle.inactiveTextColor};
  background-color: ${(props) => props.theme.profileCardStyle.inactiveBackgroundColor};
  transition: transform 0.2s; /* Animation */

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.profileCardStyle.activeBorderColor};
    ${(props) => (props.spatialNavigationActive ? ActiveState : "")}
  }
  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : ActiveState)}
    }
  }
`;
