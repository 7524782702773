import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";

interface Style {
    [key: string]: React.CSSProperties;
}

export const useSpeedtestSyle = (theme: Theme): Style => ({
    container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
        maxWidth: "59rem",
    },
    detailsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
        color: theme.textStyle.color,
        margin: `${dimens.margins.Orange}rem 0`,
    },
    detail: {
        display: "flex",
        flexDirection: "column",
        borderRadius: `${theme.cardStyle.radius}rem`,
        width: "11rem",
        height: "11rem",
        backgroundColor: theme.speedTestStyle.colors?.unknown,
        justifyContent: "center",
        alignItems: "center",
    },
    detailTitle: {
        textAlign: "center",
        fontSize: "1.2rem",
    },
    detailValue: {
        fontSize: "2rem",
        fontWeight: 600,
        margin: ".5rem 0 -.2rem",
    },

    detailUnit: {
        fontSize: "1.2rem",
        color: theme.textStyle2.color,
    },
    detailOK: {
        backgroundColor: theme.speedTestStyle.colors?.ok,
        color: "#000",
    },
    detailWarn: {
        backgroundColor: theme.speedTestStyle.colors?.warn,
        color: "#000",
    },
    detailBad: {
        backgroundColor: theme.speedTestStyle.colors?.bad,
        color: "#000",
    },
    errorMessage: {
        borderRadius: `${theme.cardStyle.radius}rem`,
        padding: `${dimens.margins.Green}rem`,
        backgroundColor: theme.speedTestStyle.colors?.warn,
    },
    disclaimer: {
        color: theme.textStyle2.color,
        textAlign: "center",
        fontSize: "1.2rem",
        marginTop: `${dimens.margins.Orange}rem`,
    },

    btn: {
        minWidth: "22rem",
    },
});
