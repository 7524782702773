import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import styles from "./styles.module.scss";
import React from "react";
const ListItem = (props: { name: string; value: string; onClick?: () => void }) => {
  const { name, value, onClick } = props;

  return (
    <Focusable
      component="li"
      className={clsx([styles.item, !props.onClick && styles.disabled])}
      onClick={onClick}
      onPressEnter={onClick}
    >
      <div className={styles.name}>{name}</div>
      <div>{value}</div>
    </Focusable>
  );
};

export default ListItem;
