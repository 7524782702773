import { logD, logW } from "@blacknut/logging/dist";
import { StorageKey, analyticsService } from "@blacknut/react-client-core/lib";
import { useEffect } from "react";
import { LOGGING_TAG } from "./Utils";
const TAG = "Perf";
const usePerformanceMonitoring = () => {
  useEffect(() => {
    if (!("PerformanceObserver" in window)) {
      logD(TAG, "No PerformanceMonitoring Api");
      return;
    }

    if (!localStorage.getItem(StorageKey.PERFORMANCE_MONITORING)) {
      logD(TAG, "PerformanceMonitoring disabled");
      return;
    }
    try {
      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          if (
            !entry.name.match(/datadoghq/) &&
            !entry.name.match("https://a.blacknut.com")
          ) {
            analyticsService.trackEvent("timing", entry.toJSON());
          }
        });
      });

      observer.observe({ type: "resource", buffered: true });
      return () => {
        observer.disconnect();
      };
    } catch (e) {
      logW(LOGGING_TAG, "Caught error on performance observer: %o", e);
      return () => {
        //Nop
      };
    }
  }, []);
};
export { usePerformanceMonitoring };
export default usePerformanceMonitoring;
