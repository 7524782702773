import {
  useRemoteNotifications,
  RemoteNotification,
  RemoteNotificationTrigger,
  State,
} from "@blacknut/react-client-core/lib";
import { FocusableSection, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ReactComponent as Delete } from "../../assets/dist/ic_delete_filled.svg";
import { ReactComponent as DeleteActive } from "../../assets/dist/ic_delete_filled_active.svg";
import { ReactComponent as Empty } from "../../assets/dist/placeholder_list_empty.svg";
import IconButton from "../../components/Button/IconButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useSubscriptionRedirect } from "../../components/SubscriptionRedirect/SubscriptionRedirect";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;

    margin: auto;
  }
`;

const Desc = styled.div`
  margin-right: ${dimens.margins.Green}rem;
`;

const ListItem = styled.li<{
  variant: "flat" | "card";
  isFirst?: boolean;
  isLast?: boolean;
}>`
  padding: ${dimens.margins.Green}rem ${dimens.margins.LightGreen}rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${dimens.margins.Green}rem;
  color: ${(props: { theme: Theme }) => props.theme.listStyle.textColor};
  &:last-child {
    margin-bottom: 0;
  }

  border-top-left-radius: ${(props) =>
    props.isFirst && props.variant === "card"
      ? props.theme.listStyle.radius
      : undefined}rem;
  border-top-right-radius: ${(props) =>
    props.isFirst && props.variant === "card"
      ? props.theme.listStyle.radius
      : undefined}rem;
  border-bottom-left-radius: ${(props) =>
    props.isLast && props.variant === "card"
      ? props.theme.listStyle.radius
      : undefined}rem;
  border-bottom-right-radius: ${(props) =>
    props.isLast && props.variant === "card"
      ? props.theme.listStyle.radius
      : undefined}rem;
  background: ${(props: { theme: Theme }) => props.theme.listStyle.backgroundColor};
`;

const List = styled.ul`
  background: ${(props: { theme: Theme }) => props.theme.listStyle.backgroundColor};
  border-radius: ${(props: { theme: Theme }) => props.theme.listStyle.radius}rem;
  list-style: none;
  padding: 0;
  margin: 0;
`;
const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  flex-shrink: 0;
  svg {
    fill: ${(props: { theme: Theme }) => props.theme.rippleColor};
    width: 3.2rem;
    height: 3.2rem;
  }
  svg:last-child {
    display: none;
  }
  @media (hover: hover) {
    &:hover {
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
  }
`;
const StyledImage = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  object-fit: cover;
  margin-right: ${dimens.margins.DarkRed}rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24rem;
  color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
  & > span {
    margin-top: ${dimens.margins.Green}rem;
  }

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: 40rem;
  }
`;

const RemoteNotificationListItem = (props: {
  notification: RemoteNotification;
  variant?: "flat" | "card";
  isFirst?: boolean;
  isLast?: boolean;
}) => {
  const { notification, variant = "flat", isFirst, isLast } = props;
  const history = useHistory();
  const { markAsRead } = useRemoteNotifications();
  const { theme } = useTheme();

  const { redirectToSubscription } = useSubscriptionRedirect();
  const { profile } = useSelector((state: State) => state.profilesState);

  const onClick = () => {
    if (notification.trigger === RemoteNotificationTrigger.INVALID_SUBSCRIPTION) {
      if (profile?.isMaster) {
        redirectToSubscription();
      }
    } else if (notification.link) {
      if (notification.link.startsWith("http")) {
        window.open(notification.link, "_blank");
      } else {
        history.push(notification.link.replace("blacknut://", "/"));
      }
    }
  };
  const onDelete = (e?: React.UIEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation();
    }
    markAsRead(notification);
  };

  const MyContent = (
    <>
      {notification.image && <StyledImage src={notification.image.url} />}
      <Desc>{notification.description}</Desc>
      <StyledIconButton onClick={onDelete} theme={theme} testID="delete">
        <Delete />
        <DeleteActive />
      </StyledIconButton>
    </>
  );

  return (
    <ListItem
      theme={theme}
      onClick={onClick}
      variant={variant}
      isFirst={isFirst}
      isLast={isLast}
    >
      {MyContent}
    </ListItem>
  );
};
export { RemoteNotificationListItem };

const RemoteNotificationsPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { notifications } = useRemoteNotifications();

  const renderNotification = useCallback((p: RemoteNotification) => {
    return <RemoteNotificationListItem notification={p} key={p.id} />;
  }, []);

  const { resume: resumeSpatialNav } = useSpatialNavigation();

  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  return (
    <Container>
      <PageTitle title={t("notifications.title")} />
      {notifications && notifications.length > 0 && (
        <FocusableSection focusKey="notifications" leaveFor={{ up: "", down: "" }}>
          <List theme={theme}>{notifications.map(renderNotification)}</List>
        </FocusableSection>
      )}
      {notifications && notifications.length === 0 && (
        <EmptyContainer theme={theme}>
          <Empty />
          <span>{t("notifications.empty")}</span>
        </EmptyContainer>
      )}
    </Container>
  );
};
export default RemoteNotificationsPage;
