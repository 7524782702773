import { createLightTheme } from "../default/Light";

const M1Theme = createLightTheme({ colorAccent: "#FF9E1B", textColor: "#000000" });
M1Theme.default.backgroundColor = "#F2F2F2";

M1Theme.default.defaultFontFamily = "Regular";
M1Theme.default.logoSize = { width: 8.7, height: 2.4 };

M1Theme.default.playButtonStyle = {
  ...M1Theme.default.playButtonStyle,
  radiusSm: 0.6,
  radiusLg: 0.6,
};

M1Theme.default.primaryButton = {
  ...M1Theme.default.primaryButton,
  borderRadius: 0.6,
};
M1Theme.default.secondaryButton = {
  ...M1Theme.default.secondaryButton,
  borderRadius: 0.6,
};

M1Theme.default.secondaryButton = {
  ...M1Theme.default.secondaryButton,
  borderRadius: 0.6,
};
M1Theme.default.toggleButton = {
  ...M1Theme.default.toggleButton,
  borderRadius: 0.6,
};
M1Theme.default.search = {
  ...M1Theme.default.search,
  toggleButtonStyle: {
    ...M1Theme.default.search.toggleButtonStyle,
    borderRadius: 0.6,
  },
};
M1Theme.default.tileCardStyle = {
  ...M1Theme.default.tileCardStyle,
  radius: M1Theme.default.cardStyle.radius,
};

M1Theme.phone = {
  ...M1Theme.phone,
  search: {
    ...(M1Theme.phone?.search || M1Theme.default.search),
    toggleButtonStyle: {
      ...(M1Theme.phone?.search || M1Theme.default.search).toggleButtonStyle,
      borderRadius: 0.6,
    },
  },
};

export default M1Theme;
