import { logD } from "@blacknut/logging/dist";
import { List as ListModel } from "@blacknut/react-client-core/lib";
import { getImageByThemeName } from "@blacknut/react-client-core/lib/models/Image";
import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { useInView } from "react-intersection-observer";
import { useTheme } from "../../../../theme/ThemeProvider";
import { LOGGING_TAG } from "../../../../utils/Utils";
import HorizontalTileList from "../../../List/HorizontalTileList";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface BrandedViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: ListModel;
  leaveFor: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  scrollable?: boolean;
  className?: string;
}

const BrandedView = ({
  list,
  leaveFor,
  onFocus,
  scrollable,
  className,
}: BrandedViewProps) => {
  logD(LOGGING_TAG, "BrandedView");
  const { theme } = useTheme();
  const [brandedContainerRef, inView] = useInView({
    threshold: 0,
  });

  let sectionTitleColor: string | undefined;

  // Get the first image
  const backgroundImage = getImageByThemeName(theme.name, list.imgBackground ?? []);

  if (backgroundImage) {
    if (backgroundImage.brandedOption === "brandedDark") {
      sectionTitleColor = "#191414";
    } else if (backgroundImage.brandedOption === "brandedLight") {
      sectionTitleColor = "#fff";
    }
  }
  const style = backgroundImage
    ? { backgroundImage: `url(${backgroundImage.url})` }
    : undefined;
  return (
    <div
      ref={brandedContainerRef}
      style={inView ? style : undefined}
      key={list.uuid}
      className={clsx(styles.container, className)}
    >
      <HorizontalTileList
        list={list}
        sectionTitleColor={sectionTitleColor}
        leaveFor={leaveFor}
        inView={inView}
        onFocus={onFocus}
        className={styles.brandedViewList}
        arrowClassName={styles.arrow}
        isBranded={true}
        scrollable={scrollable}
      />
    </div>
  );
};

export default BrandedView;
