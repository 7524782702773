import {
  useSearch,
  useAnalytics,
  useLayout,
  usePrevious,
  GameController,
  AppLayout,
} from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  focusSectionWithPath,
  NavigationFailedEvent,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButton } from "../../components/Button/V2Button";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import List from "../../components/List/FullTileList";
import dimens, { mediaQueryDesktop } from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
import { TileWidthContext } from "../../utils/TileWidthProvider";
import { isM1Build, LOGGING_TAG } from "../../utils/Utils";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import SearchCriteria from "./SearchCriteria";
import { GameStatus, SearchFormData } from "@blacknut/react-client-core/lib";
import { logE } from "@blacknut/logging/dist";
import { BatchEventOpts, useBatch } from "../../utils/BatchContext";
import { SessionDuration } from "@blacknut/react-client-core/lib/models/SearchFormData";

const Container = styled(FocusableSection)<{ theme: Theme }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${(props) => props.theme.search?.backgroundColor};
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    overflow-y: hidden;
    height: calc(100vh - ${dimens.desktop.headerHeight}rem);
    flex-basis: calc(100vh - ${dimens.desktop.headerHeight}rem);
  }
`;

const Results = styled.div<{ standardTileSize: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  display: none;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    display: block;
    padding: 3.2rem 7.1rem 3.2rem 7.1rem;
    margin-left: ${(props) => 2 * props.standardTileSize}px;
  }
  overflow-y: auto;
`;

const CriteriaContainer = styled.div<{
  theme: Theme;
  standardTileSize: number;
  layout: AppLayout;
}>`
  flex-shrink: 0;
  padding: ${dimens.margins.LightGreen}rem;
  background-color: ${(props) =>
    props.theme.search?.searchCriteriaStyle?.backgroundColor ||
    props.theme.backgroundColor};
  overflow-y: auto;
  width: 100vw;

  @media (orientation: landscape) {
    width: calc(100vw - ${dimens.phoneLandscape.navigationBarWidth}rem);
    min-height: calc(100vh - ${dimens.phoneLandscape.headerHeight}rem);
    /* position: fixed; */
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    padding: ${dimens.margins.LightGreen}rem ${dimens.margins.Orange}rem
      ${dimens.margins.LightGreen}rem ${dimens.margins.Yellow}rem;
    background-color: ${(props) =>
      props.theme.search?.searchCriteriaStyle?.backgroundColor ||
      props.theme.bottomBarStyle.backgroundColor};
    width: ${(props) => 2 * props.standardTileSize}px;
    height: ${(props) =>
      props.layout === AppLayout.TV
        ? `100vh`
        : `calc(100vh - ${dimens.desktop.headerHeight}rem)`};
    position: fixed;
  }
`;

const ResultTitle = styled.h2<{ theme: Theme }>`
  margin: 0 0 ${dimens.margins.DarkRed}rem 0;
  font-weight: ${(props) => props.theme.sectionTextStyle.fontWeight};
  font-family: ${(props) => props.theme.sectionTextStyle.fontFamily};
  text-align: left;
  align-self: stretch;
  color: ${(props) => props.theme.textStyle.color};
`;

const MainLoading = styled(CircularProgress)`
  margin: 0 auto;
`;

const StyledSubmit = styled(PrimaryButton)`
  display: block;
  margin-top: ${dimens.margins.LightGreen}rem;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    display: none;
  }
`;
const SearchPageNew = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    inProgress,
    genresLoadingError,
    dismissGenresLoadingError,
    dismissSearchError,
    error,
    paginate,
    paginating,
    genres,
    results,
    query,
    search,
  } = useSearch({
    limit: 50,
    sortGenres: isM1Build(),
  });
  const layout = useLayout();
  const { active: spatialNavActive, resume: resumeSpatialNav } = useSpatialNavigation();
  useErrorHandling({ error, clearError: dismissSearchError });
  useErrorHandling({ error: genresLoadingError, clearError: dismissGenresLoadingError });

  const { trackSearchView, trackSearch, trackSearchResultsEmpty } = useAnalytics();
  const { trackEvent } = useBatch();

  const [data, setData] = useState<SearchFormData>(
    query
      ? { ...query }
      : {
          sessionDurations: [],
          controllers: [],
          genres: [],
          name: "",
          statuses: [GameStatus.LIVE],
        },
  );

  const onChange = React.useCallback((data: SearchFormData) => {
    setData(data);
  }, []);

  const _trachSearch = React.useCallback(
    (data: SearchFormData) => {
      trackSearch(data);

      const opts: BatchEventOpts = {
        Multiplayer: !!data.multiplayer,
        Gamepads: data.controllers?.indexOf(GameController.GAMEPAD) !== -1,
      };
      if (data.age && data.age.length > 0) {
        opts["Rating"] = data.age[0];
      }
      if (data.sessionDurations && data.sessionDurations.length > 0) {
        switch (data.sessionDurations[0]) {
          case SessionDuration.LESS_10:
            opts["Duration"] = "1";
            break;
          case SessionDuration.BETWEEN_10_30:
            opts["Duration"] = "2";
            break;
          case SessionDuration.MORE_30:
            opts["Duration"] = "3";
            break;
        }
      }

      trackEvent(
        "finished_search",
        opts,
        data.genres && data.genres.length > 0
          ? data.genres.map((d) => d.title)
          : undefined,
      );
    },
    [trackEvent, trackSearch],
  );
  const onSubmit = React.useCallback(async () => {
    try {
      await search(data);

      _trachSearch(data);

      if (!mediaQueryDesktop.matches) {
        history.push("/search/results");
      }
      if (spatialNavActive) {
        focusSectionWithPath("/search");
      }
    } catch (e) {
      if (e !== "canceled") {
        logE(LOGGING_TAG, "Caught error on search", e);
      }
    }
  }, [_trachSearch, data, history, search, spatialNavActive]);

  React.useEffect(() => {
    trackSearchView();
  }, [trackSearchView]);

  React.useEffect(() => {
    trackEvent("search_launched");
  }, [trackEvent]);

  React.useEffect(() => {
    const init = async () => {
      if (mediaQueryDesktop.matches) {
        try {
          await search(data);
          _trachSearch(data);
        } catch (e) {
          if (e !== "canceled") {
            logE(LOGGING_TAG, "Caught error on search", e);
          }
        }
      }
    };
    init();
  }, [data, search, _trachSearch, theme, trackEvent]);

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  React.useEffect(() => {
    setHeaderTitle(t("search.title"));
    setHeaderLeft(<HeaderBackButton title={t("search.title")} />);
    setHeaderRight(undefined);
  }, [layout, setHeaderLeft, setHeaderRight, setHeaderTitle, t]);

  React.useEffect(() => {
    resumeSpatialNav();
    if (spatialNavActive) {
      focusSectionWithPath("/search");
    }
  }, [spatialNavActive, genres, resumeSpatialNav]);

  const onNavigationFailed = React.useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "up") {
      document.documentElement.scroll({ top: 0 });
    } else if (e.detail.direction === "down") {
      document.documentElement.scroll({ top: document.body.scrollHeight });
    }
  }, []);

  React.useEffect(() => {
    if (results && results.total === 0) {
      trackSearchResultsEmpty();
    }
  }, [results, trackSearchResultsEmpty]);
  return (
    <Container
      theme={theme}
      focusKey="search"
      enterTo="default-element"
      defaultElement={{ down: "first" }}
      onNavigationFailed={onNavigationFailed}
    >
      <TileWidthContext.Consumer>
        {({ standardTileSize }) => (
          <>
            <CriteriaContainer
              theme={theme}
              standardTileSize={standardTileSize.width}
              layout={layout}
            >
              <ScrollRestorer id="criteria" />

              <SearchCriteria
                data={data}
                genres={genres}
                onSubmit={onSubmit}
                onChange={onChange}
              />

              <StyledSubmit theme={theme} onClick={onSubmit} type="submit">
                {t("buttons.search")}
              </StyledSubmit>
            </CriteriaContainer>

            <Results id="results" standardTileSize={standardTileSize.width}>
              <ScrollRestorer id="results" />
              {inProgress && <MainLoading size={50} />}
              {!inProgress && (
                <>
                  {results && results.total === 0 && (
                    <ResultTitle theme={theme}>{t("search.results.none")}</ResultTitle>
                  )}
                  {results && results.total === 1 && (
                    <ResultTitle theme={theme}>{t("search.results.single")}</ResultTitle>
                  )}
                  {results && results.total > 1 && (
                    <ResultTitle theme={theme}>
                      {t("search.results.multi", { count: results.total })}
                    </ResultTitle>
                  )}
                  {results && (
                    <List
                      list={results}
                      numColumns={4}
                      showTitle={false}
                      paginate={paginate}
                      paginating={paginating}
                    />
                  )}
                </>
              )}
            </Results>
          </>
        )}
      </TileWidthContext.Consumer>
    </Container>
  );
};

export default SearchPageNew;
