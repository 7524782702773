import { setFamilyToken, setUserToken } from "@blacknut/react-client-core/lib";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
/** SMG PROMO : listen to message to set token provided by the promo app */
const useToken = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onMessage = (e: MessageEvent) => {
      let data = e.data;
      if (data && typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error("Caught error parsing data", data, e);
        }
        if (data) {
          switch (data.message) {
            case "setToken":
              if (data.payload?.familyToken) {
                setFamilyToken(data.payload.familyToken)(dispatch);
                if (data.payload.userToken) {
                  setUserToken(data.payload.userToken)(dispatch);
                }
                window.location.reload();
              }
          }
        }
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [dispatch]);
};

export default useToken;
