import { UserProfile } from "@blacknut/react-client-core/lib";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import AvatarImage from "../AvatarSelectionModal/AvatarImage";
import Ripple, { RippleType } from "../Ripple";

export interface ProfileProps {
  profile: UserProfile;
  onClick: (e: React.UIEvent<HTMLElement>) => void;
  className?: string;
  renderNickname?: () => JSX.Element | null;
}

const ActiveState = css`
  color: ${(props: { theme: Theme }) =>
    props.theme.profileListItemCardStyle.activeTextColor};
  transform: ${(props) =>
    props.theme.profileListItemCardStyle.scaleFactor
      ? `scale(${props.theme.profileListItemCardStyle.scaleFactor})`
      : undefined};
  background-color: ${(props: { theme: Theme }) =>
    props.theme.profileListItemCardStyle.activeBackgroundColor};
  box-shadow: 0 0 1.2rem
    ${(props) => props.theme.profileListItemCardStyle.activeBackgroundColor};
`;

const Container = styled(Focusable)<{ theme: Theme; spatialNavigationActive: boolean }>`
  border: 0.2rem solid transparent;
  color: ${(props: { theme: Theme }) =>
    props.theme.profileListItemCardStyle.inactiveTextColor};

  background-color: ${(props: { theme: Theme }) =>
    props.theme.profileListItemCardStyle.inactiveBackgroundColor};
  border-radius: ${(props: { theme: Theme }) =>
    props.theme.profileListItemCardStyle.radius}rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  color: ${(props: { theme: Theme }) =>
    props.theme.profileListItemCardStyle.inactiveTextColor};
  svg,
  img {
    width: 3.6rem;
    height: 3.6rem;
    margin-right: ${dimens.margins.Green}rem;
  }
  cursor: pointer;
  transition: transform 0.2s; /* Animation */
  @media (hover: hover) {
    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : ActiveState)}
    }
  }

  @media screen and (min-width: 1023px) {
    svg,
    img {
      width: 6rem;
      height: 6rem;
    }
  }
  &:focus {
    ${ActiveState}
  }
`;

const NickName = styled.div`
  flex: 1;
  word-break: break-all;
`;

const StyledRipple = styled(Ripple)`
  padding: ${dimens.margins.Green}rem;
  flex: 1;
  .content {
    display: flex;
    align-items: center;
  }
`;

const Profile = ({
  onClick,
  className,
  profile,
  children,
  renderNickname,
}: React.PropsWithChildren<ProfileProps>) => {
  const { theme } = useTheme();
  const { active: spatialNavigationActive } = useSpatialNavigation();

  const rippleRef = React.useRef<RippleType>(null);
  const _onClick = React.useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      rippleRef.current?.ripple(e, onClick);
    },
    [onClick],
  );
  return (
    <Container
      theme={theme}
      onClick={_onClick}
      className={className}
      data-testid={`profile-${profile.id}`}
      spatialNavigationActive={spatialNavigationActive}
      onPressEnter={_onClick}
    >
      <StyledRipple
        ref={rippleRef}
        rippleStyle={{
          rippleColor: "#dcdcdc",
          radius: theme.profileListItemCardStyle.radius,
        }}
        contentClassName="content"
      >
        {profile.avatarId && <AvatarImage avatarId={profile.avatarId} />}

        {!profile.avatarId && <AvatarDefault />}

        {!renderNickname && <NickName>{profile.nickname}</NickName>}

        {children}
      </StyledRipple>
    </Container>
  );
};
export default Profile;
