import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/ThemeProvider";
import {
  SubscriptionKindProps,
  SubscriptionSubStatus,
  UnsubscribeBtn,
  UnsubscribeSpinner,
} from "./SubscriptionKind";
import React from "react";

const SubscriptionAis = ({
  onUnsubscribePressed,
  unsubscribing,
}: SubscriptionKindProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <SubscriptionSubStatus theme={theme}>
        {t("account.subscription.ais.text2")}
      </SubscriptionSubStatus>
      <UnsubscribeBtn
        theme={theme}
        onClick={onUnsubscribePressed}
        disabled={unsubscribing}
      >
        <span>{t("buttons.unsubscribe")}</span>
        {unsubscribing && <UnsubscribeSpinner theme={theme} />}
      </UnsubscribeBtn>
    </>
  );
};
export default SubscriptionAis;
