import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { useSpring } from "react-spring";
import { Gauge } from "./Gauge";
import { Needle } from "./Needle";

const Container = styled.div`
    display: flex;
    position: relative;
    height: 150;
    align-items: center;
    justify-content: center;
`;

/**
 * source https://easings.net/#easeOutBounce
 * @param {number} x
 * @returns {number}
 */
const easeOutBounce = (x: number): number => {
    const n1 = 7.5625;
    const d1 = 2.75;

    if (x < 1 / d1) {
        return n1 * x * x;
    } else if (x < 2 / d1) {
        return n1 * (x -= 1.5 / d1) * x + 0.75;
    } else if (x < 2.5 / d1) {
        return n1 * (x -= 2.25 / d1) * x + 0.9375;
    } else {
        return n1 * (x -= 2.625 / d1) * x + 0.984375;
    }
};

/**
 * source: https://easings.net/#easeInOutBounce
 * @param {number} x
 * @returns {number}
 */
const easeInOutBounce = (x: number): number => {
    return x < 0.5 ? (1 - easeOutBounce(1 - 2 * x)) / 2 : (1 + easeOutBounce(2 * x - 1)) / 2;
};

export interface SpeedTestAnimatedGaugeProps {
    quality: number;
}

export const SpeedTestAnimatedGauge: FunctionComponent<SpeedTestAnimatedGaugeProps> = ({ quality }) => {
    const { number } = useSpring({
        from: {
            number: 0,
        },
        to: {
            number: 1,
        },
        config: {
            duration: 2000,
        },
    });

    const needleStyle: React.CSSProperties = {
        position: "absolute",
        transition: "all 1s",
    };

    return (
        <Container>
            <Gauge />
            <Needle
                style={{
                    ...needleStyle,
                    transform: number.to(
                        (n) =>
                            `translateY(60px) rotate(${-90 + easeInOutBounce(n) * quality * 1.8}deg) translateY(-60px)`,
                    ) as any,
                }}
            />
        </Container>
    );
};
