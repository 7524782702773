import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as SamsungLogo } from "../../assets/dist/store_samsung.svg";
import { ReactComponent as SamsungLogoLight } from "../../assets/dist/store_samsung_dark.svg";
import dimens from "../../theme/dimens";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionSubStatus } from "./SubscriptionKind";

const StyledSamsungLogo = styled(SamsungLogo)`
    width: 20rem;
    display: block;

    margin: ${dimens.margins.Yellow}rem auto ${dimens.margins.LightGreen}rem auto;
`;

const StyledSamsungLogoLight = styled(SamsungLogoLight)`
    width: 20rem;
    display: block;

    margin: ${dimens.margins.Yellow}rem auto ${dimens.margins.LightGreen}rem auto;
`;

const SubscriptionSamsung = () => {
    const { theme } = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <SubscriptionSubStatus theme={theme}>
                {t("account.subscription.samsung.text2", { appName: process.env.REACT_APP_APP_NAME })}
            </SubscriptionSubStatus>
            {theme.name === "light" && <StyledSamsungLogo />}
            {theme.name === "dark" && <StyledSamsungLogoLight />}
        </>
    );
};
export default SubscriptionSamsung;
