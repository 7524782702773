import { Config, User } from "@blacknut/javascript-sdk/dist";
import ElectronService from "../ElectronService";

export const startSpeedtest = (config: Config, user?: User) => {
  if (ElectronService.isAvailable()) {
    import("./SpeedTestElectronService").then((e) => {
      e.default.start(config);
    });
    return;
  }
  // web
  if (window.Worker && process.env.NODE_ENV !== "test" && user?.powerUser) {
    import("./SpeedTestWebWorkerService").then((e) => e.default.start(config));
    return;
  }
  import("./SpeedTestWebService").then((e) => e.default.start(config));
  return;
};

export const runSpeedtest = (config: Config, user?: User) => {
  if (ElectronService.isAvailable()) {
    import("./SpeedTestElectronService").then((e) => {
      e.default.run(config);
    });
    return;
  }
  // web
  if (window.Worker && process.env.NODE_ENV !== "test" && user?.powerUser) {
    import("./SpeedTestWebWorkerService").then((e) => e.default.run(config));
    return;
  }
  import("./SpeedTestWebService").then((e) => e.default.run(config));
  return;
};
