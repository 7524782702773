import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionKindProps, UnsubscribeBtn, UnsubscribeSpinner } from "./SubscriptionKind";

const SubscriptionStripe = ({ onUnsubscribePressed, unsubscribing }: SubscriptionKindProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    return (
        <UnsubscribeBtn theme={theme} onClick={onUnsubscribePressed} disabled={unsubscribing}>
            <span>{t("buttons.unsubscribe")}</span>
            {unsubscribing && <UnsubscribeSpinner theme={theme} />}
        </UnsubscribeBtn>
    );
};
export default SubscriptionStripe;
