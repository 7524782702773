import { List as ListModel } from "@blacknut/react-client-core/lib";
import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { useInView } from "react-intersection-observer";
import { LOGGING_TAG } from "../../../utils/Utils";
import HorizontalTileList from "../../List/HorizontalTileList";
import { logD } from "@blacknut/logging/dist";

interface FeaturedViewProps
  extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: ListModel;
  leaveFor: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
}
const FeaturedView = ({ list, leaveFor, onFocus }: FeaturedViewProps) => {
  logD(LOGGING_TAG, "Render featured");
  const [tileRef, inView] = useInView({
    threshold: 0,
  });
  return (
    <div ref={tileRef}>
      <HorizontalTileList
        list={list}
        key={list.uuid}
        leaveFor={leaveFor}
        inView={inView}
        onFocus={onFocus}
      />
    </div>
  );
};

export default FeaturedView;
