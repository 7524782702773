import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import React from "react";
import Modal, { ModalProps } from "../Modal";
import styles from "./styles.module.scss";

export interface Option {
  label: string;
  key: string;
  icon?: React.FC<unknown>;
  hoveredIcon?: React.FC<unknown>;
  activeIcon?: React.FC<unknown>;
}

const OptionModal = (
  props: ModalProps & {
    options: Option[];
    onSelectOption: (opt: Option) => void;
    selected?: string;
  },
) => {
  return (
    <Modal {...props} addOKButton={false}>
      <ul className={styles.list}>
        {props.options.map((opt) => {
          const onClick = () => props.onSelectOption(opt);
          const Icon =
            props.selected === opt.key && opt.activeIcon ? opt.activeIcon : opt.icon;
          return (
            <Focusable
              component="li"
              className={clsx([
                styles.item,
                props.selected === opt.key && styles.active,
              ])}
              key={opt.key}
              onClick={onClick}
              onPressEnter={onClick}
            >
              <div className={opt.hoveredIcon && styles.icon}>{Icon && <Icon />}</div>
              {opt.hoveredIcon && (
                <div className={styles.hoveredIcon}>{<opt.hoveredIcon />}</div>
              )}
              <span>{opt.label}</span>
            </Focusable>
          );
        })}
      </ul>
    </Modal>
  );
};

export default OptionModal;
