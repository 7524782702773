import { logI } from "@blacknut/logging/dist";
import { useEffect } from "react";
import electronService, {
  ElectronEvent,
  ElectronEventType,
} from "../services/ElectronService";
import { LOGGING_TAG } from "../utils/Utils";

const useDeviceInfo = () => {
  useEffect(() => {
    const sub = electronService.onElectronSubject().subscribe((e: ElectronEvent) => {
      const { event, data } = e;
      switch (event) {
        case ElectronEventType.onDeviceInfo:
          logI(LOGGING_TAG, "Device info %o", data);
          break;
      }
    });
    electronService.getDeviceInfo();
    return () => sub.unsubscribe();
  }, []);
};
export default useDeviceInfo;
