import { createLightTheme } from "../default/Light";

const AisTheme = createLightTheme({ colorAccent: "#B2D234" });

AisTheme.default.logoSize = { width: 15.7, height: 2.4 };

AisTheme.default.primaryButton = {
    ...AisTheme.default.primaryButton,
    activeTextColor: AisTheme.default.textColor,
    inactiveTextColor: AisTheme.default.textColor,
};

AisTheme.default.secondaryButton = {
    ...AisTheme.default.secondaryButton,
    activeTextColor: AisTheme.default.textColor,
    inactiveTextColor: AisTheme.default.textColor,
};

AisTheme.default.game = {
    ...AisTheme.default.game,
    favoriteButtonStyle: {
        ...AisTheme.default.secondaryButton,
        inactiveTextColor: "#B2D234",
    },
};

export default AisTheme;
