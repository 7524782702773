import {
  Device,
  Game,
  GameController,
  AppLayout,
} from "@blacknut/react-client-core/lib";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Keyboard } from "../../assets/dist/ic_keyboard_mouse_game_details.svg";
import { ReactComponent as Mouse } from "../../assets/dist/ic_mouse.svg";
import { ReactComponent as PEGI_12 } from "../../assets/dist/ic_12years_game_details.svg";
import { ReactComponent as PEGI_16 } from "../../assets/dist/ic_16years_game_details.svg";
import { ReactComponent as PEGI_18 } from "../../assets/dist/ic_18years_game_details.svg";
import { ReactComponent as PEGI_3 } from "../../assets/dist/ic_3years_game_details.svg";
import { ReactComponent as PEGI_7 } from "../../assets/dist/ic_7years_game_details.svg";
import { ReactComponent as DeviceComputer } from "../../assets/dist/ic_desktop_game_details.svg";
import { ReactComponent as Gamepad } from "../../assets/dist/ic_gamepad_game_details.svg";
import { ReactComponent as Touch } from "../../assets/dist/ic_mobile_game_details.svg";
import { ReactComponent as TouchScreen } from "../../assets/dist/ic_touch_game_details.svg";
import { ReactComponent as MultiPlayer } from "../../assets/dist/ic_multi_game_details.svg";
import { ReactComponent as GamepadPartial } from "../../assets/dist/ic_partial_gamepad_game_details.svg";
import { ReactComponent as Remote } from "../../assets/dist/ic_remote_game_details.svg";
import { ReactComponent as SinglePlayer } from "../../assets/dist/ic_solo_game_details.svg";
import { ReactComponent as DeviceTV } from "../../assets/dist/ic_tv_game_details.svg";
import dimens from "../../theme/dimens";
import { useTheme } from "src/theme/ThemeProvider";
import { Theme } from "src/theme/Theme";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`;
const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: ${dimens.margins.DarkRed}rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const RatingIconContainer = styled.div<{ theme: Theme }>`
  display: flex;

  svg {
    color: ${(props) => props.theme.cardStyle.inactiveTextColor};
    @media screen and (min-width: ${dimens.breakpoints.tablet}px) {
      margin: 0 0.5rem;
    }
    @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
      color: #fff;
      max-height: 3.2rem;
      max-width: 3.2rem;
    }
    max-height: 2.4rem;
    max-width: 2.4rem;
  }
`;

const MobileIcon = styled(Touch)`
  max-height: 3.2rem;
`;

const Text = styled.span<{ theme: Theme }>`
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    color: #fff;
  }
  color: ${(props) => props.theme.cardStyle.inactiveTextColor};
  font-size: 1.2rem;
  margin-top: 0.4rem;
`;

const Separator = styled.div<{ theme: Theme }>`
  height: 1.2rem;
  width: 0.1rem;
  margin: 1rem ${dimens.margins.DarkRed}rem;
  flex-shrink: 0;
  background-color: #ffffff40;
  @media screen and (min-width: 1023px) {
    background-color: #ffffff80;
    margin: 1rem ${dimens.margins.Green}rem;
  }
`;

// FIXME check if it can be merged with native part
const filterControllers = (
  inControllers: GameController[] | undefined,
  layout: AppLayout,
) => {
  const controllers: GameController[] = [];
  if (inControllers) {
    inControllers.map((controller) => {
      switch (controller) {
        case GameController.GAMEPAD:
          controllers.push(GameController.GAMEPAD);
          break;
        case GameController.PARTIAL_GAMEPAD:
          controllers.push(GameController.PARTIAL_GAMEPAD);
          break;
        case GameController.REMOTE_CONTROL:
          if (layout === AppLayout.TV) {
            controllers.push(GameController.REMOTE_CONTROL);
          }
          break;
        case GameController.KEYBOARD_MOUSE:
          if (layout === AppLayout.DESKTOP) {
            controllers.push(GameController.KEYBOARD_MOUSE);
          }
          break;
        case GameController.TOUCH_SCREEN:
          controllers.push(GameController.TOUCH_SCREEN);
          break;
        case GameController.MOUSE_ALONE:
          if (layout === AppLayout.DESKTOP) {
            controllers.push(GameController.MOUSE_ALONE);
          }
      }
    });
  }
  return controllers;
};

const filterDevices = (inDevice: Device[] | undefined) => {
  let devices: Device[] = [...(inDevice || [])];
  // Remove tablet if phone already set
  if (devices.indexOf(Device.PHONE) != -1) {
    devices = devices.filter((d) => d !== Device.TABLET);
  }

  return devices;
};

const GameInfo = (props: {
  game: Game;
  layout: AppLayout;
  className?: string;
  separatorClassName?: string;
}) => {
  const { game, layout, className, separatorClassName } = props;
  const { theme } = useTheme();
  const { t } = useTranslation();

  const rating = game.pegiRating || game.blacknutRating;

  const controllers = React.useMemo(
    () => filterControllers(game.controllers, layout),
    [game.controllers, layout],
  );
  const devices = React.useMemo(() => filterDevices(game.devices), [game.devices]);
  return (
    <Container className={className} id="containerSVG">
      <RatingContainer>
        <RatingIconContainer theme={theme} data-test="gamedetail-pegi">
          {rating === "3" && <PEGI_3 data-test="gamedetail-pegi-3" />}
          {rating === "7" && <PEGI_7 data-test="gamedetail-pegi-7" />}
          {rating === "12" && <PEGI_12 data-test="gamedetail-pegi-12" />}
          {rating === "16" && <PEGI_16 data-test="gamedetail-pegi-16" />}
          {rating === "18" && <PEGI_18 data-test="gamedetail-pegi-18" />}
        </RatingIconContainer>
        <Text theme={theme}>{t("game.age")}</Text>
      </RatingContainer>

      {controllers.length > 0 && (
        <Separator theme={theme} className={separatorClassName} />
      )}
      {controllers.length > 0 && (
        <RatingContainer>
          <IconsContainer>
            {controllers.map((c) => (
              <RatingIconContainer theme={theme} key={c}>
                {c === GameController.TOUCH_SCREEN && <TouchScreen />}
                {c === GameController.GAMEPAD && <Gamepad />}
                {c === GameController.PARTIAL_GAMEPAD && <GamepadPartial />}
                {c === GameController.KEYBOARD_MOUSE && <Keyboard />}
                {c === GameController.MOUSE_ALONE && <Mouse />}
                {c === GameController.REMOTE_CONTROL && <Remote />}
              </RatingIconContainer>
            ))}
          </IconsContainer>
          <Text theme={theme}>{t("game.controllers")}</Text>
        </RatingContainer>
      )}

      <Separator theme={theme} className={separatorClassName} />
      <RatingContainer>
        <RatingIconContainer theme={theme}>
          {game.multiPlayer && <MultiPlayer />}
          {!game.multiPlayer && <SinglePlayer />}
        </RatingIconContainer>
        <Text theme={theme}>{t("game.players")}</Text>
      </RatingContainer>

      {devices && devices.length > 0 && (
        <Separator theme={theme} className={separatorClassName} />
      )}

      {devices && devices.length > 0 && (
        <RatingContainer>
          <IconsContainer>
            {devices.map((d) => (
              <RatingIconContainer theme={theme} key={d} data-testid={d}>
                {d === "computer" && <DeviceComputer />}
                {d === "tv" && <DeviceTV />}
                {(d === "phone" || d === "tablet") && <MobileIcon />}
              </RatingIconContainer>
            ))}
          </IconsContainer>
          <Text theme={theme}>{t("game.devices")}</Text>
        </RatingContainer>
      )}
    </Container>
  );
};

export default React.memo(GameInfo);
