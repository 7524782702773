import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import React from "react";
import OptionModal, { Option } from "../../components/Modals/OptionModal";
import BoolOpts from "./BoolOpts";
import ListItem from "./ListItem";

const closeModal = (ref: React.MutableRefObject<ModalSubscription | undefined>) => {
  if (ref.current) {
    ref.current.remove();
    ref.current = undefined;
  }
};

const Stats4Nerds = (props: {
  stats4Nerds: boolean;
  onSelectStats4Nerds: (b: boolean) => void;
}) => {
  const { stats4Nerds, onSelectStats4Nerds } = props;
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();

  const _closeModal = React.useCallback(() => {
    closeModal(modalSubscription);
  }, []);

  const _onSelect = React.useCallback(
    (opt: Option) => {
      _closeModal();
      if (opt.key === "on" && !stats4Nerds) {
        onSelectStats4Nerds(true);
      } else if (opt.key === "off" && stats4Nerds) {
        onSelectStats4Nerds(false);
      }
    },
    [_closeModal, stats4Nerds, onSelectStats4Nerds],
  );

  const openModal = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Nerd ?"
        onSelectOption={_onSelect}
        options={BoolOpts}
        selected={stats4Nerds ? "on" : "off"}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, modalPush, _onSelect, stats4Nerds]);
  return (
    <ListItem
      name="Stats 4 Nerds"
      value={stats4Nerds ? "ON" : "OFF"}
      onClick={openModal}
    />
  );
};

export default Stats4Nerds;
