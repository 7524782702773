import { StorageKey } from "@blacknut/react-client-core/lib";
import React, { useCallback } from "react";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import OptionModal, { Option } from "../../components/Modals/OptionModal";
import BoolOpts from "./BoolOpts";
import ListItem from "./ListItem";
const PerformanceMonitoring = () => {
  const enabled = localStorage.getItem(StorageKey.PERFORMANCE_MONITORING);
  const setEnabled = useCallback((b: boolean) => {
    if (b) {
      localStorage.setItem(StorageKey.PERFORMANCE_MONITORING, "true");
    } else {
      localStorage.removeItem(StorageKey.PERFORMANCE_MONITORING);
    }
  }, []);
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();

  const _closeModal = React.useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const _onSelectOpt = React.useCallback(
    (opt: Option) => {
      _closeModal();
      if (opt.key === "on" && !enabled) {
        setEnabled(true);
      } else if (opt.key === "off" && enabled) {
        setEnabled(false);
      }
    },
    [_closeModal, enabled, setEnabled],
  );

  const openModal = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Track performance"
        onSelectOption={_onSelectOpt}
        options={BoolOpts}
        selected={enabled ? "on" : "off"}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, modalPush, _onSelectOpt, enabled]);
  return (
    <ListItem
      name="Performance monitoring"
      value={enabled ? "ON" : "OFF"}
      onClick={openModal}
    />
  );
};

export default PerformanceMonitoring;
