import * as React from "react";
import { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { ButtonStyle, Theme, ToggleButtonStyle } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import {
  Focusable,
  NavigationFailedEvent,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import Ripple, { RippleStyle, RippleType } from "../Ripple";
import clsx from "clsx";
import styles from "./styles.module.scss";
export interface ButtonProps {
  testID?: string;
  buttonStyle?: Partial<ButtonStyle>;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  type?: "button" | "submit";
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.UIEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
  onFocus?: (() => void) | undefined;
  onNavigationFailed?: (e: NavigationFailedEvent) => void;
  rippleStyle?: Partial<RippleStyle>;
}

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  white-space: nowrap;
  -webkit-app-region: no-drag;

  border: none;
  font-size: 1.6rem;

  background: none;
  outline: none;
  min-height: 4.2rem;
  padding: 0 1.6rem;
  width: 100%;
  margin: 0;
`;
export const StyledFocusable = styled(Focusable)``;
const StyledRipple = styled(Ripple)`
  flex: 1;
`;
export const Button = ({
  theme,
  buttonProps,
  className,
  testID,
  disabled,
  type = "button",
  children,
  onClick,
  style,
  onNavigationFailed,
  onFocus,
  rippleStyle,
}: PropsWithChildren<ButtonProps & { theme: Theme }>) => {
  const rippleRef = React.useRef<RippleType>(null);

  const myClick = React.useCallback(
    (e: React.UIEvent<HTMLButtonElement>) => {
      rippleRef.current?.ripple(e, onClick);
    },
    [onClick],
  );

  const onPressEnter = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      rippleRef.current?.ripple(e, () => {
        const button: HTMLButtonElement | null = (
          e.target as HTMLDivElement
        ).querySelector("button");
        if (button && button.type === "submit") {
          button.click();
          return;
        } else if (onClick) {
          onClick(e);
        }
      });
    },
    [onClick],
  );

  return (
    <StyledFocusable
      className={className}
      onPressEnter={onPressEnter}
      style={style}
      onNavigationFailed={onNavigationFailed}
      onFocus={onFocus}
    >
      <StyledRipple ref={rippleRef} rippleStyle={rippleStyle}>
        <BaseButton
          {...buttonProps}
          disabled={disabled}
          type={type}
          data-testid={testID}
          theme={theme}
          onClick={myClick}
        >
          {children}
        </BaseButton>
      </StyledRipple>
    </StyledFocusable>
  );
};

const PrimaryButtonFocus = css<{ theme: Theme; buttonStyle?: Partial<ButtonStyle> }>`
  box-shadow: 0 0 1.2rem ${(props) => props.theme.primaryButton.activeBackgroundColor};
  transform: ${(props) =>
    props.theme.primaryButton.scaleFactor
      ? `scale(${props.theme.primaryButton.scaleFactor})`
      : undefined};
  button {
    color: ${(props) => props.theme.primaryButton.activeTextColor};
    background-color: ${(props) =>
      overrideIfNeeded(
        "activeBackgroundColor",
        props.theme.primaryButton,
        props.buttonStyle,
      )};
    border-color: ${(props) =>
      overrideIfNeeded(
        "activeBorderColor",
        props.theme.primaryButton,
        props.buttonStyle,
      )};
  }
`;

const overrideIfNeeded = <T extends unknown>(
  key: keyof ButtonStyle,
  _default: Partial<ButtonStyle>,
  override?: Partial<ButtonStyle>,
): T | undefined => {
  if (override) return override[key] as T | undefined;
  return _default[key] as T | undefined;
};
const PrimaryButtonInternal = styled(Button)<{
  theme: Theme;
  buttonStyle?: Partial<ButtonStyle>;
  spatialNavigationActive: boolean;
}>`
  border-radius: ${(props) =>
    overrideIfNeeded("borderRadius", props.theme.primaryButton, props.buttonStyle)}rem;
  display: flex;
  transition: transform 0.2s; /* Animation */
  button {
    border-radius: ${(props) =>
      overrideIfNeeded("borderRadius", props.theme.primaryButton, props.buttonStyle)}rem;
    min-width: 8.8rem;
    color: ${(props) =>
      overrideIfNeeded(
        "inactiveTextColor",
        props.theme.primaryButton,
        props.buttonStyle,
      )};
    background-color: ${(props) =>
      overrideIfNeeded(
        "inactiveBackgroundColor",
        props.theme.primaryButton,
        props.buttonStyle,
      )};
    font-weight: ${(props) => props.theme.primaryButton.fontWeight};
    font-family: ${(props) => props.theme.primaryButton.fontFamily};
    border-color: ${(props) =>
      overrideIfNeeded(
        "inactiveBorderColor",
        props.theme.primaryButton,
        props.buttonStyle,
      )};
    border-width: ${(props) =>
      overrideIfNeeded("borderWidth", props.theme.primaryButton, props.buttonStyle)}rem;
    border-style: solid;
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  @media (hover: hover) {
    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : PrimaryButtonFocus)}
    }
  }
  &:focus {
    ${(props) => (props.spatialNavigationActive ? PrimaryButtonFocus : "")}
  }
`;
export const PrimaryButton = (props: PropsWithChildren<ButtonProps>) => {
  const { theme } = useTheme();
  const { active } = useSpatialNavigation();
  return (
    <PrimaryButtonInternal
      theme={theme}
      spatialNavigationActive={active}
      rippleStyle={{
        radius: overrideIfNeeded("borderRadius", theme.primaryButton, props.buttonStyle),
        rippleColor: "#fff",
      }}
      {...props}
      className={clsx([styles.primaryButton, props.className])}
    >
      {props.children}
    </PrimaryButtonInternal>
  );
};
const SecondaryButtonFocus = css<{ theme: Theme; buttonStyle?: Partial<ButtonStyle> }>`
  transform: ${(props) =>
    props.theme.secondaryButton.scaleFactor
      ? `scale(${props.theme.secondaryButton.scaleFactor})`
      : undefined};

  box-shadow: 0 0 1.2rem
    ${(props) =>
      overrideIfNeeded(
        "activeBorderColor",
        props.theme.secondaryButton,
        props.buttonStyle,
      )};
  button {
    background-color: ${(props) =>
      overrideIfNeeded(
        "activeBackgroundColor",
        props.theme.secondaryButton,
        props.buttonStyle,
      )};
    color: ${(props) =>
      overrideIfNeeded(
        "activeTextColor",
        props.theme.secondaryButton,
        props.buttonStyle,
      )};
    border-color: ${(props) =>
      overrideIfNeeded(
        "activeBorderColor",
        props.theme.secondaryButton,
        props.buttonStyle,
      )};
  }
`;

const SecondaryButtonInternal = styled(Button)<{
  theme: Theme;
  buttonStyle?: Partial<ButtonStyle>;
  spatialNavigationActive: boolean;
}>`
  border-radius: ${(props) =>
    overrideIfNeeded("borderRadius", props.theme.secondaryButton, props.buttonStyle)}rem;
  transition: transform 0.2s; /* Animation */

  button {
    border-radius: ${(props) =>
      overrideIfNeeded(
        "borderRadius",
        props.theme.secondaryButton,
        props.buttonStyle,
      )}rem;
    color: ${(props) =>
      overrideIfNeeded(
        "inactiveTextColor",
        props.theme.secondaryButton,
        props.buttonStyle,
      )};

    border-color: ${(props) =>
      overrideIfNeeded(
        "inactiveBorderColor",
        props.theme.secondaryButton,
        props.buttonStyle,
      )};
    border-width: ${(props) =>
      overrideIfNeeded(
        "borderWidth",
        props.theme.secondaryButton,
        props.buttonStyle,
      )}rem;
    border-style: solid;

    font-weight: ${(props) => props.theme.secondaryButton.fontWeight};
    font-family: ${(props) => props.theme.secondaryButton.fontFamily};

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  @media (hover: hover) {
    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : SecondaryButtonFocus)}
    }
  }
  &:focus {
    ${(props) => (props.spatialNavigationActive ? SecondaryButtonFocus : "")}
  }
`;

export const SecondaryButton = (
  props: PropsWithChildren<ButtonProps> & { buttonStyle?: Partial<ButtonStyle> },
) => {
  const { theme } = useTheme();
  const { active } = useSpatialNavigation();
  return (
    <SecondaryButtonInternal
      theme={theme}
      spatialNavigationActive={active}
      rippleStyle={{
        radius: overrideIfNeeded(
          "borderRadius",
          theme.secondaryButton,
          props.buttonStyle,
        ),
        rippleColor: "#dcdcdc",
      }}
      {...props}
      className={clsx([styles.secondaryButton, props.className])}
    >
      {props.children}
    </SecondaryButtonInternal>
  );
};

export const ToggleButton = styled(Button)<{
  buttonStyle: Partial<ToggleButtonStyle>;
  checked: boolean;
  outlined?: boolean;
}>`
  border-radius: ${(props) => props.buttonStyle.borderRadius}rem;
  display: flex;
  button {
    border-radius: ${(props) => props.buttonStyle.borderRadius}rem;
    min-width: 8.8rem;
    color: ${(props) =>
      props.checked ? props.buttonStyle.selectedTextColor : props.buttonStyle.textColor};
    background-color: ${(props) =>
      props.checked ? props.buttonStyle.selectedTintColor : props.buttonStyle.tintColor};
    font-weight: ${(props) => props.buttonStyle.fontWeight};
    font-family: ${(props) => props.buttonStyle.fontFamily};
    border: ${({ outlined, buttonStyle }) =>
      outlined
        ? `2px solid ${buttonStyle.selectedTextColor}`
        : "0.1rem solid transparent"};
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 1.2rem ${(props) => props.buttonStyle.activeTintColor};
    }
    button:hover {
      color: ${(props) => props.buttonStyle.activeTextColor};
      background-color: ${(props) => props.buttonStyle.activeTintColor};
    }
  }

  &:focus {
    box-shadow: 0 0 1.2rem ${(props) => props.buttonStyle.activeTintColor};

    button {
      color: ${(props) => props.buttonStyle.activeTextColor};
      background-color: ${(props) => props.buttonStyle.activeTintColor};
    }
  }
`;

const TertiaryButtonFocus = css`
  transform: ${(props) =>
    props.theme.tertiaryButton.scaleFactor
      ? `scale(${props.theme.tertiaryButton.scaleFactor})`
      : undefined};

  button {
    border-radius: ${(props) => props.theme.tertiaryButton.borderRadius}rem;
    border-color: ${(props) => props.theme.tertiaryButton.activeBorderColor};
  }
`;

const TertiaryButtonInternal = styled(Button)<{
  theme: Theme;
  spatialNavigationActive: boolean;
}>`
  transition: transform 0.2s; /* Animation */

  button {
    color: ${(props) => props.theme.tertiaryButton.inactiveTextColor};
    font-weight: ${(props) => props.theme.tertiaryButton.fontWeight};
    font-family: ${(props) => props.theme.tertiaryButton.fontFamily};
    border: 0.1rem solid transparent;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &:focus {
    ${(props) => (props.spatialNavigationActive ? TertiaryButtonFocus : "")}
  }
`;

export const TertiaryButton = (props: PropsWithChildren<ButtonProps>) => {
  const { theme } = useTheme();
  const { active } = useSpatialNavigation();
  return (
    <TertiaryButtonInternal
      theme={theme}
      spatialNavigationActive={active}
      rippleStyle={{
        radius: overrideIfNeeded(
          "borderRadius",
          theme.tertiaryButton,
          props.buttonStyle,
        ),
        rippleColor: theme.rippleColor,
      }}
      {...props}
      className={clsx([styles.tertiaryButton, props.className])}
    >
      {props.children}
    </TertiaryButtonInternal>
  );
};

export default Button;
