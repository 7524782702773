import { State, useLayout } from "@blacknut/react-client-core/lib";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useOrientation } from "../../utils/OrientationContext";
import NavsLinks from "./NavLinks/NavsLinks";
import NavsLinksMobile from "./NavsLinksMobile/NavsLinksMobile";
import React from "react";
const getActiveItemFromLocation = (pathname: string) => {
  if (pathname.match(/account/)) {
    return "account";
  }
  switch (pathname) {
    case "/browse":
      return "browse";
    case "/myList":
      return "myList";
    case "/search":
      return "search";
    case "/catalog":
      return "catalog";
    case "/lemag":
      return "lemag";
  }
  return undefined;
};

export interface MenuProps {
  className?: string;
  hidden: boolean;
}

const Menu = ({ className, hidden }: MenuProps) => {
  const { pathname } = useLocation();
  const profile = useSelector((state: State) => state.profilesState.profile);
  const { config } = useSelector((state: State) => state.globalState);
  const lemag = !!config?.features?.lemag;
  const layout = useLayout();
  const [activeItem, setActiveItem] = useState(
    getActiveItemFromLocation(pathname) || "catalog",
  );
  useEffect(() => {
    const active = getActiveItemFromLocation(pathname);
    if (active && active !== activeItem) {
      setActiveItem(active);
    }
  }, [activeItem, pathname]);

  const { orientation } = useOrientation();

  const navigableFilter = useCallback(() => {
    if (orientation === "LANDSCAPE") return true;
    return (
      document.documentElement.scrollHeight - document.documentElement.scrollTop ===
      window.innerHeight
    );
  }, [orientation]);
  const isSmartphone = window.matchMedia("(max-width: 768px)").matches;
  return (
    <FocusableSection
      focusKey="menu"
      className={className}
      navigableFilter={navigableFilter}
      disabled={hidden}
    >
      {isSmartphone && (
        <NavsLinksMobile
          layout={layout}
          pathname={pathname}
          activeItem={activeItem}
          lemag={lemag}
          profile={profile}
        />
      )}
      {!isSmartphone && (
        <NavsLinks
          layout={layout}
          pathname={pathname}
          activeItem={activeItem}
          lemag={lemag}
          profile={profile}
        />
      )}
    </FocusableSection>
  );
};

export default Menu;
