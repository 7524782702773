import { logE } from "@blacknut/logging/dist";
import { State, UserProfile, useProfilesPage } from "@blacknut/react-client-core/lib";
import { FocusableSection, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProfileAddCardButton from "src/components/Profile/ProfileAddCardListItem";
import PageTitle from "../../components/PageTitle/PageTitle";
import Profile from "../../components/Profile/ProfileCardItem";
import { useReactRouter } from "../../utils/Hooks";
import Utils, { LOGGING_TAG } from "../../utils/Utils";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import styles from "./styles.module.scss";

const SwitchUser = () => {
  const {
    profiles,
    onSelectProfile,
    authenticationError,
    dismissAuthenticationError,
    user,
  } = useProfilesPage(useReactRouter());
  const { t } = useTranslation();
  const { profile } = useSelector((state: State) => state.profilesState);
  const canAddProfile =
    profile && user && profiles && Utils.canAddProfile(user, profile, profiles);

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  useErrorHandling({
    error: authenticationError,
    clearError: dismissAuthenticationError,
  });

  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  const renderProfile = React.useCallback(
    (p: UserProfile) => {
      const onClick = async () => {
        try {
          await onSelectProfile(p);
        } catch (e) {
          logE(LOGGING_TAG, "Caught error on profile selection");
        }
      };
      return <Profile profile={p} key={p.id} onClick={onClick} />;
    },
    [onSelectProfile],
  );
  return (
    <div className={styles.container}>
      <PageTitle title={t("switchUser.title")} />
      {profiles && (
        <FocusableSection className={styles.grid} focusKey="profiles">
          {profiles.map(renderProfile)}
          {canAddProfile && <ProfileAddCardButton />}
        </FocusableSection>
      )}
    </div>
  );
};
export default SwitchUser;
