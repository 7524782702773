import React from "react";
import { ReactComponent as Arrow } from "../../../assets/dist/ic_arrow.svg";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";

interface ArrowButtonProps {
  onPress: () => void;
  className?: string;
}

const ArrowButton = ({ onPress, className }: ArrowButtonProps) => {
  const { active: spatialNavigationActive } = useSpatialNavigation();

  return (
    <Focusable
      className={clsx([
        styles.focusable,
        spatialNavigationActive && styles.spatialActive,
        className,
      ])}
      onPressEnter={onPress}
      onClick={onPress}
    >
      <Arrow />
    </Focusable>
  );
};

export default ArrowButton;
