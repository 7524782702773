import { Game } from "@blacknut/react-client-core/lib";
import { useMemo } from "react";

const fixReco = (game?: Game) => {
  const res = (game?.recommendations ?? []).map((list, index) => {
    return { ...list, uuid: `reco-${index}` };
  });
  return res;
};

const useReco = ({ game }: { game?: Game }) => {
  return useMemo(() => fixReco(game), [game]);
};

export default useReco;
