import { BillingInfo, Organization } from "@blacknut/react-client-core/lib";
import styled from "styled-components";
import { PrimaryButton } from "../../components/Button/V2Button";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";

export interface SubscriptionKindProps {
  onUnsubscribePressed: () => void;
  unsubscribing: boolean;
  organization?: Organization;
  billingInfo: BillingInfo;
}

const SubscriptionSubStatus = styled.span`
  color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
  display: block;
  text-align: center;
`;

export { SubscriptionSubStatus };

const UnsubscribeBtn = styled(PrimaryButton)`
  margin: ${dimens.margins.Green}rem auto;
`;

export { UnsubscribeBtn };

const UnsubscribeSpinner = styled(CircularProgress)`
  color: ${(props: { theme: Theme }) => props.theme.primaryButton.activeTextColor};
  margin-left: ${dimens.margins.DarkRed}rem;
`;

export { UnsubscribeSpinner };
