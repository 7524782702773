import { apiService } from "@blacknut/javascript-sdk/dist";
import { ApiErrorCode, logout } from "@blacknut/react-client-core/lib";

import { logD, logE } from "@blacknut/logging/dist";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearHistory, LOGGING_TAG } from "../../utils/Utils";
import MessageModal from "../Modals/MessageModal";
import { ModalSubscription, useModal } from "../Modals/ModalContext";
import React from "react";
import { Subscription } from "rxjs";

const GlobalErrorHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const { t } = useTranslation();

  const errorSuscription = useRef<Subscription>();

  const _logout = useCallback(async () => {
    logD(LOGGING_TAG, "In logout");
    await logout()(dispatch);
    clearHistory(history);
  }, [dispatch, history]);

  const dismissObsoleteModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
    }
  }, []);
  useEffect(() => {
    errorSuscription.current = apiService.onError().subscribe((error: any) => {
      logE(LOGGING_TAG, "onErrorObserver", error);
      if (error) {
        switch (error.status) {
          case "401":
            if (
              error.code !== ApiErrorCode.AUTH_FAILED &&
              error.code !== ApiErrorCode.ACCOUNT_LOCKED &&
              error.code !== ApiErrorCode.PASSWORD_TOO_SHORT
            ) {
              _logout();
            }
            break;

          case "406":
            if (
              error.code === ApiErrorCode.OBSOLETE_API ||
              error.code === ApiErrorCode.OBSOLETE_CLIENT
            ) {
              modalSubscription.current = modalPush((props) => (
                <MessageModal
                  {...props}
                  title={t("dialogs.error.title")}
                  onClose={dismissObsoleteModal}
                  message={error.title}
                />
              ));
            }
            break;
        }
      }
    });
    return () => {
      if (errorSuscription.current) {
        errorSuscription.current.unsubscribe();
        errorSuscription.current = undefined;
      }
    };
  }, [_logout, dismissObsoleteModal, modalPush, t]);

  useEffect(() => {
    return () => {
      if (errorSuscription.current) {
        errorSuscription.current.unsubscribe();
        errorSuscription.current = undefined;
      }
    };
  }, []);

  return null;
};

export default GlobalErrorHandler;
