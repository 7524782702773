import {
  RemoteNotification,
  RemoteNotificationTrigger,
  State,
  AppLayout,
  useLayout,
} from "@blacknut/react-client-core/lib";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ReactComponent as Close } from "../../../assets/dist/ic_close.svg";
import IconButton from "../../../components/Button/IconButton";
import { useSubscriptionRedirect } from "../../../components/SubscriptionRedirect/SubscriptionRedirect";
import dimens from "../../../theme/dimens";
import styles from "./styles.module.scss";

const BannerNotification = (props: {
  notification: RemoteNotification;
  onClose: () => void;
}) => {
  const { notification, onClose } = props;
  const layout = useLayout();
  const history = useHistory();
  const { redirectToSubscription } = useSubscriptionRedirect();
  const { profile } = useSelector((state: State) => state.profilesState);
  const onClickLink = async (event: React.MouseEvent<unknown>) => {
    if (notification.trigger === RemoteNotificationTrigger.INVALID_SUBSCRIPTION) {
      if (profile?.isMaster) {
        await redirectToSubscription();
      }
    } else if (notification.link) {
      if (notification.link.startsWith("blacknut://")) {
        history.push(notification.link.replace("blacknut://", "/"));
      } else {
        window.open(notification.link, "_blank");
      }
    }
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      onClose();
    }, 500);
  };
  const [yPosition, setYPosition] = React.useState(-1000);

  const _onClose = () => {
    setYPosition(-100);
    setTimeout(onClose, 500);
  };

  React.useEffect(() => {
    setYPosition(0);
  }, []);

  let linkText: string | undefined = notification.linkText;
  if (
    notification.trigger === RemoteNotificationTrigger.INVALID_SUBSCRIPTION &&
    !profile?.isMaster
  ) {
    linkText = undefined;
  }

  return (
    <>
      <div
        className={styles.container}
        style={{
          top: `calc(${
            layout === AppLayout.DESKTOP
              ? dimens.desktop.headerHeight
              : dimens.phone.headerHeight
          }rem + ${yPosition}px)`,
        }}
      >
        <span className={styles.desc}>{notification.description}</span>
        {linkText && (
          <a href="#" onClick={onClickLink} className={styles.link} data-testid="link">
            {linkText}
          </a>
        )}
        <IconButton className={styles.button} onClick={_onClose} testID="close">
          <Close />
        </IconButton>
      </div>
    </>
  );
};
export default BannerNotification;
