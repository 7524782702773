import { ImageType } from "@blacknut/javascript-sdk/dist";
import {
  AppLayout,
  List,
  State,
  StorageKey,
  Tile,
  useTile,
} from "@blacknut/react-client-core/lib";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useReactRouter } from "../../utils/Hooks";
import { useOrientation } from "../../utils/OrientationContext";
import { useTileSize } from "../../utils/TileWidthProvider";
import { layoutSelectCSS } from "../../utils/Utils";
import FadeInImage from "../FadeInImage/FadeInImage";
import { usePreview } from "../Modals/PreviewContext";
import styles from "./styles.module.scss";
import React from "react";

interface HeroTileProps {
  item: Tile;
  list: List;
}
const scrollTop = () => {
  document.documentElement.scroll && document.documentElement.scroll({ top: 0 });
};
const HeroTile = ({ item, list }: HeroTileProps) => {
  const [videoStarted, setVideoStarted] = useState(false);
  const { active: previewActive } = usePreview();

  const { onClick } = useTile({
    item,
    list,
    ...useReactRouter(),
    previewMode: previewActive,
  });
  const game = item.game;
  const { orientation } = useOrientation();
  let mainImages =
    game &&
    game.images &&
    game.images.filter((im) =>
      orientation === "LANDSCAPE"
        ? im.type === ImageType.HERO || im.type === ImageType.HERO_LANDSCAPE
        : im.type === ImageType.HERO,
    );
  if (orientation === "LANDSCAPE") {
    mainImages = mainImages?.sort((a, b) => {
      if (a.type === ImageType.HERO_LANDSCAPE) {
        return -1;
      }
      if (b.type === ImageType.HERO_LANDSCAPE) {
        return -1;
      }
      return 0;
    });
  }
  const image = mainImages ? mainImages[0] : null;
  const { layout } = useSelector((state: State) => state.globalState);

  const [focused, setFocused] = useState<boolean>(false);
  useEffect(() => {
    if (layout !== AppLayout.TV) return;
    const handleScroll = debounce(() => setFocused(window?.scrollY === 0), 5, {
      leading: true,
      trailing: true,
    });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [layout]);

  const onVideoStarted = useCallback(() => {
    setVideoStarted(true);
  }, []);
  const onFocus = useCallback(() => {
    scrollTop();
    setFocused(true);
  }, []);

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const isTVLegacy = localStorage.getItem(StorageKey.TV_LEGACY) === "true";

  const { standardTileSize } = useTileSize();
  if (!game) return null;
  const video = game.videos && game.videos.find((v) => v.type === "trailer");

  return (
    <Focusable
      className={clsx(styles.container, styles.focusable)}
      onFocus={onFocus}
      onPressEnter={onClick}
      onClick={onClick}
    >
      <>
        {image && (inView || process.env.NODE_ENV === "test") && (
          <FadeInImage className={styles.image} image={image} />
        )}

        <div
          className={clsx(styles.content, layout !== AppLayout.PHONE && styles.hasTitle)}
          ref={ref}
        >
          {layout !== AppLayout.PHONE && <h1 className={styles.title}>{game.name}</h1>}

          {(layout !== AppLayout.TV || focused || window?.scrollY === 0) &&
            layout != AppLayout.PHONE &&
            (inView || process.env.NODE_ENV === "test") &&
            !isTVLegacy &&
            video && (
              <video
                src={video.url}
                loop
                style={layoutSelectCSS(layout, {
                  default: {
                    width: `calc((6 * ${standardTileSize.width}px + 5 * 0.8rem) * 0.5)`,
                    height: `calc((6 * ${standardTileSize.width}px + 5 * 0.8rem) * 0.5 * 9 / 16)`,
                    flexShrink: 0,
                  },
                  tablet: {
                    width: `calc((6 * ${standardTileSize.width}px + 5 * 0.8rem) * 0.35)`,
                    height: `calc((6 * ${standardTileSize.width}px + 5 * 0.8rem) * 0.35 * 9 / 16)`,
                    flexShrink: 0,
                  },
                })}
                className={clsx([styles.video, videoStarted && styles.playing])}
                muted={true}
                autoPlay
                onPlaying={onVideoStarted}
              />
            )}
        </div>
      </>
    </Focusable>
  );
};
export default HeroTile;
