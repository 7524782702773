import { State } from "@blacknut/react-client-core/lib";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useHeader } from "../../components/Header/HeaderProvider";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import AvatarImage from "src/components/AvatarSelectionModal/AvatarImage";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 38rem;
  overflow-y: auto;
  color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
  align-items: center;
  .svg {
    width: 24rem;
    height: 24rem;
    align-items: center;
  }
  display: none;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    display: block;
  }
  @media screen and (min-width: 1023px) {
    .svg {
      width: 38rem;
      height: 38rem;
    }
  }
`;

const Nickname = styled.h1<{ theme: Theme }>`
  text-align: center;
  word-break: break-word;
  color: ${(props) => props.theme.titleTextStyle.color};
  font-size: ${(props) => props.theme.titleTextStyle.size}rem;
  font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
  font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
`;

const MePage = () => {
  const profile = useSelector((state: State) => state.profilesState.profile);
  const { theme } = useTheme();

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  useEffect(() => {
    setHeaderTitle(undefined);
    setHeaderLeft(<HeaderBackButton />);
    setHeaderRight(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle]);

  if (!profile) {
    return null;
  }
  return (
    <Content theme={theme}>
      {profile.avatarId && <AvatarImage avatarId={profile.avatarId} className="svg" />}

      {!profile.avatarId && <AvatarDefault />}

      <Nickname theme={theme}>{profile.nickname}</Nickname>
    </Content>
  );
};

export default MePage;
