import { List, useLayout, useSearch } from "@blacknut/react-client-core/lib";
import {
    FocusableSection,
    focusSectionWithPath,
    NavigationFailedEvent,
    useSpatialNavigation
} from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import FullTileList from "../../components/List/FullTileList";
import PageTitle from "../../components/PageTitle/PageTitle";
import dimens, { getNumColumns } from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useOrientation } from "../../utils/OrientationContext";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
const MainLoading = styled(CircularProgress)`
    margin: 0 auto;
`;

const ResultTitle = styled.h2<{ theme: Theme }>`
    margin: 0 0 ${dimens.margins.DarkRed}rem 0;
    text-align: left;
    align-self: stretch;
    color: ${(props) => props.theme.sectionTextStyle.color};
    font-size: ${(props) => props.theme.sectionTextStyle.size}rem;
    font-weight: ${(props) => props.theme.sectionTextStyle.fontWeight};
    font-family: ${(props) => props.theme.sectionTextStyle.fontFamily};
`;
const Container = styled(FocusableSection)`
    display: flex;
    flex-direction: column;
    /* overflow-y: hidden; */
    /* height: calc(100vh - ${dimens.phone.headerHeight}rem - ${dimens.phone.navigationBarHeight}rem); */
`;

const Overflowing = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: ${dimens.margins.LightGreen}rem;
`;
export interface PureSearchResultsProps {
    inProgress: boolean;
    results?: List;
    paginate: () => void;
    paginating: boolean;
}
export const PureSearchResults = ({ inProgress, results, paginate, paginating }: PureSearchResultsProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    const layout = useLayout();
    const { orientation } = useOrientation();

    const { resume: resumeSpatialNav } = useSpatialNavigation();
    useEffect(() => {
        if (!inProgress) {
            setTimeout(() => {
                resumeSpatialNav();
                focusSectionWithPath(`/results/${results?.uuid}`);
            }, 300);
        }
    }, [resumeSpatialNav, inProgress, results]);

    const onNavigationFailed = useCallback((e: NavigationFailedEvent) => {
        if (e.detail.direction === "up") {
            document.documentElement.scroll({ top: 0 });
        } else if (e.detail.direction === "down") {
            document.documentElement.scroll({ top: document.body.scrollHeight });
        }
    }, []);

    return (
        <Container focusKey="results" onNavigationFailed={onNavigationFailed}>
            <Overflowing id="results">
                <PageTitle title={t("search.title")} />
                <ScrollRestorer id="results" />
                {inProgress && <MainLoading size={50} />}
                {!inProgress && (
                    <>
                        {results && results.total === 0 && (
                            <ResultTitle theme={theme}>{t("search.results.none")}</ResultTitle>
                        )}
                        {results && results.total === 1 && (
                            <ResultTitle theme={theme}>{t("search.results.single")}</ResultTitle>
                        )}
                        {results && results.total > 1 && (
                            <ResultTitle theme={theme}>
                                {t("search.results.multi", { count: results.total })}
                            </ResultTitle>
                        )}
                        {results && (
                            <FullTileList
                                list={results}
                                numColumns={getNumColumns(layout, orientation)}
                                showTitle={false}
                                paginate={paginate}
                                paginating={paginating}
                            />
                        )}
                    </>
                )}
            </Overflowing>
        </Container>
    );
};

const SearchResults = () => {
    const chProps = useSearch({ limit: 20 });
    return <PureSearchResults {...chProps} />;
};

export default SearchResults;
