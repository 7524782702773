import { AppLayout } from "@blacknut/react-client-core/lib";
import { OrientationType } from "../utils/OrientationContext";

export declare type Dimens = {
  navigationBarWidth: number;
  maxContentWidth: number;
  navigationBarHeight: number;
  headerHeight: number;
  contentPaddingH: number;
  contentPaddingV: number;
};

export const FLAT_FEATURE_TILE_WEIGHT = 2;
export const getNumColumns = (layout: AppLayout, orientation: OrientationType) => {
  switch (layout) {
    case AppLayout.PHONE:
      return orientation === "LANDSCAPE" ? 4 : 3;
  }
  return 6;
};

const dimens = {
  margins: {
    BlueGreen: 12.8,
    LightRed: 9.6,
    Pink: 7.2,
    Blue: 6.4,
    Yellow: 4.8,
    Orange: 3.2,
    LightGreen: 2.4,
    Green: 1.6,
    DarkRed: 0.8,
    OverscanMarginV: 27,
    OverscanMarginH: 48,
  },
  breakpoints: {
    tablet: 768,
    desktop: 1280,
  },

  desktop: {
    navigationBarWidth: 9.2,
    navigationBarHeight: 5.2,
    maxContentWidth: 122.2,
    headerHeight: 3.8,
    contentPaddingH: 6.4,
    contentPaddingV: 3.2,
  } as Dimens,
  phone: {
    navigationBarWidth: 0,
    navigationBarHeight: 5.2,
    maxContentWidth: 122.2,
    headerHeight: 4.4,
    contentPaddingH: 2.4,
    contentPaddingV: 2.4,
  } as Dimens,

  phoneLandscape: {
    navigationBarWidth: 9.2,
    navigationBarHeight: 5.2,
    maxContentWidth: 122.2,
    headerHeight: 4.4,
    contentPaddingH: 2.4,
    contentPaddingV: 2.4,
  } as Dimens,
};

export default dimens;

export const mediaQueryDesktop = window.matchMedia
  ? window.matchMedia(`(min-width: ${dimens.breakpoints.desktop}px)`)
  : { matches: false };
export const mediaQueryTablet = window.matchMedia
  ? window.matchMedia(`(min-width: ${dimens.breakpoints.tablet}px)`)
  : { matches: false };
