import { DDAppender } from "@blacknut/logging-datadog/dist";
import { ConsoleAppender, LogLevel, logger } from "@blacknut/logging/dist";
import { State } from "@blacknut/react-client-core/lib";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const CONSOLE_APPENDER = new ConsoleAppender();
export const DD_APPENDER = new DDAppender({ type: "App" });
export const useLogging = () => {
  const { config } = useSelector((state: State) => state.globalState);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      logger.configure({
        level: LogLevel.LEVEL_DEBUG,
        appenders: [
          ...(logger.appenders || []).filter((f) => f !== CONSOLE_APPENDER),
          CONSOLE_APPENDER,
        ],
      });
    }
  }, []);

  useEffect(() => {
    switch (config?.userConf?.levelLogClient) {
      case "debug":
      case "trace":
        CONSOLE_APPENDER.level = LogLevel.LEVEL_DEBUG; // Can be overriden by the setter when setting it to false
        logger.configure({
          level: LogLevel.LEVEL_DEBUG,
          appenders: [
            ...(logger.appenders || []).filter((f) => f !== CONSOLE_APPENDER),
            CONSOLE_APPENDER,
          ],
        });
        break;
      case "info":
        CONSOLE_APPENDER.level = LogLevel.LEVEL_INFO; // Can be overriden by the setter when setting it to false
        break;
      case "warn":
        CONSOLE_APPENDER.level = LogLevel.LEVEL_WARN; // Can be overriden by the setter when setting it to false
        break;
    }
  }, [config]);
};
