import { logE } from "@blacknut/logging/dist";
import {
  BillingInfo,
  isValidSubscription,
  Organization,
  Status,
  User,
  SubscriptionKind,
  dismissUnsubscribeError,
  unsubscribe,
  State,
} from "@blacknut/react-client-core/lib";
import { FocusableSection, useForceFocusOnMount } from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import MessageModal from "../../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useSubscriptionRedirect } from "../../components/SubscriptionRedirect/SubscriptionRedirect";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import Utils, { LOGGING_TAG } from "../../utils/Utils";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import { SubscriptionKindProps } from "./SubscriptionKind";
import SubscriptionA1 from "./SubscriptionKind.a1";
import SubscriptionAis from "./SubscriptionKind.ais";
import SubscriptionAmazon from "./SubscriptionKind.amazon";
import SubscriptionCelcom from "./SubscriptionKind.celcom";
import SubscriptionDrei from "./SubscriptionKind.drei";
import SubscriptionGoogle from "./SubscriptionKind.google";
import SubscriptionM1 from "./SubscriptionKind.m1";
import SubscriptionPost from "./SubscriptionKind.post";
import SubscriptionSamsung from "./SubscriptionKind.samsung";
import SubscriptionStripe from "./SubscriptionKind.stripe";
import SubscriptionSwisscom from "./SubscriptionKind.swisscom";

const Container = styled(FocusableSection)`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem;
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;

    margin: auto;
  }
`;

const UnsubscribeBtn = styled(PrimaryButton)`
  margin: ${dimens.margins.Green}rem auto;
`;

const SubscriptionStatus = styled.span`
  color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
  text-align: center;
`;

const BillingType2Child = new Map<
  SubscriptionKind,
  (props: SubscriptionKindProps) => JSX.Element
>();

BillingType2Child.set(SubscriptionKind.STRIPE, SubscriptionStripe);
BillingType2Child.set(SubscriptionKind.AMAZON, SubscriptionAmazon);
BillingType2Child.set(SubscriptionKind.GOOGLE_PLAY, SubscriptionGoogle);
BillingType2Child.set(SubscriptionKind.AIS, SubscriptionAis);
BillingType2Child.set(SubscriptionKind.A1, SubscriptionA1);
BillingType2Child.set(SubscriptionKind.SWISSCOM, SubscriptionSwisscom);
BillingType2Child.set(SubscriptionKind.DREI, SubscriptionDrei);
BillingType2Child.set(SubscriptionKind.SAMSUNG_IAP, SubscriptionSamsung);
BillingType2Child.set(SubscriptionKind.POST, SubscriptionPost);
BillingType2Child.set(SubscriptionKind.CELCOM, SubscriptionCelcom);
BillingType2Child.set(SubscriptionKind.M1, SubscriptionM1);

const ValidSubscription = (props: {
  billingInfo: BillingInfo;
  user: User;
  organization?: Organization;
}) => {
  const { t, i18n } = useTranslation();
  const { billingInfo, user, organization } = props;
  const dispatch = useDispatch();
  const { inProgress: unsubscribing, error: unsubscribeError } = useSelector(
    (state: State) => state.globalState.unsubscribe,
  );

  const dismissUnsubError = useCallback(() => {
    dismissUnsubscribeError()(dispatch);
  }, [dispatch]);

  useErrorHandling({ error: unsubscribeError, clearError: dismissUnsubError });

  let nextPaymentDate: string | undefined;
  if (billingInfo.nextPayment) {
    nextPaymentDate = Utils.formatDate(
      new Date(billingInfo.nextPayment.date),
      i18n.language,
    );
  }
  const { theme } = useTheme();

  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();

  const onUnsubscribeCanceled = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const onUnsubscribeConfirmed = useCallback(async () => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
    try {
      await unsubscribe()(dispatch);
    } catch (e) {
      logE(LOGGING_TAG, "Caught error on unsub", e);
    }
  }, [dispatch]);

  const confirmUnsubscribe = useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <MessageModal
        {...props}
        title={t("dialogs.unsubscribe.title")}
        message={t("dialogs.unsubscribe.message", {
          appName: process.env.REACT_APP_APP_NAME,
        })}
        buttons={[
          <SecondaryButton key="cancel" onClick={onUnsubscribeCanceled}>
            {t("buttons.cancel")}
          </SecondaryButton>,
          <PrimaryButton key="ok" onClick={onUnsubscribeConfirmed}>
            {t("buttons.ok")}
          </PrimaryButton>,
        ]}
        onClose={onUnsubscribeCanceled}
      />
    ));
  }, [modalPush, onUnsubscribeCanceled, onUnsubscribeConfirmed, t]);

  const Child =
    billingInfo.subscription && BillingType2Child.get(billingInfo.subscription.kind);
  return (
    <>
      {nextPaymentDate && (
        <SubscriptionStatus
          theme={theme}
          dangerouslySetInnerHTML={{
            __html: t("account.subscriptionStatus.valid", {
              date: nextPaymentDate,
            }),
          }}
        />
      )}

      {Child && (
        <Child
          billingInfo={billingInfo}
          organization={organization}
          onUnsubscribePressed={confirmUnsubscribe}
          unsubscribing={unsubscribing}
        />
      )}
      {!Child && user.betaStatus === "active" && (
        <SubscriptionStatus theme={theme}>You are subscribed</SubscriptionStatus>
      )}
    </>
  );
};
const InvalidSubscription = ({ billingInfo }: { billingInfo: BillingInfo }) => {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();

  let activeUntil: string | undefined;
  if (billingInfo?.subscription?.activeUntil) {
    activeUntil = Utils.formatDate(
      new Date(billingInfo?.subscription?.activeUntil),
      i18n.language,
    );
  }

  const { redirectToSubscription } = useSubscriptionRedirect();
  return (
    <>
      <SubscriptionStatus
        theme={theme}
        dangerouslySetInnerHTML={{
          __html: t(
            billingInfo.subscription?.status !== Status.CANCELING
              ? "account.subscriptionStatus.invalid"
              : activeUntil
              ? "account.subscriptionStatus.canceling"
              : "account.subscriptionStatus.cancelingNoEndDate",
            {
              date: activeUntil || "",
            },
          ),
        }}
      />

      {billingInfo.subscription?.status !== Status.CANCELING && (
        <UnsubscribeBtn theme={theme} onClick={redirectToSubscription}>
          <span>{t("buttons.subscribe")}</span>
        </UnsubscribeBtn>
      )}
    </>
  );
};
const SubscriptionPage = () => {
  const { t } = useTranslation();
  const { user, billingInfo, organization } = useSelector(
    (state: State) => state.globalState,
  );
  const validSubscription =
    user &&
    isValidSubscription(user, billingInfo) &&
    billingInfo.subscription?.status !== Status.CANCELING;
  useForceFocusOnMount("/account/content/sub");
  return (
    <Container focusKey="sub">
      <PageTitle title={t("account.subscription.subtitle.default")} />
      {user && validSubscription && (
        <ValidSubscription
          organization={organization}
          billingInfo={billingInfo}
          user={user}
        />
      )}
      {!validSubscription && <InvalidSubscription billingInfo={billingInfo} />}
    </Container>
  );
};

export default SubscriptionPage;
