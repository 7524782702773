import { useAnalytics, List, Tile } from "@blacknut/react-client-core/lib";
import { getImageByThemeName } from "@blacknut/react-client-core/lib/models/Image";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router";
import styled from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { PrimaryButton } from "../Button/V2Button";
import FadeInImage from "../FadeInImage/FadeInImage";

const Container = styled.div<{ theme: Theme }>`
  cursor: pointer;
  position: relative;
  border-radius: ${(props) => props.theme.cardStyle.radius}rem;
  user-select: none;
  flex-shrink: 0;

  width: calc(
    100vw - ${dimens.phone.navigationBarWidth}rem - 2 *
      ${dimens.phone.contentPaddingH}rem
  );
  height: calc(
    (
        100vw - ${dimens.phone.navigationBarWidth}rem - 2 *
          ${dimens.phone.contentPaddingH}rem
      ) * 4.7 / 16
  );

  @media (orientation: landscape) {
    width: calc(
      100vw - ${dimens.phoneLandscape.navigationBarWidth}rem - 2 *
        ${dimens.phoneLandscape.contentPaddingH}rem
    );
    height: calc(
      (
          100vw - ${dimens.phoneLandscape.navigationBarWidth}rem - 2 *
            ${dimens.phoneLandscape.contentPaddingH}rem
        ) * 4.7 / 16
    );
  }

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: calc(
      100vw - ${dimens.desktop.navigationBarWidth}rem - 2 *
        ${dimens.desktop.contentPaddingH}rem
    );
    height: calc(
      (
          100vw - ${dimens.desktop.navigationBarWidth}rem - 2 *
            ${dimens.desktop.contentPaddingH}rem
        ) * 3.7 / 16
    );
  }
  padding: 0 ${dimens.margins.LightGreen}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  margin-bottom: ${dimens.margins.Green}rem;
  color: #fff;
  font-weight: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontWeight};
  font-size: 1.4rem;
  font-family: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontFamily};
  text-align: center;
  position: relative;
`;
const MyImage = styled(FadeInImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: ${(props: { theme: Theme }) => props.theme.cardStyle.radius}rem;
  overflow: hidden;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  button {
    height: 3.6rem;
  }
`;
interface RandomTileProps {
  item: Tile;
  list: List;
  className?: string;
}

const RandomTile = ({ list, className }: RandomTileProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { trackSurpriseMe } = useAnalytics();
  const _onClick = useCallback(() => {
    const idx = Math.floor(Math.random() * list.tiles.length);
    history.push(`/game/${list.tiles[idx].game?.id}`);
    trackSurpriseMe();
  }, [history, list.tiles, trackSurpriseMe]);
  const imgBackground = getImageByThemeName(theme.name, list.imgBackground ?? []);
  const [tileRef, inView] = useInView({
    threshold: 0,
  });
  return (
    <div ref={tileRef}>
      {(inView || process.env.NODE_ENV === "test") && (
        <Container theme={theme} className={className}>
          <MyImage image={imgBackground} theme={theme} />
          <Title theme={theme}>{t("randomTile.text")}</Title>
          <StyledPrimaryButton theme={theme} onClick={_onClick}>
            {t("randomTile.button")}
          </StyledPrimaryButton>
        </Container>
      )}
    </div>
  );
};
export default RandomTile;
