import * as React from "react";
import { useTranslation } from "react-i18next";
import dimens from "../../../theme/dimens";
import styled from "styled-components";
import { logE } from "@blacknut/logging/dist";
import { LOGGING_TAG } from "../../../utils/Utils";

const ContainerCopyGroup = styled.div`
  display: flex;
  justify-content: justify-between;
  min-height: 4rem;
  input {
    flex-grow: 2;
  }
`;
const ButtonCopy = styled.div`
  background-color: #737373;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  padding: 0 1rem;
  border-radius: ${dimens.margins.LightGreen / 4}rem;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #eb2553;
  }
`;
const Content = styled.div`
  border-radius: 0.8rem;
  background-color: #fff;
  padding: 0 1rem;
`;

interface ClipboardProps {
  copyText?: string;
}

const ClipboardCopy = ({ copyText }: React.PropsWithChildren<ClipboardProps>) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = React.useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText ?? "")
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((e) => {
        logE(LOGGING_TAG, "Error on copy", e);
      });
  };

  return (
    <Content>
      <ContainerCopyGroup>
        <input type="text" value={copyText} readOnly />
        <ButtonCopy onClick={handleCopyClick} aria-hidden="true">
          <span>{isCopied ? t("buttons.copied") : t("buttons.copy")}</span>
        </ButtonCopy>
      </ContainerCopyGroup>
    </Content>
  );
};

export default ClipboardCopy;
