import { State } from "@blacknut/react-client-core/lib";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { inputUI } from "@blacknut/spatialnav-sdk/dist";

const useConfig = () => {
  const { config } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    const { userConf } = config || {};
    inputUI.pollingIntervalUI = userConf?.gamepadPollingIntervalEdito;
    inputUI.pollingIntervalGame = userConf?.gamepadPollingIntervalGame;
  }, [config]);
};
export { useConfig };
export default useConfig;
