import { UserProfile } from "@blacknut/react-client-core/lib";
import * as React from "react";
import Truncate from "react-truncate";
import styled, { css } from "styled-components";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import AvatarImage from "../AvatarSelectionModal/AvatarImage";

export interface ProfileProps {
  className?: string;
  profile: UserProfile;
  size?: "small" | "medium";
  onClick: () => void;
}

const ActiveState = css`
  transform: ${(props) =>
    props.theme.profileListItemCardStyle.scaleFactor
      ? `scale(${props.theme.profileListItemCardStyle.scaleFactor})`
      : undefined};
  box-shadow: 0 0 1.2rem
    ${(props) => props.theme.profileListItemCardStyle.activeBackgroundColor};
  color: ${(props) => props.theme.profileListItemCardStyle.activeTextColor};
  background-color: ${(props) =>
    props.theme.profileListItemCardStyle.activeBackgroundColor};
`;

const Container = styled(Focusable)<{
  theme: Theme;
  size: "small" | "medium";
  spatialNavigationActive: boolean;
}>`
  cursor: pointer;
  border: 0.2rem solid transparent;
  border-radius: ${(props) => props.theme.profileListItemCardStyle.radius}rem;
  color: ${(props) => props.theme.profileListItemCardStyle.inactiveTextColor};
  flex-shrink: 0;
  width: ${(props) => (props.size === "small" ? "4.8rem" : "12rem")};
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: ${(props) => (props.size === "small" ? "12rem" : "20rem")};
    transition: transform 0.2s; /* Animation */
    @media (hover: hover) {
      &:hover {
        ${(props) => (props.spatialNavigationActive ? "" : ActiveState)}
      }
    }
  }
  padding: ${dimens.margins.DarkRed}rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  & > svg,
  & > img {
    width: ${(props) => (props.size === "small" ? "3.6rem" : "8rem")};
    height: ${(props) => (props.size === "small" ? "3.6rem" : "8rem")};

    @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
      width: ${(props) => (props.size === "small" ? "8rem" : "14rem")};
      height: ${(props) => (props.size === "small" ? "8rem" : "14rem")};
    }
    margin: 0 auto;
  }

  &:focus {
    border-color: ${(props) => props.theme.profileListItemCardStyle.activeBorderColor};
  }
`;

const Nickname = styled.div`
  margin-top: ${dimens.margins.DarkRed}rem;
  text-align: center;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;

  * {
    cursor: pointer;
  }
`;

const Profile = ({ className, profile, onClick, size = "small" }: ProfileProps) => {
  const { theme } = useTheme();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  return (
    <Container
      className={className}
      theme={theme}
      onClick={onClick}
      size={size}
      onPressEnter={onClick}
      spatialNavigationActive={spatialNavigationActive}
    >
      {profile.avatarId && <AvatarImage avatarId={profile.avatarId} />}

      {!profile.avatarId && <AvatarDefault />}

      <Nickname>
        <Truncate lines={1} width={80}>
          {profile.nickname}
        </Truncate>
      </Nickname>
    </Container>
  );
};

export default Profile;
