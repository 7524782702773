import parser from "src/services/uaParser";

export const detectDevice = (ua: any) => {
  const userParser: any = parser(ua);
  const userAgent =
    typeof userParser.os.name !== "undefined"
      ? userParser.os.name.toLowerCase().replace(/[\s_]+/g, "-")
      : null;
  const osVersion =
    typeof userParser.os.version !== "undefined"
      ? userParser.os.version.toLowerCase().replace(/[\s_]+/g, "-")
      : null;
  const browserName =
    typeof userParser.browser.name !== "undefined"
      ? userParser.browser.name.toLowerCase().replace(/[\s_]+/g, "-")
      : null;
  const browserVersion =
    typeof userParser.browser.version !== "undefined"
      ? userParser.browser.version.toLowerCase().replace(/[\s_]+/g, "-")
      : null;
  const deviceModel =
    typeof userParser.device.model !== "undefined"
      ? userParser.device.model.toLowerCase().replace(/[\s_]+/g, "-")
      : null;
  const deviceType =
    typeof userParser.device.type !== "undefined" &&
    (userParser.device.type === "mobile" ||
      userParser.device.type === "tablet" ||
      userParser.device.type === "smarttv")
      ? userParser.device.type
      : "desktop";
  const deviceData =
    typeof userParser.device.type !== "undefined" ? userParser.device.type : null;

  return {
    browserName,
    browserVersion,
    deviceData,
    deviceModel,
    deviceType,
    osVersion,
    userAgent,
  };
};
