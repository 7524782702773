import { State, UserProfile } from "@blacknut/react-client-core/lib";
import { FocusableSection, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ProfileAddButton from "src/components/Profile/ProfileAddCardListItem";
import { ReactComponent as EditIcon } from "../../assets/dist/ic_edit.svg";
import PageTitle from "../../components/PageTitle/PageTitle";
import Profile from "../../components/Profile/ProfileListItem2";
import Utils from "../../utils/Utils";
import styles from "./styles.module.scss";
import { ReactComponent as Lock } from "../../assets/dist/ic_lock closed.svg";
import { ReactComponent as LockActive } from "../../assets/dist/ic_lock closed_active.svg";

const NOP = () => null;
const EditProfilesPage = () => {
  const history = useHistory();
  const { user } = useSelector((state: State) => state.globalState);
  const { familyProfiles, profile } = useSelector((state: State) => state.profilesState);
  const profiles = familyProfiles.profiles || [];
  const { t } = useTranslation();
  const canAddProfile =
    user && profile && profiles && Utils.canAddProfile(user, profile, profiles);

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  const renderProfile = React.useCallback(
    (p: UserProfile) => {
      const onClick = () => {
        history.push(`/account/profile/${p.id}`);
      };
      return (
        <Profile
          className={styles.profile}
          profile={p}
          key={p.id}
          onClick={onClick}
          renderNickname={NOP}
        >
          <div className={styles.iconsContainer}>
            <div className={styles.nickname}>
              <div className={styles.nicknameLockContainer}>
                <span>{p.nickname}</span>
                {p.isPinLocked && (
                  <div className={styles.lock}>
                    <Lock />
                    <LockActive />
                  </div>
                )}
              </div>
              {p.isMaster && (
                <span className={styles.decoration}>
                  &nbsp;{t("profile.mainSuffix")}
                </span>
              )}
            </div>
            <div className={styles.editIconContainer}>
              <EditIcon className={styles.editIcon} />
            </div>
          </div>
        </Profile>
      );
    },
    [history, t],
  );
  return (
    <div className={styles.container}>
      <PageTitle title={t("parentalControl.title")} />
      {profiles && (
        <FocusableSection className={styles.profilesContainer} focusKey="profiles">
          {profiles.map(renderProfile)}
          {canAddProfile && <ProfileAddButton isInRow={true} />}
        </FocusableSection>
      )}
    </div>
  );
};

export default EditProfilesPage;
