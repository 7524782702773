import * as React from "react";
import { useTranslation } from "react-i18next";
import dimens from "src/theme/dimens";
import { Theme } from "src/theme/Theme";
import styled from "styled-components";
import { ReactComponent as Step1 } from "../../assets/dist/mobile-step1-chrome.svg";
import { ReactComponent as Step2 } from "../../assets/dist/mobile-step2-chrome.svg";
import { ReactComponent as DownloadNotif } from "../../assets/dist/menu-chrome.svg";
import { useTheme } from "src/theme/ThemeProvider";
import Carousel from "nuka-carousel";
import styles from "./styles.module.scss";
import logoBN from "src/assets/dist/logo_bn_rounded.png";
import { isBlacknutBuild } from "src/utils/Utils";

const Title = styled.h5<{ theme: Theme }>`
  margin: 0 0 0.4rem 0;
  color: ${(props) => props.theme.notificationInstall.textColor};
  font-size: 1.4rem;
`;

const Informations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Card = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundColor};
  border-radius: 1rem;
  padding: ${dimens.margins.DarkRed}rem;
  padding-top: ${dimens.margins.Green}rem;
`;
const ContainerStep = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  margin-top: 0.5rem;
`;

const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  @media (orientation: landscape) {
    justify-content: center;
  }
`;
const ContainerInfoText = styled.div<{ theme: Theme }>`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.notificationPWA.textColor};

  @media (orientation: landscape) {
    max-width: fit-content;
  }
`;
const InformationStep = styled.div<{ theme: Theme }>`
  position: relative;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin: ${dimens.margins.Green}rem 0 ${dimens.margins.DarkRed}rem 0;
  padding: 0 ${dimens.margins.DarkRed}rem;
  color: ${(props) => props.theme.textStyle.color};
  opacity: 0.7;
`;
const LogoStyled = styled.img`
  margin-right: 1.2rem;
  max-height: 6rem;
  max-width: 6rem;
`;
const DllIcon = styled(DownloadNotif)<{ theme: Theme }>`
  max-width: 1.6rem;
  vertical-align: middle;
  transform: translateY(-0.2rem);

  path {
    fill: ${(props) => props.theme.notificationInstall.textColor};
  }
`;
const Step1Styled = styled(Step1)<{ theme: Theme }>`
  max-height: 4.5rem;
  transform: scale(0.9);
  margin: 0 auto;

  .darkModed {
    ${({ theme }) => (theme.name === "dark" ? `fill: ${theme.textStyle.color};` : "")}
  }
`;
const Step2Styled = styled(Step2)<{ theme: Theme }>`
  max-width: 28rem;
  width: 90%;
  margin: 0 auto;

  .darkModed {
    ${({ theme }) => (theme.name === "dark" ? `fill: ${theme.textStyle.color};` : "")}
  }
`;

const ChromeNotificationMobileInstall = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [step, setStep] = React.useState(false);
  const showStep = () => {
    if (!step) {
      setStep(true);
    }
  };
  return (
    <Informations onClick={showStep}>
      <ContainerInfo>
        <LogoStyled
          src={isBlacknutBuild() ? logoBN : "/favicon-96x96.png"}
          alt="App logo"
        />
        <ContainerInfoText theme={theme}>
          <Title theme={theme}>
            {t("notifications.install.title", {
              brand: process.env.REACT_APP_APP_NAME,
            })}
          </Title>
          <span>
            {t("notifications.install.chrome.mobile.subtitle")} <DllIcon theme={theme} />
          </span>
          <span>
            {t("notifications.install.chrome.mobile.subtitle2", {
              brand: process.env.REACT_APP_APP_NAME,
            })}
          </span>
        </ContainerInfoText>
      </ContainerInfo>
      {step && (
        <Carousel
          autoplay
          wrapAround={true}
          defaultControlsConfig={{
            nextButtonStyle: { display: "none" },
            prevButtonStyle: { display: "none" },
            pagingDotsStyle: {
              fill: theme.spinnerColor,
              display: "flex",
              transform: "scale(1.5)",
            },
            pagingDotsClassName: styles.pagingDots,
          }}
        >
          <ContainerStep>
            <Card theme={theme}>
              <Step1Styled theme={theme} />
              <InformationStep theme={theme}>
                {t("notifications.install.chrome.mobile.step1")}
              </InformationStep>
            </Card>
          </ContainerStep>
          <ContainerStep>
            <Card theme={theme}>
              <Step2Styled theme={theme} />
              <InformationStep theme={theme}>
                {t("notifications.install.chrome.mobile.step1")}
              </InformationStep>
            </Card>
          </ContainerStep>
        </Carousel>
      )}
    </Informations>
  );
};
export default ChromeNotificationMobileInstall;
