import { ReactComponent as Logo } from "../../assets/dist/logo.svg";
import { ReactComponent as LogoAIS } from "../../assets/dist/logo_ais.svg";

import { ReactComponent as MultiPlayer } from "../../assets/dist/ic_multiplayer.svg";
import { ReactComponent as MultiPlayerActive } from "../../assets/dist/ic_multiplayer_active.svg";
import { ReactComponent as Gamepad } from "../../assets/dist/ic_gamepad.svg";
import { ReactComponent as GamepadActive } from "../../assets/dist/ic_gamepad_active.svg";
import { ReactComponent as Touch } from "../../assets/dist/ic_touch.svg";
import { ReactComponent as TouchActive } from "../../assets/dist/ic_touch_active.svg";
import { ReactComponent as KeyboardMouse } from "../../assets/dist/ic_keyboard_mouse.svg";
import { ReactComponent as KeyboardMouseActive } from "../../assets/dist/ic_keyboard_mouse_active.svg";
import { ReactComponent as TvRemote } from "../../assets/dist/ic_tv_remote.svg";
import { ReactComponent as TvRemoteActive } from "../../assets/dist/ic_tv_remote_active.svg";

import { ReactComponent as TimerShort } from "../../assets/dist/ic_short_time.svg";
import { ReactComponent as TimerMedium } from "../../assets/dist/ic_medium_time.svg";
import { ReactComponent as TimerLong } from "../../assets/dist/ic_long_time.svg";

import { ReactComponent as TimerShortActive } from "../../assets/dist/ic_short_time_active.svg";
import { ReactComponent as TimerMediumActive } from "../../assets/dist/ic_medium_time_active.svg";
import { ReactComponent as TimerLongActive } from "../../assets/dist/ic_long_time_active.svg";

import { ReactComponent as Account } from "../../assets/dist/ic_account.svg";
import { ReactComponent as AccountActive } from "../../assets/dist/ic_account_active.svg";

import { ReactComponent as Plans } from "../../assets/dist/ic_plans.svg";
import { ReactComponent as PlansActive } from "../../assets/dist/ic_plans_active.svg";

import { ReactComponent as SwitchProfile } from "../../assets/dist/ic_switch_profile.svg";
import { ReactComponent as SwitchProfileActive } from "../../assets/dist/ic_switch_profile_active.svg";

import { ReactComponent as Newsletter } from "../../assets/dist/ic_newsletter.svg";
import { ReactComponent as NewsletterActive } from "../../assets/dist/ic_newsletter_active.svg";

import { ReactComponent as Receipt } from "../../assets/dist/ic_receipt.svg";
import { ReactComponent as ReceiptActive } from "../../assets/dist/ic_receipt_active.svg";

import { ReactComponent as Settings } from "../../assets/dist/ic_settings.svg";
import { ReactComponent as SettingsActive } from "../../assets/dist/ic_settings_active.svg";

import { ReactComponent as Faq } from "../../assets/dist/ic_faq.svg";
import { ReactComponent as FaqActive } from "../../assets/dist/ic_faq_active.svg";

import { ReactComponent as Support } from "../../assets/dist/ic_support.svg";
import { ReactComponent as SupportActive } from "../../assets/dist/ic_support_active.svg";

import { ReactComponent as Visibility } from "../../assets/dist/ic_visibility.svg";
import { ReactComponent as VisibilityOff } from "../../assets/dist/ic_visibility_off.svg";

import { ReactComponent as Notification } from "../../assets/dist/ic_notification.svg";
import { ReactComponent as NotificationActive } from "../../assets/dist/ic_notification_active.svg";

import { ReactComponent as LightTheme } from "../../assets/dist/ic_light_theme.svg";
import { ReactComponent as LightThemeActive } from "../../assets/dist/ic_light_theme_active.svg";

import { ReactComponent as DarkTheme } from "../../assets/dist/ic_dark_theme.svg";
import { ReactComponent as DarkThemeActive } from "../../assets/dist/ic_dark_theme_active.svg";

import { ReactComponent as SortAZ } from "../../assets/dist/ic_sort_a_z.svg";
import { ReactComponent as SortAZDark } from "../../assets/dist/ic_sort_a_z_dark.svg";
import { ReactComponent as SortAZActive } from "../../assets/dist/ic_sort_a_z_active.svg";
import { ReactComponent as SortDate } from "../../assets/dist/ic_sort_date.svg";
import { ReactComponent as SortDateDark } from "../../assets/dist/ic_sort_date_dark.svg";
import { ReactComponent as SortDateActive } from "../../assets/dist/ic_sort_date_active.svg";
import { ReactComponent as SortGenre } from "../../assets/dist/ic_sort_genre.svg";
import { ReactComponent as SortGenreDark } from "../../assets/dist/ic_sort_genre_dark.svg";
import { ReactComponent as SortGenreActive } from "../../assets/dist/ic_sort_genre_active.svg";
import { ReactComponent as NotificationsPush } from "../../assets/dist/notification_active.svg";

import { ReactComponent as Speedtest } from "../../assets/dist/ic_speed_test.svg";
import { ReactComponent as SpeedtestActive } from "../../assets/dist/ic_speed_test_active.svg";

import { ReactComponent as PEGI_12 } from "../../assets/dist/ic_12years.svg";
import { ReactComponent as PEGI_16 } from "../../assets/dist/ic_16years.svg";
import { ReactComponent as PEGI_18 } from "../../assets/dist/ic_18years.svg";
import { ReactComponent as PEGI_3 } from "../../assets/dist/ic_3years.svg";
import { ReactComponent as PEGI_7 } from "../../assets/dist/ic_7years.svg";

import { ReactComponent as PEGI_12_Active } from "../../assets/dist/ic_12years_active.svg";
import { ReactComponent as PEGI_16_Active } from "../../assets/dist/ic_16years_active.svg";
import { ReactComponent as PEGI_18_Active } from "../../assets/dist/ic_18years_active.svg";
import { ReactComponent as PEGI_3_Active } from "../../assets/dist/ic_3years_active.svg";
import { ReactComponent as PEGI_7_Active } from "../../assets/dist/ic_7years_active.svg";
import { ReactComponent as NewsletterIcon } from "../../assets/dist/newsletter.svg";
import { ReactComponent as MultiDevice } from "../../assets/dist/multi-devices.svg";

import {
  BottomBarStyle,
  CardStyle,
  NamedTheme,
  NotificationInstall,
  TextInputStyle,
  ToggleButtonStyle,
} from "../Theme";

export const createLightTheme = (opts: { colorAccent: string; textColor?: string }) => {
  const { colorAccent, textColor = "#191414" } = opts;
  const cardStyle: Partial<CardStyle> = {
    inactiveBackgroundColor: "#FFFFFF",
    inactiveTextColor: textColor,
    inactiveTextColorLight: "#4c4c4c",
    inactiveBorderColor: "#FFFFFF",
    activeBackgroundColor: colorAccent,
    activeBorderColor: colorAccent,
    activeTextColor: "#FFFFFF",
    radius: 0.6,
    scaleFactor: 1.1,
  };
  const textInputStyle: Partial<TextInputStyle> = {
    labelStyle: {
      inactiveFontSize: 15,
      activeFontSize: 12,
      activeColor: colorAccent,
      inactiveColor: "#737373",
    },

    helperStyle: {
      size: 12,
      color: "#fff",
    },

    errorColor: "#D50000",

    inactiveBackgroundColor: "#FFFFFF",
    inactiveBorderColor: "transparent",
    inactiveTextColor: textColor,
    activeBackgroundColor: colorAccent,
    activeBorderColor: colorAccent,
    activeTextColor: textColor,
    borderRadius: 0.6,
  };

  const notificationInstall: Partial<NotificationInstall> = {
    textColor: colorAccent,
  };
  const toggleButtonStyle: Partial<ToggleButtonStyle> = {
    tintColor: "#FFFFFF",
    activeTintColor: colorAccent,
    selectedTintColor: "#FFFFFF",
    selectedTextColor: colorAccent,
    textColor: textColor,
    activeTextColor: "#FFFFFF",
    borderRadius: 0.6,
    fontWeight: 700,
  };

  const bottomBarStyle: Partial<BottomBarStyle> = {
    backgroundColor: "#FFFFFF",
    borderColor: "#CFCFCF",
    borderWidth: 0.05,
    activeTintColor: colorAccent,
    inactiveTintColor: "#737373",
    activeFocusedTintColor: "#FFFFFF",
    inactiveFocusedTintColor: "#FFFFFF",
    scaleFactor: 1.1,
  };

  return {
    name: "light",
    default: {
      backgroundColor: "#F2F2F2",
      inverseBackgroundColor: "#2D2D2D",
      logoSize: { width: 11.0, height: 2.4 },
      logoAISSize: { width: 15.7, height: 2.4 },
      spinnerColor: colorAccent,
      rippleColor: colorAccent,
      notificationInstall,
      images: {
        Logo: Logo,
        LogoAIS: LogoAIS,
        multiplayer: MultiPlayer,
        multiplayer_active: MultiPlayerActive,
        gamepad: Gamepad,
        gamepad_active: GamepadActive,
        touch: Touch,
        touch_active: TouchActive,
        keyboard_mouse: KeyboardMouse,
        keyboard_mouse_active: KeyboardMouseActive,
        tv_remote: TvRemote,
        tv_remote_active: TvRemoteActive,
        TimerShort,
        TimerMedium,
        TimerLong,
        TimerShortActive,
        TimerMediumActive,
        TimerLongActive,

        Plans: Plans,
        PlansActive: PlansActive,

        Newsletter: Newsletter,
        NewsletterActive: NewsletterActive,

        Receipt: Receipt,
        ReceiptActive: ReceiptActive,

        Settings: Settings,
        SettingsActive: SettingsActive,

        Faq: Faq,
        FaqActive: FaqActive,

        Support: Support,
        SupportActive: SupportActive,

        Visibility: Visibility,
        VisibilityOff: VisibilityOff,

        Notification: Notification,
        NotificationActive: NotificationActive,

        LightTheme: LightTheme,
        LightThemeActive: LightThemeActive,

        DarkTheme: DarkTheme,
        DarkThemeActive: DarkThemeActive,
        SortAZ,
        SortAZDark,
        SortAZActive,
        SortDate,
        SortDateDark,
        SortDateActive,
        SortGenre,
        SortGenreDark,
        SortGenreActive,
        Account,
        AccountActive,
        NotificationsPush,
        SwitchProfile,
        SwitchProfileActive,
        Speedtest,
        SpeedtestActive,
        PEGI_3,
        PEGI_7,
        PEGI_12,
        PEGI_16,
        PEGI_18,
        PEGI_3_Active,
        PEGI_7_Active,
        PEGI_12_Active,
        PEGI_16_Active,
        PEGI_18_Active,
        NewsletterIcon,
        MultiDevice,
      },
      statusBarStyle: {
        translucent: true,
        backgroundColor: "transparent",
        style: "dark-content",
      },

      buttonTextStyle: {
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      },

      textStyle: {
        size: 1.8,
        color: textColor,
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, "Helvetica Neue", Arial, sans-serif',
      },

      textStyle2: {
        size: 1.4,
        color: "#737373",
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, "Helvetica Neue", Arial, sans-serif',
      },

      textStyle3: {
        size: 1.2,
        color: textColor,
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, "Helvetica Neue", Arial, sans-serif',
      },
      titleTextStyle: {
        size: 3.6,
        color: textColor,
        fontWeight: 600,
      },

      sectionTextStyle: {
        size: 2,
        fontWeight: 700,
        color: textColor,
      },

      headerStyle: {
        backgroundColor: "#FFFFFF",
        textStyle: {
          size: 1.8,
          fontWeight: 500,
          color: "#4C4C4C",
        },
        buttonsTintColor: colorAccent,
        elevation: 0,
        borderColor: "#CFCFCF",
        borderWidth: 0.1,
      },

      bottomBarStyle,

      separatorColor: "#CFCFCF",
      textColor: textColor,

      navigationBar: {
        backgroundColor: "#FFFFFF",
        lightContent: false,
      },

      listStyle: {
        backgroundColor: "#FFFFFF",
        textColor: textColor,
        acessoryViewTintColor: colorAccent,
        radius: 0.6,
        activeBackgroundColor: colorAccent,
        activeTextColor: "#fff",
      },

      primaryButton: {
        inactiveBackgroundColor: colorAccent,
        inactiveBorderColor: undefined,
        inactiveTextColor: "#ffffff",

        activeBackgroundColor: colorAccent,
        activeBorderColor: undefined,
        activeTextColor: "#ffffff",

        hoverBackgroundColor: colorAccent,
        hoverBorderColor: undefined,
        hoverTextColor: "#ffffff",

        fontSize: 14,
        fontWeight: 700,
        borderWidth: 0,
        uppercased: false,
        borderRadius: 3.2,
        scaleFactor: 1.01,
      },

      secondaryButton: {
        fontSize: 14,
        fontWeight: 600,

        inactiveBackgroundColor: "#CFCFCF30",
        inactiveBorderColor: colorAccent,
        inactiveTextColor: "#191414",

        hoverBackgroundColor: "#CFCFCF80",
        hoverBorderColor: undefined,
        hoverTextColor: "#191414",

        activeBackgroundColor: colorAccent,
        activeBorderColor: colorAccent,
        activeTextColor: "#ffffff",

        borderWidth: 0.2,
        uppercased: false,
        borderRadius: 3.2,

        scaleFactor: 1.01,
      },

      iconButton: {
        inactiveBackgroundColor: "#CFCFCF30",
        inactiveBackgroundColorLighter: "ffffff",
        inactiveBorderColor: undefined,
        inactiveTextColor: "#191414",

        hoverBackgroundColor: "#CFCFCF80",
        hoverBackgroundColorLighter: colorAccent,
        hoverBorderColor: undefined,
        hoverTextColor: "#ffffff",

        activeBackgroundColor: colorAccent,
        activeBorderColor: undefined,
        activeTextColor: "#FFFFFF",

        fontSize: 14,
        fontWeight: 700,
        borderWidth: 0,
        uppercased: false,
        borderRadius: 3.2,
        scaleFactor: 1.01,
      },

      tertiaryButton: {
        inactiveBackgroundColor: undefined,
        inactiveBorderColor: undefined,
        inactiveTextColor: colorAccent,

        activeBackgroundColor: colorAccent,
        activeBorderColor: colorAccent,
        activeTextColor: "#FFFFFF",
        fontSize: 14,
        fontWeight: 600,

        uppercased: false,
        scaleFactor: 1.05,

        borderRadius: 3.2,
      },

      textInputStyle: {
        ...textInputStyle,
      },
      cardStyle: {
        ...cardStyle,
      },
      accountCardStyle: {
        ...cardStyle,
        scaleFactor: 1.02,
      },
      profileCardStyle: {
        ...cardStyle,
        inactiveBorderColor: "#F2F2F2",
        radius: 1.2,
      },

      tileCardStyle: {
        ...cardStyle,
        radius: 1.3,
        scaleFactor: 1.02,
      },
      profileListItemCardStyle: {
        ...cardStyle,
        scaleFactor: 1.01,
      },
      browseCardItemCardStyle: {
        ...cardStyle,
        scaleFactor: 1.03,
      },
      checkboxStyle: {
        activeTintColor: colorAccent,
        inactiveTintColor: textColor,
      },
      toggleButton: toggleButtonStyle,
      modalStyle: {
        backgroundColor: "#FFFFFF",
        overlayColor: "#19141450",
        padding: 3.2,
        titleTextStyle: {
          size: 2,
          color: textColor,
          fontWeight: 600,
        },

        textStyle: {
          size: 14,
          color: textColor,
        },
        buttonsAlignment: "end",
      },
      tabStyle: {
        activeBorderColor: colorAccent,
        inactiveBorderColor: "transparent",
        textStyle: {
          fontSize: 14,
          activeTextColor: colorAccent,
          inactiveTextColor: textColor,
        },
      },

      switchStyle: {
        activeThumbColor: colorAccent,
        inactiveThumbColor: "#737373",
        activeTrackColor: "#CFCFCF",
        inactiveTrackColor: "#CFCFCF",
      },

      fullscreenControls: {
        backgroundColor: "rgba(0,0,0,0.6)",
      },

      search: {
        toggleButtonStyle: {
          ...toggleButtonStyle,
          tintColor: "#F2F2F2",
          selectedTintColor: "#F2F2F2",
        },
        iconSearchInput: {
          size: 2.2,
        },
        textInputStyle: {
          ...textInputStyle,
          inactiveBackgroundColor: "#F2F2F2",
        },
      },

      inputPinStyle: {
        borderColor: "#CFCFCF",
      },

      speedTestStyle: {
        colors: {
          unknown: cardStyle.inactiveBackgroundColor,
          ok: "#b9e081",
          warn: "#f5d181",
          bad: "#fb9f9f",
        },
      },

      notificationPWA: {
        textColor: textColor,
        backgroundColor: "#FFFFFF",
      },

      labelSecureStyle: {
        color: "#856404",
        backgroundColor: "#fff3cd",
      },

      visibilityInputIconColor: "#4c4c4c",
    },

    phone: {
      titleTextStyle: {
        size: 2.8,
      },
      sectionTextStyle: {
        size: 1.6,
        fontWeight: 600,
      },
      modalStyle: {
        padding: 2.4,
      },
      search: {
        toggleButtonStyle,
        textInputStyle: {
          ...textInputStyle,
          inactiveBackgroundColor: "#FFF",
        },
      },
      bottomBarStyle: {
        ...bottomBarStyle,
        inactiveFocusedTintColor: colorAccent,
        activeFocusedTintColor: colorAccent,
      },
    },
    tablet: {
      search: {
        toggleButtonStyle,
        textInputStyle: {
          ...textInputStyle,
          inactiveBackgroundColor: "#FFF",
        },
      },
    },
  } as NamedTheme;
};

export default createLightTheme({ colorAccent: "#EB2553" });
