import { ICryptoService } from "@blacknut/react-client-core/lib";

export class CryptoService implements ICryptoService {
  private _encryptionKey = "In7Nt5RMeQ3g2mG2tcqf4BpdH4M3WPGx";
  private encoder = new TextEncoder();
  public set encryptionKey(v: string) {
    this._encryptionKey = v;
  }

  public get encryptionKey(): string {
    return this._encryptionKey;
  }

  public async encrypt(message: string) {
    const key = await window.crypto.subtle.importKey(
      "raw",
      this.encoder.encode(this.encryptionKey),
      "AES-CBC",
      false,
      ["encrypt"],
    );
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const buff = await window.crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv,
      },
      key,
      this.encoder.encode(message),
    );
    const data = Buffer.concat([Buffer.from(iv), Buffer.from(buff)]);
    return data.toString("base64").replace(/\+/g, "-").replace(/\//g, "_");
  }
}

export default CryptoService;
