import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ReactComponent as Add } from "../../assets/dist/ic_add.svg";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import Ripple, { RippleType } from "../Ripple";
import Truncate from "react-truncate";

const Content = styled.div<{ theme: Theme }>`
  color: ${(props) => props.theme.profileCardStyle.inactiveTextColor};
  width: 12rem;
  min-height: min-content;
  padding: ${dimens.margins.DarkRed}rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  svg {
    fill: ${(props) => props.theme.rippleColor};
    color: ${(props) => props.theme.rippleColor};
    width: 65%;
    height: 65%;
  }
  cursor: pointer;
`;

const Wrap = styled.div<{ theme: Theme }>`
  width: 8rem;
  height: 8rem;
  background-color: ${(props) => props.theme.profileCardStyle.inactiveBackgroundColor};
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Nickname = styled.div`
  margin-top: ${dimens.margins.DarkRed}rem;
  text-align: center;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;

  * {
    cursor: pointer;
  }
`;

const Container = styled(Focusable)`
  width: 12rem;
  flex-shrink: 0;
  border: 0.2rem solid transparent;
  border-radius: ${(props) => props.theme.profileListItemCardStyle.radius}rem;

  &:focus {
    border-color: ${(props) => props.theme.profileListItemCardStyle.activeBorderColor};
  }
`;

const StyledRipple = styled(Ripple)`
  min-width: min-content;
`;

const ProfileAddButton = ({ className }: { className?: string }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const rippleRef = React.useRef<RippleType>(null);
  const onClick = React.useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      rippleRef.current?.ripple(e, () => {
        history.push("/account/profiles/new");
      });
    },
    [history],
  );
  return (
    <StyledRipple
      ref={rippleRef}
      rippleStyle={{ radius: theme.profileListItemCardStyle.radius }}
      className={className}
    >
      <Container onPressEnter={onClick} theme={theme}>
        <Content theme={theme} onClick={onClick} data-testid="container">
          <Wrap theme={theme}>
            <Add />
          </Wrap>
          <Nickname>{t("buttons.add")}</Nickname>
        </Content>
      </Container>
    </StyledRipple>
  );
};
export default ProfileAddButton;
