import { AppLayout, useLayout } from "@blacknut/react-client-core/lib";
import {
  Focusable,
  FocusableSection,
  FocusableSectionProps,
} from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dimens from "../../../theme/dimens";
import { Theme } from "../../../theme/Theme";
import { useTheme } from "../../../theme/ThemeProvider";
import { Email, Facebook, Share, Twitter, Whatsapp } from "../icons";
import { TertiaryButton } from "../V2Button";
import ClipboardCopy from "./ClipboardCopy";
import { logE, logD } from "@blacknut/logging/dist";
import { LOGGING_TAG } from "../../../utils/Utils";

const Content = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    align-items: flex-start;
  }
`;

const ShareButtonsContainer = styled.div`
  padding: 0 ${dimens.margins.LightGreen / 2}rem ${dimens.margins.LightGreen}rem;
  border-radius: 0.8rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: ${dimens.margins.LightGreen}rem;
  min-width: 50vw;
`;
const ContentShareButton = styled(TertiaryButton)<{
  theme: Theme;
  layoutPhone: boolean;
  active: boolean;
}>`
  border: 2px solid
    ${(props) =>
      props.active
        ? props.theme.secondaryButton.activeBackgroundColor
        : props.layoutPhone
        ? props.theme.secondaryButton.activeBackgroundColor
        : "white"};
  background: ${(props) =>
    props.active
      ? props.theme.secondaryButton.activeBackgroundColor
      : props.layoutPhone
      ? props.theme.secondaryButton.inactiveBackgroundColor
      : "RGBA(0, 0, 0, 0.55)"};
  display: inline-block;
  border-radius: ${(props) => props.theme.primaryButton.borderRadius}rem;
  margin-top: ${dimens.margins.LightGreen}rem;
  span {
    color: ${(props) =>
      props.active
        ? "white"
        : props.layoutPhone
        ? props.theme.secondaryButton.activeBackgroundColor
        : "white"};
  }
  svg {
    height: 4rem;
    color: ${(props) =>
      props.active
        ? "white"
        : props.layoutPhone
        ? props.theme.secondaryButton.activeBackgroundColor
        : "white"};
    width: 1.8rem;
    margin-right: ${dimens.margins.LightGreen / 2}rem;
  }
  a {
    text-decoration: none;
  }
  &:hover,
  &:focus {
    svg,
    span {
      color: ${(props) => props.theme.secondaryButton.activeTextColor};
    }
    color: ${(props) => props.theme.secondaryButton.activeTextColor};
    border: 2px solid ${(props) => props.theme.secondaryButton.activeBackgroundColor};
    background: ${(props) => props.theme.secondaryButton.activeBackgroundColor};
  }
`;

const StyledList = styled(Focusable)`
  display: flex;
  flex-direction: row;

  padding: 0 1rem;
  & > :nth-child(0n + 2) {
    margin: 0 0.5rem 0 1rem;
  }
  & > :nth-child(0n + 3) {
    margin: 0 1rem 0 0.5rem;
  }
`;
const StyledListItem = styled(Focusable)<{ backgroundColor: string }>`
  flex-grow: 4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: ${dimens.margins.LightGreen / 2}rem ${dimens.margins.LightGreen}rem;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 0.8rem;
  min-height: 3.9rem;
  span {
    cursor: grab;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
  }
  & > * > svg {
    fill: white;
    height: 2.8rem;
  }
  cursor: grab;
`;

interface SharingProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  text?: string;
}

const Sharing = ({ text, leaveFor }: React.PropsWithChildren<SharingProps>) => {
  const layout = useLayout();
  const isOnPhone = layout === AppLayout.PHONE;
  const { theme } = useTheme();
  const { t } = useTranslation();
  const url = window.location.href;
  const [canShare, setCanShare] = React.useState(0);
  const socialData = {
    twitter: {
      label: "Twitter",
      color: "#1da1f2",
      url: `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
        text ?? "",
      )}&url=${encodeURIComponent(url)}`,
    },
    facebook: {
      label: "Facebook",
      color: "#3b5998",
      url: `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    },
    linkedin: {
      label: "Linkedin",
      color: "#0077b5",
      url: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}`,
    },
    whatsapp: {
      label: "WhatsApp",
      color: "#25d366",
      url: `whatsapp://send?text=${encodeURIComponent(
        text ?? "",
      )}%20${encodeURIComponent(url)}`,
    },
    email: {
      label: "Email",
      color: "#d93025",
      url: `mailto:?subject=${encodeURIComponent(text ?? "")}&body=${encodeURIComponent(
        url,
      )}`,
    },
    reddit: {
      label: "Reddit",
      color: "#ff4500",
      url: `https://reddit.com/submit/?url=${encodeURIComponent(url)}`,
    },
  };
  const displayIcons = () => {
    if (canShare) {
      setCanShare(0);
    } else {
      try {
        if (navigator.canShare({ url })) {
          navigator
            .share({
              url,
            })
            .then(() => logD(LOGGING_TAG, "Thanks for sharing!"))
            .catch((error) => logE(LOGGING_TAG, "Sharing failed: %o", error));
        }
      } catch (error) {
        setCanShare(1);
      }
    }
  };
  return (
    <Content focusKey="share" leaveFor={leaveFor}>
      <ContentShareButton
        active={canShare == 1}
        layoutPhone={isOnPhone}
        theme={theme}
        className="containerShare"
        onClick={displayIcons}
        buttonStyle={{
          ...theme.tertiaryButton,
          inactiveBackgroundColor: "#fff",
          inactiveTextColor: theme.rippleColor,
        }}
      >
        <Share />
        <span>{t("buttons.shareGame")}</span>
      </ContentShareButton>
      {canShare ? (
        <ShareButtonsContainer>
          <StyledList component="ul">
            {["twitter", "facebook", "whatsapp", "email"].map((network, key) => (
              <StyledListItem
                onClick={() => window.open(socialData[network].url, "_blank")}
                component="li"
                key={key}
                backgroundColor={socialData[network].color}
                theme={theme}
              >
                <span>
                  {network === "twitter" ? (
                    <Twitter />
                  ) : network === "facebook" ? (
                    <Facebook />
                  ) : network === "whatsapp" ? (
                    <Whatsapp />
                  ) : network === "email" ? (
                    <Email />
                  ) : null}
                </span>
              </StyledListItem>
            ))}
          </StyledList>
          <ClipboardCopy copyText={url} />
        </ShareButtonsContainer>
      ) : null}
    </Content>
  );
};

export default Sharing;
