import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Checkbox from "../../components/Inputs/Checkbox/Checkbox";
import PageTitle from "../../components/PageTitle/PageTitle";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import {
  Focusable,
  FocusableSection,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem;
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;

    margin: auto;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  cursor: pointer;
  margin: 0;
`;

const ImgContainer = styled.div`
  svg {
    width: 10rem; /*FIXME improve this*/
    height: auto;
    color: ${(props: { theme: Theme }) => props.theme.spinnerColor};
  }
`;

const ListItem = styled(Focusable)`
  flex: 1;
  background-color: ${(props: { theme: Theme }) =>
    props.theme.cardStyle.inactiveBackgroundColor};
  border-radius: ${(props: { theme: Theme }) => props.theme.cardStyle.radius}rem;
  margin: 0 ${dimens.margins.DarkRed}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${dimens.margins.DarkRed}rem;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  span {
    cursor: pointer;
    color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
    margin-top: ${dimens.margins.DarkRed}rem;
  }

  &:hover,
  &:focus {
    background-color: ${(props: { theme: Theme }) =>
      props.theme.cardStyle.activeBackgroundColor};

    * {
      color: ${(props: { theme: Theme }) => props.theme.toggleButton.activeTextColor};
    }
  }
`;

const PersonalizationPage = () => {
  const { themes, theme, setThemeId } = useTheme();
  const { t } = useTranslation();

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  const renderTheme = React.useCallback(
    (item: string) => {
      const onClick = () => {
        setThemeId(item);
      };
      return (
        <ListItem theme={theme} key={item} onClick={onClick} onPressEnter={onClick}>
          <ImgContainer theme={theme}>
            {item === "light" && theme.images.LightTheme && <theme.images.LightTheme />}
            {item === "dark" && theme.images.DarkTheme && <theme.images.DarkTheme />}
          </ImgContainer>
          <Checkbox
            radio={true}
            id={item}
            onCheckChange={onClick}
            checked={item === theme.name}
            focusDisabled
          />
          <span>{t(`themes.${item}`)}</span>
        </ListItem>
      );
    },
    [setThemeId, t, theme],
  );
  return (
    <Container>
      <PageTitle title={t("personalization.title")} />
      <FocusableSection focusKey="themes">
        <List>{themes.map(renderTheme)}</List>
      </FocusableSection>
    </Container>
  );
};
export default PersonalizationPage;
