import { logD, logW } from "@blacknut/logging/dist";
import {
  AppLayout,
  Lang,
  Tile,
  User,
  UserProfile,
} from "@blacknut/react-client-core/lib";
import { MAX_FAMILY_PROFILES } from "@blacknut/react-client-core/lib/models/User";
import format from "date-fns/format";
import * as H from "history";
import { MemoryHistory } from "history";
import { CSSProperties } from "react";
import electronService from "../services/ElectronService";

export default class Utils {
  public static getScrollingParent(el: HTMLElement) {
    let element: HTMLElement | null = el;
    while (element && element.nodeType !== 1) {
      element = element.parentElement;
    }
    while (
      element &&
      element.scrollHeight === element.clientHeight &&
      element.nodeType === 1 /*Element*/
    ) {
      element = element.parentNode as HTMLElement;
    }
    return element ? (element as HTMLElement) : null;
  }

  public static setScrollingParentScrollTop(el: Element, i: number) {
    // Dirty Hack when gaining focus go till found the scrolling parent and set it's offset top to 0
    while (el && el.scrollHeight === el.clientHeight && el.nodeType === 1 /*Element*/) {
      el = el.parentNode as Element;
    }
    // console.log("Scrolling ", el, i);
    if (el.scrollTop !== i) {
      // console.log("Scroll to", i);
      el.scrollTop = i;
    }
    // console.log("Scrolled ", el, i);
  }

  public static scrollBottom(el: HTMLElement) {
    // console.log("Input ", el);
    const container = Utils.getScrollingParent(el);
    // console.log("Scrolling ", container);
    if (container) {
      const tx = container.scrollHeight - container.clientHeight;
      container.scrollTop = tx;
      // console.log("Scrolled ", container, tx);
    }
  }
  public static scrollTop(el: HTMLElement) {
    // console.log("Input ", el);
    const container = Utils.getScrollingParent(el);
    // console.log("Scrolling ", container);
    if (container) {
      container.scrollTop = 0;
    }
  }

  public static formatDate(d: Date, lng?: string) {
    const lang = lng || Lang.ENGLISH;
    return format(d, lang === Lang.ENGLISH ? "MM/dd/yyyy" : "dd/MM/yyyy");
  }

  public static canAddProfile(
    user: User,
    currentProfile: UserProfile,
    profiles: UserProfile[],
  ) {
    return (
      (!user.userProfile || !user.userProfile.needValidation) &&
      currentProfile &&
      currentProfile.isMaster &&
      profiles.length < MAX_FAMILY_PROFILES
    );
  }
}

export const tile2Id = (t: Tile) => {
  if (t.game) {
    return t.game.id || "";
  }
  return "";
};

export const layoutSelectCSS = (
  layout: AppLayout,
  vals: {
    phone?: CSSProperties;
    default?: CSSProperties;
    desktop?: CSSProperties;
    tablet?: CSSProperties;
  },
) => {
  switch (layout) {
    case AppLayout.PHONE:
      return { ...vals.default, ...vals.phone };
    case AppLayout.DESKTOP:
      return { ...vals.default, ...vals.desktop };
    case AppLayout.TABLET:
      return { ...vals.default, ...vals.tablet };
  }
  return vals.default;
};

export const clearHistory = (history: H.History) => {
  if (electronService.isAvailable() && !("entries" in history)) {
    logD(LOGGING_TAG, "Clearing electron history");
    electronService.clearHistory();
    logD(LOGGING_TAG, "History cleared");
    return true;
  }
  if ("entries" in history) {
    logD(LOGGING_TAG, "Clearing memory history");
    const _history = history as MemoryHistory;
    _history.entries = [];
    _history.index = -1;
    _history.length = 0;
    return true;
  }
  return false;
};
//https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
export const isInStandaloneMode = () => {
  return (
    electronService.isAvailable() ||
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator["standalone"] ||
    document.referrer.includes("android-app://")
  );
};

export const isBlacknutBuild = () => {
  return process.env.REACT_APP_FLAVOR === "blacknut";
};
export const isGameloftBuild = () => {
  return process.env.REACT_APP_FLAVOR === "gameloft";
};
export const isDreiBuild = () => {
  return process.env.REACT_APP_FLAVOR === "drei";
};
export const isTelecallBuild = () => {
  return process.env.REACT_APP_FLAVOR === "telecall";
};
export const isM1Build = () => {
  return process.env.REACT_APP_FLAVOR === "m1";
};
export const isAISBuild = () => {
  return process.env.REACT_APP_FLAVOR === "ais";
};

export const lockOrientationLandscape = () => {
  document.documentElement.requestFullscreen &&
    document.documentElement
      .requestFullscreen({ navigationUI: "hide" })
      .then(() => {
        screen.orientation &&
          (screen.orientation as any).lock("landscape").catch((e: any) => {
            logW(LOGGING_TAG, "Error caught locking orientation", e);
          });
      })
      .catch((e) => {
        logW(LOGGING_TAG, "Error caught requsting fullscreen", e);
      });
};

export const unlockOrientation = () => {
  document.exitFullscreen &&
    document
      .exitFullscreen()
      .then(() => {
        screen.orientation && screen.orientation.unlock();
      })

      .catch((e) => {
        logW(LOGGING_TAG, "Error caught requsting fullscreen", e);
      });
};

export const LOGGING_TAG = "App";
