import { logD } from "@blacknut/logging/dist";
import {
  AppLayout,
  List,
  Tile as TileModel,
  useLayout,
} from "@blacknut/react-client-core/lib";
import { FocusableSection, FocusableSectionProps } from "@blacknut/spatialnav-sdk/dist";
import React, { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import dimens, { getNumColumns } from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { OrientationType, useOrientation } from "../../utils/OrientationContext";
import { LOGGING_TAG, tile2Id } from "../../utils/Utils";
import CircularProgress from "../CircularProgress/CircularProgress";
import Tile from "../Tile/Tile";
import MoreTile from "../Tile/TileMore/MoreTile";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  margin-bottom: ${dimens.margins.DarkRed}rem;
  color: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.color};
  font-weight: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontWeight};
  font-size: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.size}rem;
  font-family: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontFamily};
`;

const TileList = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: ${(props: { numColumns: number }) =>
    `repeat(${props.numColumns}, 1fr)`};
  gap: ${dimens.margins.DarkRed}rem;
`;

const StyledLi = styled.li`
  margin-right: ${(props: { firstRow: boolean; lastCol: boolean }) =>
    props.lastCol ? 0 : dimens.margins.DarkRed}rem;
  margin-top: ${(props: { firstRow: boolean; lastCol: boolean }) =>
    props.firstRow ? 0 : dimens.margins.DarkRed}rem;
  @supports (display: grid) {
    margin-right: 0;
    margin-top: 0;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: ${dimens.margins.Green}rem auto 0 auto;
`;

const Container = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
`;

const arrange = (
  list: List,
  layout: AppLayout,
  orientation: OrientationType,
  lastRowComplete: boolean,
) => {
  const items = list.tiles;
  if (!lastRowComplete) return items;

  let nbItems = items.length;
  if (layout !== AppLayout.DESKTOP && list.moreLink) {
    nbItems++; // for the more tile
  }
  const nbCols = getNumColumns(layout, orientation);
  let nbRows = Math.ceil(nbItems / nbCols);
  if (nbItems % nbCols != 0) {
    nbRows--; // last row not complete
  }
  const res = items.slice(0, nbRows * nbCols);
  return res;
};

interface FullTileListProps
  extends Pick<
    FocusableSectionProps,
    "leaveFor" | "disabled" | "config" | "onNavigationFailed"
  > {
  decorate?: (
    t: TileModel,
    focused: boolean,
  ) => React.ComponentType<unknown> | React.ReactElement | null;
  onClickTile?: (t: TileModel) => void;
  className?: string;
  numColumns?: number;
  showTitle?: boolean;
  list: List;
  paginating?: boolean;
  paginate?: () => void;
  lastRowComplete?: boolean; // If true list will be trimed in order to display a full grid without empty cols
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
}
const FullTileList = ({
  list,
  decorate,
  className,
  numColumns,
  showTitle = true,
  paginating,
  paginate,
  onClickTile,
  lastRowComplete = false,
  leaveFor,
  disabled,
  config,
  onNavigationFailed,
  onFocus,
}: FullTileListProps) => {
  const { theme } = useTheme();
  const layout = useLayout();
  const { orientation } = useOrientation();
  const tiles = useMemo(
    () => arrange(list, layout, orientation, lastRowComplete),
    [list, layout, orientation, lastRowComplete],
  );
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      const total = list.total;
      if (total > list.tiles.length && !paginating) {
        logD(
          LOGGING_TAG,
          "Start pagination (uuid=%o ) (%o/%o)",
          list.uuid,
          list.tiles.length,
          total,
        );
        if (paginate) {
          paginate();
        }
      }
    }
  }, [inView, list, paginate, paginating]);
  const nbCols = numColumns || getNumColumns(layout, orientation);
  const hasTitle = showTitle && list.title && list.title.trim().length > 0;

  return (
    <Container
      className={className}
      focusKey={list.uuid}
      leaveFor={leaveFor}
      disabled={disabled}
      config={config}
      onNavigationFailed={onNavigationFailed}
      onFocus={onFocus}
    >
      {(hasTitle || (!!list.moreLink && layout === AppLayout.PHONE)) && (
        <TitleContainer>
          {hasTitle && <Title theme={theme}>{list.title}</Title>}
        </TitleContainer>
      )}
      <TileList numColumns={nbCols}>
        {tiles.map((tile, index) => {
          const _onClick = onClickTile ? () => onClickTile(tile) : undefined;
          return (
            <StyledLi
              key={`${list.uuid}-${tile2Id(tile)}`}
              firstRow={index < nbCols}
              lastCol={index % nbCols === nbCols - 1}
            >
              <Tile item={tile} list={list} decorate={decorate} onClick={_onClick} />
            </StyledLi>
          );
        })}
        {!!list.moreLink && layout !== AppLayout.PHONE && (
          <li key={`${list.uuid}-more`}>
            <MoreTile list={list} item={list.tiles[tiles.length]} />
          </li>
        )}
      </TileList>
      <div ref={ref} />

      {paginating && <StyledCircularProgress size={36} />}
    </Container>
  );
};
export default FullTileList;
