import { State } from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { QRCodeSVG } from "qrcode.react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import { useTheme } from "../../theme/ThemeProvider";
import { Content, Text } from "./FaqPage.style";
const FaqPage = () => {
  const { organization, user } = useSelector((state: State) => state.globalState);
  const { theme } = useTheme();
  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  const { t, i18n } = useTranslation();
  const locale = i18n.language || "en-US";

  useEffect(() => {
    setHeaderTitle(t("faq.title"));
    setHeaderLeft(<HeaderBackButton title={t("faq.title")} />);
    setHeaderRight(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t]);
  const url =
    organization?.supportUrl ||
    `https://www.blacknut.com/${locale.split("-")[0]}/support`;
  const { resume: resumeSpatialNav } = useSpatialNavigation();
  useEffect(() => {
    resumeSpatialNav();
  }, [resumeSpatialNav]);

  if (!user) {
    return null;
  }
  return (
    <Content theme={theme}>
      <Text theme={theme}>{t("faq.text")}</Text>

      <QRCodeSVG value={url} size={256} />
    </Content>
  );
};

export default FaqPage;
