import {
  AppLayout,
  List,
  ListDisplayType,
  useLayout,
} from "@blacknut/react-client-core/lib";
import {
  Focusable,
  FocusableSection,
  FocusableSectionProps,
} from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import React, { FocusEvent, useMemo } from "react";
import { useHistory } from "react-router";
import { ReactComponent as Arrow } from "../../../assets/dist/ic_arrow.svg";
import dimens, { getNumColumns } from "../../../theme/dimens";
import { OrientationType, useOrientation } from "../../../utils/OrientationContext";
import { tile2Id } from "../../../utils/Utils";
import { usePreview } from "../../Modals/PreviewContext";
import Tile from "../../Tile/Tile";
import ArrowButton from "../ArrowButton";
import globalListStyles from "../styles.module.scss";
import styles from "./styles.module.scss";

interface HorizontalTileListNewProps
  extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  showTitle?: boolean;
  inView?: boolean;
  className?: string;
  arrowClassName?: string;
  sectionTitleColor?: string;
  onFocus?: (e: FocusEvent<HTMLDivElement>) => void;
  isBranded?: boolean;
  scrollable?: boolean;
}

const arrange = (
  list: List,
  layout: AppLayout,
  orientation: OrientationType,
  scrollable: boolean,
) => {
  if (scrollable) return list.tiles;

  const nbCols =
    list.display === ListDisplayType.FEATURED ? 3 : getNumColumns(layout, orientation);
  return list.tiles.slice(0, nbCols);
};

const HorizontalTileListNew = ({
  list,
  showTitle = true,
  className,
  arrowClassName,
  sectionTitleColor,
  leaveFor,
  disabled,
  inView = true,
  onFocus,
  isBranded = false,
  scrollable = false,
}: HorizontalTileListNewProps) => {
  const layout = useLayout();
  const { orientation } = useOrientation();
  const [isArrowButton, setIsArrowButton] = React.useState<boolean>(false);
  const items = useMemo(
    () => arrange(list, layout, orientation, scrollable),
    [list, layout, orientation, scrollable],
  );
  const history = useHistory();
  const hasTitle = showTitle && list.title && list.title.trim().length > 0;

  const handleOnFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (onFocus) onFocus(e);

    if (window.innerWidth >= dimens.breakpoints.desktop) setIsArrowButton(true);
  };
  const { active: previewActive, goToPage: goToPagePreview } = usePreview();

  const goToCatePage = () => {
    if (previewActive && list.moreLink) {
      goToPagePreview(list.moreLink);
    } else if (!!list.moreLink) {
      history.push(`/page/${list.moreLink}`);
    }
  };

  const propsToAppearArrow = {
    onBlur: () => setIsArrowButton(false),
    onMouseEnter: () =>
      window.innerWidth >= dimens.breakpoints.desktop && setIsArrowButton(true),
    onMouseLeave: () => setIsArrowButton(false),
  };

  return (
    <FocusableSection
      className={clsx(className, styles.container)}
      focusKey={list.uuid}
      leaveFor={leaveFor}
      disabled={disabled}
      defaultElement={{ down: "first", up: ".tile" }}
      config={{ restrict: "self-first" }}
      onFocus={handleOnFocus}
      {...(isBranded && propsToAppearArrow)}
    >
      {(hasTitle || (!!list.moreLink && layout === AppLayout.PHONE)) && (
        <div className={clsx(styles.titleContainer, scrollable && styles.scrollable)}>
          {hasTitle && (
            <Focusable
              className={clsx(
                styles.title,
                !!list.moreLink && styles.titleClickable,
                isBranded && styles.titleBranded,
              )}
              style={{ color: sectionTitleColor }}
              onClick={goToCatePage}
              onPressEnter={goToCatePage}
              disabled={
                !list.moreLink || window.innerWidth >= dimens.breakpoints.desktop
              }
            >
              {list.title}
              {process.env.NODE_ENV === "development" && `(${list.display})`}
              <Arrow />
            </Focusable>
          )}
        </div>
      )}

      <div
        className={clsx(styles.listContainer, scrollable && styles.scrollable)}
        {...(!isBranded && propsToAppearArrow)}
      >
        <ul className={styles.list}>
          {items.map(
            (tile, index) =>
              (inView || index === 0) && (
                <li key={`${list.uuid}-${tile2Id(tile)}`}>
                  <Tile item={tile} list={list} />
                </li>
              ),
          )}
        </ul>

        {list.moreLink && (
          <ArrowButton
            onPress={goToCatePage}
            className={clsx(
              globalListStyles.arrowButton,
              isArrowButton && globalListStyles.visible,
              arrowClassName,
            )}
          />
        )}
      </div>
    </FocusableSection>
  );
};

export default HorizontalTileListNew;
