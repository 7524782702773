import {
  ApiError,
  ApiErrorCode,
  GENERIC_ERROR,
  TIMEOUT_ERROR,
  usePrevious,
} from "@blacknut/react-client-core/lib";
import * as React from "react";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../components/Button/V2Button";
import MessageModal from "../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../components/Modals/ModalContext";

export const useErrorHandling = (
  props: {
    error?: ApiError;
    clearError: () => void;
    callback?: () => void;
  } & Partial<ReactModal.Props>,
) => {
  const { error, clearError, callback } = props;
  const modalSubscription = useRef<ModalSubscription>();
  const prevError = usePrevious(error);

  const _dismissLoadingError = useCallback(() => {
    modalSubscription.current?.remove();
    clearError();
    if (callback) {
      callback();
    }
  }, [clearError, callback]);

  const { push: modalPush } = useModal();

  useEffect(() => {
    if (!prevError && error && error.code !== TIMEOUT_ERROR) {
      modalSubscription.current = modalPush((props) => (
        <V2ErrorHandler {...props} error={error} onClick={_dismissLoadingError} />
      ));
    }
  }, [_dismissLoadingError, error, modalPush, prevError]);
};
const V2ErrorHandler = (
  props: { error: ApiError; onClick: () => void } & Partial<ReactModal.Props> &
    Pick<ReactModal.Props, "isOpen">,
) => {
  const { error } = props;
  const { t } = useTranslation();
  let message = error.title;
  let title = t("dialogs.error.title");
  if (!message || message === GENERIC_ERROR) {
    message = t("errors.generic");
  }
  if (message === TIMEOUT_ERROR) {
    title = t("offline.title");
    message = t("offline.text2");
  }
  if (
    error.status === "406" &&
    (error.code === ApiErrorCode.OBSOLETE_API ||
      error.code === ApiErrorCode.OBSOLETE_CLIENT)
  ) {
    return null;
  }

  return (
    <MessageModal
      {...props}
      message={message || t("errors.generic")}
      title={title}
      onClose={props.onClick}
      testID="errorModal"
      buttons={[
        <PrimaryButton key="ok" onClick={props.onClick}>
          {t("buttons.ok")}
        </PrimaryButton>,
      ]}
    />
  );
};
export default V2ErrorHandler;
