export const AvatarCategories = [
  "games",
  "personalities",
  "monsters",
  "naturally",
  "foody",
];

export const AvatarImagesJSON = [
  {
    id: "games_anarcute",
    filename: "anarcute.png",
    category: "games",
  },
  {
    id: "games_anna",
    filename: "anna.png",
    category: "games",
  },
  {
    id: "games_aragami",
    filename: "aragami.png",
    category: "games",
  },
  {
    id: "games_beholder",
    filename: "beholder.png",
    category: "games",
  },
  {
    id: "games_callofJuarez",
    filename: "callofJuarez.png",
    category: "games",
  },
  {
    id: "games_chariot",
    filename: "chariot.png",
    category: "games",
  },
  {
    id: "games_dragoDino",
    filename: "dragoDino.png",
    category: "games",
  },
  {
    id: "games_evoland",
    filename: "evoland.png",
    category: "games",
  },
  {
    id: "games_garfieldKart",
    filename: "garfieldKart.png",
    category: "games",
  },
  {
    id: "games_gravel",
    filename: "gravel.png",
    category: "games",
  },
  {
    id: "games_hue",
    filename: "hue.png",
    category: "games",
  },
  {
    id: "games_mxgp3",
    filename: "mxgp3.png",
    category: "games",
  },
  {
    id: "games_outcast",
    filename: "outcast.png",
    category: "games",
  },
  {
    id: "games_raideniv",
    filename: "raideniv.png",
    category: "games",
  },
  {
    id: "games_toyboxturbo",
    filename: "toyboxturbo.png",
    category: "games",
  },
  {
    id: "games_yono",
    filename: "yono.png",
    category: "games",
  },
  {
    id: "foody_bleck",
    filename: "bleck.png",
    category: "foody",
  },
  {
    id: "foody_bob",
    filename: "bob.png",
    category: "foody",
  },
  {
    id: "foody_boo",
    filename: "boo.png",
    category: "foody",
  },
  {
    id: "foody_don",
    filename: "don.png",
    category: "foody",
  },
  {
    id: "foody_groot",
    filename: "groot.png",
    category: "foody",
  },
  {
    id: "foody_hall",
    filename: "hall.png",
    category: "foody",
  },
  {
    id: "foody_jan",
    filename: "jan.png",
    category: "foody",
  },
  {
    id: "foody_lila",
    filename: "lila.png",
    category: "foody",
  },
  {
    id: "foody_loli",
    filename: "loli.png",
    category: "foody",
  },
  {
    id: "foody_pola",
    filename: "pola.png",
    category: "foody",
  },
  {
    id: "foody_steve",
    filename: "steve.png",
    category: "foody",
  },
  {
    id: "foody_stu-aza",
    filename: "stu-aza.png",
    category: "foody",
  },
  {
    id: "monsters_akia",
    filename: "akia.png",
    category: "monsters",
  },
  {
    id: "monsters_asak",
    filename: "asak.png",
    category: "monsters",
  },
  {
    id: "monsters_blem",
    filename: "blem.png",
    category: "monsters",
  },
  {
    id: "monsters_blub",
    filename: "blub.png",
    category: "monsters",
  },
  {
    id: "monsters_burk",
    filename: "burk.png",
    category: "monsters",
  },
  {
    id: "monsters_ewai",
    filename: "ewai.png",
    category: "monsters",
  },
  {
    id: "monsters_gram",
    filename: "gram.png",
    category: "monsters",
  },
  {
    id: "monsters_gwan",
    filename: "gwan.png",
    category: "monsters",
  },
  {
    id: "monsters_moosh",
    filename: "moosh.png",
    category: "monsters",
  },
  {
    id: "monsters_otaho",
    filename: "otaho.png",
    category: "monsters",
  },
  {
    id: "monsters_slurp",
    filename: "slurp.png",
    category: "monsters",
  },
  {
    id: "monsters_valo",
    filename: "valo.png",
    category: "monsters",
  },
  {
    id: "monsters_zieut",
    filename: "zieut.png",
    category: "monsters",
  },
  {
    id: "naturally_dany",
    filename: "dany.png",
    category: "naturally",
  },
  {
    id: "naturally_fred",
    filename: "fred.png",
    category: "naturally",
  },
  {
    id: "naturally_freez",
    filename: "freez.png",
    category: "naturally",
  },
  {
    id: "naturally_gwen",
    filename: "gwen.png",
    category: "naturally",
  },
  {
    id: "naturally_lu",
    filename: "lu.png",
    category: "naturally",
  },
  {
    id: "naturally_shan",
    filename: "shan.png",
    category: "naturally",
  },
  {
    id: "naturally_sol",
    filename: "sol.png",
    category: "naturally",
  },
  {
    id: "naturally_swan",
    filename: "swan.png",
    category: "naturally",
  },
  {
    id: "naturally_tristan",
    filename: "tristan.png",
    category: "naturally",
  },
  {
    id: "naturally_truck",
    filename: "truck.png",
    category: "naturally",
  },
  {
    id: "naturally_wendy",
    filename: "wendy.png",
    category: "naturally",
  },
  {
    id: "naturally_ylana",
    filename: "ylana.png",
    category: "naturally",
  },
  {
    id: "personalities_cléo",
    filename: "cléo.png",
    category: "personalities",
  },
  {
    id: "personalities_fanzy",
    filename: "fanzy.png",
    category: "personalities",
  },
  {
    id: "personalities_game",
    filename: "game.png",
    category: "personalities",
  },
  {
    id: "personalities_jo",
    filename: "jo.png",
    category: "personalities",
  },
  {
    id: "personalities_karl",
    filename: "karl.png",
    category: "personalities",
  },
  {
    id: "personalities_lena",
    filename: "lena.png",
    category: "personalities",
  },
  {
    id: "personalities_phone",
    filename: "phone.png",
    category: "personalities",
  },
  {
    id: "personalities_sarah",
    filename: "sarah.png",
    category: "personalities",
  },
  {
    id: "personalities_seb",
    filename: "seb.png",
    category: "personalities",
  },
  {
    id: "personalities_vanessa",
    filename: "vanessa.png",
    category: "personalities",
  },
];
