import { StorageKey } from "@blacknut/react-client-core/lib";
import React, { useCallback } from "react";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import OptionModal, { Option } from "../../components/Modals/OptionModal";
import BoolOpts from "./BoolOpts";
import ListItem from "./ListItem";
const Mute = () => {
  const mute = localStorage.getItem(StorageKey.STREAM_MUTED);
  const setMute = useCallback((b: boolean) => {
    if (b) {
      localStorage.setItem(StorageKey.STREAM_MUTED, "true");
    } else {
      localStorage.removeItem(StorageKey.STREAM_MUTED);
    }
  }, []);
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();

  const _closeModal = React.useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const _onSelectMute = React.useCallback(
    (opt: Option) => {
      _closeModal();
      if (opt.key === "on" && !mute) {
        setMute(true);
      } else if (opt.key === "off" && mute) {
        setMute(false);
      }
    },
    [_closeModal, mute, setMute],
  );

  const openModal = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Stream muted"
        onSelectOption={_onSelectMute}
        options={BoolOpts}
        selected={mute ? "on" : "off"}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, modalPush, _onSelectMute, mute]);
  return <ListItem name="Mute" value={mute ? "ON" : "OFF"} onClick={openModal} />;
};

export default Mute;
