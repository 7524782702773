import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionSubStatus } from "./SubscriptionKind";

const SubscriptionPost = () => {
    const { theme } = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <SubscriptionSubStatus theme={theme}>{t("account.subscription.post.text2")}</SubscriptionSubStatus>
        </>
    );
};
export default SubscriptionPost;
