import styled from "styled-components";
import { Theme } from "../../theme/Theme";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 38rem;
    align-items: center;
`;

export const Text = styled.h1<{ theme: Theme }>`
    text-align: center;
    word-break: break-word;
    color: ${(props) => props.theme.titleTextStyle.color};
    font-size: ${(props) => props.theme.titleTextStyle.size}rem;
    font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
    font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
`;
