import { is_iOS } from "@blacknut/javascript-sdk/dist";
import { isInStandaloneMode } from "src/utils/Utils";

export const ViewOrNot = (deviceInfos: any) => {
  const { browserName, userAgent, deviceType, deviceModel } = deviceInfos;

  if (isInStandaloneMode()) return "false";
  if (browserName === "chrome") {
    if (userAgent === "android" || deviceType === "desktop" || deviceModel === "ipad") {
      return "true";
    }
  } else if (browserName === "mobile-safari") {
    if (deviceModel === "ipad" || deviceModel === "iphone") {
      return "true";
    }
  } else if (browserName === "safari" && is_iOS()) {
    return "true";
  }
  return "false";
};
