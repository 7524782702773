import { AppLayout, UserProfile } from "@blacknut/react-client-core/lib";
import * as React from "react";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";

import Profile from "./ProfileCardItem";
import { useSelector } from "react-redux";
import styles from "src/pages/Profiles/styles.module.scss";
import { State } from "@blacknut/react-client-core/lib";

export interface ProfileAddCardProps {
  profilMaster: UserProfile;
  onSelectProfile: (
    aProfile: UserProfile,
    redirectUrl?: string | undefined,
  ) => Promise<void>;
}

const ProfileAddCard = ({ profilMaster, onSelectProfile }: ProfileAddCardProps) => {
  const { pause: pauseSpatialNav } = useSpatialNavigation();
  const onClick = () => {
    pauseSpatialNav();
    onSelectProfile(profilMaster, "/account/profiles/new");
  };
  return (
    <li className={styles.item} key="add">
      <Profile onClick={onClick} profile={profilMaster} addTile={true} />
    </li>
  );
};

export default ProfileAddCard;
