import { networkService } from "@blacknut/react-client-core/lib";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import { PrimaryButton } from "../../components/Button/V2Button";
import Header from "../../components/Header/Header";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";

const Container = styled.div`
  background-color: ${(props: { theme: Theme }) => props.theme.backgroundColor};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: ${dimens.margins.LightGreen}rem;
`;

const Content = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  min-width: 32rem;
  text-align: center;
`;

const Title = styled.div<{ theme: Theme }>`
  color: ${(props) => props.theme.titleTextStyle.color};
  font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
  font-size: ${(props) => props.theme.titleTextStyle.size}rem;
  font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
`;

const Text = styled.div<{ theme: Theme }>`
  color: ${(props) => props.theme.textStyle.color};
  font-weight: ${(props) => props.theme.textStyle.fontWeight};
  font-size: ${(props) => props.theme.textStyle.size}rem;
  font-family: ${(props) => props.theme.textStyle.fontFamily};
`;

const Button = styled(PrimaryButton)`
  margin-top: ${dimens.margins.Green}rem;
  width: 100%;
`;

const OfflinePage = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const retry = React.useCallback(() => {
    networkService.emitOnline();
    history.replace("/");
  }, [history]);
  return (
    <Container theme={theme}>
      <Header />
      <Content focusKey="offline" leaveFor={{ up: "", down: "", left: "", right: "" }}>
        <Title theme={theme}>{t("offline.title")}</Title>
        <Text theme={theme}>
          {t("offline.text1", { appName: process.env.REACT_APP_APP_NAME })}
        </Text>
        <Text theme={theme}>{t("offline.text2")}</Text>
        <Button theme={theme} onClick={retry}>
          {t("buttons.retry")}
        </Button>
      </Content>
    </Container>
  );
};
export default OfflinePage;
