import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import dimens from "../../theme/dimens";
import { HeaderStyle, Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import electronService from "../../services/ElectronService";
import { State } from "@blacknut/react-client-core/lib";
import { useSelector } from "react-redux";
import { SubscriptionKind } from "@blacknut/javascript-sdk/dist";

const overrideIfNeeded = <T extends unknown>(
  key: keyof HeaderStyle,
  _default: Partial<HeaderStyle>,
  override?: Partial<HeaderStyle>,
): T | undefined => {
  if (override) return override[key] as T | undefined;
  return _default[key] as T | undefined;
};

const StyledHeader = styled(FocusableSection)<{
  theme: Theme;
  headerStyle?: Partial<HeaderStyle>;
  hidden: boolean;
}>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${dimens.phone.headerHeight}rem;
  background-color: ${(props) =>
    overrideIfNeeded("backgroundColor", props.theme.headerStyle, props.headerStyle)};
  border-bottom: ${(props) =>
      overrideIfNeeded("borderWidth", props.theme.headerStyle, props.headerStyle)}rem
    solid
    ${(props) =>
      overrideIfNeeded("borderColor", props.theme.headerStyle, props.headerStyle)};
  -webkit-app-region: drag;
  z-index: 11;
  padding: 0 0.3rem;
  flex-shrink: 0;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    height: ${dimens.desktop.headerHeight}rem;
  }
`;

const LogoContainer = styled.div<{ theme: Theme; centered: boolean }>`
  cursor: ${(props) => (props.onClick ? "pointer" : undefined)};
  margin-left: ${(props) =>
    props.centered ? "auto" : `${dimens.margins.LightGreen}rem`};
  margin-right: ${(props) => (props.centered ? "auto" : undefined)};

  > svg {
    vertical-align: middle;
    width: ${(props) => props.theme.logoSize.width}rem;
    height: ${(props) => props.theme.logoSize.height}rem;
  }

  > span > svg {
    vertical-align: middle;
    width: ${(props) => props.theme.logoAISSize.width}rem;
    height: ${(props) => props.theme.logoAISSize.height}rem;
  }
`;

const Title = styled.div<{ theme: Theme }>`
  margin-left: ${dimens.margins.LightGreen}rem;
  color: ${(props) => props.theme.headerStyle.textStyle.color};
  font-family: ${(props) => props.theme.headerStyle.textStyle.fontFamily};
  font-size: ${(props) => props.theme.headerStyle.textStyle.size}rem;
  font-weight: ${(props) => props.theme.headerStyle.textStyle.fontWeight};

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    display: none;
  }
`;

const HeaderRightContainer = styled.div`
  margin-right: ${dimens.margins.Green}rem;
  margin-left: auto;

  @media screen and (min-height: 568px) and (orientation: landscape) {
    display: none;
  }
`;

const HeaderLeftContainer = styled.div<{ darwin: boolean }>`
  margin-left: ${(props) => (props.darwin ? 0 : dimens.margins.Green)}rem;
  max-width: calc(100vw - ${(props) => (props.darwin ? 0 : dimens.margins.Green)}rem);
`;

interface HeaderProps {
  title?: string;
  headerRight?: JSX.Element;
  headerRightContainer?: JSX.Element;
  headerLeft?: JSX.Element;
  headerStyle?: Partial<HeaderStyle>;
  className?: string;
  hidden?: boolean;
}

const WebHeader = ({
  title,
  headerRight,
  headerLeft,
  headerStyle,
  className,
  hidden = false,
  headerRightContainer,
}: HeaderProps) => {
  const { theme } = useTheme();
  const history = useHistory();
  const { familySubscription } = useSelector((state: State) => state.profilesState);
  const isVirginSub =
    SubscriptionKind.VIRGIN_MOBILE &&
    familySubscription?.subscription?.kind === SubscriptionKind.VIRGIN_MOBILE;

  const goToSlash = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <StyledHeader
      focusKey="header"
      component="header"
      theme={theme}
      className={className}
      headerStyle={headerStyle}
      hidden={hidden}
      disabled={hidden}
    >
      {headerLeft && (
        <HeaderLeftContainer darwin={electronService.getPlatform() === "darwin"}>
          {headerLeft}
        </HeaderLeftContainer>
      )}
      {!headerLeft && headerRight && <div />}

      {title && !headerLeft && <Title theme={theme}>{title}</Title>}

      {/* LOGO */}
      {title === undefined && (
        <LogoContainer
          onClick={goToSlash}
          theme={theme}
          data-testid="logo"
          centered={false}
        >
          {isVirginSub
            ? theme.images.LogoAIS && (
                <span>
                  <theme.images.LogoAIS />
                </span>
              )
            : theme.images.Logo && <theme.images.Logo />}
        </LogoContainer>
      )}

      {headerRight && !headerRightContainer && (
        <HeaderRightContainer>{headerRight}</HeaderRightContainer>
      )}
      {headerRightContainer}
    </StyledHeader>
  );
};

export default WebHeader;
