import { usePrevious } from "@blacknut/react-client-core/lib";
import {
  bachEnableSections,
  disableAllSectionsExcept,
  FocusableSection,
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button, CustomBackSubscription, useCustomBack } from "@blacknut/react-sdk/dist";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { ReactComponent as Close } from "../../assets/dist/ic_close.svg";
import styles from "./styles.module.scss";

export interface OnCloseEvent {
  preventGoBack?: boolean;
}

export interface ModalProps extends ReactModal.Props {
  title?: string;
  testID?: string;
  onClose?: (e?: OnCloseEvent) => void;
  buttons?: React.ReactNode;
  onAfterOpen?: () => void;
  closeButtonText?: string;
  // Auto add OK button is close prop is set bu no buttons
  addOKButton?: boolean;
  addCloseButton?: boolean;
  defaultFocus?: string;
}

const StyledTitle = styled.h2<{ theme: Theme }>`
  color: ${(props) => props.theme.modalStyle.titleTextStyle.color};
  text-align: left;
  padding: 0;
  margin: 0 0 ${dimens.margins.Green}rem 0;
  font-weight: ${(props) => props.theme.modalStyle.titleTextStyle.fontWeight};
  font-size: ${(props) => props.theme.modalStyle.titleTextStyle.size}rem;
`;

const StyledButtonBar = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.theme.modalStyle.buttonsAlignment == "start"
      ? "flex-start"
      : props.theme.modalStyle.buttonsAlignment == "end"
      ? "flex-end"
      : "center"};
  margin-top: ${dimens.margins.Orange}rem;
  align-items: center;
  & > * {
    margin-right: ${dimens.margins.Green}rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const StyledReactModal = styled(ReactModal)`
  @media (orientation: landscape) {
    max-width: 80vw;
  }
`;
const Modal = ({
  isOpen,
  title,
  buttons,
  onClose,
  onAfterOpen,
  className,
  closeButtonText,
  addOKButton = true,
  addCloseButton = false,
  testID,
  defaultFocus,
  children,
}: React.PropsWithChildren<ModalProps>) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const disabledSections = React.useRef([] as string[]);
  const { active: spatialNavigationActive, resume } = useSpatialNavigation();
  const { push: backPush } = useCustomBack();
  const backSubscription = React.useRef<CustomBackSubscription>();
  const historyUnregisterCallback = React.useRef<() => void>();
  //Custom back on tizen
  React.useEffect(() => {
    if (isOpen && onClose) {
      backSubscription.current = backPush(() => {
        onClose();
        return true;
      });
    }
    return () => {
      backSubscription.current?.remove();
      backSubscription.current = undefined;
    };
  }, [backPush, isOpen, onClose]);

  React.useEffect(() => {
    return () => {
      if (historyUnregisterCallback.current) {
        historyUnregisterCallback.current();
        historyUnregisterCallback.current = undefined;
      }
    };
  }, []);

  const _onAfterOpen = React.useCallback(() => {
    resume();
    //Disable all focusable sections except the current one
    disabledSections.current = disableAllSectionsExcept("/modal");
    if (spatialNavigationActive) {
      focusSectionWithPath("/modal");
    }

    if (onAfterOpen) {
      onAfterOpen();
    }
  }, [spatialNavigationActive, onAfterOpen, resume]);

  //Fix using Backspace key on PC to go back (onClose never called)
  const history = useHistory();
  const prevLocation = usePrevious(history.location.pathname);
  React.useEffect(() => {
    if (isOpen) {
      const unreg = history.listen((location) => {
        if (prevLocation /*fix for tests */ && prevLocation !== location.pathname) {
          // location has changed while being opened, this indicates using Backspace on PC to go back
          // but on close has never been called, and modal subscriptions not removed
          // do it now
          onClose && onClose({ preventGoBack: true });
        }
      });
      historyUnregisterCallback.current = unreg;
    }
    return () => {
      //NOP
    };
  }, [history, isOpen, onClose, prevLocation]);

  const _onAfterClose = React.useCallback(() => {
    bachEnableSections(disabledSections.current);

    //Wait till app.router disable spatial nav to reenable it
    setTimeout(() => {
      resume();
    }, 200);
  }, [resume]);

  React.useEffect(() => {
    if (spatialNavigationActive) {
      focusSectionWithPath("/modal");
    }
  }, [spatialNavigationActive]);

  const _onRequestClose = React.useCallback(() => {
    onClose && onClose();
  }, [onClose]);
  return (
    <StyledReactModal
      testId={testID}
      isOpen={isOpen}
      onAfterOpen={_onAfterOpen}
      className={className}
      onRequestClose={_onRequestClose}
      onAfterClose={_onAfterClose}
      style={{
        overlay: {
          position: "fixed",
          zIndex: 1002,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.modalStyle.overlayColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          width: window.innerWidth >= 960 ? 680 : "95%",
          background: theme.modalStyle.backgroundColor,
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "6px",
          outline: "none",
          padding: `${theme.modalStyle.padding}rem`,
          border: "none",
          borderTop: `5px solid ${theme.rippleColor}`,
          color: "#000",
          boxShadow: "0 0 24px rgba(0,0,0,.3)",
        },
      }}
    >
      <FocusableSection
        focusKey="modal"
        leaveFor={{ down: "" }}
        config={{ restrict: "self-only" }}
        {...(defaultFocus && { ...{ defaultElement: { down: defaultFocus } } })}
      >
        {addCloseButton && (
          <Button
            variant="iconButton"
            className={styles.button}
            onClick={_onRequestClose}
            testID="close"
          >
            <Close />
          </Button>
        )}

        {title && <StyledTitle theme={theme}>{title}</StyledTitle>}

        <div>{children}</div>

        {(buttons || addOKButton) && (
          <StyledButtonBar theme={theme}>
            {buttons}
            {!buttons && addOKButton && onClose && (
              <Button
                variant={buttons ? "secondary" : "primary"}
                onClick={_onRequestClose}
                testID="ok"
              >
                {closeButtonText || t("buttons.ok")}
              </Button>
            )}
          </StyledButtonBar>
        )}
      </FocusableSection>
    </StyledReactModal>
  );
};

export default Modal;
