import React from "react";
import { HeaderStyle } from "../../theme/Theme";

export interface HeaderContextProps {
  setTitle: (s: string | undefined) => void;
  setHeaderRight: (e?: JSX.Element) => void;
  setHeaderLeft: (e?: JSX.Element) => void;
  setHeaderStyle: (style: Partial<HeaderStyle> | undefined) => void;

  showHeader: () => void;
  hideHeader: () => void;
  headerHidden: boolean;
}
export const HeaderContext = React.createContext<HeaderContextProps>({
  setTitle: () => {
    console.warn("Plz wrap component in a header provider");
  },
  setHeaderRight: () => {
    console.warn("Plz wrap component in a header provider");
  },
  setHeaderLeft: () => {
    console.warn("Plz wrap component in a header provider");
  },
  showHeader: () => {
    console.warn("Plz wrap component in a header provider");
  },
  hideHeader: () => {
    console.warn("Plz wrap component in a header provider");
  },
  setHeaderStyle: () => {
    console.warn("Plz wrap component in a header provider");
  },
  headerHidden: false,
});

export const useHeader = () => {
  return React.useContext(HeaderContext);
};

//FIXME remove once gameplay page has been refactored
export function withHeaderContext<TOriginalProps extends {}>(
  Component: React.ComponentType<TOriginalProps>,
) {
  return (props: TOriginalProps) => {
    const ctx = React.useContext(HeaderContext);

    return (<Component {...props} {...ctx} />) as React.ReactElement<
      Omit<
        TOriginalProps,
        | "hideHeader"
        | "showHeader"
        | "setHeaderLeft"
        | "setHeaderRight"
        | "setTitle"
        | "headerHidden"
      >
    >;
  };
}
