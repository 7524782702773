import {
  Lang,
  setUpdateAvailable,
  State,
  StorageKey,
  streamStopped,
  usePrevious,
} from "@blacknut/react-client-core/lib";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import electronService, {
  ElectronEvent,
  ElectronEventType,
} from "../../services/ElectronService";
import LocalStorageService from "../../services/LocalStorageService";
import { PrimaryButton } from "../Button/V2Button";
import MessageModal from "../Modals/MessageModal";
import { ModalSubscription, useModal } from "../Modals/ModalContext";

const UpdateManager = () => {
  const { user, frontUrl, updateAvailable } = useSelector(
    (state: State) => state.globalState,
  );
  const { resolvedStream: playedStream } = useSelector(
    (state: State) => state.gamesState,
  );
  const dispatch = useDispatch();
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const { t, i18n } = useTranslation();

  const updateChecked = useRef(false);

  const closeModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const onUpdateWebConfirmed = useCallback(() => {
    dispatch(setUpdateAvailable(false));
    closeModal();
    window.location.reload();
  }, [closeModal, dispatch]);

  const goToDownload = useCallback(() => {
    closeModal();
    const downloadUrl =
      process.env.REACT_APP_DOWNLOAD_URL ||
      `${frontUrl}/${(i18n.language || Lang.ENGLISH).split("-")[0]}/download`;

    LocalStorageService.removeItem(StorageKey.UPDATE_AVAILABLE);
    window.open(downloadUrl);
  }, [closeModal, frontUrl, i18n.language]);

  const openElectronUpdateError = useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <MessageModal
        {...props}
        title={t("dialogs.updateApp.title")}
        message={t("dialogs.updateApp.updateManual")}
        buttons={
          <PrimaryButton key="ok" onClick={goToDownload}>
            {t("buttons.ok")}
          </PrimaryButton>
        }
      />
    ));
  }, [goToDownload, modalPush, t]);

  const onUpdateConfirmed = useCallback(() => {
    closeModal();
    if (playedStream) {
      streamStopped(
        playedStream.stream.id,
        "Exit after update",
        undefined,
        true,
      )(dispatch);
    }

    LocalStorageService.removeItem(StorageKey.UPDATE_AVAILABLE);
    electronService.acceptUpdate();
  }, [closeModal, dispatch, playedStream]);

  const openElectronUpdateInfo = useCallback(
    (newVersion: string) => {
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          title={t("dialogs.updateApp.title")}
          message={t("dialogs.updateApp.message", { version: newVersion })}
          buttons={
            <PrimaryButton key="ok" onClick={onUpdateConfirmed}>
              {t("buttons.restart")}
            </PrimaryButton>
          }
        />
      ));
    },
    [modalPush, onUpdateConfirmed, t],
  );

  const prevUpdateAvailable = usePrevious(updateAvailable);
  useEffect(() => {
    if (updateAvailable && !prevUpdateAvailable) {
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          title={t("dialogs.updateApp.title")}
          message={t("dialogs.updateApp.updateWeb")}
          buttons={
            <PrimaryButton key="ok" onClick={onUpdateWebConfirmed}>
              {t("buttons.restart")}
            </PrimaryButton>
          }
        />
      ));
    }
  }, [modalPush, onUpdateWebConfirmed, prevUpdateAvailable, t, updateAvailable]);

  useEffect(() => {
    if (user) {
      const checkUpdates =
        (process.env.REACT_APP_CHECK_FOR_UPDATES === undefined ||
          process.env.REACT_APP_CHECK_FOR_UPDATES === "true") &&
        electronService.isAvailable() &&
        !updateChecked.current;

      if (!checkUpdates) {
        return () => {
          //nop
        };
      }

      const subscription = electronService
        .onElectronSubject()
        .subscribe((e: ElectronEvent) => {
          const { event, data } = e;
          switch (event) {
            case ElectronEventType.onUpdateDownloaded:
              LocalStorageService.getItem(StorageKey.UPDATE_AVAILABLE).then(
                (count: number) => {
                  count = count || 0;
                  if (count > 2) {
                    openElectronUpdateError();
                    return;
                  } else {
                    openElectronUpdateInfo(data as string);
                  }
                  LocalStorageService.setItem(StorageKey.UPDATE_AVAILABLE, count + 1);
                },
              );
              break;
            case ElectronEventType.onUpdateError:
              LocalStorageService.getItem(StorageKey.UPDATE_AVAILABLE).then(
                (count: number) => {
                  count = count || 0;
                  if (count > 2) {
                    openElectronUpdateError();
                    return;
                  }
                  LocalStorageService.setItem(StorageKey.UPDATE_AVAILABLE, count + 1);
                },
              );
              break;
          }
        });
      updateChecked.current = true;
      electronService.checkUpdate();
      return () => {
        subscription.unsubscribe();
      };
    }
    return () => {
      //nope
    };
  }, [openElectronUpdateError, openElectronUpdateInfo, updateChecked, user]);
  return null;
};
export default React.memo(UpdateManager);
