import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import Modal, { ModalProps } from "../Modals/Modal";
import styles from "./styles.module.scss";
import { AvatarCategories, AvatarImagesJSON } from "src/utils/avatar";
import { useState } from "react";
import { isPhone } from "@blacknut/javascript-sdk/dist";
import clsx from "clsx";
import AvatarImage from "./AvatarImage";

interface AvatarSelectionModalProps {
  onSelectAvatar: (id: string) => void;
  currentAvatar: string;
}

const AvatarSelectionModalNew = (props: AvatarSelectionModalProps & ModalProps) => {
  const { onSelectAvatar, currentAvatar } = props;
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const avatarCurrentCategory = AvatarImagesJSON.find(
    (avatar) => avatar?.id === currentAvatar,
  )?.category;

  const [currentCategory, setCurrentCategory] = useState<string>(
    avatarCurrentCategory || AvatarCategories[0],
  );

  const isOldAvatarSelected = (avatarId: string, currentValue: string) =>
    (avatarId === "games_anarcute" && currentValue === "1") ||
    (avatarId === "games_anna" && currentValue === "2") ||
    (avatarId === "games_aragami" && currentValue === "3") ||
    (avatarId === "games_beholder" && currentValue === "4") ||
    (avatarId === "games_callofJuarez" && currentValue === "5") ||
    (avatarId === "games_chariot" && currentValue === "6") ||
    (avatarId === "games_dragoDino" && currentValue === "7") ||
    (avatarId === "games_evoland" && currentValue === "8") ||
    (avatarId === "games_garfieldKart" && currentValue === "9") ||
    (avatarId === "games_gravel" && currentValue === "10") ||
    (avatarId === "games_hue" && currentValue === "11") ||
    (avatarId === "games_mxgp3" && currentValue === "12") ||
    (avatarId === "games_outcast" && currentValue === "13") ||
    (avatarId === "games_raideniv" && currentValue === "14") ||
    (avatarId === "games_toyboxturbo" && currentValue === "15") ||
    (avatarId === "games_yono" && currentValue === "16");

  const renderAvatar = React.useCallback(
    (avatar: { id: string; category: string; filename: string }) => {
      const onClick = () => onSelectAvatar(avatar?.id);
      return (
        <Focusable
          onPressEnter={onClick}
          onClick={onClick}
          key={avatar?.id}
          data-testid={avatar?.id}
          className={styles.focusAvatar}
        >
          <AvatarImage
            avatarId={avatar?.id}
            isActive={
              currentAvatar === avatar?.id ||
              isOldAvatarSelected(avatar?.id, currentAvatar)
            }
            key={avatar?.id}
            isHoverable={true}
          />
        </Focusable>
      );
    },
    [currentAvatar, onSelectAvatar],
  );

  return (
    <Modal
      {...props}
      addCloseButton={true}
      addOKButton={false}
      className={clsx([isPhone() ? styles.fullscreen : styles.content])}
    >
      <div className={styles.container}>
        <ul
          className={clsx([
            styles.list,
            spatialNavigationActive && styles.spatialActiveList,
          ])}
        >
          {AvatarCategories.map((category, i) => {
            return (
              <Focusable
                onPressEnter={() => setCurrentCategory(category)}
                onClick={() => setCurrentCategory(category)}
                key={`category_${i}`}
                data-testid={`category_${i}`}
                className={clsx([
                  styles.focusCategory,
                  spatialNavigationActive && styles.spatialActive,
                ])}
              >
                <li
                  className={clsx([
                    styles.listItem,
                    currentCategory === category && styles.listIem_avatarCurrentCategory,
                  ])}
                  key={`category_${i}`}
                  onClick={() => setCurrentCategory(category)}
                >
                  <p>{category}</p>
                </li>
              </Focusable>
            );
          })}
        </ul>

        <div className={styles.separator} />

        <div className={styles.avatarContainer}>
          {AvatarImagesJSON?.filter((av) => av.category === currentCategory).map((e) =>
            renderAvatar(e),
          )}
        </div>
      </div>
    </Modal>
  );
};
export default AvatarSelectionModalNew;
