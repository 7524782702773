/* eslint-disable no-plusplus */
/* eslint-disable valid-typeof */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */

export default function uaParser(ua: any) {
  /**
   * Constants
   */
  const EMPTY = "";
  const UNKNOWN = "?";
  const FUNC_TYPE = "function";
  const UNDEF_TYPE = "undefined";
  const OBJ_TYPE = "object";
  const STR_TYPE = "string";
  const MODEL = "model";
  const NAME = "name";
  const TYPE = "type";
  const VENDOR = "vendor";
  const VERSION = "version";
  const CONSOLE = "console";
  const MOBILE = "mobile";
  const TABLET = "tablet";
  const SMARTTV = "smarttv";
  const UA_MAX_LENGTH = 350;

  const AMAZON = "Amazon";
  const APPLE = "Apple";
  const ASUS = "ASUS";
  const BROWSER = "Browser";
  const CHROME = "Chrome";
  const FIREFOX = "Firefox";
  const GOOGLE = "Google";
  const HUAWEI = "Huawei";
  const LG = "LG";
  const MICROSOFT = "Microsoft";
  const MOTOROLA = "Motorola";
  const OPERA = "Opera";
  const SAMSUNG = "Samsung";
  const SHARP = "Sharp";
  const SONY = "Sony";
  const XIAOMI = "Xiaomi";

  /**
   * Helper
   */

  const lowerize = function (str: any) {
    return str.toLowerCase();
  };

  const has = function (str1: any, str2: any) {
    return typeof str1 === STR_TYPE
      ? lowerize(str2).indexOf(lowerize(str1)) !== -1
      : false;
  };

  const trim = function (str: any, len: any) {
    if (typeof str === STR_TYPE) {
      str = str.replace(/^\s\s*/, EMPTY).replace(/\s\s*$/, EMPTY);
      return typeof len === UNDEF_TYPE ? str : str.substring(0, UA_MAX_LENGTH);
    }
    return false;
  };

  /// ////////////
  // Map helper
  /// ///////////

  const rgxMapper = function (this: any, uaData: any, arrays: any) {
    let i = 0;
    let j;
    let k;
    let p;
    let q;
    let matches;
    let match;

    // loop through all regexes maps
    while (i < arrays.length && !matches) {
      const regex = arrays[i]; // even sequence (0,2,4,..)
      const props = arrays[i + 1]; // odd sequence (1,3,5,..)
      j = k = 0;

      // try matching uastring with regexes
      while (j < regex.length && !matches) {
        matches = regex[j++].exec(uaData);

        if (matches) {
          for (p = 0; p < props.length; p++) {
            match = matches[++k];
            q = props[p];
            // check if given property is actually array
            if (typeof q === OBJ_TYPE && q.length > 0) {
              if (q.length === 2) {
                if (typeof q[1] === FUNC_TYPE) {
                  // assign modified match
                  this[q[0]] = q[1].call(this, match);
                } else {
                  // assign given value, ignore regex match
                  this[q[0]] = q[1];
                }
              } else if (q.length === 3) {
                // check whether function or regex
                if (typeof q[1] === FUNC_TYPE && !(q[1].exec && q[1].test)) {
                  // call function (usually string mapper)
                  this[q[0]] = match ? q[1].call(this, match, q[2]) : undefined;
                } else {
                  // sanitize match using given regex
                  this[q[0]] = match ? match.replace(q[1], q[2]) : undefined;
                }
              } else if (q.length === 4) {
                this[q[0]] = match
                  ? q[3].call(this, match.replace(q[1], q[2]))
                  : undefined;
              }
            } else {
              this[q] = match || undefined;
            }
          }
        }
      }
      i += 2;
    }
  };
  const strMapper = function (str: any, map: any) {
    for (const i in map) {
      // check if current value is array
      if (typeof map[i] === OBJ_TYPE && map[i].length > 0) {
        for (let j = 0; j < map[i].length; j++) {
          if (has(map[i][j], str)) {
            return i === UNKNOWN ? undefined : i;
          }
        }
      } else if (has(map[i], str)) {
        return i === UNKNOWN ? undefined : i;
      }
    }
    return str;
  };

  /// ////////////
  // String map
  /// ///////////

  // Safari < 3.0
  const oldSafariMap = {
    "1.0": "/8",
    1.2: "/1",
    1.3: "/3",
    "2.0": "/412",
    "2.0.2": "/416",
    "2.0.3": "/417",
    "2.0.4": "/419",
    "?": "/",
  };
  const windowsVersionMap = {
    ME: "4.90",
    "NT 3.11": "NT3.51",
    "NT 4.0": "NT4.0",
    2000: "NT 5.0",
    XP: ["NT 5.1", "NT 5.2"],
    Vista: "NT 6.0",
    7: "NT 6.1",
    8: "NT 6.2",
    8.1: "NT 6.3",
    10: ["NT 6.4", "NT 10.0"],
    RT: "ARM",
  };

  /// ///////////
  // Regex map
  /// //////////

  const regexes = {
    browser: [
      [
        // Chrome for Android/iOS
        /\b(?:crmo|crios)\/([\w\.]+)/i,
      ],
      [VERSION, [NAME, "Chrome"]],

      [
        // Microsoft Edge
        /edg(?:e|ios|a)?\/([\w\.]+)/i,
      ],
      [VERSION, [NAME, "Edge"]],

      [
        // Presto based
        /(opera mini)\/([-\w\.]+)/i, // Opera Mini
        /(opera [mobiletab]{3,6})\b.+version\/([-\w\.]+)/i, // Opera Mobi/Tablet
        /(opera)(?:.+version\/|[\/ ]+)([\w\.]+)/i, // Opera
      ],
      [NAME, VERSION],

      [
        // Opera mini on iphone >= 8.0
        /opios[\/ ]+([\w\.]+)/i,
      ],
      [VERSION, [NAME, `${OPERA} Mini`]],

      [
        // Opera Webkit
        /\bopr\/([\w\.]+)/i,
      ],
      [VERSION, [NAME, OPERA]],

      [
        // IE11
        /trident.+rv[: ]([\w\.]{1,9})\b.+like gecko/i,
      ],
      [VERSION, [NAME, "IE"]],

      [
        // Opera Touch
        /\bopt\/([\w\.]+)/i,
      ],
      [VERSION, [NAME, `${OPERA} Touch`]],

      [
        // Opera Coast
        /coast\/([\w\.]+)/i,
      ],
      [VERSION, [NAME, `${OPERA} Coast`]],

      [
        // Firefox for iOS
        /fxios\/([-\w\.]+)/i,
      ],
      [VERSION, [NAME, FIREFOX]],

      [
        // Chrome WebView
        / wv\).+(chrome)\/([\w\.]+)/i,
      ],
      [[NAME, `${CHROME} WebView`], VERSION],

      [
        // Android Browser
        /droid.+ version\/([\w\.]+)\b.+(?:mobile safari|safari)/i,
      ],
      [VERSION, [NAME, `Android ${BROWSER}`]],

      [
        // Chrome/OmniWeb/Arora/Tizen/Nokia
        /(chrome|omniweb|arora|[tizenoka]{5} ?browser)\/v?([\w\.]+)/i,
      ],
      [NAME, VERSION],

      [
        // Mobile Safari
        /version\/([\w\.\,]+) .*mobile\/\w+ (safari)/i,
      ],
      [VERSION, [NAME, "Mobile Safari"]],

      [
        // Safari & Safari Mobile
        /version\/([\w(\.|\,)]+) .*(mobile ?safari|safari)/i,
      ],
      [VERSION, NAME],

      [
        // Safari < 3.0
        /webkit.+?(mobile ?safari|safari)(\/[\w\.]+)/i,
      ],
      [NAME, [VERSION, strMapper, oldSafariMap]],
      [/(webkit|khtml)\/([\w\.]+)/i],
      [NAME, VERSION],
    ],

    device: [
      /**
       * MOBILES & TABLETS
       * Ordered by popularity
       */
      [
        // Samsung
        /\b(sch-i[89]0\d|shw-m380s|sm-[ptx]\w{2,4}|gt-[pn]\d{2,4}|sgh-t8[56]9|nexus 10)/i,
      ],
      [MODEL, [VENDOR, SAMSUNG], [TYPE, TABLET]],
      [
        /\b((?:s[cgp]h|gt|sm)-\w+|galaxy nexus)/i,
        /samsung[- ]([-\w]+)/i,
        /sec-(sgh\w+)/i,
      ],
      [MODEL, [VENDOR, SAMSUNG], [TYPE, MOBILE]],

      [
        // Apple
        // iPod/iPhone
        /\((ip(?:hone|od)[\w ]*);/i,
      ],
      [MODEL, [VENDOR, APPLE], [TYPE, MOBILE]],

      [
        // iPad
        /\((ipad);[-\w\),; ]+apple/i,
        /applecoremedia\/[\w\.]+ \((ipad)/i,
        /\b(ipad)\d\d?,\d\d?[;\]].+ios/i,
      ],
      [MODEL, [VENDOR, APPLE], [TYPE, TABLET]],

      [
        // Huawei
        /\b((?:ag[rs][23]?|bah2?|sht?|btv)-a?[lw]\d{2})\b(?!.+d\/s)/i,
      ],
      [MODEL, [VENDOR, HUAWEI], [TYPE, TABLET]],
      [
        /(?:huawei|honor)([-\w ]+)[;\)]/i,
        /\b(nexus 6p|\w{2,4}e?-[atu]?[ln][\dx][012359c][adn]?)\b(?!.+d\/s)/i,
      ],
      [MODEL, [VENDOR, HUAWEI], [TYPE, MOBILE]],

      [
        // Xiaomi
        // Xiaomi POCO
        // Xiaomi Hongmi 'numeric' models
        // Xiaomi Hongmi
        // Xiaomi Redmi
        // Xiaomi Mi
        /\b(poco[\w ]+)(?: bui|\))/i,
        /\b; (\w+) build\/hm\1/i,
        /\b(hm[-_ ]?note?[_ ]?(?:\d\w)?) bui/i,
        /\b(redmi[\-_ ]?(?:note|k)?[\w_ ]+)(?: bui|\))/i,
        /\b(mi[-_ ]?(?:a\d|one|one[_ ]plus|note lte|max|cc)?[_ ]?(?:\d?\w?)[_ ]?(?:plus|se|lite)?)(?: bui|\))/i,
      ],
      [
        [MODEL, /_/g, " "],
        [VENDOR, XIAOMI],
        [TYPE, MOBILE],
      ],

      [
        // Mi Pad tablets
        /\b(mi[-_ ]?(?:pad)(?:[\w_ ]+))(?: bui|\))/i,
      ],
      [
        [MODEL, /_/g, " "],
        [VENDOR, XIAOMI],
        [TYPE, TABLET],
      ],

      [
        // OPPO
        /; (\w+) bui.+ oppo/i,
        /\b(cph[12]\d{3}|p(?:af|c[al]|d\w|e[ar])[mt]\d0|x9007|a101op)\b/i,
      ],
      [MODEL, [VENDOR, "OPPO"], [TYPE, MOBILE]],

      [
        // Vivo
        /vivo (\w+)(?: bui|\))/i,
        /\b(v[12]\d{3}\w?[at])(?: bui|;)/i,
      ],
      [MODEL, [VENDOR, "Vivo"], [TYPE, MOBILE]],

      [
        // Realme
        /\b(rmx[12]\d{3})(?: bui|;|\))/i,
      ],
      [MODEL, [VENDOR, "Realme"], [TYPE, MOBILE]],

      [
        // Motorola
        /\b(milestone|droid(?:[2-4x]| (?:bionic|x2|pro|razr))?:?( 4g)?)\b[\w ]+build\//i,
        /\bmot(?:orola)?[- ](\w*)/i,
        /((?:moto[\w\(\) ]+|xt\d{3,4}|nexus 6)(?= bui|\)))/i,
      ],
      [MODEL, [VENDOR, MOTOROLA], [TYPE, MOBILE]],
      [/\b(mz60\d|xoom[2 ]{0,2}) build\//i],
      [MODEL, [VENDOR, MOTOROLA], [TYPE, TABLET]],

      [
        // LG
        /((?=lg)?[vl]k\-?\d{3}) bui| 3\.[-\w; ]{10}lg?-([06cv9]{3,4})/i,
      ],
      [MODEL, [VENDOR, LG], [TYPE, TABLET]],
      [
        /(lm(?:-?f100[nv]?|-[\w\.]+)(?= bui|\))|nexus [45])/i,
        /\blg[-e;\/ ]+((?!browser|netcast|android tv)\w+)/i,
        /\blg-?([\d\w]+) bui/i,
      ],
      [MODEL, [VENDOR, LG], [TYPE, MOBILE]],

      [
        // Lenovo
        /(ideatab[-\w ]+)/i,
        /lenovo ?(s[56]000[-\w]+|tab(?:[\w ]+)|yt[-\d\w]{6}|tb[-\d\w]{6})/i,
      ],
      [MODEL, [VENDOR, "Lenovo"], [TYPE, TABLET]],

      [
        // Nokia
        /(?:maemo|nokia).*(n900|lumia \d+)/i,
        /nokia[-_ ]?([-\w\.]*)/i,
      ],
      [
        [MODEL, /_/g, " "],
        [VENDOR, "Nokia"],
        [TYPE, MOBILE],
      ],
      [
        // Google
        // Google Pixel C
        /(pixel c)\b/i,
      ],
      [MODEL, [VENDOR, GOOGLE], [TYPE, TABLET]],

      [
        // Google Pixel
        /droid.+; (pixel[\daxl ]{0,6})(?: bui|\))/i,
      ],
      [MODEL, [VENDOR, GOOGLE], [TYPE, MOBILE]],

      [
        // Sony
        /droid.+ (a?\d[0-2]{2}so|[c-g]\d{4}|so[-gl]\w+|xq-a\w[4-7][12])(?= bui|\).+chrome\/(?![1-6]{0,1}\d\.))/i,
      ],
      [MODEL, [VENDOR, SONY], [TYPE, MOBILE]],
      [/sony tablet [ps]/i, /\b(?:sony)?sgp\w+(?: bui|\))/i],
      [
        [MODEL, "Xperia Tablet"],
        [VENDOR, SONY],
        [TYPE, TABLET],
      ],

      [
        // OnePlus
        / (kb2005|in20[12]5|be20[12][59])\b/i,
        /(?:one)?(?:plus)? (a\d0\d\d)(?: b|\))/i,
      ],
      [MODEL, [VENDOR, "OnePlus"], [TYPE, MOBILE]],

      [
        // Asus
        /(?:\b|asus_)(transfo[prime ]{4,10} \w+|eeepc|slider \w+|nexus 7|padfone|p00[cj])/i,
      ],
      [MODEL, [VENDOR, ASUS], [TYPE, TABLET]],
      [/ (z[bes]6[027][012][km][ls]|zenfone \d\w?)\b/i],
      [MODEL, [VENDOR, ASUS], [TYPE, MOBILE]],

      [
        // HTC
        // HTC Nexus 9
        /(nexus 9)/i,
      ],
      [MODEL, [VENDOR, "HTC"], [TYPE, TABLET]],

      [
        // HTC
        /(htc)[-;_ ]{1,2}([\w ]+(?=\)| bui)|\w+)/i,
      ],
      [VENDOR, [MODEL, /_/g, " "], [TYPE, MOBILE]],

      [
        // Acer
        /droid.+; ([ab][1-7]-?[0178a]\d\d?)/i,
      ],
      [MODEL, [VENDOR, "Acer"], [TYPE, TABLET]],

      [
        // Meizu
        /droid.+; (m[1-5] note) bui/i,
        /\bmz-([-\w]{2,})/i,
      ],
      [MODEL, [VENDOR, "Meizu"], [TYPE, MOBILE]],
      [
        // Sharp
        /\b(sh-?[altvz]?\d\d[a-ekm]?)/i,
      ],
      [MODEL, [VENDOR, SHARP], [TYPE, MOBILE]],
      [
        // MIXED
        /(hp) ([\w ]+\w)/i, // HP iPAQ
        /(asus)-?(\w+)/i, // Asus
        /(microsoft); (lumia[\w ]+)/i, // Microsoft Lumia
        /(lenovo)[-_ ]?([-\w]+)/i, // Lenovo
        /(oppo) ?([\w ]+) bui/i, // OPPO
      ],
      [VENDOR, MODEL, [TYPE, MOBILE]],
      [
        /(archos) (gamepad2?)/i, // Archos
        /(hp).+(touchpad(?!.+tablet)|tablet)/i, // HP TouchPad
        /(dell) (strea[kpr\d ]*[\dko])/i, // Dell Streak
      ],
      [VENDOR, MODEL, [TYPE, TABLET]],

      [
        // Surface Duo
        /(surface duo)/i,
      ],
      [MODEL, [VENDOR, MICROSOFT], [TYPE, TABLET]],

      /**
       * CONSOLES
       */

      [
        // Ouya
        // Nintendo
        /(ouya)/i,
        /(nintendo) ([wids3utch]+)/i,
      ],
      [VENDOR, MODEL, [TYPE, CONSOLE]],

      [
        // Nvidia
        /droid.+; (shield) bui/i,
      ],
      [MODEL, [VENDOR, "Nvidia"], [TYPE, CONSOLE]],

      [
        // Playstation
        /(playstation [345portablevi]+)/i,
      ],
      [MODEL, [VENDOR, SONY], [TYPE, CONSOLE]],

      [
        // Microsoft Xbox
        /\b(xbox(?: one)?(?!; xbox))[\); ]/i,
      ],
      [MODEL, [VENDOR, MICROSOFT], [TYPE, CONSOLE]],

      /**
       * SMARTTVS
       */
      [
        // Samsung
        /smart-tv.+(samsung)/i,
      ],
      [VENDOR, [TYPE, SMARTTV]],
      [/hbbtv.+maple;(\d+)/i],
      [
        [MODEL, /^/, "SmartTV"],
        [VENDOR, SAMSUNG],
        [TYPE, SMARTTV],
      ],

      [
        // LG SmartTV
        /(nux; netcast.+smarttv|lg (netcast\.tv-201\d|android tv))/i,
      ],
      [
        [VENDOR, LG],
        [TYPE, SMARTTV],
      ],
      [
        // Apple TV
        /(apple) ?tv/i,
      ],
      [VENDOR, [MODEL, `${APPLE} TV`], [TYPE, SMARTTV]],

      [
        // Google Chromecast
        /crkey/i,
      ],
      [
        [MODEL, `${CHROME}cast`],
        [VENDOR, GOOGLE],
        [TYPE, SMARTTV],
      ],

      [
        // Fire TV
        /droid.+aft(\w)( bui|\))/i,
      ],
      [MODEL, [VENDOR, AMAZON], [TYPE, SMARTTV]],

      [
        // Sharp
        /\(dtv[\);].+(aquos)/i,
        /(aquos-tv[\w ]+)\)/i,
      ],
      [MODEL, [VENDOR, SHARP], [TYPE, SMARTTV]],

      [
        // Sony
        /(bravia[\w ]+)( bui|\))/i,
      ],
      [MODEL, [VENDOR, SONY], [TYPE, SMARTTV]],

      [
        // Xiaomi
        /(mitv-\w{5}) bui/i,
      ],
      [MODEL, [VENDOR, XIAOMI], [TYPE, SMARTTV]],

      [
        // Roku
        // HbbTV devices
        /\b(roku)[\dx]*[\)\/]((?:dvp-)?[\d\.]*)/i,
        /hbbtv\/\d+\.\d+\.\d+ +\([\w ]*; *(\w[^;]*);([^;]*)/i,
      ],
      [
        [VENDOR, trim],
        [MODEL, trim],
        [TYPE, SMARTTV],
      ],

      [
        // SmartTV from Unidentified Vendors
        /\b(android tv|smart[- ]?tv|opera tv|tv; rv:)\b/i,
      ],
      [[TYPE, SMARTTV]],

      /**
       * MIXED (GENERIC)
       */
      [
        // Android Phones from Unidentified Vendors
        /droid .+?; ([^;]+?)(?: bui|\) applew).+? mobile safari/i,
      ],
      [MODEL, [TYPE, MOBILE]],

      [
        // Android Tablets from Unidentified Vendors
        /droid .+?; ([^;]+?)(?: bui|\) applew).+?(?! mobile) safari/i,
      ],
      [MODEL, [TYPE, TABLET]],

      [
        // Unidentifiable Tablet
        /\b((tablet|tab)[;\/]|focus\/\d(?!.+mobile))/i,
      ],
      [[TYPE, TABLET]],

      [
        // Unidentifiable Mobile
        /(phone|mobile(?:[;\/]| [ \w\/\.]*safari)|pda(?=.+windows ce))/i,
      ],
      [[TYPE, MOBILE]],

      [
        // Generic Android Device
        /(android[-\w\. ]{0,9});.+buil/i,
      ],
      [MODEL, [VENDOR, "Generic"]],
    ],

    os: [
      [
        // Windows
        // Windows (iTunes)
        /microsoft (windows) (vista|xp)/i,
      ],
      [NAME, VERSION],

      [
        // Windows RT
        // Windows Phone
        /(windows) nt 6\.2; (arm)/i,
        /(windows (?:phone(?: os)?|mobile))[\/ ]?([\d\.\w ]*)/i,
        /(windows)[\/ ]?([ntce\d\. ]+\w)(?!.+xbox)/i,
      ],
      [NAME, [VERSION, strMapper, windowsVersionMap]],
      [/(win(?=3|9|n)|win 9x )([nt\d\.]+)/i],
      [
        [NAME, "Windows"],
        [VERSION, strMapper, windowsVersionMap],
      ],
      [
        // iOS/macOS
        // iOS
        /ip[honead]{2,4}\b(?:.*os ([\w]+) like mac|; opera)/i,
        /cfnetwork\/.+darwin/i,
      ],
      [
        [VERSION, /_/g, "."],
        [NAME, "iOS"],
      ],

      [
        // Mac OS
        /(mac os x) ?([\w\. ]*)/i,
        /(macintosh|mac_powerpc\b)(?!.+haiku)/i,
      ],
      [
        [NAME, "Mac OS"],
        [VERSION, /_/g, "."],
      ],

      [
        // Mobile OSes
        // Android-x86/HarmonyOS
        /droid ([\w\.]+)\b.+(android[- ]x86|harmonyos)/i,
      ],
      [VERSION, NAME],

      [
        // Android/WebOS
        // Tizen
        /(android|webos)[-\/ ]?([\w\.]*)/i,
        /(tizen)[\/ ]([\w\.]+)/i,
      ],
      [NAME, VERSION],

      [
        // WebOS
        /web0s;.+rt(tv)/i,
        /\b(?:hp)?wos(?:browser)?\/([\w\.]+)/i,
      ],
      [VERSION, [NAME, "webOS"]],

      [
        // Google Chromecast
        // Google Chromecast
        /crkey\/([\d\.]+)/i,
      ],
      [VERSION, [NAME, `${CHROME}cast`]],

      [
        // Chromium OS
        /(cros) [\w]+ ([\w\.]+\w)/i,
      ],
      [[NAME, "Chromium OS"], VERSION],

      [
        // Console
        // Nintendo/Playstation
        // Microsoft Xbox (360, One, X, S, Series X, Series S)
        /(nintendo|playstation) ([wids345portablevuch]+)/i,
        /(xbox); +xbox ([^\);]+)/i,
      ],
      [NAME, VERSION],
    ],
  };

  /**
   *
   * Functions
   */

  const getBrowser = function () {
    const browserData = {};
    browserData[NAME] = undefined;
    browserData[VERSION] = undefined;
    rgxMapper.call(browserData, ua, regexes.browser);
    return browserData;
  };

  const getDevice = function () {
    const deviceData = {};
    deviceData[VENDOR] = undefined;
    deviceData[MODEL] = undefined;
    deviceData[TYPE] = undefined;
    rgxMapper.call(deviceData, ua, regexes.device);
    return deviceData;
  };

  const getOS = function () {
    const osData = {};
    osData[NAME] = undefined;
    osData[VERSION] = undefined;
    rgxMapper.call(osData, ua, regexes.os);
    return osData;
  };

  return {
    browser: getBrowser(),
    os: getOS(),
    device: getDevice(),
  };
}
