import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import React, { PropsWithChildren, useCallback, useRef } from "react";
import styled from "styled-components";
import { useTheme } from "../../theme/ThemeProvider";
import Ripple, { RippleStyle, RippleType } from "../Ripple";
import styles from "./styles.module.scss";

const StyledFocusable = styled(Focusable)``;

const StyledRipple = styled(Ripple)`
  ${StyledFocusable}:focus & {
    overflow: visible;
  }
`;
const Button = styled.button<{ spatialNavigationActive: boolean }>`
  cursor: pointer;
  background-color: transparent;
  min-width: 3.2rem;
  min-height: 3.2rem;
  display: flex !important;
  justify-content: center;
  padding: 0;
  align-items: center;
  transition: transform 0.2s; /* Animation */
  border-radius: ${(props) => props.theme.primaryButton.borderRadius}rem;
  border-color: ${(props) => props.theme.primaryButton.borderRadius.inactiveBorderColor};
  border-width: ${(props) => props.theme.primaryButton.borderWidth}rem;
  border-style: solid;

  ${StyledFocusable}:focus & {
    overflow: visible;
    box-shadow: 0 0 1.2rem ${(props) => props.theme.primaryButton.activeBackgroundColor};
    transform: ${(props) =>
      props.theme.primaryButton.scaleFactor
        ? `scale(${props.theme.primaryButton.scaleFactor})`
        : undefined};
  }
`;

interface IconButtonProps {
  onClick: React.UIEventHandler<HTMLElement>;
  className?: string;
  testID?: string;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  disabled?: boolean;
  onFocus?: () => void;
  rippleStyle?: RippleStyle;
}
const IconButton = ({
  onClick,
  className,
  testID,
  buttonProps,
  children,
  disabled,
  onFocus,
  rippleStyle = { radius: "50%" },
}: PropsWithChildren<IconButtonProps>) => {
  const { theme } = useTheme();
  const { active: spatialNavigationActive } = useSpatialNavigation();

  const rippleRef = useRef<RippleType>(null);
  const _onClick = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      rippleRef.current?.ripple(e, onClick);
    },
    [onClick],
  );
  return (
    <StyledFocusable
      onFocus={onFocus}
      theme={theme}
      onPressEnter={_onClick}
      disabled={disabled}
      className={className}
    >
      <StyledRipple className={className} ref={rippleRef} rippleStyle={rippleStyle}>
        <Button
          onClick={_onClick}
          {...buttonProps}
          type="button"
          data-testid={testID}
          disabled={disabled}
          theme={theme}
          spatialNavigationActive={spatialNavigationActive}
          className={styles.button}
        >
          {children}
        </Button>
      </StyledRipple>
    </StyledFocusable>
  );
};

export default IconButton;
