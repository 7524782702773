import { MenuItem, useMenu } from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { isM1Build } from "src/utils/Utils";
import styled from "styled-components";
import { ReactComponent as Sort } from "../../assets/dist/ic_sort.svg";
import IconButton from "../../components/Button/IconButton";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import StandardTileView from "./StandardTile";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${dimens.phone.contentPaddingV}rem ${dimens.phone.contentPaddingH}rem;
  flex: 1;
`;

const Content = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

const MainLoading = styled(CircularProgress)`
  margin: auto;
  align-self: center;
`;

const HeaderButton = styled(IconButton)`
  cursor: pointer;
  display: inline-flex;
  svg {
    color: ${(props: { theme: Theme }) => props.theme.headerStyle.buttonsTintColor};
    width: 2.4rem;
    height: 2.4rem;
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
`;
const renderStandardTile = (item: MenuItem, index: number) => {
  return <StandardTileView item={item} nbCols={1} index={index} key={item.uuid} />;
};

const arrange = (items: MenuItem[] | undefined, sortAZ: boolean) => {
  const res = items ? [...items] : [];
  if (sortAZ) {
    res.sort((a, b) => {
      if (!a.title) return -1;
      if (!b.title) return 1;
      return a.title.localeCompare(b.title);
    });
  }
  return res;
};
const BrowsePageNew = () => {
  const { loading, loadError, dismissError, menu } = useMenu({
    isScreenForeground: true,
  });

  const [sortAZ, setSortAZ] = useState(false);

  const toggleSort = useCallback(() => {
    setSortAZ((b) => !b);
  }, []);
  const data = useMemo(() => arrange(menu?.pages, sortAZ), [menu, sortAZ]);

  const history = useHistory();
  const { theme } = useTheme();
  useErrorHandling({
    error: loadError,
    clearError: dismissError,
    callback: history.goBack,
  });

  const { t } = useTranslation();
  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  React.useEffect(() => {
    setHeaderTitle(t("browse.title"));
    setHeaderLeft(<HeaderBackButton title={t("browse.title")} />);
    if (isM1Build()) {
      setHeaderRight(
        <HeaderButton onClick={toggleSort} theme={theme}>
          <Sort />
        </HeaderButton>,
      );
    }
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t, theme, toggleSort]);

  const { resume } = useSpatialNavigation();
  useEffect(() => {
    if (data.length) {
      resume();
      setTimeout(() => {
        focusSectionWithPath("/browse");
      }, 200);
    }
  }, [data.length, resume]);

  return (
    <Container theme={theme}>
      <ScrollRestorer id="main" />
      <Content focusKey="browse">
        <div>{data.map(renderStandardTile)}</div>
      </Content>
      {loading && <MainLoading size={50} />}
    </Container>
  );
};
export default BrowsePageNew;
