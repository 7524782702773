import { logE } from "@blacknut/logging/dist";
import {
  CreateProfileFormData,
  useCreateProfile,
} from "@blacknut/react-client-core/lib";

import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import EditProfile from "../../components/EditProfile/EditProfile";
import PageTitle from "../../components/PageTitle/PageTitle";
import dimens from "../../theme/dimens";
import { LOGGING_TAG } from "../../utils/Utils";
import { useErrorHandling } from "../../utils/V2ErrorHandler";

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem;
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;
    margin: auto;
  }
`;

const CreateProfilePage = () => {
  const { profiles, inProgress, createError, onSubmit, dismissCreateError } =
    useCreateProfile();
  const { t } = useTranslation();
  const history = useHistory();
  useErrorHandling({ error: createError, clearError: dismissCreateError });
  const { resume: resumeSpatialNav } = useSpatialNavigation();

  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  const _onSubmit = React.useCallback(
    async (data: CreateProfileFormData) => {
      try {
        await onSubmit(data);
        history.goBack();
      } catch (e) {
        logE(LOGGING_TAG, "Error creating profile", e);
      }
    },
    [history, onSubmit],
  );

  return (
    <Container>
      <PageTitle title={t("createProfile.title")} />
      <EditProfile onSubmit={_onSubmit} profiles={profiles} />
    </Container>
  );
};

export default CreateProfilePage;
