import { is_iOS } from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import {
  AppLayout,
  HasNativeNotif,
  NETWORK_EVENTS,
  State,
  StorageKey,
  apiService,
  initialize,
  networkService,
  useAnalytics,
  useLayout,
  usePrevious,
} from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import GlobalErrorHandler from "src/components/ErrorHandler/GlobalErrorHandler";
import Header from "src/components/Header/Header";
import { HeaderContext } from "src/components/Header/HeaderProvider";
import { MenuContext } from "src/components/Menu/MenuProvider";
import Notifications from "src/components/Notifications/Notifications";
import SpeedTestsHandler from "src/components/SpeedTests/SpeedTestsHandler";
import UpdateManager from "src/components/UpdateManager/UpdateManager";
import { Button, useBackKey, useCustomBack } from "@blacknut/react-sdk/dist";
import useDebug from "src/hooks/useDebug";
import useDeeplink from "src/hooks/useDeeplink";
import useDeviceInfo from "src/hooks/useDeviceInfo";
import usePushNotifications from "src/hooks/usePushNotifications";
import AccountPage from "src/pages/Account/AccountPage";
import BrowsePage from "src/pages/Browse/BrowsePage";
import CategoryPage from "src/pages/Category/Category";
import GamePage from "src/pages/Game/Game";
import PageGameGenre from "src/pages/Game/PageGameGenre/PageGameGenre";
import GamePlayPage from "src/pages/GamePlayPage/GamePlayPage";
import HomePage from "src/pages/Home/Home";
import LeMagPage from "src/pages/LeMag/LeMagPage";
import LeMagArticlePage from "src/pages/LeMagArticle/LeMagArticlePage";
import MyListPage from "src/pages/MyList/MyListPage";
import NotificationsPage from "src/pages/NotificationsPage/NotificationsPage";
import OfflinePage from "src/pages/Offline/OfflinePage";
import { PreviewPage } from "src/pages/Preview/Preview";
import ProfilesPage from "src/pages/Profiles/ProfilesPage";
import ProfilePinPage from "src/pages/ProfilesPin/ProfilePin";
import SearchPage from "src/pages/Search/Search";
import SearchResultsPage from "src/pages/SearchResults/SearchResults";
import StreamsRecoverPage from "src/pages/StreamRecover/StreamRecover";
import { HeaderStyle } from "src/theme/Theme";
import { useTheme } from "src/theme/ThemeProvider";
import {
  LOGGING_TAG,
  isAISBuild,
  isDreiBuild,
  isM1Build,
  isTelecallBuild,
} from "src/utils/Utils";
import useWebsiteCookie from "src/utils/useWebsiteCookie";
import {
  ModalContextProvider,
  ModalSubscription,
  ModalSwitch,
  useModal,
} from "../components/Modals/ModalContext";
import { usePreview } from "../components/Modals/PreviewContext";
import useToken from "../hooks/useToken";
import { useBatch } from "../utils/BatchContext";
import nativeBridge from "../utils/NativeBridge";
import useConfig from "../utils/useConfig";
import usePerformanceMonitoring from "../utils/usePerformanceMonitoring";
import {
  App,
  Content,
  MainLoading,
  MenuContainer,
  RoutesContainer,
  StyledMenu,
  StyledRouter,
} from "./App.router.styles";
import { useTranslation } from "react-i18next";
import MessageModal from "src/components/Modals/MessageModal";
import { PrimaryButton, SecondaryButton } from "src/components/Button/V2Button";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Index = () => {
  const menuWillShowListeners = useRef<Map<() => void, () => void>>(new Map()).current;
  const menuWillHideListeners = useRef<Map<() => void, () => void>>(new Map()).current;
  const [menuHidden, setMenuHidden] = useState(false);
  const [headerHidden, setHeaderHidden] = useState(false);
  const { pause: pauseSpatialNav } = useSpatialNavigation();
  const history = useHistory<{ returnUrl?: string }>();
  // Pause spatial navigation when route changes
  const prevLocation = usePrevious(history.location.pathname);
  useEffect(() => {
    const unreg = history.listen((location, action) => {
      if (prevLocation !== location.pathname && action !== "REPLACE") {
        pauseSpatialNav();
      }

      logD(LOGGING_TAG, "Location changed: %o (%o)", location.pathname, action);
      if (location.state?.returnUrl && action !== "REPLACE") {
        // history.replace({
        //   ...location,
        //   state: {},
        // });
      }
    });
    return () => {
      unreg();
    };
  }, [history, pauseSpatialNav, prevLocation]);

  const { theme } = useTheme();
  const hideMenu = useCallback(() => {
    for (const key of menuWillHideListeners.keys()) {
      key();
    }
    setMenuHidden(true);
  }, [menuWillHideListeners]);

  const showMenu = useCallback(() => {
    for (const key of menuWillShowListeners.keys()) {
      key();
    }
    setMenuHidden(false);
  }, [menuWillShowListeners]);

  const usingLayout = useLayout();
  const isOnTv = usingLayout === AppLayout.TV;
  const [headerTitle, setHeaderTitle] = useState<string | undefined>();
  const [headerRight, setHeaderRight] = useState<JSX.Element | undefined>();
  const [headerLeft, setHeaderLeft] = useState<JSX.Element | undefined>();
  const [headerStyle, setHeaderStyle] = useState<Partial<HeaderStyle> | undefined>();
  const showHeader = useCallback(() => {
    isOnTv ? setHeaderHidden(true) : setHeaderHidden(false);
  }, [isOnTv]);
  const hideHeader = useCallback(() => {
    setHeaderHidden(true);
  }, []);

  return (
    <HeaderContext.Provider
      value={{
        setTitle: setHeaderTitle,
        setHeaderRight,
        setHeaderLeft,
        showHeader,
        hideHeader,
        headerHidden,
        setHeaderStyle,
      }}
    >
      <Content>
        <ScrollToTop />

        <Header
          title={headerTitle}
          headerRight={headerRight}
          headerLeft={headerLeft}
          hidden={isOnTv ? true : headerHidden}
          headerStyle={headerStyle}
        />
        <MenuContainer theme={theme}>
          <MenuContext.Provider
            value={{
              showMenu,
              hideMenu,
            }}
          >
            <StyledMenu hidden={menuHidden} />
            <RoutesContainer
              id="main"
              isOnTv={isOnTv}
              theme={theme}
              menuHidden={menuHidden}
            >
              <Switch>
                <Route path="/catalog">
                  <HomePage />
                </Route>
                <Route path="/lemag" exact>
                  <LeMagPage />
                </Route>
                <Route path="/lemag/:id" exact>
                  <LeMagArticlePage />
                </Route>
                <Route path="/browse">
                  <BrowsePage />
                </Route>
                <Route path="/page/:id">
                  <CategoryPage />
                </Route>
                <Route path="/genre/:id">
                  <PageGameGenre />
                </Route>
                <Route path="/game/:id" exact>
                  <GamePage />
                </Route>
                <Route path="/game/:gameId/play">
                  <GamePlayPage />
                </Route>
                <Route path="/search" exact>
                  <SearchPage />
                </Route>
                <Route path="/search/results" exact>
                  <SearchResultsPage />
                </Route>
                <Route path="/myList" exact>
                  <MyListPage />
                </Route>
                <Route path="/account">
                  <AccountPage />
                </Route>
                <Route path="/preview">
                  <PreviewPage />
                </Route>
                <Route path="*">
                  <Redirect to="/catalog" />
                </Route>
              </Switch>
            </RoutesContainer>
          </MenuContext.Provider>
        </MenuContainer>

        <SpeedTestsHandler />
      </Content>
    </HeaderContext.Provider>
  );
};

const Offline = () => {
  const history = useHistory();
  useEffect(() => {
    networkService.onNetworkEvent().subscribe((e: any) => {
      if (e === NETWORK_EVENTS.OFFLINE) {
        history.replace("/offline");
      } else if (e === NETWORK_EVENTS.ONLINE) {
        history.replace("/");
      }
    });
  }, [history]);
  return null;
};

const DeeplinkHandler = () => {
  useDeeplink();
  return null;
};

const PushNotificationHandler = () => {
  usePushNotifications();
  return null;
};

let GlobalStyles: React.LazyExoticComponent<any>;
const GlobalStylesWrapper = () => {
  if (!GlobalStyles) {
    GlobalStyles = React.lazy(() => {
      if (isDreiBuild()) return import("src/style-drei");
      if (isTelecallBuild()) return import("src/style-telecall");
      if (isM1Build()) return import("src/style-m1");

      return import("src/style-default");
    });
  }
  return <GlobalStyles />;
};

let LoginPage: React.LazyExoticComponent<any>;
const LoginPageWrapper = () => {
  if (!LoginPage) {
    LoginPage = React.lazy(() => {
      if (isM1Build()) return import("src/pages/Onboarding/Onboarding-m1/Signin");

      if (
        apiService.userAgent.os === "Fireos" &&
        nativeBridge.module?.handleInAppSignin
      ) {
        return import("src/pages/Onboarding/amazon/signin");
      }

      throw new Error("unsupported");
    });
  }
  return <LoginPage />;
};

let SubscribePage: React.LazyExoticComponent<any>;
const SubscribePageWrapper = () => {
  if (!SubscribePage) {
    SubscribePage = React.lazy(() => {
      if (
        apiService.userAgent.os === "Fireos" &&
        nativeBridge.module?.handleInAppSubscribe
      ) {
        return import("src/pages/Onboarding/amazon/subscribe");
      }
      throw new Error("unsupported");
    });
  }
  return <SubscribePage />;
};

let OnboardingPage: React.LazyExoticComponent<any>;
const OnboardingPageWrapper = () => {
  if (!OnboardingPage) {
    OnboardingPage = React.lazy(() => {
      if (isM1Build()) return import("src/pages/Onboarding/Onboarding-m1");
      if (isDreiBuild()) return import("src/pages/Onboarding/Onboarding-drei");
      if (isAISBuild()) return import("src/pages/Onboarding/ais");
      if (isTelecallBuild()) return import("src/pages/Onboarding/telecall");

      if (
        apiService.userAgent.os === "Fireos" &&
        nativeBridge.module?.handleInAppSignup
      ) {
        return import("src/pages/Onboarding/amazon");
      }
      return import("src/pages/Onboarding/Onboarding");
    });
  }
  return <OnboardingPage />;
};

const RedirectOnStartup = ({
  tokenFromCommandLine,
}: {
  tokenFromCommandLine?: boolean;
}) => {
  const { familyToken, layout } = useSelector((state: State) => state.globalState);
  const { profile } = useSelector((state: State) => state.profilesState);
  const location = useLocation();
  const { active: previewActive } = usePreview();

  const history = useHistory();
  const mounted = useRef(false);

  // Redirect on notifications first time if notifications are disabled
  useEffect(() => {
    if (
      (profile || familyToken) &&
      nativeBridge.module?.hasNativeNotif &&
      !localStorage.getItem(StorageKey.ALLOW_PUSH_NOTIF)
    ) {
      nativeBridge.module
        .hasNativeNotif()
        .then((res) => {
          if (res === HasNativeNotif.DENIED) {
            history.replace("/notifications");
            return;
          }
        })
        .catch((e) => {
          logE("Caught error on hasNativeNotif: %o", e);
        });
    }
  }, [familyToken, history, profile]);

  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;
    logD(
      LOGGING_TAG,
      "in RedirectOnStartup profile=%o, family token=%o tokenFromCommandLine: %o, preview=%o",
      profile,
      familyToken,
      tokenFromCommandLine,
      previewActive,
    );
    logD(LOGGING_TAG, "location.pathname: %o", location.pathname);

    if (previewActive) {
      return;
    }
    if (!profile && familyToken) {
      const qs = new URLSearchParams(window.location.search);
      // Passthrought all params except return url which will be given as state in order to be removed on page change
      const oldReturnUrl = qs.get("returnUrl");
      if (oldReturnUrl) {
        qs.delete("returnUrl");
      }
      let returnUrl: string | undefined = oldReturnUrl || location.pathname;
      if (returnUrl === "/profiles") {
        returnUrl = undefined;
      }
      const query = qs ? `?${qs.toString()}` : "";
      const redirect = `/profiles${query}`;
      logD(LOGGING_TAG, "Redirect to %o return=%o", redirect, returnUrl);
      history.replace(redirect, { returnUrl });
    } else if (!profile && !familyToken) {
      if (location.pathname !== "/welcome") {
        const qs = new URLSearchParams(window.location.search);
        // Passthrought all params except return url which will be given as state in order to be removed on page change
        const oldReturnUrl = qs.get("returnUrl");
        if (oldReturnUrl) {
          qs.delete("returnUrl");
        }
        const query = qs ? `?${qs.toString()}` : "";
        const redirect = `/welcome${query}`;
        let returnUrl: string | undefined = oldReturnUrl || location.pathname;
        if (returnUrl === "/welcome") {
          returnUrl = undefined;
        }
        logD(LOGGING_TAG, "Redirect to %o", redirect, returnUrl);
        history.replace(redirect, { returnUrl });
      }
    } else if (tokenFromCommandLine) {
      const qs = new URLSearchParams(window.location.search);
      // Passthrought all params except return url which will be given as state in order to be removed on page change
      const oldReturnUrl = qs.get("returnUrl");
      if (oldReturnUrl) {
        qs.delete("returnUrl");
      }
      const query = qs ? `?${qs.toString()}` : "";
      const redirect = `/profiles${query}`;
      let returnUrl: string | undefined = oldReturnUrl || location.pathname;
      if (returnUrl === "/profiles") {
        returnUrl = undefined;
      }
      logD(LOGGING_TAG, "Redirect to %o %o", redirect, returnUrl);
      history.replace(redirect, { returnUrl });
    }
  }, [
    history,
    profile,
    familyToken,
    location.pathname,
    tokenFromCommandLine,
    layout,
    previewActive,
  ]);
  return null;
};

const BackHandler = () => {
  useBackKey();

  const { push: backPush } = useCustomBack();
  const modalSub = useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const { t } = useTranslation();
  const { active: spatialNavigationActive } = useSpatialNavigation();

  useEffect(() => {
    backPush(() => {
      if (
        (nativeBridge.module?.exit && window.location.pathname === "/catalog") ||
        window.location.pathname === "/welcome"
      ) {
        const onClose = () => {
          modalSub.current?.remove();
          modalSub.current = undefined;
        };
        const onConfirmed = () => {
          modalSub.current?.remove();
          modalSub.current = undefined;
          nativeBridge.module?.exit && nativeBridge.module.exit();
        };
        modalSub.current = modalPush((props) => (
          <MessageModal
            {...props}
            title={t("dialogs.exitApp.title")}
            message={t("dialogs.exitApp.message")}
            buttons={[
              <Button variant="secondary" key="cancel" onClick={onClose} testID="cancel">
                {t("buttons.cancel")}
              </Button>,
              spatialNavigationActive ? (
                <Button
                  variant="secondary"
                  key="ok"
                  onClick={onConfirmed}
                  testID="confirm"
                >
                  {t("buttons.ok")}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  key="ok"
                  onClick={onConfirmed}
                  testID="confirm"
                >
                  {t("buttons.ok")}
                </Button>
              ),
            ]}
          />
        ));
        return true;
      }

      return false;
    });
  }, []);

  return null;
};

const AppRouter = ({ tokenFromCommandLine }: { tokenFromCommandLine?: boolean }) => {
  const _isAIS = isAISBuild();
  const _isDreiBuild = isDreiBuild();
  const _isTelecallBuild = isTelecallBuild();
  const _isM1Build = isM1Build();

  const { appState, user } = useSelector((state: State) => ({
    appState: state.globalState.app,
    user: state.globalState.user,
  }));
  const { initialized, initializing } = appState;
  const { profile } = useSelector((state: State) => state.profilesState);
  const { authentication } = useSelector((state: State) => state.globalState);

  const { pauseMobileLanding, resumeMobileLanding } = useBatch();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!initialized) {
      initialize()(dispatch);
    }
  }, [dispatch, initialized]);

  useEffect(() => {
    if (!initialized) {
      pauseMobileLanding();
    } else {
      resumeMobileLanding();
    }
  }, [initialized, pauseMobileLanding, resumeMobileLanding]);

  const { trackAppOpen } = useAnalytics();

  useConfig();
  useDeviceInfo();
  useDebug();
  useWebsiteCookie();
  usePerformanceMonitoring();
  useEffect(() => {
    if ((initialized && !_isM1Build) || (_isM1Build && !user?.anonymous)) {
      trackAppOpen(apiService.locale || "NA");
    }
  }, [_isM1Build, initialized, trackAppOpen, user?.anonymous]);

  //FIXME review this, use new behaviour
  useEffect(() => {
    if (initialized && window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          message: "ready",
        }),
        "*",
      );
    }
  }, [initialized]);

  // in case of pin protected profile, the local storage set item is not enougth, since not stored
  const { userToken, familyToken } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    nativeBridge.module?.setUserToken && nativeBridge.module?.setUserToken(userToken);
  }, [userToken]);
  useEffect(() => {
    nativeBridge.module?.setFamilyToken &&
      nativeBridge.module?.setFamilyToken(familyToken);
  }, [familyToken]);
  useToken();
  if (!initialized || initializing) {
    return <MainLoading />;
  }

  return (
    <>
      <App style={{ minHeight: is_iOS() ? "-webkit-fill-available" : undefined }}>
        <Suspense fallback={null}>
          <GlobalStylesWrapper />
        </Suspense>
        {initialized && (
          <StyledRouter>
            <ModalContextProvider>
              <RedirectOnStartup tokenFromCommandLine={tokenFromCommandLine} />
              <GlobalErrorHandler />
              <UpdateManager />
              <Offline />
              <DeeplinkHandler />
              <PushNotificationHandler />
              <BackHandler />
              <ModalSwitch>
                <Route path="/offline">
                  <OfflinePage />
                </Route>
                <Route path="/welcome">
                  <Suspense fallback={null}>
                    <OnboardingPageWrapper />
                  </Suspense>
                </Route>

                <Route path="/signin" exact>
                  <Suspense fallback={null}>
                    <LoginPageWrapper />
                  </Suspense>
                </Route>

                <Route path="/subscribe" exact>
                  <Suspense fallback={null}>
                    <SubscribePageWrapper />
                  </Suspense>
                </Route>

                <Route path="/profiles" exact>
                  <ProfilesPage />
                </Route>
                <Route path="/profiles/:id/pin">
                  <ProfilePinPage />
                </Route>
                <Route path="/notifications">
                  <NotificationsPage />
                </Route>
                <Route path="/streams/recover">
                  <StreamsRecoverPage />
                </Route>
                <Route path="/preview">
                  <PreviewPage />
                </Route>

                {profile && (
                  <Route path="/">
                    <Index />
                  </Route>
                )}
                {!profile && !authentication?.inProgress && (
                  <Route path="/">
                    <Redirect to="/welcome" />
                  </Route>
                )}
              </ModalSwitch>
            </ModalContextProvider>
          </StyledRouter>
        )}
      </App>
      <Notifications />
    </>
  );
};
export default React.memo(AppRouter);
