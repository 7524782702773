import { logW } from "@blacknut/logging/dist";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import QueryString from "query-string";
import {
  PreviewPageOps,
  ProfileType,
  editoService,
} from "@blacknut/javascript-sdk/dist";
import { useTheme } from "../../theme/ThemeProvider";

const TAG = "Preview";
const defaultValue = {
  active: false,
  goToPage: () => {
    logW(TAG, "Nop");
  },
  restrictions: {},
};

export const PreviewContext = React.createContext<{
  active: boolean;
  pageUuid?: string;
  goToPage: (id: string) => void;
  restrictions: Partial<PreviewPageOps>;
}>(defaultValue);

export function usePreview() {
  return useContext(PreviewContext);
}

const makeRestrictions = (query: QueryString.ParsedQuery<string>) => {
  const {
    cluster,
    country,
    device,
    organization,
    userProfile,
    subscriptionStatus,
    subscriptionPlan,
    os,
  } = query;

  const body: Partial<PreviewPageOps> = {};
  if (cluster) {
    body["cluster"] = [cluster as string];
  }
  if (country && typeof country === "string") body["country"] = country;
  if (device && typeof device === "string") body["device"] = device;
  if (organization && typeof organization === "string")
    body["organization"] = organization;
  if (os && typeof os === "string") body["os"] = os;
  if (userProfile && typeof userProfile === "string")
    body["userProfile"] = userProfile as ProfileType;
  if (subscriptionStatus && typeof subscriptionStatus === "string")
    body["subscriptionStatus"] = subscriptionStatus;
  if (subscriptionPlan && typeof subscriptionPlan === "string")
    body["subscriptionPlan"] = subscriptionPlan;
  return body;
};
export const PreviewContextProvider = (props: PropsWithChildren<unknown>) => {
  const [pageUuid, setPageUuid] = useState("");
  const [active, setActive] = useState(location.pathname === "/preview");
  const search = location.search;
  const parsedQuery = useMemo(() => QueryString.parse(search), [search]);
  const restrictions = useMemo(() => makeRestrictions(parsedQuery), [parsedQuery]);
  const { setThemeId, theme: currentTheme } = useTheme();

  useEffect(() => {
    if (location.pathname === "/preview") {
      const { token, locale, theme, pageUuid } = parsedQuery;
      editoService.endpoint = process.env.REACT_APP_PARTNER_API_PATH || "";
      if (!token || Array.isArray(token)) return;
      if (!locale || Array.isArray(locale)) return;
      if (!pageUuid || Array.isArray(pageUuid)) return;
      editoService.locale = locale;
      editoService.token = token;
      setPageUuid(pageUuid);
      if (currentTheme.name !== theme && theme) {
        setThemeId(theme as string);
      }
      setActive(true);
    } else {
      setActive(false);
    }
  }, [currentTheme.name, parsedQuery, setThemeId]);
  return (
    <PreviewContext.Provider
      value={{ active, pageUuid, goToPage: setPageUuid, restrictions }}
    >
      {props.children}
    </PreviewContext.Provider>
  );
};
