import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dimens from "../../theme/dimens";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionSubStatus } from "./SubscriptionKind";
import { ReactComponent as GooglePayLogo } from "../../assets/dist/store_google.svg";

const StyledGoogleLogo = styled(GooglePayLogo)`
    width: 20rem;
    display: block;

    margin: ${dimens.margins.Yellow}rem auto ${dimens.margins.LightGreen}rem auto;
`;

const SubscriptionGoogle = () => {
    const { theme } = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <SubscriptionSubStatus theme={theme}>
                {t("account.subscription.google.text2", { appName: process.env.REACT_APP_APP_NAME })}
            </SubscriptionSubStatus>
            <StyledGoogleLogo />
        </>
    );
};
export default SubscriptionGoogle;
