import * as React from "react";
import { Theme } from "src/theme/Theme";
import styled from "styled-components";
import { ReactComponent as InstallDesktopChrome } from "../../assets/dist/install-desktop-chrome_notif-pwa.svg";
import { ReactComponent as Lock } from "../../assets/dist/lock_notif-pwa.svg";
import { ReactComponent as DownloadNotif } from "../../assets/dist/ic-Install-desktop-chrome.svg";

import { useTheme } from "src/theme/ThemeProvider";
import { useTranslation, Trans } from "react-i18next";
import dimens from "src/theme/dimens";

import logoBN from "src/assets/dist/logo_bn_rounded.png";
import { isBlacknutBuild } from "src/utils/Utils";

const Title = styled.h5<{ theme: Theme }>`
  margin: 0;
  color: ${(props) => props.theme.notificationInstall.textColor};
  font-size: 1.4rem;
`;
const InstallDesktopChromeStyled = styled.div`
  display: flex;
  align-items: center;
  width: 22.6rem;
  width: 90%;
  height: 3.2rem;
  border-radius: 3rem;
  background: #e6e6e6;
  padding: 0 1.2rem;
  position: relative;

  p {
    transform: translateY(1px);
  }

  svg:first-child {
    width: 21px;
    margin-right: 0.2rem;
  }
  svg:last-child {
    width: 26px;
    position: absolute;
    right: 4.6rem;
    bottom: 50%;
    transform: translateY(50%);
  }
`;
const Informations = styled.div<{ isCursorPointer: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isCursorPointer }) => (isCursorPointer ? "cursor: pointer;" : "")}
`;

const ContainerInfoText = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
`;
const Text = styled.p<{ fontSize?: string; color?: string }>`
  color: ${({ theme, color }) => color || theme.notificationPWA.textColor};
  margin: ${dimens.margins.DarkRed}rem 0;
  font-size: ${({ fontSize }) => fontSize || "1.4rem"};
  line-height: 2.2rem;
`;

const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`;
const LogoStyled = styled.img`
  margin-right: ${dimens.margins.Green}rem;
  max-height: 8rem;
  max-width: 8rem;
`;
const DllIcon = styled(DownloadNotif)<{ theme: Theme }>`
  max-width: 1.6rem;
  vertical-align: middle;
  transform: translateY(-0.2rem);
  margin: 0 0.3rem;

  path {
    fill: ${(props) => props.theme.notificationInstall.textColor};
  }
`;
const NotificationInstall = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [showInfo, setShowInfo] = React.useState(false);
  const showInfoManagement = () => {
    showInfo ? setShowInfo(false) : setShowInfo(true);
  };

  return (
    <Informations onClick={() => setShowInfo(true)} isCursorPointer={!showInfo}>
      <ContainerInfo>
        <LogoStyled
          src={isBlacknutBuild() ? logoBN : "/favicon-96x96.png"}
          alt="App logo"
        />
        <ContainerInfoText>
          <Title theme={theme}>
            {t("notifications.install.title", {
              brand: process.env.REACT_APP_APP_NAME,
            })}
          </Title>
          <Text theme={theme}>
            <Trans
              t={t}
              i18nKey="notifications.install.chrome.subtitle"
              components={[<DllIcon key={0} theme={theme} />]}
            />
          </Text>
          {showInfo && (
            <InstallDesktopChromeStyled onClick={showInfoManagement}>
              <Lock />
              <Text theme={theme} fontSize="1.1rem" color={"#191414"}>
                {window?.location?.hostname}
              </Text>
              <InstallDesktopChrome />
            </InstallDesktopChromeStyled>
          )}
        </ContainerInfoText>
      </ContainerInfo>
    </Informations>
  );
};
export default NotificationInstall;
