import { List, Tile, useTile } from "@blacknut/react-client-core/lib";
import { getImageByThemeName } from "@blacknut/react-client-core/lib/models/Image";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import styled, { css } from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useReactRouter } from "../../utils/Hooks";
import FadeInImage from "../FadeInImage/FadeInImage";
import { usePreview } from "../Modals/PreviewContext";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface V2BannerTileProps {
  item: Tile;
  list: List;
  className?: string;
  inView: boolean;
}

const BannerTile = ({ item, list, className, inView = true }: V2BannerTileProps) => {
  const { theme } = useTheme();
  const { active: previewActive } = usePreview();

  const { onClick } = useTile({
    item,
    list,
    ...useReactRouter(),
    previewMode: previewActive,
  });
  const imgBanner = getImageByThemeName(theme.name, list.imgBackground ?? []);
  return (
    <Focusable
      className={clsx(styles.container, styles.focusable, className)}
      onClick={onClick}
      onPressEnter={onClick}
    >
      {inView && <FadeInImage image={imgBanner} className={styles.image} />}
    </Focusable>
  );
};

export default BannerTile;
