import {
  GameStreamProtocol,
  NotificationMessage,
  NotificationMessageKind,
  notificationService,
  RemoteNotification,
  RemoteNotificationTrigger,
  State,
} from "@blacknut/react-client-core/lib";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PrimaryButton } from "../components/Button/V2Button";
import MessageModal from "../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../components/Modals/ModalContext";
import electronService from "../services/ElectronService";

const usePushNotifications = () => {
  const { t } = useTranslation();
  const { push: modalPush } = useModal();
  // FIX : https://blacknut.atlassian.net/browse/CA-2598
  // Since popup is closed within callback, the reference to the modalPush fn is still the same
  const modalPushRef = useRef(modalPush);
  const modalSubscription = useRef<ModalSubscription>();
  const { running: playing } = useSelector((state: State) => state.gamesState);
  const { protocole: protocol } = useSelector((state: State) => state.globalState);
  const history = useHistory();
  const closeModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  useEffect(() => {
    modalPushRef.current = modalPush;
  }, [modalPush]);
  const handleIdleUserError = useCallback(
    (notification: RemoteNotification) => {
      if (!playing || protocol === GameStreamProtocol.WEBRTC) {
        return;
      }

      closeModal();

      setTimeout(() => {
        modalSubscription.current = modalPushRef.current((props) => (
          <MessageModal
            {...props}
            title={notification.title}
            message={notification.description}
            onClose={closeModal}
            buttons={
              <PrimaryButton key="ok" onClick={closeModal}>
                {t("buttons.ok")}
              </PrimaryButton>
            }
          />
        ));

        /*if (protocol === GameStreamProtocole.WEBRTC) {
                    history.goBack();
                } else */ if (electronService.isAvailable()) {
          electronService.killPlayer();
        }
      }, 500); // wait for reference to be updated
    },
    [closeModal, playing, protocol, t],
  );

  const handleIdleUserWarn = useCallback(
    (notification: RemoteNotification) => {
      if (!playing || protocol !== GameStreamProtocol.WEBRTC) {
        return;
      }

      closeModal();

      setTimeout(() => {
        modalSubscription.current = modalPushRef.current((props) => (
          <MessageModal
            {...props}
            title={notification.title}
            message={notification.description}
            onClose={closeModal}
            buttons={
              <PrimaryButton key="ok" onClick={closeModal}>
                {t("buttons.ok")}
              </PrimaryButton>
            }
          />
        ));
      }, 500); // wait for reference to be updated
    },
    [closeModal, playing, protocol, t],
  );
  useEffect(() => {
    const sub = notificationService.onMessage().subscribe((n: NotificationMessage) => {
      if (n.kind !== NotificationMessageKind.NOTIFICATION_RESP) return;
      const { notification } = n.payload;
      if (notification.trigger === RemoteNotificationTrigger.IDLE_USER_ERROR) {
        handleIdleUserError(notification);
      } /* else if (notification.trigger === RemoteNotificationTrigger.IDLE_USER_WARN) {
                handleIdleUserWarn(notification);
            }*/
    });
    return () => {
      sub.unsubscribe();
    };
  }, [handleIdleUserError, handleIdleUserWarn]);
};
export default usePushNotifications;
