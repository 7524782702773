import { AppLayout, UserProfile } from "@blacknut/react-client-core/lib";
import React from "react";
import { ReactComponent as MyList } from "src/assets/dist/ic_collection.svg";
import { ReactComponent as Browse } from "src/assets/dist/ic_explorer.svg";
import { ReactComponent as Home } from "src/assets/dist/ic_home.svg";
import { ReactComponent as LeMag } from "src/assets/dist/ic_lemag.svg";
import { ReactComponent as Search } from "src/assets/dist/ic_search.svg";
import AccountLink from "../../AccountNavLink";
import NavLink from "../../NavLink";
import styles from "../styles.module.scss";

export interface NavsLinksProps {
  layout: AppLayout;
  pathname: string;
  activeItem: string;
  lemag: boolean;
  profile: UserProfile | undefined;
}

const NavsLinks = ({ layout, pathname, activeItem, lemag, profile }: NavsLinksProps) => {
  return (
    <div className={layout == AppLayout.TV ? styles.isOnTv : styles.container}>
      <div className={styles.wrap}>
        <NavLink
          to="/catalog"
          replace={pathname === "/catalog"}
          isActive={() => activeItem === "catalog"}
          data-testid="catalog"
          className={styles.marginIcon}
        >
          <Home className="svg" />
        </NavLink>
        {lemag && (
          <NavLink
            to="/lemag"
            replace={pathname === "/lemag"}
            isActive={() => activeItem === "lemag"}
            data-testid="lemag"
            className={styles.marginIcon}
          >
            <LeMag className="svg" />
          </NavLink>
        )}

        <NavLink
          to="/browse"
          replace={pathname === "/browse"}
          isActive={() => activeItem === "browse"}
          data-testid="browse"
          className={styles.marginIcon}
        >
          <Browse className="svg" />
        </NavLink>

        <NavLink
          replace={pathname === "/myList"}
          to="/myList"
          isActive={() => activeItem === "myList"}
          data-testid="myList"
          requiresAnonymousCheck={true}
          className={styles.marginIcon}
        >
          <MyList className="svg" />
        </NavLink>

        <NavLink
          to="/search"
          replace={pathname === "/search"}
          isActive={() => activeItem === "search"}
          data-testid="search"
          className={styles.marginIcon}
        >
          <Search className="svg" />
        </NavLink>

        {profile && <AccountLink activeItem={activeItem} profile={profile} />}
      </div>
    </div>
  );
};

export default NavsLinks;
