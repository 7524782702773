import * as React from "react";
import styled from "styled-components";
import dimens from "../../../theme/dimens";
import { TextInputStyle, Theme } from "../../../theme/Theme";
import { useTheme } from "../../../theme/ThemeProvider";
import {
  Focusable,
  JsSpatialNavigation,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { isTizen } from "@blacknut/javascript-sdk/dist";

const inputPaddingV = 1.2;
const inputPaddingH = 1.2;

export const overrideIfNeeded = <T extends unknown>(
  key: keyof TextInputStyle,
  _default: Partial<TextInputStyle>,
  override?: Partial<TextInputStyle>,
): T | undefined => {
  if (override) return override[key] as T | undefined;
  return _default[key] as T | undefined;
};

export const StyledLabel = styled.label<{
  theme: Theme;
  inputFocused: boolean;
  inputFilled: boolean;
  hasIcon?: boolean;
}>`
  font-size: 1.3rem;
  font-weight: normal;
  margin-bottom: ${dimens.margins.DarkRed}rem;
  color: ${(props) => props.theme.textInputStyle.labelStyle.inactiveColor};
`;

const InputWrap = styled.div<{
  theme: Theme;
  hasError: boolean;
  textInputStyle?: Partial<TextInputStyle>;
  disabled?: boolean;
  inputFocused: boolean;
}>`
  min-height: 5.6rem;
  border-radius: ${(props) => props.theme.textInputStyle.borderRadius}rem;
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: ${(props) =>
    props.hasError
      ? "#f8d7da"
      : props.textInputStyle?.inactiveBackgroundColor ||
        props.theme.textInputStyle.inactiveBackgroundColor};
  border: ${(props) =>
    props.hasError
      ? "0.2rem solid #f8d7da"
      : `0.2rem solid ${
          props.inputFocused
            ? overrideIfNeeded(
                "inactiveBorderColor",
                props.theme.textInputStyle,
                props.textInputStyle,
              )
            : overrideIfNeeded(
                "inactiveBorderColor",
                props.theme.textInputStyle,
                props.textInputStyle,
              )
        }`};
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: all 500ms;
  align-items: center;

  input,
  textarea {
    margin: 0.4rem 0 0 0;
    user-select: text;
    display: block;
    padding: ${inputPaddingV}rem ${inputPaddingH}rem;
    font-size: inherit;
    flex: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background-color: transparent;
    color: ${(props) =>
      props.hasError
        ? props.theme.textInputStyle.errorColor
        : props.theme.textInputStyle.inactiveTextColor};
  }
  textarea {
    margin-top: 1.4rem;
  }
  input::placeholder,
  textarea::placeholder {
    opacity: ${(props) => (props.inputFocused ? 0 : 1)};
    transition: all 0.2s;
    font-weight: 600;
    color: ${(props) => props.theme.textInputStyle.labelStyle.inactiveColor};
  }

  & > svg {
    color: ${(props: { theme: Theme; inputFocused: boolean }) =>
      props.inputFocused
        ? props.theme.textInputStyle.labelStyle?.inactiveColor
        : props.theme.textInputStyle.labelStyle?.inactiveColor};
    width: ${(props: { theme: Theme }) => props.theme.search.iconSearchInput?.size}rem;
    margin-left: ${dimens.margins.LightGreen / 2}rem;
    opacity: ${(props: { theme: Theme; inputFocused: boolean }) =>
      props.inputFocused ? 1 : 0.4};
  }
`;
const Container = styled(Focusable)<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    min-width: 28rem;
  }
  &:focus ${InputWrap} {
    border-color: var(--input--active-border);
  }
`;
export const StyledError = styled.span`
  margin-left: ${inputPaddingH}rem;
  color: ${(props: { theme: Theme }) => props.theme.textInputStyle.errorColor};
  font-size: ${(props: { theme: Theme }) => props.theme.textStyle2.size}rem;
`;

export interface TextInputProps {
  error?: string | boolean;
  startAdornment?: React.ReactElement<unknown>;
  endAdornment?: React.ReactElement<unknown>;
  rows?: number;
  focusKey?: string;
  label?: string;
  testID?: string;
  id?: string;
  wrapClassName?: string;
  textInputStyle?: Partial<TextInputStyle>;
  value?: string;
  disabled?: boolean;
  className?: string;
  type?: "email" | "text" | "password" | "number";
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  inputProps?: Partial<React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>>;
  placeholder?: string;
  nextFocusForward?: { DOWN?: string };
  icon?: JSX.Element;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = ({
  rows,
  id,
  testID,
  startAdornment,
  endAdornment,
  onInputFocused,
  onInputBlur,
  type,
  name,
  onChange,
  inputProps,
  placeholder,
  value,
  disabled,
  inputRef,
  onKeyDown,
  onKeyUp,
  textInputStyle,
}: TextInputProps & {
  onInputFocused: () => void;
  onInputBlur: () => void;
  inputRef: React.MutableRefObject<HTMLElement | null>;
}) => {
  const remainingProps = { ...inputProps };
  delete remainingProps.children;
  const input = React.createElement(rows === undefined ? "input" : "textarea", {
    ...remainingProps,
    onBlur: onInputBlur,
    onFocus: onInputFocused,
    onKeyDown: onKeyDown,
    onKeyUp: onKeyUp,
    ref: inputRef,
    type,
    id,
    name,
    onChange,
    value,
    placeholder,
    rows,
    disabled,
    "data-testid": testID,
  });

  return (
    <>
      {startAdornment}
      {input}
      {endAdornment}
    </>
  );
};
const TextInput = (props: React.PropsWithChildren<TextInputProps>) => {
  const { active } = useSpatialNavigation();
  const {
    label,
    value,
    className,
    id,
    error,
    wrapClassName,
    disabled,
    textInputStyle,
    children,
    nextFocusForward,
    icon,
    onKeyUp,
    onKeyDown,
  } = props;
  let filled = false;
  if (typeof value === "string") {
    filled = (value as string).trim().length > 0;
  } else {
    filled = value !== undefined;
  }

  const [inputFocused, setInputFocused] = React.useState(false);
  const { theme } = useTheme();

  const { active: spatialNavigationActive } = useSpatialNavigation();
  const ref = React.useRef<HTMLElement>(null);

  const onInputBlur = React.useCallback(() => {
    setInputFocused(false);
    if (spatialNavigationActive) {
      const focusable = ref.current && ref.current.closest("[tabIndex]");
      if (focusable) {
        (focusable as HTMLElement).focus();
      }
    }
  }, [spatialNavigationActive]);
  const onInputFocus = React.useCallback(() => {
    setInputFocused(true);
  }, []);

  const onPressEnter = React.useCallback(() => {
    if (!inputFocused && active) {
      setInputFocused(true);
      ref.current?.focus();
    }
  }, [active, inputFocused]);

  const _onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (isTizen()) {
        if (e.keyCode === 65376 || e.keyCode === 65385) {
          // FIXME check if still the case when in a iframe
          // when you press the Done/Cancel button in OSK
          // https://blacknut.atlassian.net/browse/CA-297

          if (e.keyCode === 65376 && nextFocusForward && ref.current) {
            e.currentTarget.blur();
            e.stopPropagation();

            setTimeout(() => {
              JsSpatialNavigation.move(nextFocusForward, ref.current, {
                focusText: true,
              });
            }, 200);
          } else {
            // Unfocus current element
            e.currentTarget.blur();
            e.stopPropagation();
            setTimeout(() => {
              const focusable = ref.current && ref.current.closest("[tabIndex]");
              if (focusable) {
                (focusable as HTMLElement).focus();
              }
            }, 200);
          }
        }
      } else {
        if (e.key === "Enter") {
          // Unfocus current element
          e.currentTarget.blur();
          e.stopPropagation();
          setTimeout(() => {
            const focusable = ref.current && ref.current.closest("[tabIndex]");
            if (focusable) {
              (focusable as HTMLElement).focus();
            }
          }, 200);
        }
        if (onKeyDown) {
          onKeyDown(e);
        }
      }
    },
    [nextFocusForward, onKeyDown],
  );
  return (
    <Container theme={theme} className={className} onPressEnter={onPressEnter}>
      {label && (
        <StyledLabel
          htmlFor={id}
          theme={theme}
          inputFocused={inputFocused}
          inputFilled={filled}
          hasIcon={!!icon}
        >
          {label}
        </StyledLabel>
      )}
      <InputWrap
        theme={theme}
        inputFocused={inputFocused}
        hasError={!!error}
        className={wrapClassName}
        disabled={disabled}
        textInputStyle={textInputStyle}
      >
        {icon && icon}
        {children && <>{children}</>}
        {!children && (
          <Input
            {...props}
            inputRef={ref}
            onInputBlur={onInputBlur}
            onInputFocused={onInputFocus}
            onKeyDown={_onKeyDown}
            onKeyUp={onKeyUp}
          />
        )}
      </InputWrap>
      {error && <StyledError theme={theme}>{error}</StyledError>}
    </Container>
  );
};
export default TextInput;
