import { UserProfile } from "@blacknut/react-client-core/lib";
import * as React from "react";
import Truncate from "react-truncate";
import styled from "styled-components";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import AvatarImage from "../AvatarSelectionModal/AvatarImage";

const Container = styled.div`
  width: 9rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 ${dimens.margins.Green}rem;
  text-align: center;
  flex-shrink: 0;
  min-height: min-content;
  word-break: break-all;
  cursor: ${(props: { onClick?: () => void }) =>
    props.onClick ? "pointer" : undefined};
`;

const Avatar = styled.div`
  height: 8rem;
  width: 8rem;
  margin-bottom: 0.8rem;
  border-radius: 4rem;
  flex-shrink: 0;
`;

const NickName = styled.div`
  word-break: break-word;
  color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
  cursor: pointer;
  * {
    cursor: pointer !important;
  }
`;

export interface ProfileProps {
  profile: UserProfile;
  className?: string;
  onClick?: () => void;
}

const Profile = ({ profile, className, onClick }: ProfileProps) => {
  const { theme } = useTheme();
  return (
    <Container className={className} onClick={onClick} key={profile.id}>
      <Avatar>
        {profile.avatarId && <AvatarImage avatarId={profile.avatarId} />}
        {!profile.avatarId && <AvatarDefault />}
      </Avatar>

      <NickName theme={theme}>
        <Truncate width="80" lines={2}>
          {profile.nickname}
        </Truncate>
      </NickName>
    </Container>
  );
};

export default Profile;
