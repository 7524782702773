import { createLightTheme } from "../default/Light";

const TelecallTheme = createLightTheme({ colorAccent: "#00A0D2" });
TelecallTheme.default.defaultFontFamily = "regular";

TelecallTheme.default.tileCardStyle = {
  ...TelecallTheme.default.tileCardStyle,
  radius: TelecallTheme.default.cardStyle.radius,
};

TelecallTheme.default.backgroundColor = "#EEEEEE";
TelecallTheme.default.headerStyle = {
  ...TelecallTheme.default.headerStyle,
  backgroundColor: "#EEEEEE",
  borderColor: "#EEEEEE",
};

TelecallTheme.default.logoSize = { width: 5.7, height: 2.4 };

TelecallTheme.default.bottomBarStyle = {
  ...TelecallTheme.default.bottomBarStyle,
  backgroundColor: "#EEEEEE",
  inactiveTintColor: "#4C4C4C",
  borderColor: "#EEEEEE",
};

TelecallTheme.default.search.toggleButtonStyle = {
  ...TelecallTheme.default.search.toggleButtonStyle,
  tintColor: "#fff",
};

export default TelecallTheme;
