export interface BaseListItem {
  id: string;
  icon?: React.FC<unknown>;
}

export interface MyAccountSection {
  title?: string;
  type: MyAccountSectionType;
  data: BaseListItem[];
}

export interface AccountListItem extends BaseListItem {
  title: string;
  onPress: () => void;
}

export interface ThemeListItem extends BaseListItem {
  theme: string;
}

export enum MyAccountSectionType {
  NOTIFICATION,
  SETTINGS,
  APPEARANCE,
}

export const MAX_NOTIF_COUNT = 2;
