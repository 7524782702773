import { State } from "@blacknut/react-client-core/lib";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const useWebsiteCookie = () => {
  const { userToken } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    if (userToken) {
      Cookies.set(
        "user_state",
        JSON.stringify({
          accessuser_state: userToken.accessToken,
          refreshToken: userToken.refreshToken,
        }),
        {
          domain: ".blacknut.com",
          secure: true,
          expires: 365,
        },
      );
    } else {
      Cookies.remove("user_state");
    }
  }, [userToken]);
};
export default useWebsiteCookie;
