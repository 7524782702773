import * as React from "react";
import styled from "styled-components";
import { ReactComponent as CheckedCircle } from "../../../assets/dist/ic_check_circle_checked.svg";
import { ReactComponent as Checked } from "../../../assets/dist/ic_check_square_checked.svg";
import { ReactComponent as UncheckedCircle } from "../../../assets/dist/ic_check_circle.svg";
import { ReactComponent as Unchecked } from "../../../assets/dist/ic_check_square.svg";
import dimens from "../../../theme/dimens";
import { CheckboxStyle, Theme } from "../../../theme/Theme";
import { useTheme } from "../../../theme/ThemeProvider";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";

const Container = styled(Focusable)<{
  styleDsabled: boolean;
  focusDisabled: boolean;
  labelAlignment: "start" | "end";
  focusVisible?: boolean;
  theme: Theme;
}>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.labelAlignment === "end" ? "row" : "row-reverse")};
  justify-content: ${(props) =>
    props.labelAlignment === "end" ? "flex-start" : "flex-end"};
  opacity: ${(props) => (props.styleDsabled ? 0.5 : 1)};
  ${({ focusDisabled }) => focusDisabled && "pointer-events: none;"};
  &:focus {
    label,
    svg {
      ${({ focusVisible, theme }) => (focusVisible ? `color: ${theme.rippleColor}` : "")}
    }
  }
`;

const StyledLabel = styled.label<{ labelAlignment: "start" | "end"; theme: Theme }>`
  margin-left: ${(props) =>
    props.labelAlignment === "end" ? `${dimens.margins.DarkRed}rem` : 0};
  margin-right: ${(props) => (props.labelAlignment === "end" ? 0 : "auto")};
  cursor: pointer;
  color: ${(props) => props.theme.textStyle.color};
  a {
    color: ${(props) => props.theme.rippleColor};
    text-decoration: none;
  }
`;

const CheckboxContainer = styled.div<{
  theme: Theme;
  checked: boolean;
  checkboxStyle?: Partial<CheckboxStyle>;
}>`
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    fill: ${(props) =>
      props.checked
        ? props.checkboxStyle?.activeTintColor ||
          props.theme.checkboxStyle.activeTintColor
        : props.checkboxStyle?.inactiveTintColor ||
          props.theme.checkboxStyle.inactiveTintColor};
    color: ${(props) =>
      props.checked
        ? props.checkboxStyle?.activeTintColor ||
          props.theme.checkboxStyle.activeTintColor
        : props.checkboxStyle?.inactiveTintColor ||
          props.theme.checkboxStyle.inactiveTintColor};
  }
  input {
    display: none;
  }
`;

interface CheckboxProps {
  onCheckChange: (e: React.UIEvent<unknown>) => void;
  label?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  radio?: boolean;
  disabled?: boolean;
  labelAlignment?: "start" | "end";
  testID?: string;
  checkboxStyle?: Partial<CheckboxStyle>;
  id: string;
  asHTML?: boolean;
  focusDisabled?: boolean;
  focusVisible?: boolean;
}

declare type ResultProps = CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>;

const Checkbox = ({
  className,
  labelAlignment = "end",
  id,
  label,
  radio,
  disabled,
  checked,
  onCheckChange,
  children,
  testID,
  checkboxStyle,
  focusDisabled = !!disabled,
  focusVisible = true,
  asHTML = false,
}: React.PropsWithChildren<ResultProps>) => {
  const { theme } = useTheme();
  const _onCheckChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<unknown>) => {
      if (disabled && e) {
        e.preventDefault();
        e.stopPropagation();
      } else if (!disabled) {
        onCheckChange(e as any);
      }
    },
    [disabled, onCheckChange],
  );

  return (
    <Container
      theme={theme}
      className={className}
      disabled={focusDisabled}
      styleDsabled={!!disabled}
      focusDisabled={focusDisabled}
      labelAlignment={labelAlignment}
      onPressEnter={_onCheckChange}
      focusVisible={focusVisible}
    >
      <CheckboxContainer theme={theme} checked={!!checked} checkboxStyle={checkboxStyle}>
        <input
          type={radio ? "radio" : "checkbox"}
          id={id}
          onChange={_onCheckChange}
          checked={checked}
          disabled={disabled}
          data-testid={testID}
        />
        {checked && !radio && <Checked onClick={_onCheckChange} />}
        {!checked && !radio && <Unchecked onClick={_onCheckChange} />}
        {checked && radio && <CheckedCircle onClick={_onCheckChange} />}
        {!checked && radio && <UncheckedCircle onClick={_onCheckChange} />}
      </CheckboxContainer>
      {label && asHTML && (
        <StyledLabel
          labelAlignment={labelAlignment}
          theme={theme}
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
      {label && !asHTML && (
        <StyledLabel labelAlignment={labelAlignment} theme={theme} htmlFor={id}>
          {label}
        </StyledLabel>
      )}
      {children}
    </Container>
  );
};
export default Checkbox;
