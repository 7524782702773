import {
  ClientDeviceType,
  DefaultFullscreenHelper,
  PlayerLogLevel,
  exitFullscreen,
} from "@blacknut/javascript-sdk/dist";
import { DDAppender } from "@blacknut/logging-datadog/dist";
import { LogLevel, logD, logE, logger } from "@blacknut/logging/dist";
import { configure, GameLauncher, WebRTCError } from "@blacknut/playerwebrtc-sdk/dist";
import {
  ApiErrorCode,
  State,
  StorageKey,
  apiService,
} from "@blacknut/react-client-core/lib";
import { ModalContext as SDKModalContext } from "@blacknut/react-sdk/dist";
import { inputUI } from "@blacknut/spatialnav-sdk/dist/input";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useMuteVideo } from "src/utils/MuteVideoContext";
import { DD_APPENDER } from "src/utils/useLogging";
import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import { useMenu } from "../../components/Menu/MenuProvider";
import MessageModal from "../../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import { useSubscriptionRedirect } from "../../components/SubscriptionRedirect/SubscriptionRedirect";
import { useTheme } from "../../theme/ThemeProvider";
import { nativeBridge } from "../../utils/NativeBridge";
import { LOGGING_TAG } from "../../utils/Utils";
import React from "react";

const TAG = "WebRTCGamePlay";

const GamePlayPage = () => {
  const history = useHistory();
  const { hideMenu, showMenu } = useMenu();
  const {
    setHeaderStyle,
    hideHeader,
    showHeader,
    setTitle: setHeaderTitle,
    setHeaderLeft,
    setHeaderRight,
  } = useHeader();
  const { push: modalPush, modals, Modal } = useModal();

  const windowed = localStorage.getItem(StorageKey.WINDOWED);
  const mute = localStorage.getItem(StorageKey.STREAM_MUTED);
  const { isVideoMuted } = useMuteVideo();
  const modalSubscription = useRef<ModalSubscription>();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const { config, stats4Nerds, locale  } = useSelector((state: State) => state.globalState);

  // remove app DDAppender and add a new DDAppender with corresponding level
  useEffect(() => {
    const newDDAppender = new DDAppender({ type: "web_player" });
    switch (config?.userConf?.levelLogPlayer) {
      case PlayerLogLevel.TRACE:
      case PlayerLogLevel.DEBUG:
        newDDAppender.level = LogLevel.LEVEL_DEBUG;
        break;
      case PlayerLogLevel.INFO:
        newDDAppender.level = LogLevel.LEVEL_INFO;
        break;
      case PlayerLogLevel.WARN:
        newDDAppender.level = LogLevel.LEVEL_WARN;
        break;
    }

    // Required for i18n ... FIXME to be reviewed
    configure({
      endpoint: apiService.endpoint,
      userAgent: {
        ...apiService.userAgent,
        flavor: apiService.userAgent.flavor || "app",
      },

      userToken: {
        ...apiService.userToken!,
      },
      logging: {
        appenders: logger.appenders,
        level: logger.level,
      },
      appVersion: process.env.REACT_APP_VERSION,

      locale,
    });

    
    logger.configure({
      level: newDDAppender.level,
      appenders: [...(logger.appenders || []), newDDAppender].filter(
        (f) => f !== DD_APPENDER,
      ),
    });
    return () => {
      let appLevel = LogLevel.LEVEL_INFO;
      switch (config?.userConf?.levelLogClient) {
        case "debug":
          appLevel = LogLevel.LEVEL_DEBUG;
          break;
        case "info":
          appLevel = LogLevel.LEVEL_INFO;
          break;
        case "warn":
          appLevel = LogLevel.LEVEL_WARN;
          break;
      }
      logger.configure({
        level: appLevel,
        appenders: [...(logger.appenders || []), DD_APPENDER].filter(
          (f) => f !== newDDAppender,
        ),
      });
    };
  }, [config, locale]);
  const { profile } = useSelector((state: State) => state.profilesState);

  const tearDown = useCallback(() => {
    showHeader();
    showMenu();
    inputUI.gameMode = false;

    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
  }, [showHeader, showMenu]);

  // Configure header
  useEffect(() => {
    setHeaderTitle("");
    setHeaderRight(undefined);
    setHeaderLeft(<HeaderBackButton />);
    setHeaderStyle({
      ...theme.headerStyle,
      backgroundColor: "transparent",
      borderWidth: 0,
    });

    return () => {
      setHeaderStyle(undefined);
    };
  }, [
    setHeaderLeft,
    setHeaderRight,
    setHeaderStyle,
    setHeaderTitle,
    t,
    theme.headerStyle,
  ]);

  const closeErrorModal = useCallback(() => {
    tearDown();
    history.goBack();
  }, [history, tearDown]);

  const onPlay = useCallback(() => {
    inputUI.gameMode = true;
    hideHeader();
    const $video = document.querySelector("video");

    if (isVideoMuted && $video) {
      $video.muted = true;

      if (!!!mute) {
        $video["data-tounmute"] = "true";
      }
    }
  }, [hideHeader, isVideoMuted, mute]);

  const { redirectToSubscription } = useSubscriptionRedirect();

  const _redirectToSubscription = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
    tearDown();

    //Le's defer redirect, otherwise, redirect will go the / but modal close will go back
    //Stream will be relaunched
    setTimeout(redirectToSubscription, 500);
  }, [redirectToSubscription, tearDown]);

  const onError = useCallback(
    (e: WebRTCError) => {
      logE(TAG, "Caught error on player", e);

      //Close exit confirmation modal if any
      modalSubscription.current?.remove();

      inputUI.gameMode = false;

      if (e.code === ApiErrorCode.NOT_SUBSCRIBED) {
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            message={t("dialogs.error.notSubscribed.message")}
            title={t("dialogs.error.notSubscribed.title")}
            onClose={closeErrorModal}
            buttons={
              profile?.isMaster && [
                <PrimaryButton key="ok" onClick={_redirectToSubscription}>
                  {t("buttons.ok")}
                </PrimaryButton>,
                <SecondaryButton key="cancel" onClick={closeErrorModal}>
                  {t("buttons.cancel")}
                </SecondaryButton>,
              ]
            }
          />
        ));
      } else {
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            message={e.message}
            title={t("dialogs.error.title")}
            onClose={closeErrorModal}
            buttons={[
              <PrimaryButton key="ok" onClick={closeErrorModal}>
                {t("buttons.ok")}
              </PrimaryButton>,
            ]}
          />
        ));
      }
    },
    [modalPush, t, closeErrorModal, profile?.isMaster, _redirectToSubscription],
  );

  const onCancelLaunch = useCallback(() => {
    // This can occurs whenever user
    // Click on the navigator back button (launcher is unmounted triggering the onCancelLaunch)
    // Click on the soft back button (launcher is unmounted triggering the onCancelLaunch)
    // Click on the OK button in the confirmation message
    // for case 1&2 we do not need to go back
    const location =
      (history.location.state as any)?.background?.pathname || history.location.pathname;

    if (location.match(/play$/)) {
      history.goBack();
    }
    tearDown();
  }, [history, tearDown]);

  const onStop = useCallback(() => {
    // This can occurs whenever user
    // Click on the navigator back button (launcher is unmounted triggering the onStop)
    // Click on the soft back button (launcher is unmounted triggering the onStop)
    // Click on the OK button in the exit confirmation message
    // In game exit
    // for case 1&2 we do not need to go back
    const location =
      (history.location.state as any)?.background?.pathname || history.location.pathname;
    if (location.match(/play$/)) {
      history.goBack();
    }
    tearDown();
  }, [history, tearDown]);

  const params = useParams<{ gameId: string }>();

  useEffect(() => {
    const module = nativeBridge.module;
    return () => {
      const p =
        module?.handleInAppWebRTC() && module?.exitFullscreen
          ? module?.exitFullscreen?.()
          : exitFullscreen(document);

      if (p) {
        p.then(() => {
          logD(LOGGING_TAG, "exit fullscreen ok");
        }).catch((e) => {
          logE(LOGGING_TAG, "exit fullscreen ko: %o", e);
        });
      }
    };
  }, []);

  useEffect(() => {
    hideMenu();
  }, [hideMenu]);

  return (
    <>
      <SDKModalContext.Provider
        value={{
          modals,
          push: modalPush,
          Modal,
        }}
      >
        <GameLauncher
          gameId={params.gameId}
          onCancelLaunch={onCancelLaunch}
          onError={onError}
          onStop={onStop}
          onPlay={onPlay}
          stats={stats4Nerds}
          confirmExit={true}
          fullscreenHelper={
            nativeBridge.module?.handleInAppWebRTC()
              ? {
                  requestFullscreen: nativeBridge.module?.requestFullscreen?.bind(
                    nativeBridge.module,
                  ),
                  addFullcreenChangeListener:
                    nativeBridge.module?.addFullcreenChangeListener?.bind(
                      nativeBridge.module,
                    ),
                  removeFullscreenChangeListener:
                    nativeBridge.module?.removeFullscreenChangeListener?.bind(
                      nativeBridge.module,
                    ),
                  exitFullscreen: nativeBridge.module?.exitFullscreen?.bind(
                    nativeBridge.module,
                  ),
                  isFullscreen: nativeBridge.module?.isFullscreen?.bind(
                    nativeBridge.module,
                  ),
                  teardown: () => {
                    // ??
                  },
                }
              : new DefaultFullscreenHelper()
          }
          preferedWindowMode={windowed ? "windowed" : undefined}
          autoplay={
            nativeBridge.module?.handleInAppWebRTC() ||
            apiService.userAgent.client === ClientDeviceType.TV
          }
          mute={!!mute}
        />
      </SDKModalContext.Provider>
    </>
  );
};

export default GamePlayPage;
