import React, { PropsWithChildren, useCallback } from "react";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";

interface FocusableNavLinkProps extends PropsWithChildren<NavLinkProps> {
  onPressEnter?: (event: React.UIEvent) => void;
}
const ActiveState = css`
  transform: ${(props) =>
    props.theme.accountCardStyle.scaleFactor
      ? `scale(${props.theme.accountCardStyle.scaleFactor})`
      : undefined};

  background-color: ${(props) => props.theme.primaryButton.activeBackgroundColor};
  color: ${(props) => props.theme.primaryButton.activeTextColor};
  box-shadow: 0 0 1.2rem ${(props) => props.theme.primaryButton.activeBackgroundColor};
  svg {
    color: ${(props) => props.theme.primaryButton.activeTextColor};
  }

  &.active {
    svg {
      color: ${(props) => props.theme.primaryButton.activeTextColor};
    }
  }

  span {
    color: ${(props) => props.theme.primaryButton.activeTextColor};
  }
  button {
    background-color: ${(props) => props.theme.primaryButton.activeTextColor};
    color: ${(props) => props.theme.primaryButton.activeBackgroundColor};
  }
`;
const StyledFocusable = styled(Focusable)``;

const StyledNavLink = styled(NavLink)<{
  theme: Theme;
  spatialNavigationActive: boolean;
}>`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: ${dimens.margins.DarkRed}rem ${dimens.margins.Green}rem;
  border-radius: ${(props) => props.theme.accountCardStyle.radius}rem;
  color: ${(props) => props.theme.textStyle.color};
  transition: all 250ms;

  svg {
    width: 3.6rem;
    height: 3.6rem;
    display: inline-block;
    margin-right: ${dimens.margins.DarkRed}rem;
  }

  &:hover {
    ${(props) => (props.spatialNavigationActive ? "" : ActiveState)}
  }
  &.active {
    color: ${(props) =>
      props.to !== "#" && props.theme.primaryButton.activeBackgroundColor};

    svg {
      color: ${(props) =>
        props.to !== "#" && props.theme.primaryButton.activeBackgroundColor};
    }
  }
  ${StyledFocusable}:focus & {
    ${ActiveState}
  }
`;

const FocusableNavLink = (props: FocusableNavLinkProps) => {
  const { theme } = useTheme();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const history = useHistory();
  const to = props.to.toString();
  const replace = props.replace;
  const onPressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (props.onPressEnter) {
        props.onPressEnter(e);
        if (e.defaultPrevented) {
          return;
        }
      }
      if (replace) {
        history.replace(to);
      } else {
        history.push(to);
      }
    },
    [props, replace, history, to],
  );
  return (
    <StyledFocusable onPressEnter={onPressEnter}>
      <StyledNavLink
        {...props}
        theme={theme}
        activeClassName="active"
        spatialNavigationActive={spatialNavigationActive}
      >
        {props.children}
      </StyledNavLink>
    </StyledFocusable>
  );
};
export default FocusableNavLink;
