import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as CelcomLogo } from "../../assets/dist/celcom.svg";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionKindProps, UnsubscribeBtn, UnsubscribeSpinner } from "./SubscriptionKind";

const StyledLogo = styled(CelcomLogo)`
    max-width: 150px;
    align-self: center;
`;
const SubscriptionCelcom = ({ onUnsubscribePressed, unsubscribing }: SubscriptionKindProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <StyledLogo />
            <UnsubscribeBtn theme={theme} onClick={onUnsubscribePressed} disabled={unsubscribing}>
                <span>{t("buttons.unsubscribe")}</span>
                {unsubscribing && <UnsubscribeSpinner theme={theme} />}
            </UnsubscribeBtn>
        </>
    );
};
export default SubscriptionCelcom;
