import { useAnalytics } from "@blacknut/react-client-core/lib";
import React, { useEffect, useRef } from "react";
import dimens from "../../theme/dimens";
import { useOrientation } from "../../utils/OrientationContext";
import BrowsePageDesktop from "./BrowsePage-desktop";
import BrowsePagePhone from "./BrowsePage-phone";

const BrowsePage = () => {
  const { trackExplorer } = useAnalytics();
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      trackExplorer();
    }
  }, [trackExplorer]);

  const { orientation } = useOrientation();

  const width = Math.max(window.innerWidth, window.innerHeight);
  if (width >= dimens.breakpoints.desktop || orientation === "LANDSCAPE")
    return <BrowsePageDesktop />;
  return <BrowsePagePhone />;
};
export default BrowsePage;
