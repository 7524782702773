import { logE } from "@blacknut/logging/dist";
import {
  ApiErrorCode,
  FEATURE_FLAG,
  getConfig,
  useProfileUnlockPage,
} from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  useForceFocusOnMount,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { TertiaryButton } from "../../components/Button/V2Button";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import Header from "../../components/Header/Header";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import MessageModal from "../../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import Profile from "../../components/Profile/Profile";
import { useTheme } from "../../theme/ThemeProvider";
import { useReactRouter } from "../../utils/Hooks";
import { LOGGING_TAG } from "../../utils/Utils";
import ErrorHandler, { useErrorHandling } from "../../utils/V2ErrorHandler";
import styles from "./styles.module.scss";
import { Pin } from "@blacknut/react-sdk/dist";

const ProfilePinPage = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const routerWrap = useReactRouter<{ id: string }>();
  const dispatch = useDispatch();

  const location = useLocation<{ returnUrl?: string }>();
  let { returnUrl } = location.state || {};
  if (returnUrl === "/" || returnUrl === "/catalog") {
    returnUrl = undefined;
  }

  const {
    profile,
    authenticationError,
    authenticating,
    resetPinInProgress,
    onSelectProfile,
    resetPin,
    resetPinError,
    dismissResetPinError,
    dismissAuthenticationError,
    logout,
    loading,
    loadError,
    dismissLoadError,
    config,
  } = useProfileUnlockPage({
    ...routerWrap,
    returnUrl,
  });
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();

  const closeModal = React.useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);
  const _dismissAuthenticationError = React.useCallback(() => {
    dismissAuthenticationError();
    closeModal();
  }, [closeModal, dismissAuthenticationError]);

  const onPinEntered = React.useCallback(
    async (pin: string) => {
      try {
        await onSelectProfile(pin);
      } catch (e) {
        logE(LOGGING_TAG, "Caught error on profile selection");
        if (e.code !== ApiErrorCode.AUTH_FAILED) {
          modalSubscription.current = modalPush((props) => (
            <ErrorHandler {...props} error={e} onClick={_dismissAuthenticationError} />
          ));
        }
      }
    },
    [_dismissAuthenticationError, modalPush, onSelectProfile],
  );

  const dismissResetPinNoMailModal = React.useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
    logout();
    window.location.reload();
  }, [logout]);

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  React.useEffect(() => {
    setHeaderTitle(undefined);
    setHeaderLeft(undefined);
    setHeaderRight(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t]);

  const _resetPin = React.useCallback(async () => {
    // in case of pin protected profile while coming from a deeplink, to avoid having config.features undefined
    let refreshedConfig;
    if (!config?.features) {
      try {
        refreshedConfig = await getConfig()(dispatch);
      } catch (e) {
        logE(LOGGING_TAG, "Caught error getting config");
      }
    }

    if (
      config?.features?.[FEATURE_FLAG.PIN_RESET_EMAIL] === true ||
      refreshedConfig?.features?.[FEATURE_FLAG.PIN_RESET_EMAIL] === true
    ) {
      try {
        await resetPin();
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            title={t("dialogs.resetPinSuccess.title")}
            onClose={closeModal}
            message={t("dialogs.resetPinSuccess.message")}
          />
        ));
      } catch (e) {
        logE(LOGGING_TAG, "Caught error reseting pin %o", e);
      }
    } else {
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          onClose={dismissResetPinNoMailModal}
          message={t("dialogs.resetPinNoMail.message")}
        />
      ));
    }
  }, [
    closeModal,
    config?.features,
    dismissResetPinNoMailModal,
    dispatch,
    modalPush,
    resetPin,
    t,
  ]);

  useErrorHandling({ error: resetPinError, clearError: dismissResetPinError });
  useErrorHandling({ error: loadError, clearError: dismissLoadError });

  const { pause: pauseSpatialNav, resume: resumeSpatialNav } = useSpatialNavigation();
  useForceFocusOnMount("/pin");
  React.useEffect(() => {
    if (authenticating) {
      pauseSpatialNav();
    } else {
      resumeSpatialNav();
    }
  }, [authenticating, pauseSpatialNav, resumeSpatialNav]);
  return (
    <div
      className={styles.page}
      style={{
        backgroundColor: theme.pinPage?.backgroundColor || theme.backgroundColor,
      }}
    >
      <Header headerLeft={<HeaderBackButton />} />

      <FocusableSection
        className={styles.container}
        focusKey="pin"
        enterTo="default-element"
        defaultElement={{ down: "first" }}
      >
        {profile && (
          <>
            <Profile className={styles.profile} profile={profile} />
            <Pin
              className={styles.pin}
              size="large"
              onPinEntered={onPinEntered}
              errored={!!authenticationError}
            />

            {authenticationError &&
              authenticationError.code === ApiErrorCode.AUTH_FAILED && (
                <div className={styles.error}>{t("profilePin.error")}</div>
              )}

            <div className={styles.subtile}>{t("profilePin.subtitle")}</div>

            {profile.isMaster && (
              <div className={styles.forgotPasswordContainer}>
                <TertiaryButton onClick={_resetPin}>
                  <span>{t("buttons.forgotPin")}</span>
                </TertiaryButton>
                {resetPinInProgress && (
                  <CircularProgress className={styles.resetPinLoading} size={20} />
                )}
              </div>
            )}
          </>
        )}

        {(loading || authenticating) && (
          <CircularProgress className={styles.mainLoading} size={50} />
        )}
      </FocusableSection>
    </div>
  );
};

export default ProfilePinPage;
