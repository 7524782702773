import {
  ImageType,
  useGame,
  useLayout,
  AppLayout,
  GameGenre,
} from "@blacknut/react-client-core/lib";

import {
  FocusableSection,
  getSectionIdFromPath,
  NavigationFailedEvent,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Button } from "@blacknut/react-sdk/dist";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { ReactComponent as FavoriteOff } from "../../../assets/dist/ic_favorite_off_filled.svg";
import { ReactComponent as FavoriteOffActive } from "../../../assets/dist/ic_favorite_off_filled_active.svg";
import { ReactComponent as FavoriteOn } from "../../../assets/dist/ic_favorite_on_filled.svg";
import { ReactComponent as FavoriteOnActive } from "../../../assets/dist/ic_favorite_on_filled_active.svg";
import { ReactComponent as Fullscreen } from "../../../assets/dist/ic_fullscreen_filled.svg";
import { ReactComponent as FullscreenActive } from "../../../assets/dist/ic_fullscreen_filled_active.svg";
import Sharing from "../../../components/Button/ShareButton/ShareButton";
import HeaderBackButton from "../../../components/Header/HeaderBackButton";
import { useHeader } from "../../../components/Header/HeaderProvider";
import LeMagArticleList from "../../../components/LeMagArticleList/LeMagArticleList";
import FullTileList from "../../../components/List/FullTileList";
import { useMenu } from "../../../components/Menu/MenuProvider";
import { HeaderStyle } from "../../../theme/Theme";
import { useTheme } from "../../../theme/ThemeProvider";
import { ScrollRestorer } from "../../../utils/scroll/ScrollProvider";
import useAnonymousCheck from "../../../utils/useAnonymousCheck";
import {
  lockOrientationLandscape,
  LOGGING_TAG,
  unlockOrientation,
} from "../../../utils/Utils";
import { useErrorHandling } from "../../../utils/V2ErrorHandler";
import useGameSpatialNavigation from "../useGameSpatialNavigation";
import useReco from "../useReco";
import { hexToRgb } from "src/utils/ColorUtils";
import {
  BackgroundImage,
  ButtonsContainer,
  Container,
  ContainerBackgroundContent,
  ContainerGameInfo,
  Content,
  Description,
  FreeText,
  Header,
  LeMagContainer,
  MainLoading,
  Mask,
  ReadMore,
  RecoContainer,
  RecoTitle,
  RecoTitleContainer,
  SectionHeader,
  Separator,
  StyledFavBtn,
  StyledFullscreenBtn,
  StyledGameInfo,
  StyledPlayBtn,
  Title,
  Video,
} from "./Game.portrait.styles";
import { GameGenreContainer, ButtonGenre } from "../PageGameGenre/PageGameGenre.styles";
import LeMagArticle from "@blacknut/react-client-core/lib/models/LeMagArticle";
import { BatchEventOpts, useBatch } from "../../../utils/BatchContext";
import { logD } from "@blacknut/logging/dist";
import styles from "./styles.module.scss";

const GamePage = ({
  videoFullscreen,
  setVideoFullscreen,
}: {
  videoFullscreen: boolean;
  setVideoFullscreen: (fullscreen: boolean) => void;
}) => {
  const params = useParams<{ id: string }>();
  const {
    loading,
    loadingError,
    dismissLoadingError,
    game,
    favoritePending,
    favorite,
    toggleFavorite,
    favoriteError,
    dismissFavoriteError,
    config,
  } = useGame({
    gameId: params.id,
  });
  const { active: spatialNavigationActive } = useSpatialNavigation();

  const { showMenu, hideMenu } = useMenu();

  const history = useHistory();
  let developer = "";
  if (game && game.developer) {
    developer += game.developer.name;
  }
  if (game && game.publisher) {
    if (developer) {
      developer += " | ";
    }
    developer += game.publisher.name;
  }

  const backgroundImage =
    game && game.images && game.images.find((img) => img.type === ImageType.BACKGROUND);

  const video = game && game.videos && game.videos.find((v) => v.type === "trailer");

  let descHTMLNoP = "";
  if (game && game.descriptionHtml) {
    descHTMLNoP = game.descriptionHtml.replace("<p>", "").replace("</p>", "");
  }

  let freeTextHTMLNoP = "";
  if (game && game.freeText) {
    freeTextHTMLNoP = game.freeText.replace("<p>", "").replace("</p>", "");
  }
  const { theme } = useTheme();
  const layout = useLayout();

  const { checkForAnonymous } = useAnonymousCheck();
  const onPlayClicked = useCallback(() => {
    if (checkForAnonymous()) {
      history.push(`/game/${game?.id}/play`);
    }
  }, [checkForAnonymous, history, game?.id]);

  const _toggleFavorite = useCallback(() => {
    if (checkForAnonymous()) {
      toggleFavorite();
    }
  }, [checkForAnonymous, toggleFavorite]);
  const { t } = useTranslation();

  const {
    setHeaderLeft,
    setTitle: setHeaderTitle,
    setHeaderRight,
    hideHeader,
    showHeader,
    setHeaderStyle,
  } = useHeader();

  useEffect(() => {
    setHeaderTitle("");
    setHeaderRight(undefined);
    setHeaderLeft(<HeaderBackButton className={styles.arrowBackWhite} />);
    setHeaderStyle({
      ...theme.headerStyle,
      backgroundColor: "transparent",
      borderWidth: 0,
    });
    return () => {
      setHeaderStyle(undefined);
    };
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, setHeaderStyle, theme.headerStyle]);

  const { trackEvent } = useBatch();
  useEffect(() => {
    if (game) {
      const opts: BatchEventOpts = {
        id: game.id,
        name: game.name,
      };
      if (game.genre) {
        opts["category"] = game.genre;
      }
      trackEvent("visited_game", opts);
    }
  }, [game, trackEvent]);

  const colorHeaderInterpolation = (color: string) => {
    const position = refContainer.current?.getBoundingClientRect()?.top;
    const codeColorHexa = hexToRgb(color);
    const colorRGB = `RGBA(${codeColorHexa?.r},${codeColorHexa?.g},${
      codeColorHexa?.b
    }, ${Math.abs(position ? position / 400 : 0)})`;
    return colorRGB;
  };
  useEffect(() => {
    const handleScroll = debounce(
      () => {
        if (window.scrollY > 200) {
          const newHeaderStyle: Partial<HeaderStyle> = {
            ...theme.headerStyle,
            textStyle: {
              color: colorHeaderInterpolation(
                theme.headerStyle.textStyle?.color ?? "#FFFFFF",
              ),
            },
          };
          setHeaderLeft(
            <HeaderBackButton
              title={game?.name}
              headerStyle={newHeaderStyle}
              className={styles.arrowBackRed}
            />,
          );
          setHeaderStyle({
            ...theme.headerStyle,
            backgroundColor: colorHeaderInterpolation(
              theme.headerStyle.backgroundColor ?? "#000000",
            ),
            borderWidth: 0.1,
          });
        } else {
          setHeaderLeft(<HeaderBackButton className={styles.arrowBackWhite} />);
          setHeaderStyle({
            ...theme.headerStyle,
            backgroundColor: "transparent",
            borderWidth: 0,
          });
        }
      },
      5,
      { leading: true, trailing: true },
    );
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [game, setHeaderStyle, setHeaderLeft, theme.headerStyle]);

  const onExitFullscreen = useCallback(
    (e?: React.UIEvent<HTMLElement>, goBack?: boolean) => {
      if (goBack) window.history.back(); // to nullify the previously set --> window.history.pushState(null, "", location.href);
      if (e) e.stopPropagation();

      showHeader();
      showMenu();
      setVideoFullscreen(false);
      unlockOrientation();
    },
    [setVideoFullscreen, showHeader, showMenu],
  );

  const onEnterFullscreen = useCallback(
    (e?: React.UIEvent<HTMLElement>) => {
      if (e) {
        e.stopPropagation();
      }
      setVideoFullscreen(true);
      hideMenu();
      hideHeader();
      lockOrientationLandscape();

      //temporary fix for https://blacknut.atlassian.net/browse/CA-3199
      //a better would be to push video fullscreen into history and remove custom back
      const handlePopstate = () => {
        onExitFullscreen();
        window.removeEventListener("popstate", handlePopstate);
      };

      window.history.pushState(null, "", location.href);
      window.addEventListener("popstate", handlePopstate);
    },
    [hideHeader, hideMenu, onExitFullscreen, setVideoFullscreen],
  );
  useEffect(() => {
    const handleScroll = () => {
      const positionVideo = refContainer.current?.getBoundingClientRect()?.bottom;
      if (positionVideo && positionVideo < 200 && !videoFullscreen) {
        videoRef.current?.pause();
      } else {
        videoRef.current?.play();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [game, videoFullscreen]);

  useErrorHandling({
    error: loadingError,
    clearError: dismissLoadingError,
    callback: history.goBack,
  });
  useErrorHandling({ error: favoriteError, clearError: dismissFavoriteError });

  // show header on back
  useEffect(() => {
    showHeader();
  }, [showHeader]);

  // show menu on back
  useEffect(() => {
    showMenu();
  }, [showMenu]);

  useGameSpatialNavigation({ game, videoFullscreen });

  const leMagSectionIdMain = `@${getSectionIdFromPath(`/${game?.id}/readMore`)}`;
  const shareIdList = `@${getSectionIdFromPath(`/${game?.id}/share`)}`;
  const recoSectionId = `@${getSectionIdFromPath(`/${game?.id}/reco-0`)}`;
  const btnsSectionId = `@${getSectionIdFromPath(`/${game?.id}/btns`)}`;
  const onNavigationFailedScrollTop = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction == "up") {
      document.documentElement.scroll({ top: 0 });
    }
  }, []);
  const recommandations = useReco({ game });
  const refContainer = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const articles = useMemo(
    () => (game?.lemag ? game.lemag.filter((art) => art.title) : []),
    [game],
  );
  const onClickArticle = useCallback(
    (article: LeMagArticle) => {
      const match = article.link.match(/.*\/(.*)$/);
      if (match) [history.push(`/lemag/${match[1]}`)];
    },
    [history],
  );

  const webRTCSupported =
    process.env.NODE_ENV === "test" || "RTCPeerConnection" in window;
  const renderGameType = useCallback(
    (genre: GameGenre) => {
      const onClick = async () => {
        history.push(`/genre/${genre.uuid}`);
      };
      return (
        <ButtonGenre
          variant="secondary"
          testID={genre.uuid}
          onClick={onClick}
          theme={theme}
        >
          {genre.title}
        </ButtonGenre>
      );
    },
    [history, theme],
  );
  return (
    <Container
      theme={theme}
      focusKey={`${params.id}`}
      onNavigationFailed={onNavigationFailedScrollTop}
    >
      {loading && <MainLoading size={50} />}
      <ScrollRestorer />

      {game && (
        <>
          {backgroundImage && (
            <>
              <BackgroundImage src={backgroundImage.url} />
              <Mask className="masque" />
            </>
          )}

          {/* Game info */}

          {video && (
            <Video
              game={game}
              fullscreen={videoFullscreen}
              onExitFullscreen={(e) => onExitFullscreen(e, true)}
              onPlayClicked={onPlayClicked}
              muteControls={videoFullscreen}
              focusPathPrefix={`/${game.id}`}
              leaveFor={{ down: btnsSectionId }}
              videoRef={videoRef}
              mute={videoFullscreen ? undefined : true}
            />
          )}
          {!videoFullscreen && (
            <>
              <Header ref={refContainer}>
                {/* Background video */}

                <Title theme={theme}>{game.name}</Title>
                <ButtonsContainer
                  focusKey="btns"
                  leaveFor={{
                    down:
                      articles.length > 0
                        ? leMagSectionIdMain
                        : recommandations && recommandations.length > 0
                        ? recoSectionId
                        : undefined,
                  }}
                >
                  <StyledPlayBtn
                    variant={spatialNavigationActive ? "secondary" : "primary"}
                    testID="play"
                    theme={theme}
                    onClick={onPlayClicked}
                    disabled={!webRTCSupported}
                  >
                    {config?.features?.ericssonBoost &&
                    config?.features?.ericssonBoostSubscribed === false
                      ? t("buttons.play_hd")
                      : t("buttons.play")}
                  </StyledPlayBtn>

                  <StyledFavBtn
                    variant="iconButton"
                    theme={theme}
                    disabled={favoritePending}
                    onClick={_toggleFavorite}
                    testID="favorite"
                  >
                    {favorite && (
                      <>
                        <FavoriteOn />
                        <FavoriteOnActive />
                      </>
                    )}
                    {!favorite && (
                      <>
                        <FavoriteOff />
                        <FavoriteOffActive />
                      </>
                    )}
                  </StyledFavBtn>
                  <StyledFullscreenBtn
                    variant="iconButton"
                    testID="maximize"
                    theme={theme}
                    onClick={onEnterFullscreen}
                  >
                    <Fullscreen />
                    <FullscreenActive />
                  </StyledFullscreenBtn>
                </ButtonsContainer>

                {!webRTCSupported && (
                  <span className={styles.errorWebRTC}>
                    {t("errors.webrtc.unsupported")}
                  </span>
                )}
              </Header>
              <ContainerBackgroundContent theme={theme}>
                <Content theme={theme}>
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: descHTMLNoP,
                    }}
                  />
                  {config?.features?.lemag && articles.length > 0 && (
                    <FocusableSection focusKey="readMore">
                      <ReadMore
                        theme={theme}
                        onClick={() => {
                          onClickArticle(articles?.[0]);
                        }}
                      >
                        {t("buttons.readMore")}
                      </ReadMore>
                    </FocusableSection>
                  )}

                  <ContainerGameInfo theme={theme}>
                    <StyledGameInfo theme={theme} game={game} layout={layout} />
                    <Separator theme={theme} />
                    <div>
                      {game.developer && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t("game.developer", {
                              developer: game.developer.name,
                            }),
                          }}
                        />
                      )}
                      {game.publisher && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t("game.publisher", {
                              publisher: game.publisher.name,
                            }),
                          }}
                        />
                      )}
                    </div>
                  </ContainerGameInfo>
                  <GameGenreContainer
                    focusKey="gameGenre"
                    leaveFor={{ up: leMagSectionIdMain }}
                  >
                    {game && game.genres && game.genres.map(renderGameType)}
                  </GameGenreContainer>
                  <LeMagContainer>
                    {config?.features?.lemag && articles.length > 0 && (
                      <>
                        <Separator theme={theme} />
                        <SectionHeader theme={theme}>{t("game.lemag")}</SectionHeader>
                        <FocusableSection
                          focusKey="lemag"
                          leaveFor={{ up: leMagSectionIdMain }}
                          disabled={videoFullscreen}
                        >
                          <LeMagArticleList
                            onClick={onClickArticle}
                            articles={articles}
                            ItemProps={{
                              onMobile: true,
                              style: { color: theme.cardStyle.inactiveTextColor },
                            }}
                          />
                        </FocusableSection>
                      </>
                    )}
                    <Separator theme={theme} />
                    <SectionHeader theme={theme}>{t("game.info")}</SectionHeader>

                    <FreeText
                      dangerouslySetInnerHTML={{
                        __html: freeTextHTMLNoP,
                      }}
                    />
                  </LeMagContainer>
                  <Sharing />
                </Content>
              </ContainerBackgroundContent>
              {recommandations && recommandations.length > 0 && (
                <RecoContainer theme={theme}>
                  {recommandations.map((list, i) => {
                    return (
                      <div key={`${i}`}>
                        <RecoTitleContainer>
                          <RecoTitle theme={theme}>{list.title}</RecoTitle>
                        </RecoTitleContainer>
                        <FullTileList
                          numColumns={layout === AppLayout.PHONE ? 3 : 6}
                          showTitle={false}
                          list={list}
                          leaveFor={{
                            up: shareIdList,
                          }}
                        />
                      </div>
                    );
                  })}
                </RecoContainer>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};
export default GamePage;
