import {
  AppLayout,
  useAnalytics,
  useLayout,
  useLeMagFeaturedArticles,
  usePrevious,
} from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  NavigationFailedEvent,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import LeMagArticleListItem from "../../components/LeMagArticleListItem/LeMagArticleListItem";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import styles from "./styles.module.scss";
import React from "react";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import LeMagArticleModal from "../../components/LeMagArticleModal/LeMagArticleModal";

const LeMagPage = () => {
  const {
    loading,
    loadError,
    dismissLoadError,
    articles,
    fetchArticles,
    paginateNext,
    paginating,
    totalArticles,
  } = useLeMagFeaturedArticles({ isScreenForeground: true, limit: 20 });

  const modalSubscription = useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const layout = useLayout();

  useErrorHandling({ error: loadError, clearError: dismissLoadError, callback: goBack });

  const closeArticleModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);
  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const { setHeaderRight, setTitle: setHeaderTitle, setHeaderLeft } = useHeader();

  const { t } = useTranslation();
  useEffect(() => {
    {
      setHeaderLeft(<HeaderBackButton title="LeMag" />);
      setHeaderTitle("LeMag");
      setHeaderRight(undefined);
    }
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t]);
  const [paginationThresholdRef, inView] = useInView({
    threshold: 0,
  });

  const { trackLeMag } = useAnalytics();
  useEffect(() => {
    trackLeMag();
  }, [trackLeMag]);

  const prevArticles = usePrevious(articles);
  const { resume: resumeSpatialNav } = useSpatialNavigation();
  useEffect(() => {
    if (!prevArticles && articles) {
      setTimeout(() => {
        resumeSpatialNav();
      }, 200);
    }
  }, [articles, prevArticles, resumeSpatialNav]);

  const onNavigationFailed = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "up") {
      document.documentElement.scroll({ top: 0 });
    } else if (e.detail.direction === "down") {
      document.documentElement.scroll({ top: document.body.scrollHeight });
    }
  }, []);

  useEffect(() => {
    if (inView) {
      if (articles.length < totalArticles && !paginating) {
        paginateNext();
      }
    }
  }, [articles.length, inView, paginateNext, paginating, totalArticles]);
  return (
    <FocusableSection
      className={styles.container}
      focusKey="/lemag"
      onNavigationFailed={onNavigationFailed}
    >
      {loading && <CircularProgress className={styles.loading} size={50} />}

      {articles.length > 0 && (
        <ul className={styles.list}>
          {articles.map((art) => {
            const onClick = () => {
              if (layout === AppLayout.TV) {
                modalSubscription.current = modalPush((props) => (
                  <LeMagArticleModal
                    {...props}
                    articles={articles}
                    article={art}
                    onClose={closeArticleModal}
                    closeButtonText={t("buttons.cancel")}
                    history={history}
                  />
                ));
              } else {
                history.push(`/lemag/${art.short_slug}`);
              }
            };
            return (
              <li key={art.slug} onClick={onClick}>
                <LeMagArticleListItem article={art} onPressEnter={onClick} />
              </li>
            );
          })}
        </ul>
      )}

      <div ref={paginationThresholdRef} />
      {paginating && <CircularProgress className={styles.loading} size={36} />}
    </FocusableSection>
  );
};
export default LeMagPage;
