import { State } from "@blacknut/react-client-core/lib";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import electronService from "../services/ElectronService";
const useDebug = () => {
  const { config } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    electronService.sendDebug(config?.userConf?.levelLogClient === "debug");
  }, [config]);
};
export default useDebug;
