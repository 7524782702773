import { logE } from "@blacknut/logging/dist";
import {
  ApiErrorCode,
  MAX_FAMILY_PROFILES,
  RouteNames,
  useProfilesPage,
  UserProfile,
} from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  useForceFocusOnMount,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import Header from "../../components/Header/Header";
import { useHeader } from "../../components/Header/HeaderProvider";
import ProfilAddCard from "../../components/Profile/ProfileAddCard";
import Profile from "../../components/Profile/ProfileCardItem";
import { navigate, useReactRouter } from "../../utils/Hooks";
import Utils, { LOGGING_TAG } from "../../utils/Utils";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import styles from "./styles.module.scss";

const ProfilesPage = () => {
  const routerWrap = useReactRouter<{ returnUrl?: string }>();
  const history = useHistory();

  const location = useLocation<{ returnUrl?: string }>();
  let { returnUrl } = location.state || {};
  if (returnUrl === "/" || returnUrl === "/catalog") {
    returnUrl = undefined;
  }
  const {
    user,
    profile,
    profiles,
    authenticating,
    loading,
    onSelectProfile,
    authenticationError,
    dismissAuthenticationError,
    loadingError,
    dismissLoadingError,
  } = useProfilesPage({
    ...routerWrap,
    navigate: (route) => {
      if (typeof route !== "string") {
        if (
          (route.name === RouteNames.HOME ||
            route.name ===
              RouteNames.PAYMENT_SELECTION) /* Payment selection is deprecated*/ &&
          returnUrl
        ) {
          // Selecting same profile, route is hard coded
          return navigate(history, returnUrl);
        } else if (route.name === RouteNames.PROFILE_UNLOCK) {
          //https://blacknut.atlassian.net/browse/CA-3799
          return navigate(
            history,
            { ...route, returnUrl: undefined },
            { returnUrl: returnUrl || route.returnUrl },
          );
        }
      }
      return navigate(history, route, {
        returnUrl,
      });
    },
  });
  const { t } = useTranslation();
  const { pause: pauseSpatialNav, resume: resumeSpatialNav } = useSpatialNavigation();
  const [loadingSelectAuto, setLoadingSelectAuto] = useState<boolean>(false);
  const addProfile = profiles?.filter(
    (profile) => profile.isMaster == true,
  )[0] as UserProfile;
  let title = "";
  if (profile) {
    title = t("profiles.titles.select");
  } else {
    if (profiles && profiles.length > 1) {
      title = t("profiles.titles.multi");
    } else if (profiles) {
      title = t("profiles.titles.single");
    }
  }
  const renderProfile = useCallback(
    (profile: UserProfile) => {
      const onClick = () => {
        pauseSpatialNav();
        onSelectProfile(profile);
      };
      return (
        <li className={styles.item}>
          <Profile onClick={onClick} profile={profile} key={profile.id} />
        </li>
      );
    },
    [onSelectProfile, pauseSpatialNav],
  );

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  React.useEffect(() => {
    setHeaderTitle(undefined);
    setHeaderLeft(undefined);
    setHeaderRight(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t]);

  // Auto select first profile
  useEffect(() => {
    if (!profile && profiles && profiles.length === 1 && !authenticating) {
      setLoadingSelectAuto(true);
      const init = async () => {
        try {
          await onSelectProfile(profiles[0]);
          setLoadingSelectAuto(false);
        } catch (e) {
          logE(LOGGING_TAG, "Caught error on profile selection %o", e);
          setLoadingSelectAuto(false);
        }
      };
      init();
    }
  }, [authenticating, onSelectProfile, profile, profiles]);

  const _dismissAuthenticationError = useCallback(() => {
    if (authenticationError) {
      dismissAuthenticationError();
      if (
        authenticationError.status === "401" &&
        authenticationError.code === ApiErrorCode.ACCOUNT_LOCKED
      ) {
        routerWrap.navigate({ name: RouteNames.FORGOT_PASSWORD });
      }
    }
  }, [authenticationError, dismissAuthenticationError, routerWrap]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  useErrorHandling({
    error:
      loadingError && loadingError.code !== "unauthorized" ? loadingError : undefined,
    clearError: dismissLoadingError,
    callback: reload,
  });

  useEffect(() => {
    if (loadingError?.code === "unauthorized") {
      reload();
    }
  }, [loadingError?.code, reload]);

  useErrorHandling({
    error: authenticationError,
    clearError: _dismissAuthenticationError,
  });

  useForceFocusOnMount("/profiles");
  useEffect(() => {
    if (loading) {
      pauseSpatialNav();
    } else {
      resumeSpatialNav();
    }
  }, [loading, pauseSpatialNav, resumeSpatialNav]);
  const canAddProfile =
    (user && profile && profiles && Utils.canAddProfile(user, profile, profiles)) ||
    (!user &&
      !profile &&
      profiles &&
      profiles.length <
        MAX_FAMILY_PROFILES); /* if no current profile allow add if max not reached */

  return (
    <div className={styles.page}>
      <Header />
      {!loadingSelectAuto && (
        <div className={styles.container}>
          <h1 className={styles.title}>{title}</h1>

          <FocusableSection focusKey="profiles">
            <div className={styles.profilesContainer}>
              {profiles && profiles.map(renderProfile)}
              {canAddProfile && (
                <ProfilAddCard
                  profilMaster={addProfile}
                  onSelectProfile={onSelectProfile}
                />
              )}
            </div>
          </FocusableSection>
          {(authenticating || loading) && (
            <CircularProgress className={styles.loading} size={50} />
          )}
        </div>
      )}
      {loadingSelectAuto && (
        <CircularProgress className={styles.initialLoading} size={50} />
      )}
    </div>
  );
};
export default ProfilesPage;
