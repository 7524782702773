import { logD } from "@blacknut/logging/dist";
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { LOGGING_TAG } from "./Utils";

export declare type MuteVideoType = boolean | undefined;

export const MuteVideoContext = React.createContext<{
  isVideoMuted: MuteVideoType;
}>({
  isVideoMuted: undefined,
});

export const MuteVideoContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isVideoMuted, setIsVideoMuted] = useState<MuteVideoType>(undefined);

  useEffect(() => {
    const listener = (e: any) => {
      const $videos = document.querySelectorAll("video");
      logD(LOGGING_TAG, "muteWebView event dispatched", e.detail);

      if (e.detail == true) {
        setIsVideoMuted(true);
        $videos.forEach((video) => {
          if (!video.muted) {
            video.muted = true;
            video["data-tounmute"] = "true";
          }
        });
      }
      if (e.detail == false) {
        setIsVideoMuted(false);
        $videos.forEach((video) => {
          if (video["data-tounmute"] == "true") {
            video.muted = false;
            video.removeAttribute("data-tounmute");
          }
        });
      }
    };

    window.addEventListener("muteWebView", listener);
    return () => {
      window.removeEventListener("muteWebView", listener);
    };
  }, []);

  return (
    <MuteVideoContext.Provider
      value={{
        isVideoMuted,
      }}
    >
      {children}
    </MuteVideoContext.Provider>
  );
};

export const useMuteVideo = () => useContext(MuteVideoContext);
