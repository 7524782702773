import { useProfilesPage } from "@blacknut/react-client-core/lib";
import React, { useCallback } from "react";
import styled from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useReactRouter } from "../../utils/Hooks";
import { FocusableSection, NavigationFailedEvent } from "@blacknut/spatialnav-sdk/dist";
import Utils from "../../utils/Utils";
import ProfileAddButtonListItem from "../Profile/ProfileAddButtonListItem";
import Profile from "../Profile/ProfileListItem";
const Container = styled(FocusableSection)<{ $centered?: boolean }>`
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  min-height: min-content;
`;
const Separator = styled.div`
  margin: ${dimens.margins.Green}rem ${dimens.margins.DarkRed}rem;
  background: ${(props: { theme: Theme }) => props.theme.separatorColor};
  width: 1px;
  flex-shrink: 0;
  height: 8rem;
  align-self: flex-start;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    height: 14rem;
  }
`;

const ProfileAddButtonListItemStyled = styled(ProfileAddButtonListItem)`
  width: 11rem;
  height: 14rem;
`;

const ProfileStyled = styled(Profile)<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.listStyle.backgroundColor};
  width: 11rem;
  height: 14rem;
  margin: 0 ${dimens.margins.DarkRed - 0.2}rem;
  border-radius: 1.2rem;
`;

const ProfileHorizontalList = (props: { className?: string }) => {
  const { profiles, onSelectProfile, profile, user } = useProfilesPage(useReactRouter());
  const canAddProfile =
    user && profile && profiles && Utils.canAddProfile(user, profile, profiles);
  const { theme } = useTheme();
  const onNavigationFailed = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "right") {
      e.target.scrollTo({ left: e.target.offsetWidth });
    }
  }, []);

  return (
    <Container
      className={props.className}
      focusKey="profiles"
      onNavigationFailed={onNavigationFailed}
    >
      {canAddProfile && (
        <>
          <ProfileAddButtonListItemStyled />
          <Separator theme={theme} />
        </>
      )}
      {profiles &&
        profiles.map((p) => {
          return (
            <ProfileStyled
              theme={theme}
              key={p.id}
              profile={p}
              onClick={() => onSelectProfile(p)}
              size="medium"
            />
          );
        })}
    </Container>
  );
};

export default ProfileHorizontalList;
