import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionKindProps, SubscriptionSubStatus, UnsubscribeBtn, UnsubscribeSpinner } from "./SubscriptionKind";
import { ReactComponent as SwisscomLogo } from "../../assets/dist/store_swisscom.svg";
import styled from "styled-components";

const StyledLogo = styled(SwisscomLogo)`
    max-width: 150px;
    align-self: center;
`;

const SubscriptionSwisscom = ({ onUnsubscribePressed, unsubscribing }: SubscriptionKindProps) => {
    const { theme } = useTheme();
    const { t } = useTranslation();
    return (
        <>
            <StyledLogo />
            <UnsubscribeBtn theme={theme} onClick={onUnsubscribePressed} disabled={unsubscribing}>
                <span>{t("buttons.unsubscribe")}</span>
                {unsubscribing && <UnsubscribeSpinner theme={theme} />}
            </UnsubscribeBtn>
        </>
    );
};
export default SubscriptionSwisscom;
