import { useHistory } from "react-router";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../assets/dist/ic_arrow_back_active.svg";
import electronService from "../../services/ElectronService";
import { HeaderStyle, TextStyle, Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import dimens from "../../theme/dimens";
import IconButton from "../Button/IconButton";
import React from "react";

const overrideIfNeeded = <T extends unknown>(
  key: keyof TextStyle,
  _default: Partial<TextStyle>,
  override?: Partial<TextStyle>,
): T | undefined => {
  if (override) return override[key] as T | undefined;
  return _default[key] as T | undefined;
};

const StyledWebBackButton = styled(IconButton)<{ theme: Theme; darwin: boolean }>`
  display: block;
  border-radius: 50% !important;

  svg {
    fill: ${(props) => props.theme.headerStyle.buttonsTintColor};
    color: ${(props) => props.theme.headerStyle.buttonsTintColor};
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    padding: 0.4rem;
  }

  &:focus {
    background-color: ${(props) => props.theme.rippleColor};
    box-shadow: 0 0 1.2rem ${(props) => props.theme.rippleColor};

    svg {
      color: #ffffff !important;
    }
  }

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    border-radius: 0.6rem !important;
    -webkit-app-region: no-drag;
    display: flex;
    align-self: stretch;
    button {
      min-height: 0;
    }

    svg {
      margin: 0 0.6rem;
      padding: 0.2rem;
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${(props) => props.theme.rippleColor};
        box-shadow: 0 0 1.2rem ${(props) => props.theme.rippleColor};

        svg {
          color: #ffffff;
        }
      }
    }
    &:focus {
      background-color: ${(props) => props.theme.rippleColor};
      box-shadow: 0 0 1.2rem ${(props) => props.theme.rippleColor};

      svg {
        color: #ffffff;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div<{
  theme: Theme;
  headerStyle?: Partial<HeaderStyle>;
}>`
  color: ${(props) =>
    overrideIfNeeded(
      "color",
      props.theme.headerStyle.textStyle,
      props.headerStyle?.textStyle,
    )};
  font-family: ${(props) => props.theme.buttonTextStyle.fontFamily};
  font-size: ${(props) => props.theme.headerStyle.textStyle.size}rem;
  font-weight: ${(props) => props.theme.headerStyle.textStyle.fontWeight};
  margin-left: ${dimens.margins.Green}rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface HeaderBackButtonProps {
  title?: string;
  headerStyle?: Partial<HeaderStyle>;
  onClick?: () => void;
  className?: string;
}
const HeaderBackButton = ({
  title,
  headerStyle,
  onClick,
  className,
}: HeaderBackButtonProps) => {
  const history = useHistory();
  const { theme } = useTheme();

  return (
    <Container>
      <StyledWebBackButton
        theme={theme}
        className={className}
        darwin={electronService.getPlatform() === "darwin"}
        onClick={onClick || history.goBack}
        testID="back"
        rippleStyle={title ? { radius: 0 } : undefined}
      >
        <BackIcon />
      </StyledWebBackButton>
      {title && (
        <Title theme={theme} headerStyle={headerStyle}>
          {title}
        </Title>
      )}
    </Container>
  );
};
export default HeaderBackButton;
