import { logD } from "@blacknut/logging/dist";
import { State } from "@blacknut/react-client-core/lib";
import * as H from "history";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";

const openedModals: React.ComponentType<Partial<ReactModal.Props>>[] = [];

const TAG = "Modal";

const defaultValue = {
  modals: openedModals,
  push: () => {
    console.log("nop");
    return {
      remove: () => {
        console.log("nop");
      },
    };
  },
};

export interface ModalSubscriptionOpt {
  preventGoBack?: boolean;
}
export interface ModalSubscription {
  remove: (e?: ModalSubscriptionOpt) => void;
}

export interface ModalContextInjectedProps {
  modalPush: <TOriginalProps extends Omit<ReactModal.Props, "isOpen">>(
    modal: React.ComponentType<TOriginalProps & Pick<ReactModal.Props, "isOpen">>,
  ) => ModalSubscription;
}

export const ModalContext = React.createContext<{
  modals: React.ComponentType<Partial<ReactModal.Props>>[];
  push: <TOriginalProps extends Omit<ReactModal.Props, "isOpen">>(
    modal: React.ComponentType<TOriginalProps & Pick<ReactModal.Props, "isOpen">>,
  ) => ModalSubscription;
  Modal?: React.ComponentType<Partial<ReactModal.Props>>;
}>(defaultValue);

export const ModalSwitch = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation<{ background: H.Location<unknown> }>();
  const background = location.state && location.state.background;
  const { Modal } = useModal();
  return (
    <>
      <Switch location={background || location}>{children}</Switch>

      {/* Show the modal when a background page is set */}
      {background && <Route path="/modal">{Modal && <Modal isOpen={true} />}</Route>}
    </>
  );
};

export const ModalContextProvider = (props: PropsWithChildren<unknown>) => {
  const [modals, setModals] = useState(
    [] as React.ComponentType<Partial<ReactModal.Props>>[],
  );
  const MyModal = modals && modals.length > 0 ? modals[0] : undefined;
  const history = useHistory();
  const historyRef = useRef(history);

  const { user } = useSelector((state: State) => state.globalState);

  useEffect(() => {
    if (!user) {
      setModals([]);
    }
  }, [user]);

  const push = useCallback(
    <TOriginalProps extends Omit<ReactModal.Props, "isOpen">>(
      modal: React.ComponentType<TOriginalProps>,
    ) => {
      setModals((modals) => {
        return [...modals, modal];
      });

      return {
        remove: (e?: ModalSubscriptionOpt) => {
          const { preventGoBack = false } = e || {};
          setModals((modals) => {
            const res = modals.filter((m) => m !== modal);
            return res;
          });
          if (!preventGoBack) {
            historyRef.current?.goBack();
          }
        },
      };
    },
    [],
  );
  useEffect(() => {
    if (MyModal) {
      history.push({
        pathname: "/modal",
        state: {
          background: history.location,
        },
      });

      const unlisten = history.listen((location) => {
        logD(TAG, "location: %o", location);
        setModals((modals) => {
          logD(TAG, "Remove modal");
          const res = modals.filter((m) => m !== MyModal);
          return res;
        });
      });
      return () => {
        unlisten();
      };
    }
    return () => {
      //NOP
    };
  }, [MyModal, history]);

  return (
    <ModalContext.Provider
      value={{
        modals,
        push,
        Modal: MyModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export function withModalContext<TOriginalProps extends ModalContextInjectedProps>(
  Component: React.ComponentType<ModalContextInjectedProps>,
) {
  const Wrapped = (props: TOriginalProps) => {
    const { push } = useContext(ModalContext);
    return <Component {...props} modalPush={push} />;
  };
  return Wrapped;
}

export function useModal() {
  return useContext(ModalContext);
}
