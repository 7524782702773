import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Play } from "../../assets/dist/ic_play.svg";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import styles from "./styles.module.scss";
import clsx from "clsx";

const iconHeight = 1.8;
const padding = 0.8;
export interface PlayIconProps {
  active: boolean;
  className?: string;
  onClick: () => void;
}

const PlayIcon = ({ onClick, active, className }: PlayIconProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const _onClick = React.useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  //FIXME  theme.playButtonStyle?.radiusLg : what to do : css var or custom scss

  return (
    <div
      className={clsx([styles.container, active && styles.active, className])}
      onClick={_onClick}
      data-testid="play"
      style={{
        borderRadius: `${
          theme.playButtonStyle?.radiusLg || padding * 2 + iconHeight
        }rem`,
      }}
    >
      <div className={styles.text}>{t("buttons.play")}</div>
      <Play className={styles.icon} />
    </div>
  );
};

export default PlayIcon;
