import React from "react";
import dimens from "src/theme/dimens";
import { Theme } from "src/theme/Theme";
import styled from "styled-components";
import { ReactComponent as CloseButton } from "../../assets/dist/ic_close.svg";
import { useTheme } from "src/theme/ThemeProvider";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import Chrome from "./NotificationChrome";
import Safari from "./NotificationSafariIpad";
import SafariMobile from "./NotificationSafariMobile";
import ChromeMobile from "./NotificationChromeMobile";
import { AppLayout, StorageKey } from "@blacknut/react-client-core/lib";
import { useOrientation } from "src/utils/OrientationContext";
import electronService from "../../services/ElectronService";
import { is_iOS } from "@blacknut/javascript-sdk/dist";

const Container = styled.div<{ theme: Theme; right: number; maxWidth: number }>`
  position: fixed;
  top: calc(${dimens.phone.headerHeight}rem + ${dimens.margins.DarkRed}rem);
  right: ${({ right }) => right}rem;
  background: ${({ theme }) => theme.notificationPWA.backgroundColor};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: ${dimens.margins.Green}rem;
  padding-top: ${dimens.margins.LightGreen}rem;
  z-index: 100;
  min-width: 35rem;
  max-width: ${({ maxWidth }) => maxWidth}rem;
  height: min-content;
  margin: 0 !important;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: 0 0 1.2rem var(--card-active-background);
    }
  }

  @media screen and (min-width: ${dimens.breakpoints
      .desktop}px) and (position: landscape) {
    top: calc(${dimens.desktop.headerHeight}rem + ${dimens.margins.DarkRed}rem);
  }
`;
const ContainerMobile = styled.div<{ theme: Theme }>`
  margin: 0 !important;
  position: fixed;
  right: 0;
  left: 0;
  padding: 1.4rem;
  padding-top: ${dimens.margins.LightGreen}rem;
  padding-top: 0;
  border-radius: 12px 12px 0 0;
  background: ${({ theme }) => theme.notificationPWA.backgroundColor};
  display: flex;
  flex-direction: column;
  z-index: 90;
  min-width: 100vw;
  height: min-content;
  box-shadow: 0 10rem ${({ theme }) => theme.bottomBarStyle.backgroundColor};
  bottom: calc((${dimens.phone.navigationBarHeight}rem) + env(safe-area-inset-bottom));

  @media (orientation: landscape) {
    bottom: env(safe-area-inset-bottom);
  }
`;
const ContainerCloseButton = styled(Focusable)<{ theme: Theme }>`
  margin-left: auto;
  z-index: 20;
  width: 1.6rem;
  cursor: pointer;
  height: 1.6rem;
  * > svg {
    fill: ${(props) => props.theme.notificationInstall.textColor};
  }
  position: absolute;
  top: ${dimens.margins.LightGreen}rem;
  right: 1.8rem;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    top: 1.2rem;
    right: 1.2rem;
  }
`;
const CloseButtonStyled = styled(CloseButton)<{ theme: Theme }>`
  fill: ${(props) => props.theme.notificationInstall.textColor};
  transform: scale(1.6);

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    transform: scale(1.8);
  }
`;

const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
`;
const ChromeStyle = styled(Chrome)`
  flex: 2 1 0;
`;
const ChromeMobileStyle = styled(ChromeMobile)`
  flex: 2 1 0;
`;
const SafariStyle = styled(Safari)`
  flex: 2 1 0;
`;
const DragUp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin-bottom: 1rem;
`;

const NotificationInstall = (deviceInfos: any) => {
  const { theme } = useTheme();
  const { orientation } = useOrientation();
  const browserName = deviceInfos.deviceInfos.browserName;
  const deviceType = deviceInfos.deviceInfos.deviceType;
  const os = deviceInfos.deviceInfos.userAgent;
  const model = deviceInfos.deviceInfos.deviceModel;

  const setNotificationInstall = () => {
    localStorage.setItem(StorageKey.INSTALL_NOTIF, "false");
    window.dispatchEvent(new Event("storage"));
  };

  if (deviceType === "unknown" || electronService.isAvailable()) {
    return null;
  } else if (
    (browserName == "chrome" && os != "android") ||
    (browserName == "chrome" &&
      orientation === "LANDSCAPE" &&
      deviceType === AppLayout.TABLET)
  ) {
    return (
      <Container theme={theme} right={3.5} maxWidth={45}>
        <ContainerCloseButton onClick={setNotificationInstall} theme={theme}>
          <CloseButtonStyled theme={theme} />
        </ContainerCloseButton>
        <ContainerInfo>
          {orientation === "LANDSCAPE" && deviceType === AppLayout.TABLET ? (
            <ChromeMobileStyle />
          ) : (
            <ChromeStyle />
          )}
        </ContainerInfo>
      </Container>
    );
  } else if (browserName == "chrome" && os === "android") {
    return (
      <ContainerMobile theme={theme}>
        <ContainerCloseButton onClick={setNotificationInstall} theme={theme}>
          <CloseButtonStyled theme={theme} />
        </ContainerCloseButton>
        <DragUp />
        <ContainerInfo>
          <ChromeMobileStyle />
        </ContainerInfo>
      </ContainerMobile>
    );
  } else if (
    (browserName == "mobile-safari" && model === "ipad") ||
    (browserName == "safari" && is_iOS())
  ) {
    return (
      <Container theme={theme} right={1} maxWidth={35}>
        <ContainerCloseButton onClick={setNotificationInstall} theme={theme}>
          <CloseButtonStyled theme={theme} />
        </ContainerCloseButton>
        <ContainerInfo>
          <SafariStyle />
        </ContainerInfo>
      </Container>
    );
  } else if (browserName == "mobile-safari" && os === "ios") {
    return (
      <ContainerMobile theme={theme}>
        <ContainerCloseButton onClick={setNotificationInstall} theme={theme}>
          <CloseButtonStyled theme={theme} />
        </ContainerCloseButton>
        <DragUp />
        <ContainerInfo>
          <SafariMobile />
        </ContainerInfo>
      </ContainerMobile>
    );
  } else {
    return null;
  }
};
export default NotificationInstall;
