const getEnv = (env?: string) => {
  if (env?.match(/dev/)) {
    return "dev";
  } else if (env?.match(/staging/)) {
    return "staging";
  } else if (env?.match(/integration/)) {
    return "integration";
  }
  return "prod";
};
export default getEnv;
export { getEnv };
