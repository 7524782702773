import * as React from "react";
import Transition from "react-transition-group/Transition";
import { CSSProperties } from "styled-components";

interface FadeAndSlideTransitionProps {
    duration: number;
    direction: "down_in" | "right_in" | "left_in" | "up_in";
    directionOut?: "right_out" | "left_out";

    inProp: boolean;
}

// Styles that will be applied to children as the status
// of the transition changes. Each key of the
// 'transitionStyles' object matches the name of a
// 'status' provided by <Transition />.
class FadeAndSlideTransition extends React.PureComponent<FadeAndSlideTransitionProps> {
    private defaultStyle: CSSProperties;
    private transitionStyles: {
        entered?: CSSProperties;
        entering?: CSSProperties;
        exiting?: CSSProperties;
    };

    constructor(props: FadeAndSlideTransitionProps) {
        super(props);
        // Styles to set on children which are necessary in order
        // for the animation to work.
        this.defaultStyle = {
            // Transition "opacity" and "transform" CSS properties.
            // Set duration of the transition to the duration of the animation.
            transition: `${props.duration}ms ease-in`,
            transitionProperty: "opacity, transform",
        };

        switch (props.direction) {
            case "down_in": {
                this.transitionStyles = {
                    // Transition to component being visible and having its position reset.
                    entered: {
                        opacity: 1,
                        transform: "translateY(0)",
                    },

                    // Start with component invisible and shifted up by 10%
                    entering: {
                        opacity: 0,
                        transform: "translateY(-100%)",
                    },

                    // Fade element out and slide it back up on exit.
                    exiting: {
                        opacity: 0,
                        transform: "translateY(-100%)",
                    },
                };
                break;
            }
            case "up_in": {
                this.transitionStyles = {
                    // Transition to component being visible and having its position reset.
                    entered: {
                        opacity: 1,
                        transform: "translateY(0)",
                    },

                    // Start with component invisible and shifted up by 10%
                    entering: {
                        opacity: 0,
                        transform: "translateY(100%)",
                    },

                    // Fade element out and slide it back up on exit.
                    exiting: {
                        opacity: 0,
                        transform: "translateY(100%)",
                    },
                };
                break;
            }
            case "left_in": {
                const end = props.directionOut === "left_out" ? -100 : 100;
                this.transitionStyles = {
                    // Transition to component being visible and having its position reset.
                    entered: {
                        opacity: 1,
                        transform: "translateX(0)",
                    },

                    // Start with component invisible and shifted up by 10%
                    entering: {
                        opacity: 0,
                        transform: "translateX(-100%)",
                    },

                    // Fade element out and slide it back up on exit.
                    exiting: {
                        opacity: 0,
                        transform: `translateX(${end}%)`,
                    },
                };
                break;
            }
            case "right_in": {
                const end = props.directionOut === "left_out" ? -100 : 100;

                this.transitionStyles = {
                    // Transition to component being visible and having its position reset.
                    entered: {
                        opacity: 1,
                        transform: "translateX(0)",
                    },

                    // Start with component invisible and shifted up by 10%
                    entering: {
                        opacity: 0,
                        transform: "translateX(100%)",
                    },

                    // Fade element out and slide it back up on exit.
                    exiting: {
                        opacity: 0,
                        transform: `translateX(${end}%)`,
                    },
                };
                break;
            }
        }
    }

    public render() {
        const { inProp, duration } = this.props;
        return (
            <Transition
                in={inProp}
                timeout={{
                    // Set 'enter' timeout to '0' so that enter animation
                    // will start immediately.
                    enter: 0,

                    // Set 'exit' timeout to 'duration' so that the 'exited'
                    // status won't be applied until animation completes.
                    exit: duration,
                }}
            >
                {
                    // Children is a function that receives the current
                    // status of the animation.
                    (status: string) => {
                        // Don't render anything if component has 'exited'.
                        if (status === "exited") {
                            return null;
                        }

                        // Apply different styles to children based
                        // on the current value of 'status'.
                        const currentStyles = this.transitionStyles[status];
                        const style = Object.assign({}, this.defaultStyle, currentStyles);
                        return React.Children.map(this.props.children, (child) => {
                            const res = React.cloneElement(child as React.ReactElement<{ style: CSSProperties }>, {
                                style,
                            });
                            return res;
                        });
                    }
                }
            </Transition>
        );
    }
}
// <FadeAndSlideTransition /> is a component that wraps children in
// a <Transition /> component.
// 'children' is the element to be animated.
// 'duration' is the duration of the animation in milliseconds.
// The `in` prop will be provided by <TransitionGroup />.
export default FadeAndSlideTransition;
