import * as React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../theme/ThemeProvider";
import { Theme } from "../../theme/Theme";
export interface CircularProgressProps {
  size?: number;
  thickness?: number;
  color?: "primary" | "inherit";
  className?: string;
  testID?: string;
}
const SIZE = 44;

const Container = styled.div`
  display: inline-block;
  line-height: 1; /* Keep the progress centered*/
  animation: circularRotate 1.4s linear infinite;
  color: ${(props: { theme: Theme; color: "primary" | "inherit" }) =>
    props.color === "primary" ? props.theme.rippleColor : props.color};
  @keyframes circularRotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Circle = styled.circle`
  stroke: currentColor;
  animation: circularDash 1.4s ease-in-out infinite;
  /* Some default value that looks fine waiting for the animation to kicks in.*/
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px; /* Add the unit to fix a Edge 16 and below bug.*/

  @keyframes circularDash {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0px;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -120px;
    }
  }
`;

export default class CircularProgress extends React.Component<CircularProgressProps> {
  public render() {
    const {
      size = 20,
      thickness = 3.6,
      color = "primary",
      className,
      testID,
    } = this.props;

    return (
      <ThemeContext.Consumer>
        {({ theme }) => (
          <Container
            theme={theme}
            className={className}
            style={{ width: size, height: size }}
            role="progressbar"
            color={color}
            data-testid={testID}
          >
            <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
              <Circle
                cx={SIZE}
                cy={SIZE}
                r={(SIZE - thickness) / 2}
                fill="none"
                strokeWidth={thickness}
              />
            </svg>
          </Container>
        )}
      </ThemeContext.Consumer>
    );
  }
}
