import { MenuItem } from "@blacknut/react-client-core/lib";
import { getImageByThemeName } from "@blacknut/react-client-core/lib/models/Image";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import styles from "./styles.module.scss";

const ActiveState = css`
  color: ${(props) => props.theme.browseCardItemCardStyle.activeTextColor};
  background-color: ${(props) =>
    props.theme.browseCardItemCardStyle.activeBackgroundColor};
  transform: ${(props) =>
    props.theme.browseCardItemCardStyle.scaleFactor
      ? `scale(${props.theme.browseCardItemCardStyle.scaleFactor})`
      : undefined};
  box-shadow: 0 0 1.2rem
    ${(props) => props.theme.browseCardItemCardStyle.activeBackgroundColor};
  z-index: 1;

  img {
    filter: brightness(0) invert(1);
    mix-blend-mode: color-dodge;
  }
`;
const StandardTile = styled(Focusable)<{
  theme: Theme;
  firstCol: boolean;
  lastCol: boolean;
  spatialNavigationActive: boolean;
}>`
  padding: ${dimens.margins.DarkRed}rem;
  margin-bottom: ${dimens.margins.DarkRed}rem;

  border-radius: ${(props) => props.theme.browseCardItemCardStyle.radius}rem;
  position: relative;
  overflow: hidden;
  background-color: ${(props) =>
    props.theme.browseCardItemCardStyle.inactiveBackgroundColor};
  cursor: pointer;
  transition: transform 0.2s; /* Animation */
  flex: 0 0 calc(50% - ${dimens.margins.Green}rem);
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.browseCardItemCardStyle.inactiveTextColor};

  margin-left: ${(props) => (props.firstCol ? 0 : undefined)};
  margin-right: ${(props) => (props.lastCol ? 0 : undefined)};
  @media (orientation: landscape) {
    padding: ${dimens.margins.Green}rem;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    flex: 0 0 calc(33% - 2 * ${dimens.margins.Green}rem);
  }
  @media (hover: hover) {
    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : ActiveState)}
    }
  }

  &:focus {
    ${(props) => (props.spatialNavigationActive ? ActiveState : "")}
  }
`;

const StandardTileTitle = styled.h3<{ theme: Theme }>`
  flex: 1;
  cursor: pointer;
  font-size: ${(props) => props.theme.browseCardItemCardStyle.fontSize};
  font-weight: ${(props) => props.theme.browseCardItemCardStyle.fontWeight};
  font-family: ${(props) => props.theme.browseCardItemCardStyle.fontFamily};
  margin: 0;
`;

const StandardImage = styled.img`
  width: 4rem;
  height: 4rem;
  margin-right: ${dimens.margins.Green}rem;
  border-radius: ${(props: { theme: Theme }) => props.theme.cardStyle.radius}rem;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: 6rem;
    height: 6rem;
  }
`;
interface StandardTileViewProps {
  index: number;
  nbCols: number;
  item: MenuItem;
}
const StandardTileView = ({ index, item, nbCols }: StandardTileViewProps) => {
  const history = useHistory();
  const { theme } = useTheme();
  const onClick = () => {
    history.push(`/page/${item.uuid}`);
  };
  const image = getImageByThemeName(theme.name, item.imgIcon ?? []);
  const { active: spatialNavigationActive } = useSpatialNavigation();
  return (
    <StandardTile
      spatialNavigationActive={spatialNavigationActive}
      onPressEnter={onClick}
      key={item.uuid}
      theme={theme}
      onClick={onClick}
      firstCol={index % nbCols === 0}
      role="listitem"
      lastCol={(index + nbCols) % nbCols === nbCols - 1}
      className={styles.standardTile}
    >
      {image && <StandardImage theme={theme} src={image.url} />}
      <StandardTileTitle theme={theme}>{item.title}</StandardTileTitle>
    </StandardTile>
  );
};

export default StandardTileView;
