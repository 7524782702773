import { createDarkTheme } from "../default/Dark";

const AisTheme = createDarkTheme({ colorAccent: "#B2D234" });
AisTheme.default.logoSize = { width: 15.7, height: 2.4 };

const defaultRes = AisTheme.default || {};
const primaryButton = defaultRes.primaryButton || {};
primaryButton.activeTextColor = "#2D2D2D";
primaryButton.inactiveTextColor = "#2D2D2D";
defaultRes.primaryButton = primaryButton;

AisTheme.default.bottomBarStyle = {
    ...AisTheme.default.bottomBarStyle,
    activeFocusedTintColor: "#2D2D2D",
};

AisTheme.default = defaultRes;

export default AisTheme;
