import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButton } from "../../components/Button/V2Button";
import Header from "../../components/Header/Header";
import GameTile from "../../components/Tile/Tile";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useReactRouter } from "../../utils/Hooks";
import {
  ImageType,
  List,
  ListDisplayType,
  Game,
  Tile,
  ActiveStream,
  useStreamRecoverPage,
} from "@blacknut/react-client-core/lib";

const arrange = (data: ActiveStream[]) => {
  const res: Map<string, Tile> = new Map();
  data.map((activeStream: ActiveStream) => {
    const tile: Tile = {
      game: {
        id: activeStream.game.id,
        name: activeStream.game.name,
        images: [
          {
            id: "-1",
            uuid: "-1",
            url: activeStream.game.image,
            urlMini: activeStream.game.image,
            type: ImageType.MAIN,
          },
        ],
      } as Game,
    };
    res.set(activeStream.streamId, tile);
  });
  return res;
};

const Title = styled.h1<{ theme: Theme }>`
  color: ${(props) => props.theme.titleTextStyle.color};
  font-size: ${(props) => props.theme.titleTextStyle.size}rem;
  margin-bottom: ${dimens.margins.Yellow}rem;
  font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
  font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
  text-align: center;
`;

const Page = styled.div<{ theme: Theme }>`
  background-color: ${(props) => props.theme.backgroundColor};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{ theme: Theme }>`
  flex: 1;
  background-color: ${(props) => props.theme.backgroundColor};

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const CancelButton = styled(PrimaryButton)`
  margin-top: ${dimens.margins.Yellow}rem;
  min-width: 32rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  flex-wrap: wrap;
  min-height: min-content;
`;

const DummyList: List = {
  uuid: "-1",
  display: ListDisplayType.FULL,
  tiles: [],
  total: 0,
  title: "",
  isPaginated: false,
};
const StreamRecoverItem = (props: { item: Tile; onClick: () => void }) => {
  const { item, onClick } = props;
  return (
    <GameTile
      onClick={onClick}
      onPlayClicked={onClick}
      featured={false}
      item={item}
      list={DummyList}
    />
  );
};

const StreamsRecoverPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { streams, cancelAllStreams, recoverStream } = useStreamRecoverPage(
    useReactRouter(),
  );
  const data = React.useMemo(() => arrange(streams), [streams]);
  const history = useHistory();
  const renderStream = React.useCallback(
    (stream: ActiveStream) => {
      const onClick = () => {
        recoverStream(stream);
      };
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return (
        <StreamRecoverItem
          item={data.get(stream.streamId)!}
          onClick={onClick}
          key={stream.streamId}
        />
      );
    },
    [data, recoverStream],
  );
  React.useEffect(() => {
    if (streams.length === 0) {
      history.push("/");
    }
  }, [streams, history]);
  return (
    <Page>
      <Header />

      <Container theme={theme}>
        <Title theme={theme}>{t("recover.title")}</Title>

        <Content>{streams.map((s) => renderStream(s))}</Content>

        <CancelButton theme={theme} onClick={cancelAllStreams}>
          {t("buttons.cancel")}
        </CancelButton>
      </Container>
    </Page>
  );
};
export default StreamsRecoverPage;
