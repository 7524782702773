import {
  GameController,
  GameGenre,
  GameStatus,
  PEGIRating,
  ProfileType,
  SearchFormData,
  State,
  SessionDuration,
  AppLayout,
  apiService,
} from "@blacknut/react-client-core/lib";
import React, { UIEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Button, StyledFocusable, ToggleButton } from "../../components/Button/V2Button";
import Checkbox from "../../components/Inputs/Checkbox/Checkbox";
import TextInput from "../../components/Inputs/TextInput/V2TextInput";
import dimens from "../../theme/dimens";
import { Theme, ToggleButtonStyle } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import {
  FocusableSection,
  getSectionIdFromPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { ReactComponent as Search } from "src/assets/dist/ic_search.svg";

interface SearchCriteriaProps {
  genres?: GameGenre[];
  data: SearchFormData;
  onSubmit: () => void;
  onChange: (data: SearchFormData, submit: boolean) => void;
}

const CheckboxButtonActiveState = css<{ buttonStyle: Partial<ToggleButtonStyle> }>`
  label {
    color: ${(props) => props.buttonStyle.activeTextColor};
  }
  box-shadow: 0 0 1.2rem ${(props) => props.buttonStyle.activeTintColor};
  button {
    color: ${(props) => props.buttonStyle.activeTextColor};
    background-color: ${(props) => props.buttonStyle.activeTintColor};
  }
`;
const CheckboxButtonSelectedState = css<{ buttonStyle: Partial<ToggleButtonStyle> }>`
  .svg_check_border {
    color: white;
  }
  .svg_check {
    stroke: ${(props) => props.buttonStyle.activeTintColor};
  }
`;

const CheckboxButton = styled(Button)<{
  buttonStyle: Partial<ToggleButtonStyle>;
  spatialNavigationActive: boolean;
}>`
  border-radius: ${(props) => props.buttonStyle.borderRadius}rem;
  display: flex;
  label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  button {
    border-radius: ${(props) => props.buttonStyle.borderRadius}rem;
    color: ${(props) => props.buttonStyle.textColor};
    font-weight: ${(props) => props.buttonStyle.fontWeight};
    font-family: ${(props) => props.buttonStyle.fontFamily};
    padding: ${dimens.margins.DarkRed}rem ${dimens.margins.Green}rem;
    background-color: ${(props) => props.buttonStyle.tintColor};
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    @media (min-width: ${dimens.breakpoints.desktop}px) {
      min-width: 8.8rem;
      background-color: ${(props) => props.theme.backgroundColor};
    }
  }
  @media (min-width: ${dimens.breakpoints.tablet}px) {
    &:hover {
      ${CheckboxButtonActiveState}
      ${CheckboxButtonSelectedState}
    }
  }
  &:focus {
    ${(props) => (props.spatialNavigationActive ? CheckboxButtonActiveState : "")}
    ${(props) => (props.spatialNavigationActive ? CheckboxButtonSelectedState : "")}
  }
`;

const CheckboxWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-width: 0; /*https://css-tricks.com/flexbox-truncated-text/*/
  & > div:last-child {
    flex: ${(props: { label?: string }) => (props.label ? "1" : "0")};
    margin-left: ${(props: { label?: string }) =>
      props.label ? ` ${dimens.margins.DarkRed}rem` : "auto"};
  }
`;
const CheckboxIconWrapFocus = css`
  svg:first-child {
    display: none;
  }
  svg:last-child {
    display: block;
  }
`;
const CheckboxIconWrap = styled.div<{ spatialNavigationActive: boolean }>`
  svg {
    width: 3.2rem;
    height: 3.2rem;
    display: block;
  }
  svg:last-child {
    display: none;
  }
  @media (min-width: ${dimens.breakpoints.tablet}px) {
    ${StyledFocusable}:hover & {
      ${CheckboxIconWrapFocus}
    }
  }
  ${StyledFocusable}:focus & {
    ${(props) => (props.spatialNavigationActive ? CheckboxIconWrapFocus : "")}
  }
`;

const GenresContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .searchToggleButton {
    margin-right: ${dimens.margins.DarkRed}rem;
    margin-top: ${dimens.margins.DarkRed}rem;

    &:last-child {
      margin-right: 0;
    }
  }
  .moreLessBtn {
    margin-top: ${dimens.margins.DarkRed}rem;
  }
`;

const Section = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${dimens.margins.Green}rem;
  padding-bottom: ${dimens.margins.Green}rem;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${(props: { theme: Theme }) => props.theme.separatorColor};
  }
`;

const MiscSection = styled(Section)`
  & > * {
    margin-bottom: ${dimens.margins.LightGreen / 4}rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PegiRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props: { last?: boolean }) =>
    props.last ? 0 : `${dimens.margins.DarkRed}rem`};
  & > * {
    flex: 1 0 0;
    margin: 0 ${dimens.margins.DarkRed}rem;
    display: flex;
    & > div {
      flex: 1;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const DurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${dimens.margins.LightGreen / 4}rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledNameInput = styled(TextInput)<{ theme: Theme }>`
  margin-bottom: ${dimens.margins.DarkRed}rem;
  min-width: 0;
`;

const MyCheckboxButton = (props: {
  label?: string;
  checked: boolean;
  onClick: () => void;
  Icon?: React.FC;
  ActiveIcon?: React.FC;
  theme: Theme;
  testID?: string;
}) => {
  const { label, checked, onClick, ActiveIcon, Icon, theme, testID } = props;
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const checkboxClick = (e: UIEvent<unknown>) => {
    onClick();
    e.stopPropagation();
  };
  return (
    <CheckboxButton
      buttonStyle={theme.search.toggleButtonStyle || theme.toggleButton}
      onClick={onClick}
      testID={testID}
      spatialNavigationActive={spatialNavigationActive}
    >
      <CheckboxWrap label={label}>
        <CheckboxIconWrap spatialNavigationActive={spatialNavigationActive}>
          {Icon && <Icon />}
          {ActiveIcon && <ActiveIcon />}
        </CheckboxIconWrap>
        <Checkbox
          id={label || "n/a"}
          label={label}
          checked={checked}
          onCheckChange={checkboxClick}
          labelAlignment="start"
          focusDisabled
        />
      </CheckboxWrap>
    </CheckboxButton>
  );
};

const SearchCriteria = ({ genres, data, onSubmit, onChange }: SearchCriteriaProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { user, config, layout } = useSelector((state: State) => state.globalState);
  const { profile } = useSelector((state: State) => state.profilesState);
  const {
    genres: selectedGenres,
    multiplayer,
    sessionDurations,
    name,
    age,
    controllers,
  } = data;
  const containerRef = useRef<HTMLDivElement>(null);
  const [filteredGenres, setFilteredGenres] = useState<GameGenre[] | undefined>(genres);
  const [indexToHideGenres, setIndexToHideGenres] = useState<number | undefined>(
    undefined,
  );
  const searchLiveCheckboxId = `@${getSectionIdFromPath(`/search/searchLiveCheckbox`)}`;
  const searchInputSectionId = `@${getSectionIdFromPath(`/search/searchInputSection`)}`;
  const tagsSectionId = `@${getSectionIdFromPath(`/search/tagsSection`)}`;
  const gameTypeSectionId = `@${getSectionIdFromPath(`/search/gameTypeSection`)}`;
  const durationSectionId = `@${getSectionIdFromPath(`/search/durationSection`)}`;
  const pegiSectionId = `@${getSectionIdFromPath(`/search/pegiSection`)}`;

  const _onFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  useEffect(() => {
    if (genres?.length && !filteredGenres?.length) setFilteredGenres(genres);
  }, [filteredGenres?.length, genres]);

  const filterGenresWithIndex = useCallback(
    (index: number) => {
      if (genres) setFilteredGenres(genres.filter((_, i) => i < index));
    },
    [genres],
  );

  useEffect(() => {
    if (indexToHideGenres || !filteredGenres?.length) return;

    const $container = containerRef.current;
    if (!$container) return;

    const containerWidth = $container.offsetWidth;
    const $btns = $container.querySelectorAll<HTMLElement>(".searchToggleButton");

    const linesWanted = 4;
    let currentLine = 0;
    let currentLineWidth = 0;

    $btns.forEach(($btn, i) => {
      if (currentLine >= linesWanted) return;
      const btnWidth = $btn.offsetWidth + dimens.margins.DarkRed * 10;
      const newCurrentLineWidth = currentLineWidth + btnWidth;

      if (currentLine + 1 === linesWanted) {
        setIndexToHideGenres(i - 1);
        filterGenresWithIndex(i - 1);
      }

      if (newCurrentLineWidth > containerWidth) {
        currentLineWidth = 0;
        currentLine++;
      }

      currentLineWidth += btnWidth;

      if (i === $btns.length - 1 && currentLine <= linesWanted) {
        setIndexToHideGenres(i + 1);
        filterGenresWithIndex(i + 1);
      }
    });
  }, [filterGenresWithIndex, filteredGenres?.length, indexToHideGenres]);

  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ ...data, name: e.target.value }, false);
    },
    [data, onChange],
  );

  const onLiveToggle = useCallback(() => {
    if (data.statuses) {
      onChange({ ...data, statuses: undefined }, true);
    } else {
      onChange({ ...data, statuses: [GameStatus.LIVE] }, true);
    }
  }, [data, onChange]);

  const toggleGenre = useCallback(
    (g: GameGenre, data: SearchFormData) => {
      let idx;
      if (data.genres && (idx = data.genres.findIndex((g2) => g2.id === g.id)) !== -1) {
        const newGenres = data.genres.slice(0, idx).concat(data.genres.slice(idx + 1));
        onChange({ ...data, genres: newGenres }, true);
      } else {
        const newGenres = [...(data.genres || [])];
        newGenres.push(g);
        onChange({ ...data, genres: newGenres }, true);
      }
    },
    [onChange],
  );

  const toggleMultiplayer = useCallback(() => {
    onChange({ ...data, multiplayer: !multiplayer }, true);
  }, [data, multiplayer, onChange]);

  const toggleController = (controller: GameController) => {
    let newControllers = controllers ? [...controllers] : [];
    if (!!newControllers.includes(controller)) {
      newControllers = newControllers.filter((c) => c !== controller);
    } else {
      newControllers.push(controller);
    }

    onChange({ ...data, controllers: [...newControllers] }, true);
  };

  const togglePegi = useCallback(
    (item: PEGIRating, data: SearchFormData) => {
      const { age } = data;
      let index;
      // tslint:disable-next-line:no-conditional-assignment
      if (!age || (index = age.indexOf(item)) === -1) {
        const newAge = !age ? [] : age.splice(0);
        newAge.push(item);
        onChange({ ...data, age: newAge }, true);
      } else {
        const newAge = age.slice(0, index).concat(age.slice(index + 1));
        onChange({ ...data, age: newAge }, true);
      }
    },
    [onChange],
  );

  const onClickPegi3 = useCallback(() => {
    togglePegi(PEGIRating.PEGI_3, data);
  }, [togglePegi, data]);
  const onClickPegi7 = useCallback(() => {
    togglePegi(PEGIRating.PEGI_7, data);
  }, [togglePegi, data]);
  const onClickPegi12 = useCallback(() => {
    togglePegi(PEGIRating.PEGI_12, data);
  }, [togglePegi, data]);
  const onClickPegi16 = useCallback(() => {
    togglePegi(PEGIRating.PEGI_16, data);
  }, [togglePegi, data]);
  const onClickPegi18 = useCallback(() => {
    togglePegi(PEGIRating.PEGI_18, data);
  }, [togglePegi, data]);
  const onClickPegiAll = useCallback(() => {
    const { age } = data;
    if (age && age.length === 5) {
      onChange({ ...data, age: [] }, true);
    } else {
      onChange(
        {
          ...data,
          age: [
            PEGIRating.PEGI_3,
            PEGIRating.PEGI_7,
            PEGIRating.PEGI_12,
            PEGIRating.PEGI_16,
            PEGIRating.PEGI_18,
          ],
        },
        true,
      );
    }
  }, [data, onChange]);

  const toggleDuration = useCallback(
    (item: SessionDuration, data: SearchFormData) => {
      const { sessionDurations } = data;
      // tslint:disable-next-line:no-conditional-assignment
      if (!sessionDurations || sessionDurations.indexOf(item) === -1) {
        onChange(
          {
            ...data,
            sessionDurations: [item],
          },
          true,
        );
      } else {
        onChange(
          {
            ...data,
            sessionDurations: [],
          },
          true,
        );
      }
    },
    [onChange],
  );
  const onClickTimerShort = useCallback(() => {
    toggleDuration(SessionDuration.LESS_10, data);
  }, [toggleDuration, data]);
  const onClickTimerMedium = useCallback(() => {
    toggleDuration(SessionDuration.BETWEEN_10_30, data);
  }, [toggleDuration, data]);
  const onClickTimerLong = useCallback(() => {
    toggleDuration(SessionDuration.MORE_30, data);
  }, [toggleDuration, data]);

  return (
    <form onSubmit={_onFormSubmit} role="form">
      {((user && user.powerUser) || config?.features?.search) && (
        <>
          <Section
            focusKey="searchInputSection"
            leaveFor={{
              down: user && user.powerUser ? searchLiveCheckboxId : tagsSectionId,
            }}
            theme={theme}
            config={{ restrict: "self-first" }}
          >
            <StyledNameInput
              onChange={onChangeName}
              value={name}
              label={t("search.game-title")}
              placeholder={t("search.game-title")}
              theme={theme}
              testID="name"
              id="name"
              textInputStyle={theme.search.textInputStyle}
              icon={<Search />}
            />
          </Section>
          <Section
            focusKey="searchLiveCheckbox"
            leaveFor={{ up: searchInputSectionId, down: tagsSectionId }}
            theme={theme}
            config={{ restrict: "self-first" }}
          >
            {user && user.powerUser && (
              <Checkbox
                checked={!!data.statuses}
                label="Only live"
                onCheckChange={onLiveToggle}
                id="live"
                testID="live"
              />
            )}
          </Section>
        </>
      )}

      {genres && filteredGenres && (
        <Section
          focusKey="tagsSection"
          leaveFor={{
            up: user && user.powerUser ? searchLiveCheckboxId : searchInputSectionId,
            down: gameTypeSectionId,
          }}
          theme={theme}
          config={{ restrict: "self-first" }}
        >
          {filteredGenres.length > 0 && (
            <GenresContainer ref={containerRef}>
              {filteredGenres.map((e) => (
                <ToggleButton
                  className="searchToggleButton"
                  style={{
                    opacity: !indexToHideGenres ? 0 : 1,
                  }}
                  key={e.id}
                  checked={
                    !!selectedGenres && !!selectedGenres.find((g) => g.id === e.id)
                  }
                  theme={theme}
                  onClick={() => toggleGenre(e, data)}
                  buttonStyle={theme.search.toggleButtonStyle || theme.toggleButton}
                >
                  {e.title}
                </ToggleButton>
              ))}

              {indexToHideGenres && indexToHideGenres !== genres.length && (
                <ToggleButton
                  checked={true}
                  theme={theme}
                  className="moreLessBtn"
                  style={{
                    opacity: !indexToHideGenres ? 0 : 1,
                  }}
                  onClick={() =>
                    filterGenresWithIndex(
                      filteredGenres.length < genres.length
                        ? genres.length
                        : indexToHideGenres,
                    )
                  }
                  buttonStyle={theme.search.toggleButtonStyle || theme.toggleButton}
                  outlined={true}
                >
                  {filteredGenres.length < genres.length
                    ? t("search.more")
                    : t("search.less")}
                  ...
                </ToggleButton>
              )}
            </GenresContainer>
          )}
        </Section>
      )}

      <div>
        <MiscSection
          focusKey="gameTypeSection"
          leaveFor={{ up: tagsSectionId, down: durationSectionId }}
          theme={theme}
          config={{ restrict: "self-first" }}
        >
          <MyCheckboxButton
            label={t("search.multiplayer")}
            checked={!!multiplayer}
            onClick={toggleMultiplayer}
            Icon={theme.images["multiplayer"]}
            ActiveIcon={theme.images["multiplayer_active"]}
            theme={theme}
          />

          <MyCheckboxButton
            label={t("search.gamepad")}
            checked={!!controllers?.includes(GameController.GAMEPAD)}
            onClick={() => toggleController(GameController.GAMEPAD)}
            Icon={theme.images["gamepad"]}
            ActiveIcon={theme.images["gamepad_active"]}
            theme={theme}
          />

          {/* TABLET + COMPUTER */}
          {(layout === AppLayout.TABLET || layout === AppLayout.DESKTOP) && (
            <MyCheckboxButton
              label={t("search.keyboard-mouse")}
              checked={!!controllers?.includes(GameController.KEYBOARD_MOUSE)}
              onClick={() => toggleController(GameController.KEYBOARD_MOUSE)}
              Icon={theme.images["keyboard_mouse"]}
              ActiveIcon={theme.images["keyboard_mouse_active"]}
              theme={theme}
            />
          )}

          {/* TABLET + PHONE */}
          {(layout === AppLayout.TABLET || layout === AppLayout.PHONE) && (
            <MyCheckboxButton
              label={t("search.touch")}
              checked={!!controllers?.includes(GameController.TOUCH_SCREEN)}
              onClick={() => toggleController(GameController.TOUCH_SCREEN)}
              Icon={theme.images["touch"]}
              ActiveIcon={theme.images["touch_active"]}
              theme={theme}
            />
          )}

          {/* TV */}
          {layout === AppLayout.TV &&
            apiService.userAgent.brand?.toLocaleLowerCase() !== "amazon" && (
              <MyCheckboxButton
                label={t("search.tv-remote")}
                checked={!!controllers?.includes(GameController.REMOTE_CONTROL)}
                onClick={() => toggleController(GameController.REMOTE_CONTROL)}
                Icon={theme.images["tv_remote"]}
                ActiveIcon={theme.images["tv_remote_active"]}
                theme={theme}
              />
            )}

          {/* FIRE TV */}
          {layout === AppLayout.TV &&
            apiService.userAgent.brand?.toLocaleLowerCase() === "amazon" && (
              <MyCheckboxButton
                label={t("search.tv-remote")}
                checked={!!controllers?.includes(GameController.REMOTE_CONTROL_AMAZON)}
                onClick={() => toggleController(GameController.REMOTE_CONTROL_AMAZON)}
                Icon={theme.images["tv_remote"]}
                ActiveIcon={theme.images["tv_remote_active"]}
                theme={theme}
              />
            )}
        </MiscSection>

        <Section
          focusKey="durationSection"
          leaveFor={{ up: gameTypeSectionId, down: pegiSectionId }}
          theme={theme}
          config={{ restrict: "self-first" }}
        >
          <DurationContainer>
            <MyCheckboxButton
              checked={
                !!sessionDurations &&
                sessionDurations.indexOf(SessionDuration.LESS_10) !== -1
              }
              onClick={onClickTimerShort}
              Icon={theme.images.TimerShort}
              ActiveIcon={theme.images.TimerShortActive}
              testID="timerShort"
              theme={theme}
              label={t("search.10-minutes")}
            />

            <MyCheckboxButton
              checked={
                !!sessionDurations &&
                sessionDurations.indexOf(SessionDuration.BETWEEN_10_30) !== -1
              }
              onClick={onClickTimerMedium}
              Icon={theme.images.TimerMedium}
              ActiveIcon={theme.images.TimerMediumActive}
              testID="timerMedium"
              theme={theme}
              label={t("search.10-30-minutes")}
            />

            <MyCheckboxButton
              checked={
                !!sessionDurations &&
                sessionDurations.indexOf(SessionDuration.MORE_30) !== -1
              }
              onClick={onClickTimerLong}
              Icon={theme.images.TimerLong}
              ActiveIcon={theme.images.TimerLongActive}
              testID="timerLong"
              theme={theme}
              label={t("search.30-minutes")}
            />
          </DurationContainer>
        </Section>

        {profile && profile.userProfile === ProfileType.GENERIC && (
          <Section
            focusKey="pegiSection"
            leaveFor={{ up: durationSectionId }}
            theme={theme}
            config={{ restrict: "self-first" }}
          >
            <PegiRow>
              <MyCheckboxButton
                checked={!!age && age.indexOf(PEGIRating.PEGI_3) !== -1}
                onClick={onClickPegi3}
                Icon={theme.images.PEGI_3}
                ActiveIcon={theme.images.PEGI_3_Active}
                testID="pegi3"
                theme={theme}
              />
              <MyCheckboxButton
                checked={!!age && age.indexOf(PEGIRating.PEGI_7) !== -1}
                onClick={onClickPegi7}
                Icon={theme.images.PEGI_7}
                ActiveIcon={theme.images.PEGI_7_Active}
                testID="pegi7"
                theme={theme}
              />
            </PegiRow>
            <PegiRow>
              <MyCheckboxButton
                checked={!!age && age.indexOf(PEGIRating.PEGI_12) !== -1}
                onClick={onClickPegi12}
                Icon={theme.images.PEGI_12}
                ActiveIcon={theme.images.PEGI_12_Active}
                testID="pegi12"
                theme={theme}
              />

              <MyCheckboxButton
                checked={!!age && age.indexOf(PEGIRating.PEGI_16) !== -1}
                onClick={onClickPegi16}
                Icon={theme.images.PEGI_16}
                ActiveIcon={theme.images.PEGI_16_Active}
                testID="pegi16"
                theme={theme}
              />
            </PegiRow>

            <PegiRow last={true}>
              <MyCheckboxButton
                checked={!!age && age.indexOf(PEGIRating.PEGI_18) !== -1}
                onClick={onClickPegi18}
                Icon={theme.images.PEGI_18}
                ActiveIcon={theme.images.PEGI_18_Active}
                testID="pegi18"
                theme={theme}
              />
              <MyCheckboxButton
                label={t("buttons.all")}
                checked={!!age && age.length === 5}
                onClick={onClickPegiAll}
                testID="pegiAll"
                theme={theme}
              />
            </PegiRow>
          </Section>
        )}
      </div>
    </form>
  );
};

export default SearchCriteria;
