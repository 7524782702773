import { logout, State } from "@blacknut/react-client-core/lib";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PrimaryButton, SecondaryButton } from "../components/Button/V2Button";
import MessageModal from "../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../components/Modals/ModalContext";

const useAnonymousCheck = () => {
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: State) => state.globalState);
  const history = useHistory();
  const onConfirmed = useCallback(() => {
    modalSubscription.current?.remove();
    logout()(dispatch);
    history.push("/welcome");
  }, [history, dispatch]);
  return {
    checkForAnonymous: useCallback(() => {
      if (user?.anonymous) {
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            title={t("dialogs.anonymous.m1.title")}
            onClose={() => {
              modalSubscription.current?.remove();
            }}
            testID="anon"
            message={t("dialogs.anonymous.m1.message")}
            buttons={[
              <SecondaryButton
                key="cancel"
                onClick={() => {
                  modalSubscription.current?.remove();
                }}
                testID="cancel"
              >
                {t("buttons.cancel")}
              </SecondaryButton>,
              <PrimaryButton key="ok" onClick={onConfirmed} testID="confirm">
                {t("buttons.register")}
              </PrimaryButton>,
            ]}
          />
        ));
        return false;
      }
      return true;
    }, [modalPush, onConfirmed, t, user?.anonymous]),
  };
};

export default useAnonymousCheck;
