export type FontWeight =
  | "normal"
  | "bold"
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

export interface NotificationInstall {
  textColor: string;
}
export interface NamedTheme {
  name: string;
  default: Omit<Theme, "name">;
  phone?: Partial<Omit<Theme, "name">>;
  tablet?: Partial<Omit<Theme, "name">>;
  tv?: Partial<Omit<Theme, "name">>;
}

export interface TextInputLabelStyle {
  fontFamily: string;
  fontWeight: FontWeight;
  activeFontSize: number;
  inactiveFontSize: number;
  activeColor: string;
  inactiveColor: string;
}

export interface TextInputStyle {
  helperStyle: Partial<TextStyle>;
  labelStyle: Partial<TextInputLabelStyle>;
  errorColor?: string;

  inactiveBackgroundColor?: string;
  inactiveBorderColor?: string;
  inactiveTextColor?: string;

  activeBackgroundColor?: string;
  activeBorderColor?: string;
  activeTextColor?: string;
  borderRadius: number;
}
export interface HeaderStyle {
  backgroundColor: string;
  buttonsTintColor: string;
  elevation: number;
  textStyle: Partial<TextStyle>;
  borderColor?: string;
  borderWidth?: number;
}

export interface ButtonStyle {
  fontFamily: string;
  fontWeight: FontWeight;
  fontSize: number;

  inactiveBackgroundColor?: string;
  inactiveBorderColor?: string;
  inactiveTextColor: string;

  activeBackgroundColor?: string;
  activeBorderColor?: string;
  activeTextColor: string;

  hoverBackgroundColor?: string;
  hoverBorderColor?: string;
  hoverTextColor: string;

  uppercased?: boolean;
  borderRadius?: number;
  borderWidth?: number;

  scaleFactor: number;
}

export interface CheckboxStyle {
  activeTintColor?: string;
  inactiveTintColor?: string;
}

export interface BottomBarStyle {
  backgroundColor: string;
  activeTintColor: string;
  inactiveTintColor: string;
  activeFocusedTintColor: string;
  inactiveFocusedTintColor: string;
  borderColor?: string;
  borderWidth?: number;
  scaleFactor?: number;
}

export interface NavigationBarStyle {
  backgroundColor: string;
  lightContent: boolean;
}

export interface TextStyle {
  fontFamily: string;
  fontWeight: FontWeight;
  size: number;
  color: string;
}

export interface ListStyle {
  backgroundColor: string;
  activeBackgroundColor: string;
  activeTextColor: string;
  textColor: string;
  acessoryViewTintColor: string;
  radius: number;
}

export interface CardStyle {
  inactiveBackgroundColor: string;
  inactiveBorderColor?: string;
  inactiveTextColor: string;
  inactiveTextColorLight?: string;

  activeBackgroundColor?: string;
  activeBorderColor?: string;
  activeTextColor: string;

  radius: number;
  scaleFactor?: number;

  fontFamily?: string;
  fontWeight?: FontWeight;
  fontSize?: number;
}

export interface ModalStyle {
  titleTextStyle: Partial<TextStyle>;

  textStyle: Partial<TextStyle>;

  backgroundColor: string;
  overlayColor: string;
  padding: number;
  buttonsAlignment: "start" | "end" | "center";
}

export interface ToggleButtonStyle {
  selectedTintColor: string;
  tintColor: string;
  activeTintColor: string;
  selectedTextColor: string;
  activeTextColor: string;
  textColor: string;
  borderRadius?: number;
  fontFamily?: string;
  fontWeight?: FontWeight;
}

export interface TabStyle {
  inactiveBackgroundColor?: string;
  inactiveBorderColor?: string;

  activeBackgroundColor?: string;
  activeBorderColor?: string;

  textStyle: Partial<{
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    activeTextColor: string;
    inactiveTextColor: string;
  }>;
}

export interface SwitchStyle {
  activeThumbColor: string;
  inactiveThumbColor: string;
  activeTrackColor: string;
  inactiveTrackColor: string;
}

export interface FullscreenControlsStyle {
  backgroundColor: string;
}

export interface SearchPageStyle {
  backgroundColor?: string;
  toggleButtonStyle?: Partial<ToggleButtonStyle>;
  textInputStyle: Partial<TextInputStyle>;

  searchCriteriaStyle?: {
    backgroundColor?: string;
  };

  iconSearchInput?: {
    size?: number;
  };
}

export interface MyListPageStyle {
  headerStyle: Partial<{
    textStyle: Partial<TextStyle>;
  }>;
}

export interface SpeedTestStyle {
  disclaimer: {
    color: string;
  };
  colors: {
    unknown: string;
    ok: string;
    warn: string;
    bad: string;
  };
}

export interface Theme {
  name: string;
  defaultFontFamily?: string;
  images: {
    [key: string]:
      | React.FC<{ className?: string; style?: any; onClick?: () => void }>
      | undefined;
  };
  logoSize: { width: number; height: number };
  backgroundColor: string;
  inverseBackgroundColor: string;

  spinnerColor: string;
  rippleColor: string;

  headerStyle: Partial<HeaderStyle>;

  bottomBarStyle: Partial<BottomBarStyle>;

  navigationBar: Partial<NavigationBarStyle>;

  separatorColor: string;
  textColor: string;

  textStyle: Partial<TextStyle>;

  textStyle2: Partial<TextStyle>;

  textStyle3: Partial<TextStyle>;

  listStyle: Partial<ListStyle>;
  titleTextStyle: Partial<TextStyle>;

  sectionTextStyle: Partial<TextStyle>;

  primaryButton: Partial<ButtonStyle>;

  secondaryButton: Partial<ButtonStyle>;

  tertiaryButton: Partial<ButtonStyle>;

  iconButton: Partial<ButtonStyle>;

  cardStyle: Partial<CardStyle>;
  accountCardStyle: Partial<CardStyle>;
  profileCardStyle: Partial<CardStyle>;
  tileCardStyle: Partial<CardStyle>;
  profileListItemCardStyle: Partial<CardStyle>;
  browseCardItemCardStyle: Partial<CardStyle>;

  modalStyle: Partial<ModalStyle>;

  toggleButton: Partial<ToggleButtonStyle>;

  textInputStyle: Partial<TextInputStyle>;

  checkboxStyle: CheckboxStyle;

  tabStyle: Partial<TabStyle>;

  switchStyle: Partial<SwitchStyle>;

  fullscreenControls: Partial<FullscreenControlsStyle>;

  search: Partial<SearchPageStyle>;

  accountPage?: Partial<{ backgroundColor?: string }>;
  pinPage?: Partial<{ backgroundColor?: string }>;

  game?: Partial<{ favoriteButtonStyle: Partial<ButtonStyle> }>;

  myList?: Partial<MyListPageStyle>;

  speedTestStyle: Partial<SpeedTestStyle>;

  playButtonStyle?: {
    radiusSm: number;
    radiusLg: number;
  };

  notificationPWA?: {
    textColor?: string;
    backgroundColor?: string;
  };

  labelSecureStyle: {
    color: string;
    backgroundColor: string;
  };

  visibilityInputIconColor: string;
}
//FIXME  move to /utils
export const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const hex2rgb = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return { r, g, b };
};
