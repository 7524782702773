import * as React from "react";
import styled from "styled-components";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import Modal, { ModalProps } from "./Modal";

export interface MessageModalProps extends ModalProps {
  message: string;
  defaultFocus?: string;
}

const Message = styled.p`
  color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
`;

const MessageModal = (props: MessageModalProps) => {
  const { message } = props;
  const { theme } = useTheme();
  return (
    <Modal {...props}>
      <Message theme={theme} dangerouslySetInnerHTML={{ __html: message }} />
    </Modal>
  );
};
export default MessageModal;
