import { State } from "@blacknut/react-client-core/lib";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionSubStatus } from "./SubscriptionKind";

const SubscriptionAis = () => {
  const { theme } = useTheme();
  const { organization } = useSelector((state: State) => state.globalState);
  const { t } = useTranslation();
  return (
    <SubscriptionSubStatus
      theme={theme}
      dangerouslySetInnerHTML={{
        __html: t("account.subscription.drei.text2", {
          url: organization?.subscriptionUrl,
        }),
      }}
    />
  );
};
export default SubscriptionAis;
