import { logD } from "@blacknut/logging/dist";
import { List as ListModel } from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  FocusableSectionProps,
  LeaveFor,
} from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { LOGGING_TAG } from "../../../utils/Utils";
import RandomTile from "../../TileRandom/RandomTile";

interface RandomViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: ListModel;
  leaveFor: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
}

const RandomView = ({ list, leaveFor, onFocus }: RandomViewProps) => {
  const t = list.tiles[0];
  logD(LOGGING_TAG, "Render random");

  return (
    <FocusableSection
      focusKey={list.uuid}
      key={list.uuid}
      leaveFor={leaveFor}
      onFocus={onFocus}
    >
      <RandomTile item={t} list={list} />
    </FocusableSection>
  );
};

export default RandomView;
