import { State } from "@blacknut/react-client-core/lib";
import * as React from "react";
import { useSelector } from "react-redux";
import BlacknutSupportPage from "./BlacknutSupportPage";
import OrganizationSupportPage from "./OrganizationSupportPage";

const SupportPage = () => {
  const { organization } = useSelector((state: State) => state.globalState);
  if (organization && organization.supportUrl) {
    return <OrganizationSupportPage organization={organization} />;
  }
  return <BlacknutSupportPage />;
};
export default SupportPage;
