import ReactModal from "react-modal";
import styled from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import IconButton from "../Button/IconButton";

export const CloseButtonStyle = styled(IconButton)<{ theme: Theme }>`
  svg {
    color: ${(props) => props.theme.primaryButton.activeBackgroundColor};
    height: 4.6rem;
    width: 4.6rem;
  }
`;

export const Container = styled.div<{ theme: Theme }>`
  margin-left: ${dimens.margins.Yellow}rem;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: ${dimens.margins.Green}rem;
  justify-self: self-end;
  margin: ${dimens.margins.Green}rem 0 ${dimens.margins.LightGreen}rem;
`;

export const ContainerLeMagArticleList = styled.div`
  & > * {
    z-index: 2;
  }
`;

export const ContainerDescription = styled.div`
  position: relative;
  z-index: 1;
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: ${dimens.margins.Green}rem ${dimens.margins.Orange}rem;
  padding-left: 0;
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${dimens.margins.Green}rem;
  width: 40%;
`;

export const ContentQRCode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  & svg {
    width: 300px;
    min-height: 200px;
  }
  margin: ${dimens.margins.LightGreen}rem auto;
`;

export const ContainerTop = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Description = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 25vh;
  z-index: 2;
  line-height: 40px;
`;

export const GradientBox = styled.div`
  position: absolute;
  bottom: -10px;
  height: 50%;
  width: 100%;
  z-index: 5;
  background: linear-gradient(
    transparent 0px,
    RGBA(0, 0, 0, 0.6) 85%,
    RGBA(0, 0, 0, 0.9) 95%,
    black 100%
  );
`;

export const Header = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 auto 0 0;
  color: #fff;
`;

export const Separator = styled.div`
  border-top: 1px solid ${(props: { theme: Theme }) => props.theme.separatorColor};
  padding-top: ${dimens.margins.LightGreen}rem;
  opacity: 0.5;
`;

export const StyledReactModal = styled(ReactModal)`
  max-width: 100vw;
  max-height: 100vh;
`;

export const SubTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin: ${dimens.margins.Green}rem 0;
`;

export const TextButtonPlay = styled.span`
  width: 14.8rem;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
`;

export const TitleLeMag = styled.div<{ theme: Theme }>`
  margin-bottom: ${dimens.margins.LightGreen}rem;
  font-size: ${(props) => props.theme.sectionTextStyle.size}rem;
  color: #fff;
  font-weight: ${(props) => props.theme.sectionTextStyle.fontWeight};
`;
