import {
  List,
  ListDisplayType,
  Tile as TileModel,
} from "@blacknut/react-client-core/lib";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";
import { Theme } from "../../../theme/Theme";
import { useTheme } from "../../../theme/ThemeProvider";
import { TileWidthContext } from "../../../utils/TileWidthProvider";
import FadeInImage from "../../FadeInImage/FadeInImage";
import TileMoreMask from "../../TileMoreMask/TileMoreMask";
import styles from "./styles.module.scss";
import clsx from "clsx";
interface MoreTileProps {
  className?: string;
  list: List;
  item?: TileModel;
}
const ActiveState = css`
  transform: ${(props) =>
    props.theme.tileCardStyle.scaleFactor
      ? `scale(${props.theme.tileCardStyle.scaleFactor})`
      : undefined};
  box-shadow: 0 0 1.2rem ${(props) => props.theme.tileCardStyle.activeBackgroundColor};
  z-index: 4;
`;
const Container = styled(Focusable)<{ theme: Theme; spatialNavigationActive: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: ${(props) => props.theme.tileCardStyle.radius}rem;
  user-select: none;
  flex-shrink: 0;
  transition: transform 0.2s; /* Animation */
  background-color: ${(props) => props.theme.tileCardStyle.inactiveBackgroundColor};
  overflow: hidden;
  @media (hover: hover) {
    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : ActiveState)}
    }
  }
  &:focus {
    ${(props) => (props.spatialNavigationActive ? ActiveState : "")}
  }
`;

const StyledImage = styled(FadeInImage)`
  filter: "grayscale(100%)";
`;

const MoreTile = ({ className, list, item }: MoreTileProps) => {
  const { theme } = useTheme();
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push(`/page/${list.moreLink}`);
  }, [history, list.moreLink]);
  const mainImages =
    item?.game &&
    item.game.images &&
    item.game.images.filter((im) =>
      ["thumbnail", "thumbnail_featured", "main"].includes(im.type),
    );
  const image = mainImages ? mainImages[0] : undefined;
  const { active: spatialNavigationActive } = useSpatialNavigation();
  if (image === undefined) {
    return null;
  } else {
    return (
      <TileWidthContext.Consumer>
        {({ standardTileSize, featuredTileSize }) => (
          <Container
            theme={theme}
            className={clsx([styles.container, className])}
            onClick={onClick}
            onPressEnter={onClick}
            data-testid="container"
            spatialNavigationActive={spatialNavigationActive}
            style={{
              width:
                list.display === ListDisplayType.FEATURED
                  ? featuredTileSize.width
                  : standardTileSize.width,
              height:
                list.display === ListDisplayType.FEATURED
                  ? featuredTileSize.height
                  : standardTileSize.height,
            }}
          >
            {<StyledImage image={image} />}
            <TileMoreMask />
          </Container>
        )}
      </TileWidthContext.Consumer>
    );
  }
};
export default MoreTile;
