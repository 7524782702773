import * as React from "react";

export function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 15 12"
      fill="none"
      className="w-5 h-5 fill-white"
    >
      <path d="M14.736 1.429c-0.553 .243-1.138 .404-1.738 .477c.631-0.38 1.104-0.975 1.33-1.676c-0.596 .347-1.246 .593-1.923 .728c-0.936-1.003-2.429-1.251-3.639-0.604c-1.209 .646-1.833 2.026-1.519 3.361c-2.432-0.117-4.7-1.263-6.237-3.152c-0.263 .444-0.41 .96-0.41 1.522c0 1.052 .535 1.977 1.346 2.52c-0.48-0.015-0.95-0.145-1.37-0.379v.038c-0.001 1.441 1.014 2.684 2.427 2.97c-0.444 .118-0.909 .136-1.36 .051c.399 1.233 1.536 2.077 2.831 2.102c-1.266 .995-2.875 1.446-4.474 1.254c1.388 .889 3.001 1.36 4.649 1.359c5.57 0 8.611-4.611 8.611-8.604c0-0.128 0-0.258-0.009-0.387c.595-0.428 1.107-0.959 1.514-1.568l-0.03-0.012Z" />
    </svg>
  );
}

export function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 245 244"
      className="w-5 h-5 fill-white"
      color="#3b5998"
    >
      <path d="M245 122.746c0-67.791-54.845-122.746-122.5-122.746c-67.655 0-122.5 54.955-122.5 122.746c0 61.265 44.797 112.046 103.359 121.254v-85.773h-31.103v-35.481h31.103v-27.043c0-30.763 18.289-47.756 46.27-47.756c13.403 0 27.422 2.398 27.422 2.398v30.207h-15.447c-15.218 0-19.963 9.462-19.963 19.169v23.025h33.974l-5.431 35.481h-28.543v85.773c58.563-9.208 103.359-59.989 103.359-121.254" />
    </svg>
  );
}

export function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      className="w-5 h-5 fill-white"
    >
      <path d="M12 11h5.535v2.837h.079c.77-1.381 2.655-2.837 5.464-2.837C28.92 11 30 14.637 30 19.367V29h-5.769v-8.54c0-2.037-.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509V29H12V11zM2 11h6v18H2V11zM8 6a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  );
}

export function Whatsapp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 418 420"
      className="w-5 h-5 fill-white"
    >
      <path d="M304.761 251.687c-5.2-2.602-30.768-15.181-35.535-16.918-4.766-1.736-8.233-2.602-11.7 2.602-3.466 5.205-13.433 16.92-16.467 20.39-3.032 3.47-6.066 3.907-11.266 1.302-5.2-2.603-21.957-8.093-41.82-25.811-15.46-13.79-25.897-30.818-28.93-36.024-3.033-5.206-.324-8.02 2.28-10.613 2.34-2.33 5.201-6.074 7.8-9.111 2.601-3.036 3.468-5.206 5.201-8.675 1.733-3.472.867-6.508-.434-9.11-1.3-2.603-11.7-28.2-16.033-38.614-4.221-10.139-8.509-8.765-11.7-8.926-3.03-.15-6.502-.183-9.967-.183-3.467 0-9.102 1.302-13.869 6.507-4.766 5.206-18.2 17.788-18.2 43.382 0 25.598 18.633 50.325 21.234 53.795 2.6 3.472 36.67 55.997 88.838 78.522 12.406 5.359 22.093 8.558 29.646 10.954 12.457 3.959 23.794 3.4 32.754 2.06 9.991-1.491 30.768-12.578 35.102-24.725 4.333-12.15 4.333-22.56 3.032-24.73-1.299-2.169-4.766-3.47-9.966-6.074m-94.886 129.555h-.07c-31.041-.012-61.486-8.351-88.046-24.111l-6.317-3.75-65.47 17.175 17.474-63.833-4.112-6.546c-17.316-27.54-26.463-59.372-26.449-92.058.038-95.374 77.64-172.969 173.059-172.969 46.204.016 89.637 18.033 122.298 50.73 32.66 32.697 50.636 76.158 50.618 122.379-.039 95.382-77.64 172.983-172.985 172.983M357.099 61.035C317.805 21.696 265.55.023 209.873 0 95.156 0 1.79 93.357 1.745 208.106c-.015 36.682 9.568 72.484 27.782 104.047L0 420l110.331-28.941c30.4 16.58 64.626 25.32 99.459 25.331h.086c114.704 0 208.078-93.366 208.124-208.117.021-55.61-21.607-107.9-60.9-147.238" />
    </svg>
  );
}

export function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 483 483"
      className="w-5 h-5 fill-white"
    >
      <path d="M424.3 57.75H59.1c-32.6 0-59.1 26.5-59.1 59.1v249.6c0 32.6 26.5 59.1 59.1 59.1h365.1c32.6 0 59.1-26.5 59.1-59.1v-249.5c.1-32.6-26.4-59.2-59-59.2zm32.1 308.7c0 17.7-14.4 32.1-32.1 32.1H59.1c-17.7 0-32.1-14.4-32.1-32.1v-249.5c0-17.7 14.4-32.1 32.1-32.1h365.1c17.7 0 32.1 14.4 32.1 32.1v249.5h.1z" />
      <path d="M304.8 238.55l118.2-106c5.5-5 6-13.5 1-19.1-5-5.5-13.5-6-19.1-1l-163 146.3-31.8-28.4c-.1-.1-.2-.2-.2-.3-.7-.7-1.4-1.3-2.2-1.9L78.3 112.35c-5.6-5-14.1-4.5-19.1 1.1-5 5.6-4.5 14.1 1.1 19.1l119.6 106.9-119.1 111.5c-5.4 5.1-5.7 13.6-.6 19.1 2.7 2.8 6.3 4.3 9.9 4.3 3.3 0 6.6-1.2 9.2-3.6l120.9-113.1 32.8 29.3c2.6 2.3 5.8 3.4 9 3.4s6.5-1.2 9-3.5l33.7-30.2 120.2 114.2c2.6 2.5 6 3.7 9.3 3.7 3.6 0 7.1-1.4 9.8-4.2 5.1-5.4 4.9-14-.5-19.1l-118.7-112.7z" />
    </svg>
  );
}

export function Reddit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      className="w-5 h-5 fill-white"
    >
      <path d="M24,11.5c0-1.654-1.346-3-3-3c-0.964,0-1.863,0.476-2.422,1.241c-1.639-1.006-3.747-1.64-6.064-1.723 c0.064-1.11,0.4-3.049,1.508-3.686c0.72-0.414,1.733-0.249,3.01,0.478C17.189,6.317,18.452,7.5,20,7.5c1.654,0,3-1.346,3-3 s-1.346-3-3-3c-1.382,0-2.536,0.944-2.883,2.217C15.688,3,14.479,2.915,13.521,3.466c-1.642,0.945-1.951,3.477-2.008,4.551 C9.186,8.096,7.067,8.731,5.422,9.741C4.863,8.976,3.964,8.5,3,8.5c-1.654,0-3,1.346-3,3c0,1.319,0.836,2.443,2.047,2.844 C2.019,14.56,2,14.778,2,15c0,3.86,4.486,7,10,7s10-3.14,10-7c0-0.222-0.019-0.441-0.048-0.658C23.148,13.938,24,12.795,24,11.5z  M2.286,13.366C1.522,13.077,1,12.351,1,11.5c0-1.103,0.897-2,2-2c0.635,0,1.217,0.318,1.59,0.816 C3.488,11.17,2.683,12.211,2.286,13.366z M6,13.5c0-1.103,0.897-2,2-2s2,0.897,2,2c0,1.103-0.897,2-2,2S6,14.603,6,13.5z  M15.787,18.314c-1.063,0.612-2.407,0.949-3.787,0.949c-1.387,0-2.737-0.34-3.803-0.958c-0.239-0.139-0.321-0.444-0.182-0.683 c0.139-0.24,0.444-0.322,0.683-0.182c1.828,1.059,4.758,1.062,6.59,0.008c0.239-0.138,0.545-0.055,0.683,0.184 C16.108,17.871,16.026,18.177,15.787,18.314z M16,15.5c-1.103,0-2-0.897-2-2c0-1.103,0.897-2,2-2s2,0.897,2,2 C18,14.603,17.103,15.5,16,15.5z M21.713,13.365c-0.397-1.155-1.201-2.195-2.303-3.048C19.784,9.818,20.366,9.5,21,9.5 c1.103,0,2,0.897,2,2C23,12.335,22.468,13.073,21.713,13.365z" />
    </svg>
  );
}

export function FacebookMessenger() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      className="w-5 h-5 fill-white"
    >
      <path d="M25 2C12.348 2 2 11.598 2 23.5c0 6.508 3.133 12.285 8 16.219v8.937l1.469-.781 7.219-3.75C20.703 44.665 22.8 45 25 45c12.652 0 23-9.598 23-21.5S37.652 2 25 2zm0 2c11.645 0 21 8.758 21 19.5S36.645 43 25 43c-2.164 0-4.258-.313-6.219-.875l-.375-.094-.343.188L12 45.375v-6.563l-.375-.28C6.961 34.941 4 29.538 4 23.5 4 12.758 13.355 4 25 4zm-2.281 13.719l-12.032 12.75L21.5 24.406l5.781 6.188 11.875-12.875-10.531 5.906z" />
    </svg>
  );
}

export function Share() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path
        d="M25 0l9.649 9.649c.268 .207 .481 .462 .639 .764c.178 .339 .267 .701 .267 1.086c0 .646-0.229 1.197-0.686 1.655c-0.457 .457-1.008 .685-1.655 .685c-0.366 0-0.712-0.081-1.038-0.243c-0.296-0.146-0.549-0.346-0.76-0.599l-4.076-4.076v23.486c0 .647-0.228 1.198-0.685 1.655c-0.457 .457-1.009 .686-1.655 .686c-0.646 0-1.198-0.229-1.655-0.686c-0.457-0.457-0.686-1.008-0.686-1.655v-0.022v-23.464l-4.162 4.16c-0.425 .461-1.035 .75-1.713 .75c-1.287 0-2.33-1.044-2.33-2.331c0-0.757 .36-1.429 .919-1.855Zm-13.865 50c-1.442 0-2.655-0.492-3.639-1.475c-0.983-0.984-1.475-2.197-1.475-3.639v-22.096c0-1.442 .492-2.654 1.475-3.638c.984-0.983 2.197-1.475 3.639-1.475h5.89c0 0 .004 .005 .012 .014c1.274 .015 2.303 1.053 2.303 2.331c0 1.28-1.033 2.319-2.311 2.33c-0.003 .004-0.004 .006-0.004 .006h-5.89c-0.153 0-0.263 .034-0.331 .102c-0.068 .068-0.102 .178-0.102 .33v22.096c0 .153 .034 .263 .102 .331c.068 .068 .178 .102 .331 .102h27.73c.153 0 .263-0.034 .331-0.102c.068-0.068 .102-0.178 .102-0.331v-22.096c0-0.152-0.034-0.262-0.102-0.33c-0.068-0.068-0.178-0.102-0.331-0.102h-5.89c0 0-0.001-0.002-0.004-0.006c-1.27-0.02-2.293-1.056-2.293-2.33c0-1.256 .994-2.281 2.238-2.329c.022-0.01 .041-0.016 .059-0.016c1.93-0.036 5.89 0 5.89 0c1.442 0 2.655 .492 3.639 1.475c.983 .984 1.475 2.196 1.475 3.638v22.096c0 1.442-0.492 2.655-1.475 3.639c-0.984 .983-2.197 1.475-3.639 1.475Z"
        fill="currentColor"
      />
    </svg>
  );
}
