import { useAnalytics, usePrevious } from "@blacknut/react-client-core/lib";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useOrientation } from "../../utils/OrientationContext";
import GamePagePhoneLandscape from "./landscape";
import GamePagePhonePortrait from "./portrait";

const GamePage = () => {
  const params = useParams<{ id: string }>();
  const [videoFullscreen, setVideoFullscreen] = useState(false);

  const { trackGameDetail } = useAnalytics();

  const prevId = usePrevious(params.id);
  useEffect(() => {
    if (prevId !== params.id) {
      trackGameDetail(params.id);
    }
  }, [params.id, prevId, trackGameDetail]);

  const { orientation } = useOrientation();
  const orientationAtFullscreen = useRef(orientation);
  const _setVideoFullscreen = useCallback(
    (b: boolean) => {
      if (b) {
        orientationAtFullscreen.current = orientation;
      }
      setVideoFullscreen(b);
    },
    [orientation],
  );

  let _orientation = orientation;
  if (videoFullscreen) {
    _orientation = orientationAtFullscreen.current;
  }
  if (_orientation === "LANDSCAPE") {
    return (
      <GamePagePhoneLandscape
        videoFullscreen={videoFullscreen}
        setVideoFullscreen={_setVideoFullscreen}
      />
    );
  }
  return (
    <GamePagePhonePortrait
      videoFullscreen={videoFullscreen}
      setVideoFullscreen={_setVideoFullscreen}
    />
  );
};
export default GamePage;
