import { logE } from "@blacknut/logging/dist";
import {
  UpdateFamilyProfileData,
  useEditProfileForm,
} from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import EditProfile from "../../components/EditProfile/EditProfile";
import MessageModal from "../../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import PageTitle from "../../components/PageTitle/PageTitle";
import dimens from "../../theme/dimens";
import { LOGGING_TAG } from "../../utils/Utils";
import { useErrorHandling } from "../../utils/V2ErrorHandler";

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem;
  justify-content: center;
  display: flex;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;

    margin: auto;
  }
`;

const EditProfileNew = () => {
  const params = useParams<{ id: string }>();
  const {
    profile,
    profiles,
    saveError,
    saveProfile,
    dismissSaveError,
    dismissDeleteError,
    deleteError,
    deleteProfile,
  } = useEditProfileForm({ id: params.id });
  const { t } = useTranslation();
  const defaultFocus = "deleteProfileModal_ok";
  const { resume: resumeSpatialNav, active: spatialNavigationActive } =
    useSpatialNavigation();
  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  useErrorHandling({ error: saveError, clearError: dismissSaveError });
  useErrorHandling({ error: deleteError, clearError: dismissDeleteError });
  const history = useHistory();

  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();

  const onDeleteCanceled = React.useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const onDeleteConfirmed = React.useCallback(async () => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
    try {
      await deleteProfile();
      history.goBack();
    } catch (e) {
      logE(LOGGING_TAG, "Error deleting profile %o", e);
    }
  }, [deleteProfile, history]);

  const _onDelete = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <MessageModal
        {...props}
        title={t("dialogs.deleteProfile.title")}
        defaultFocus={`.${defaultFocus}`}
        buttons={[
          <SecondaryButton key="cancel" onClick={onDeleteCanceled}>
            {t("buttons.cancel")}
          </SecondaryButton>,
          spatialNavigationActive ? (
            <SecondaryButton
              key="ok"
              onClick={onDeleteConfirmed}
              className={defaultFocus}
            >
              {t("buttons.delete")}
            </SecondaryButton>
          ) : (
            <PrimaryButton key="ok" onClick={onDeleteConfirmed}>
              {t("buttons.delete")}
            </PrimaryButton>
          ),
        ]}
        message={t("dialogs.deleteProfile.message")}
        onClose={onDeleteCanceled}
      />
    ));
  }, [modalPush, onDeleteCanceled, onDeleteConfirmed, spatialNavigationActive, t]);

  const _onSubmit = React.useCallback(
    async (data: UpdateFamilyProfileData) => {
      try {
        await saveProfile(data);
        history.goBack();
      } catch (e) {
        logE(LOGGING_TAG, "Error updating profile %o", e);
      }
    },
    [history, saveProfile],
  );

  return (
    <Container>
      <PageTitle title={t("updateProfile.title")} />
      <EditProfile
        profile={profile}
        onSubmit={_onSubmit}
        onDelete={!profile || profile.isMaster ? undefined : _onDelete}
        profiles={profiles}
      />
    </Container>
  );
};

export default EditProfileNew;
