import { logD } from "@blacknut/logging/dist";
import { List as ListModel } from "@blacknut/react-client-core/lib";
import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import FullTileList from "../../../components/List/FullTileList";
import { LOGGING_TAG } from "../../../utils/Utils";

interface FullViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: ListModel;
  leaveFor: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
}

const FullView = ({ list, leaveFor, onFocus }: FullViewProps) => {
  logD(LOGGING_TAG, "Render full");
  return (
    <FullTileList list={list} key={list.uuid} leaveFor={leaveFor} onFocus={onFocus} />
  );
};

export default FullView;
