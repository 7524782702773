import { logE } from "@blacknut/logging/dist";
import {
  AppLayout,
  Image,
  List,
  ListDisplayType,
  StorageKey,
  Tile as TileModel,
  useLayout,
  useTile,
  Video,
} from "@blacknut/react-client-core/lib";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Home } from "../../assets/dist/ic_home.svg";
import { useReactRouter } from "../../utils/Hooks";
import { useOrientation } from "../../utils/OrientationContext";
import { useTileSize } from "../../utils/TileWidthProvider";
import useAnonymousCheck from "../../utils/useAnonymousCheck";
import { LOGGING_TAG } from "../../utils/Utils";
import FadeInImage from "../FadeInImage/FadeInImage";
import { usePreview } from "../Modals/PreviewContext";
import PlayIcon from "../PlayIcon/PlayIcon";
import styles from "./styles.module.scss";

interface TileProps {
  className?: string;
  item: TileModel;
  featured?: boolean;
  list: List;
  decorate?: (t: TileModel, focused: boolean) => React.ReactNode;
  onClick?: () => void;
  onPlayClicked?: () => void;
}

const Tile = ({
  className,
  item,
  featured = false,
  list,
  decorate,
  onClick: onClickFromProps,
  onPlayClicked: onPlayClickedFromProps,
}: TileProps) => {
  const { active: previewActive } = usePreview();
  const { onClick, onPlayClicked } = useTile({
    item,
    list,
    ...useReactRouter(),
    previewMode: previewActive,
  });
  const [videoLoaded, setVideoLoaded] = useState(false);
  let image: Image | undefined;
  let videoThumbnail: Video | undefined;
  const videoItem = useRef<HTMLVideoElement | null>(null);

  if (item.game) {
    const mainImages =
      item.game &&
      item.game.images &&
      item.game.images.filter((im) =>
        ["thumbnail", "thumbnail_featured", "main"].includes(im.type),
      );
    image = mainImages ? mainImages[0] : undefined;
    const videoThumbnails =
      item.game &&
      item.game.videos &&
      item.game.videos.filter((v) => v.type === "thumbnail");
    videoThumbnail = videoThumbnails ? videoThumbnails[0] : undefined;
  } else {
    logE(LOGGING_TAG, "invalid tile: %o", item);
  }

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hovering, setHovering] = useState(false);
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const isTVLegacy = localStorage.getItem(StorageKey.TV_LEGACY) === "true";
  const onTimeUpdate = useCallback(() => {
    if (videoItem && videoItem.current && videoItem.current.currentTime > 0.25) {
      setVideoLoaded(true);
      setVideoPlaying(true);
    }
  }, []);

  const onMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setFocused(false);
    setVideoLoaded(false);
    setHovering(false);
  }, []);

  const onBlur = useCallback(() => {
    setVideoLoaded(false);
    setFocused(false);
  }, []);
  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  useEffect(() => {
    if (spatialNavigationActive && hovering) {
      setHovering(false);
    }
  }, [hovering, spatialNavigationActive]);

  const layout = useLayout();
  const _featured = featured || list.display === ListDisplayType.FEATURED;

  const isWaitingTimeout: { current: NodeJS.Timeout | null } = useRef(null);
  const [longFocused, setLongFocused] = useState(false);
  useEffect(() => {
    if (layout !== AppLayout.TV) return;
    if (!focused) {
      setLongFocused(false);
      clearTimeout(isWaitingTimeout.current as NodeJS.Timeout);
    } else {
      isWaitingTimeout.current = setTimeout(() => {
        setLongFocused(true);
      }, 1000);
    }

    return () => {
      clearTimeout(isWaitingTimeout.current as NodeJS.Timeout);
    };
  }, [focused, layout]);

  const { checkForAnonymous } = useAnonymousCheck();
  const _onPlayClicked = useCallback(() => {
    if (checkForAnonymous()) {
      if (onPlayClickedFromProps) {
        onPlayClickedFromProps();
      } else {
        onPlayClicked();
      }
    }
  }, [checkForAnonymous, onPlayClicked, onPlayClickedFromProps]);

  const [ref, inView] = useInView({
    threshold: 0,
  });

  const { standardTileSize, featuredTileSize } = useTileSize();
  return (
    <Focusable
      onPressEnter={onClickFromProps || onClick}
      className={clsx([
        styles.container,
        // !WARNING! DO NOT use class "tile" for styling
        // It is purposely not scoped since it needs to be accessible from FocusableSection defaultElement prop
        "tile",
        styles.focusable,
        className,
      ])}
      onMouseEnter={layout === AppLayout.DESKTOP ? onMouseEnter : undefined}
      onMouseLeave={layout === AppLayout.DESKTOP ? onMouseLeave : undefined}
      onClick={onClickFromProps || onClick} //
      data-testid="container"
      style={{
        width: _featured ? featuredTileSize.width : standardTileSize.width,
        height: _featured ? featuredTileSize.height : standardTileSize.height,
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <div className={styles.wrap} ref={ref}>
        {(inView || process.env.NODE_ENV === "test") && (
          <>
            {image && (
              <FadeInImage
                image={image}
                suffixOnPixelRatio1={_featured ? "_md" : "_sm"}
                className={clsx([videoLoaded ? styles.fadeIn : styles.fadeOut])}
              />
            )}

            {!image && (
              <div className={styles.missing} data-testid="placeholder">
                <Home />
              </div>
            )}
            {!decorate && (
              <>
                {!isTVLegacy &&
                  (layout !== AppLayout.TV || longFocused) &&
                  videoThumbnail &&
                  ((hovering && !spatialNavigationActive) ||
                    (spatialNavigationActive && focused)) && (
                    <video
                      ref={videoItem}
                      data-testid="video"
                      className={clsx([styles.video, videoPlaying && styles.playing])}
                      muted
                      autoPlay
                      playsInline
                      onTimeUpdate={videoPlaying ? undefined : onTimeUpdate}
                      src={videoThumbnail?.url}
                      loop={true}
                    />
                  )}

                {item.game && !spatialNavigationActive && (
                  <PlayIcon
                    className={styles.play}
                    active={hovering}
                    onClick={_onPlayClicked}
                  />
                )}
              </>
            )}

            {decorate && decorate(item, false)}
          </>
        )}
      </div>
    </Focusable>
  );
};
export default Tile;
