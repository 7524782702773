import { User, ContactMessageFormData } from "@blacknut/react-client-core/lib";
import { Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dimens from "../../theme/dimens";
import { PrimaryButton } from "../Button/V2Button";
import CircularProgress from "../CircularProgress/CircularProgress";
import TextInput from "../Inputs/TextInput/V2TextInput";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledMessage = styled(TextInput)`
  margin-top: ${dimens.margins.Green}rem;
`;

const StyledEmail = styled(TextInput)``;

const ButtonBar = styled.div`
  margin-top: ${dimens.margins.Green}rem;
  display: flex;
  flex-direction: row;
  & > * {
    flex: 1;
    margin-right: ${dimens.margins.Green}rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 0.5rem;
`;

interface AccountSupportFormProps {
  sending: boolean;
  initialValues: ContactMessageFormData;
  onSubmit: (values: ContactMessageFormData) => void;
  user: User;
}

const AccountSupportForm = ({
  sending,
  initialValues,
  onSubmit,
  user,
}: AccountSupportFormProps) => {
  const { t } = useTranslation();

  const validate = React.useCallback(
    (values: ContactMessageFormData) => {
      const { email, message } = values;
      const errors: Partial<Record<keyof ContactMessageFormData, string>> = {};
      if (!message || message.trim().length === 0) {
        errors.message = t("errors.message.required");
      }
      if (!email || email.trim().length === 0) {
        errors.email = t("errors.email.required");
      }
      return errors;
    },
    [t],
  );
  const _onSubmit = React.useCallback(
    (e: ContactMessageFormData) => {
      onSubmit(e);
    },
    [onSubmit],
  );
  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit} validate={validate}>
      {({ values, errors, handleChange, handleBlur, handleSubmit, touched }) => (
        <StyledForm onSubmit={handleSubmit} noValidate={false} data-testid="form">
          <StyledEmail
            type="email"
            name="email"
            onChange={handleChange}
            inputProps={{
              onBlur: handleBlur,
            }}
            disabled={!!user?.supportEmail}
            value={values.email}
            label={t("inputs.email")}
            placeholder={t("inputs.email")}
            error={touched.email && errors.email}
            id="email"
            testID="email"
          />

          <StyledMessage
            type="text"
            name="message"
            onChange={handleChange}
            inputProps={{
              onBlur: handleBlur,
            }}
            value={values.message}
            label={t("inputs.message")}
            placeholder={t("inputs.message")}
            error={touched.message && errors.message}
            id="message"
            rows={10}
            testID="message"
          />

          <ButtonBar>
            <PrimaryButton
              type="submit"
              disabled={!!Object.keys(errors)?.length || sending}
            >
              <span>
                {sending
                  ? t("account.buttons.sendInProgress")
                  : t("account.buttons.send")}
              </span>
              {sending && <StyledCircularProgress color="inherit" />}
            </PrimaryButton>
          </ButtonBar>
        </StyledForm>
      )}
    </Formik>
  );
};
export default AccountSupportForm;
