import React from "react";
import styled from "styled-components";
import { useTheme } from "../../theme/ThemeProvider";
import { Theme } from "../../theme/Theme";
import { ReactComponent as More } from "src/assets/dist/more.svg";

const Container = styled.div<{ theme: Theme }>`
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  svg {
    stop:nth-child(1) {
      stop-color: ${(props) => props.theme.tileMoreColor};
    }
    stop:nth-child(2) {
      stop-color: ${(props) => props.theme.tileMoreColor};
    }
    path:nth-child(3) {
      fill: ${(props) => props.theme.tileMoreColor};
    }
  }
`;
const TileMoreMask = () => {
  const { theme } = useTheme();
  return (
    <Container theme={theme} data-testid="TileMoreMask">
      <More />
    </Container>
  );
};

export default TileMoreMask;
