import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ReactComponent as Add } from "../../assets/dist/ic_add.svg";
import { useTheme } from "../../theme/ThemeProvider";
import { Nickname } from "../Profile/ProfileCardItem";
import Ripple, { RippleType } from "../Ripple";
import { Container, Hover } from "./ProfileStyles";

const StyledHover = styled(Hover)<{ $isInRow?: boolean }>`
  &:focus {
    ${(props) =>
      props.spatialNavigationActive
        ? `svg {
      color: ${props.theme.profileCardStyle.activeTextColor};
      border: 0.2rem solid ${props.theme.profileCardStyle.activeTextColor};
    }`
        : ""}
  }
  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      svg {
        color: ${(props) => props.theme.profileCardStyle.activeTextColor};
        border: 0.2rem solid ${(props) => props.theme.profileCardStyle.activeTextColor};
      }
    }
  }

  ${({ $isInRow }) =>
    $isInRow &&
    `
    border: none;
    border-radius: 0.6rem;
    div {
      margin-top: 0;
      font-size: 1.6rem;
    }
  `}
`;

const StyledAdd = styled(Add)<{ $isInRow?: boolean }>`
  border: 0.2rem solid ${(props) => props.theme.rippleColor};
  border-radius: 50%;
  padding: 1rem;
  color: ${(props) => props.theme.rippleColor};

  ${({ $isInRow }) =>
    $isInRow &&
    `
  padding: 0.5rem;
  width: 3.6rem !important;
  height: 3.6rem !important;
  margin-right: 1.6rem !important;

    @media (min-width: 1023px) {
      width: 6rem !important;
      height: 6rem !important;
    }
  `}
`;

const StyledContainer = styled(Container)<{ $isInRow?: boolean }>`
  width: 13rem;

  ${({ $isInRow }) =>
    $isInRow &&
    `
    padding: 1.6rem;
    flex-direction: row;
    width: fit-content
  `}
`;

interface ProfileAddCardButtonProps {
  isInRow?: boolean;
  containerClassName?: string;
  onClick?: (e: React.UIEvent<HTMLElement>) => void;
}

const ProfileAddCardButton = ({
  isInRow = false,
  containerClassName,
  onClick,
}: ProfileAddCardButtonProps) => {
  const { theme } = useTheme();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const { t } = useTranslation();
  const history = useHistory();
  const rippleRef = React.useRef<RippleType>(null);
  const _onClick = React.useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      rippleRef.current?.ripple(
        e,
        onClick
          ? onClick
          : () => {
              history.push("/account/profiles/new");
            },
      );
    },
    [history, onClick],
  );
  return (
    <StyledHover
      theme={theme}
      spatialNavigationActive={spatialNavigationActive}
      onPressEnter={_onClick}
      $isInRow={isInRow}
    >
      <Ripple
        ref={rippleRef}
        rippleStyle={{ radius: theme.profileCardStyle.radius, rippleColor: "#dcdcdc" }}
      >
        <StyledContainer
          $isInRow={isInRow}
          theme={theme}
          onClick={_onClick}
          data-testid="add"
          className={containerClassName}
        >
          <StyledAdd $isInRow={isInRow} theme={theme} />

          <Nickname>{t("buttons.add")}</Nickname>
        </StyledContainer>
      </Ripple>
    </StyledHover>
  );
};
export default ProfileAddCardButton;
