import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import CircularProgress from "../components/CircularProgress/CircularProgress";
import Menu, { MenuProps } from "../components/Menu/Menu";
import { Theme } from "../theme/Theme";
import dimens from "../theme/dimens";

export const Router = BrowserRouter;
export const App = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props: { theme: Theme }) => props.theme.backgroundColor};
`;

export const MainLoading = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
`;

export const RoutesContainer = styled.div<{
  theme: Theme;
  isOnTv: boolean;
  menuHidden: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundColor};
  flex: 1;

  margin-top: ${(props) => (props.isOnTv ? 0 : dimens.phone.headerHeight)}rem;
  margin-bottom: ${(props) =>
    props.menuHidden ? 0 : dimens.phone.navigationBarHeight}rem;
  padding-bottom: env(safe-area-inset-bottom);

  @media (orientation: landscape) {
    margin-bottom: 0;
    margin-left: ${dimens.phoneLandscape.navigationBarWidth}rem;
  }
  @media (min-width: ${dimens.breakpoints.desktop}px) and (orientation: landscape) {
    margin-top: ${(props) => (props.isOnTv ? 0 : dimens.desktop.headerHeight)}rem;
    margin-bottom: 0;
    margin-left: ${dimens.desktop.navigationBarWidth}rem;
  }
`;

export const StyledMenu = styled(Menu)<MenuProps>`
  transition: all 0.2s;
  transform: ${(props: { hidden: boolean }) =>
    props.hidden ? `translateY(200px)` : undefined};

  @media (min-width: ${dimens.breakpoints.desktop}px), (orientation: landscape) {
    transform: ${(props: { hidden: boolean }) =>
      props.hidden
        ? `translateX(-${dimens.desktop.navigationBarWidth + 2.5 /*active marker*/}rem)`
        : undefined};
  }
`;

export const StyledRouter = styled(Router)`
  flex: 1;
  display: flex;
`;

export const MenuContainer = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  background-color: ${(props) => props.theme.bottomBarStyle.backgroundColor};
  @media (min-width: ${dimens.breakpoints.desktop}px), (orientation: landscape) {
    flex-direction: row;
  }
`;
