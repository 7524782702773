import * as React from "react";
import { useTranslation } from "react-i18next";
import dimens from "src/theme/dimens";
import { Theme } from "src/theme/Theme";
import styled from "styled-components";
import { ReactComponent as Step1 } from "../../assets/dist/ipad-step1-safari.svg";
import { ReactComponent as Step2 } from "../../assets/dist/mobile-step2-safari.svg";
import { ReactComponent as Step3 } from "../../assets/dist/ipad-step3-safari.svg";
import { useTheme } from "src/theme/ThemeProvider";
import Carousel from "nuka-carousel";
import { ReactComponent as DownloadNotif } from "../../assets/dist/share-safari.svg";
import styles from "./styles.module.scss";
import logoBN from "src/assets/dist/logo_bn_rounded.png";
import { isBlacknutBuild } from "src/utils/Utils";

const Title = styled.h5<{ theme: Theme }>`
  color: ${(props) => props.theme.notificationInstall.textColor};
  margin: 0 0 ${dimens.margins.DarkRed}rem 0;
  font-size: 1.4rem;
`;

const Informations = styled.div<{ theme: Theme }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContainerStep = styled.div<{ theme: Theme }>`
  z-index: 21;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundColor};
  padding: ${dimens.margins.DarkRed}rem;
  border-radius: 0.6rem;
  margin-bottom: 3rem;
  margin-top: 1rem;

  & > svg {
    max-height: 50px;
  }
`;
const InformationStep = styled.div<{ theme: Theme }>`
  position: relative;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin: ${dimens.margins.Green}rem 0 ${dimens.margins.DarkRed}rem 0;
  padding: 0 ${dimens.margins.DarkRed}rem;
  color: ${(props) => props.theme.textStyle.color};
  opacity: 0.7;
`;

const DllIcon = styled(DownloadNotif)<{ theme: Theme }>`
  max-width: 1.6rem;
  vertical-align: middle;
  transform: translateY(-0.4rem);
  margin: 0 0.3rem;

  path {
    fill: ${(props) => props.theme.notificationInstall.textColor};
  }
`;

const ContainerInfoText = styled.div<{ theme: Theme }>`
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: -webkit-fill-available;
  color: ${({ theme }) => theme.notificationPWA.textColor};
`;

const ContainerInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;
const LogoStyled = styled.img`
  margin-right: ${dimens.margins.Green}rem;
  max-height: 6rem;
  max-width: 6rem;
`;
const Step1Styled = styled(Step1)`
  max-height: 4.5rem;
  transform: scale(0.8);
  margin: 0 auto;
`;
const Step2Styled = styled(Step2)<{ theme: Theme }>`
  transform: scale(0.8);
  margin: 0 auto;

  .darkModed {
    ${({ theme }) => (theme.name === "dark" ? `fill: ${theme.textStyle.color};` : "")}
  }
`;
const Step3Styled = styled(Step3)<{ theme: Theme }>`
  transform: scale(0.8);
  margin: 0 auto;

  .darkModed {
    ${({ theme }) => (theme.name === "dark" ? `fill: ${theme.textStyle.color};` : "")}
  }
`;

const NotificationSafariIpad = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [step, setStep] = React.useState(false);

  const showStep = () => {
    if (!step) {
      setStep(true);
    }
  };
  return (
    <Informations onClick={showStep} theme={theme}>
      <ContainerInfo>
        <LogoStyled
          src={isBlacknutBuild() ? logoBN : "/favicon-96x96.png"}
          alt="App logo"
        />
        <ContainerInfoText theme={theme}>
          <Title theme={theme}>
            {t("notifications.install.title", {
              brand: process.env.REACT_APP_APP_NAME,
            })}
          </Title>
          <span>
            {t("notifications.install.safari.subtitle")} <DllIcon theme={theme} />
          </span>
          <br />
          <span>
            {t("notifications.install.safari.subtitle2", {
              brand: process.env.REACT_APP_APP_NAME,
            })}
          </span>
        </ContainerInfoText>
      </ContainerInfo>

      {step && (
        <Carousel
          autoplay
          wrapAround={true}
          defaultControlsConfig={{
            nextButtonStyle: { display: "none" },
            prevButtonStyle: { display: "none" },
            pagingDotsStyle: {
              fill: theme.spinnerColor,
              display: "flex",
              transform: "scale(1.5)",
            },
            pagingDotsClassName: styles.pagingDots,
          }}
        >
          <ContainerStep theme={theme}>
            <Step1Styled theme={theme} />
            <InformationStep theme={theme}>
              {t("notifications.install.safari.step1")}
            </InformationStep>
          </ContainerStep>
          <ContainerStep theme={theme}>
            <Step2Styled theme={theme} />
            <InformationStep theme={theme}>
              {t("notifications.install.safari.step2")}
            </InformationStep>
          </ContainerStep>
          <ContainerStep theme={theme}>
            <Step3Styled theme={theme} />
            <InformationStep theme={theme}>
              {t("notifications.install.safari.step3")}
            </InformationStep>
          </ContainerStep>
        </Carousel>
      )}
    </Informations>
  );
};
export default NotificationSafariIpad;
