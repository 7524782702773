import { logD } from "@blacknut/logging/dist";
import { List as ListModel } from "@blacknut/react-client-core/lib";
import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { useInView } from "react-intersection-observer";
import { LOGGING_TAG } from "../../../utils/Utils";
import HorizontalTileList from "../../List/HorizontalTileList";

interface FlatViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: ListModel;
  leaveFor: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  scrollable?: boolean;
  className?: string;
}

const FlatView = ({ list, leaveFor, onFocus, scrollable, className }: FlatViewProps) => {
  logD(LOGGING_TAG, "Render flat");
  const [tileRef, inView] = useInView({
    threshold: 0,
  });

  return (
    <div ref={tileRef} className={className}>
      <HorizontalTileList
        list={list}
        key={list.uuid}
        leaveFor={leaveFor}
        inView={inView}
        onFocus={onFocus}
        scrollable={scrollable}
      />
    </div>
  );
};

export default FlatView;
