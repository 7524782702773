import { LeMagFeaturedArticle } from "@blacknut/react-client-core/lib";
import React from "react";
import styled, { css } from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import styles from "./styles.module.scss";

const ActiveState = css`
  transform: ${(props) =>
    props.theme.tileCardStyle.scaleFactor
      ? `scale(${props.theme.tileCardStyle.scaleFactor})`
      : undefined};
  box-shadow: 0 0 1.2rem ${(props) => props.theme.tileCardStyle.activeBackgroundColor};
`;
const Container = styled(Focusable)<{ theme: Theme; spatialNavigationActive: boolean }>`
  display: flex;
  position: relative;
  border-radius: 2.4rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 250ms;
  //Force aspect ratio 1/1
  //https://css-tricks.com/aspect-ratios-grid-items/
  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100%);
  }

  @media (hover: hover) {
    &:hover {
      ${(props) => (props.spatialNavigationActive ? "" : ActiveState)}
      z-index: 4;
    }
  }

  &:focus {
    ${(props) => (props.spatialNavigationActive ? ActiveState : "")}
  }
`;

const Mask = styled.div`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.85));
  position: absolute;
  z-index: 4;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

const DescriptionWrap = styled.div`
  padding: ${dimens.margins.Green}rem;
`;

const Description = styled.div`
  color: #fff;
  font-size: 1.4rem;
  line-height: 2.5ex;
  height: 7.5ex; /* 2.5ex for each visible line */
  overflow: hidden;
  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Title = styled.div`
  color: #fff;
  font-size: 2rem;
  margin: ${dimens.margins.Green}rem;
`;
const BackgroundImage = styled.img`
  position: absolute !important;
  inset: 0;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: auto;
`;

interface LeMagArticleListItemProps {
  article: LeMagFeaturedArticle;
  onPressEnter: () => void;
}
const LeMagArticleListItem = ({ article, onPressEnter }: LeMagArticleListItemProps) => {
  const { theme } = useTheme();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  return (
    <Container
      theme={theme}
      onPressEnter={onPressEnter}
      spatialNavigationActive={spatialNavigationActive}
      className={styles.container}
    >
      <BackgroundImage src={article.image_1by1} />
      <Mask>
        <Title>{article.title}</Title>
        <DescriptionWrap style={{ backgroundColor: article.tile_color }}>
          <Description>{article.description}</Description>
        </DescriptionWrap>
      </Mask>
    </Container>
  );
};
export default LeMagArticleListItem;
