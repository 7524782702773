import {
  dismissNewsletterError,
  toggleNewsletter,
  State,
} from "@blacknut/react-client-core/lib";
import { logE } from "@blacknut/logging/dist";
import { FocusableSection, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Checkbox from "../../components/Inputs/Checkbox/Checkbox";
import PageTitle from "../../components/PageTitle/PageTitle";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import { LOGGING_TAG } from "../../utils/Utils";

const Container = styled(FocusableSection)`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;

    margin: auto;
  }
`;

const StyledIcon = styled.div<{ theme: Theme }>`
  svg {
    width: 12rem;
    height: auto;
    color: ${(props) => props.theme.rippleColor};
  }
  margin-bottom: ${dimens.margins.Green}rem;
`;

const NewsletterPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, newsletter: newsletterState } = useSelector(
    (state: State) => state.globalState,
  );
  const { theme } = useTheme();
  const onClick = useCallback(async () => {
    if (user) {
      try {
        await toggleNewsletter(user)(dispatch);
      } catch (e) {
        logE(LOGGING_TAG, "Caught error toggling newsletter");
      }
    }
  }, [user, dispatch]);

  const _dismissNewsletterError = useCallback(() => {
    dismissNewsletterError()(dispatch);
  }, [dispatch]);

  useErrorHandling({
    error: newsletterState.error,
    clearError: _dismissNewsletterError,
  });

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);
  if (!user) {
    return null;
  }

  return (
    <Container focusKey="newsletter">
      <PageTitle title={t("account.newsletter")} />
      <StyledIcon theme={theme}>
        {theme.images.NewsletterIcon && <theme.images.NewsletterIcon />}
      </StyledIcon>
      <Checkbox
        onCheckChange={onClick}
        checked={user.newsletter}
        label={t("inputs.newsletter", { appName: process.env.REACT_APP_APP_NAME })}
        id="newsletter"
        disabled={newsletterState.updating}
      />
    </Container>
  );
};
export default NewsletterPage;
