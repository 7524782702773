import { logE } from "@blacknut/logging/dist";
import { ContactMessageFormData, useSupportForm } from "@blacknut/react-client-core/lib";
import { FocusableSection, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AccountSupportForm from "../../components/AccountSupportForm/AccountSupportForm";
import PageTitle from "../../components/PageTitle/PageTitle";
import dimens from "../../theme/dimens";
import { LOGGING_TAG } from "../../utils/Utils";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import packages from "../../../package.json";

const Container = styled(FocusableSection)`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem;
  justify-content: center;
  display: flex;
  min-height: min-content;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;

    margin: auto;
  }
`;

const SupportPage = () => {
  const { send, sendError, user, sending, dismissSendError } = useSupportForm({
    handleErrorAsNotifications: false,
  });
  const { t } = useTranslation();
  const initialEmail = user?.supportEmail
    ? user?.supportEmail
    : !user?.autoEmail
    ? user?.email
    : "";

  const initialValues: ContactMessageFormData = {
    email: initialEmail,
    message: "",
    name:
      user?.firstName || user?.lastName
        ? `${(user?.firstName || "") + " "}${user?.lastName || ""}`
        : initialEmail,
    client: user?.id,
    clientVersion: packages.version,
  };
  const { resume: resumeSpatialNav } = useSpatialNavigation();
  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);
  const _send = React.useCallback(
    async (data: ContactMessageFormData) => {
      try {
        await send(data);
      } catch (e) {
        logE(LOGGING_TAG, "caught error sending message", e);
      }
    },
    [send],
  );
  useErrorHandling({ error: sendError, clearError: dismissSendError });
  if (!user) {
    return null;
  }

  return (
    <Container focusKey="support">
      <PageTitle title={t("support.title")} />
      <AccountSupportForm
        initialValues={initialValues}
        sending={sending}
        onSubmit={_send}
        user={user}
      />
    </Container>
  );
};
export default SupportPage;
