import { List as ListModel } from "@blacknut/react-client-core/lib";
import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import HeroTile from "../../TileHero/HeroTile";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";

interface HeroViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: ListModel;
  leaveFor: LeaveFor;
}

const HeroView = ({ list, leaveFor }: HeroViewProps) => {
  const t = list.tiles[0];

  return (
    <FocusableSection focusKey={list.uuid} key={list.uuid} leaveFor={leaveFor}>
      <HeroTile item={t} list={list} />
    </FocusableSection>
  );
};

export default HeroView;
