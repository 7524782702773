import styled from "styled-components";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useHeader } from "../Header/HeaderProvider";
import React, { useEffect } from "react";
import HeaderBackButton from "../Header/HeaderBackButton";
import dimens from "../../theme/dimens";
interface PageTitleProps {
    title: string;
}

const Title = styled.h1<{ theme: Theme }>`
    text-align: center;
    color: ${(props) => props.theme.titleTextStyle.color};
    font-size: ${(props) => props.theme.titleTextStyle.size}rem;
    font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
    font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
    display: none;
    @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
        display: block;
    }
`;

const PageTitle = ({ title }: PageTitleProps) => {
    const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
    useEffect(() => {
        setHeaderTitle(title);
        setHeaderLeft(<HeaderBackButton title={title} />);
        setHeaderRight(undefined);
    }, [setHeaderLeft, setHeaderRight, setHeaderTitle, title]);
    return null;
};
export default PageTitle;
