import { apiService } from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";
import { PlayerType, State } from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import MessageModal from "../../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import { useSubscriptionRedirect } from "../../components/SubscriptionRedirect/SubscriptionRedirect";
import { useBatch } from "../../utils/BatchContext";
import { nativeBridge } from "../../utils/NativeBridge";
import { LOGGING_TAG } from "../../utils/Utils";
import WebRTCGamePlayPage from "./WebRTCGamePlayPage";
import React from "react";
const GamePlayPage = () => {
  const [player, setPlayer] = useState<string>();
  const params = useParams<{ gameId: string }>();
  const history = useHistory();
  const { profile } = useSelector((state: State) => state.profilesState);
  const { config, user } = useSelector((state: State) => state.globalState);
  const { redirectToSubscription } = useSubscriptionRedirect();
  const { t } = useTranslation();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const isSky = apiService.userAgent.brand === "SkyLive";

  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const closeErrorModal = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
    //Le's defer redirect, otherwise, redirect will go the / but modal close will go back
    setTimeout(history.goBack, 500);
  }, [history]);

  const _redirectToSubscription = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;

    //Le's defer redirect, otherwise, redirect will go the / but modal close will go back
    //Stream will be relaunched
    setTimeout(redirectToSubscription, 500);
  }, [redirectToSubscription]);
  const { trackEvent, pauseMobileLanding, resumeMobileLanding } = useBatch();

  const _switchToWebRTC = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
    setPlayer("webrtc");
  }, []);

  useEffect(() => {
    pauseMobileLanding();
    return () => {
      resumeMobileLanding();
    };
  }, [pauseMobileLanding, resumeMobileLanding]);

  useEffect(() => {
    trackEvent("started_game", {
      id: params.gameId,
    });
  }, [params.gameId, trackEvent]);

  useEffect(() => {
    if (!user?.powerUser && isSky) {
      setPlayer("webrtc");
    } else {
      let sPlayer = config?.userConf?.player;
      if (!sPlayer) {
        if (
          apiService.userAgent.os === "Android" ||
          apiService.userAgent.os === "Fireos"
        ) {
          // Default is legacy for the moment
          sPlayer = PlayerType.LEGACY;
        } else {
          sPlayer = PlayerType.WEBRTC;
        }
      }
      switch (sPlayer) {
        case PlayerType.WEBRTC:
          setPlayer("webrtc");
          break;
        default:
          if (!nativeBridge.module || !nativeBridge.module.play) {
            modalSubscription.current = modalPush((props) => (
              <MessageModal
                {...props}
                message="No native player available. Switch to WebRTC ?"
                title="Error"
                onClose={closeErrorModal}
                buttons={[
                  <PrimaryButton key="ok" onClick={_switchToWebRTC}>
                    {t("buttons.ok")}
                  </PrimaryButton>,
                  <SecondaryButton key="cancel" onClick={closeErrorModal}>
                    {t("buttons.cancel")}
                  </SecondaryButton>,
                ]}
              />
            ));
            return;
          }
          nativeBridge.module?.play!({ game: params.gameId, player: sPlayer })
            .then(() => {
              history.goBack();
            })
            .catch((e) => {
              logE(LOGGING_TAG, "Caught error on play : %o", e);

              if (e.code === "not_subscribed") {
                modalSubscription.current = modalPush((props) => (
                  <MessageModal
                    {...props}
                    message={t("dialogs.error.notSubscribed.message")}
                    title={t("dialogs.error.notSubscribed.title")}
                    onClose={closeErrorModal}
                    buttons={
                      profile?.isMaster && [
                        <PrimaryButton key="ok" onClick={_redirectToSubscription}>
                          {t("buttons.ok")}
                        </PrimaryButton>,
                        <SecondaryButton key="cancel" onClick={closeErrorModal}>
                          {t("buttons.cancel")}
                        </SecondaryButton>,
                      ]
                    }
                  />
                ));
              } else {
                modalSubscription.current = modalPush((props) => (
                  <MessageModal
                    {...props}
                    message={t("errors.generic")}
                    title={t("dialogs.error.title")}
                    onClose={closeErrorModal}
                    buttons={[
                      <PrimaryButton key="ok" onClick={closeErrorModal}>
                        {t("buttons.ok")}
                      </PrimaryButton>,
                    ]}
                  />
                ));
              }
            });
      }
    }
  }, [
    _redirectToSubscription,
    _switchToWebRTC,
    closeErrorModal,
    config?.userConf?.player,
    history,
    isSky,
    modalPush,
    params.gameId,
    profile?.isMaster,
    t,
    user?.powerUser,
  ]);

  if (!player || player !== "webrtc") return null;
  return <WebRTCGamePlayPage />;
};

export default GamePlayPage;
