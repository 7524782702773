import React, { FunctionComponent } from "react";
import { animated } from "react-spring";

export interface NeedleProps {
    style: React.CSSProperties;
}

export const Needle: FunctionComponent<NeedleProps> = (props) => {
    return (
        <animated.div {...props}>
            <svg width="29" height="124" viewBox="0 0 29 124" fill="none">
                <path
                    fill="#004960"
                    d="M18.71 5.33c0-0.04 0-0.08 0-0.12c0-2.47-2-4.48-4.48-4.48c-2.47 0-4.48 2.01-4.48 4.48c0 .03 .01 .06 .01 .09l-9.31 104.03h.02c-0.01 .2-0.02 .41-0.02 .62c0 7.61 6.17 13.78 13.78 13.78c7.62 0 13.79-6.17 13.79-13.78c0-0.66-0.05-1.31-0.14-1.95Z"
                    fillRule="evenodd"
                />
            </svg>
        </animated.div>
    );
};
