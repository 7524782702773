import { getSubscription } from "@blacknut/react-client-core/lib";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useTheme } from "../../theme/ThemeProvider";
import { SubscriptionKindProps, SubscriptionSubStatus } from "./SubscriptionKind";

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  svg {
    height: 3.2rem;
    width: auto;
  }
`;
const SubscriptionA1 = ({ organization }: SubscriptionKindProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const refreshSub = () => {
      if (document.visibilityState === "visible") {
        getSubscription()(dispatch);
      }
    };
    document.addEventListener("visibilitychange", refreshSub);
    return () => {
      document.removeEventListener("visibilitychange", refreshSub);
    };
  }, [dispatch]);

  return (
    <>
      {theme.images.Logo && (
        <LogoContainer>
          <theme.images.Logo />
        </LogoContainer>
      )}

      <SubscriptionSubStatus
        theme={theme}
        dangerouslySetInnerHTML={{
          __html: t("account.subscription.m1.text2", {
            url: organization?.subscriptionUrl,
          }),
        }}
      />
    </>
  );
};
export default SubscriptionA1;
