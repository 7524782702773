import { Organization } from "@blacknut/react-client-core/lib";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PageTitle from "../../components/PageTitle/PageTitle";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";

interface OrganizationSupportPageProps {
  organization: Organization;
}

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem;
  justify-content: center;
  display: flex;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0 ${dimens.margins.BlueGreen}rem;
    max-width: 88rem;
    min-width: 70rem;
    margin: auto;
  }
`;
const Text = styled.div<{ theme: Theme }>`
  text-align: center;
  a {
    color: ${(props) => props.theme.rippleColor};
  }
`;
const OrganizationSupportPage = ({ organization }: OrganizationSupportPageProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <Container>
      <PageTitle title={t("support.title")} />
      <Text
        theme={theme}
        dangerouslySetInnerHTML={{
          __html: t("support.textOrga", { url: organization.supportUrl }),
        }}
      />
    </Container>
  );
};
export default OrganizationSupportPage;
