import styled, { css } from "styled-components";
import dimens from "../../../theme/dimens";
import { Theme } from "../../../theme/Theme";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import { PrimaryButton } from "src/components/Button/V2Button";
import { Button } from "@blacknut/react-sdk";

export const GameGenreContainer = styled(FocusableSection)<{ theme: Theme }>`
  display: flex;
  flex-direction: row;
  margin-top: ${dimens.margins.LightGreen}rem;
  justify-content: start;
  font-size: 1.6rem;
  max-width: 90vw;
  flex-wrap: wrap;
  @media (orientation: landscape) {
    margin-top: 0;
  }
`;

export const ButtonGenre = styled(Button)<{ theme: Theme }>`
  cursor: pointer;
  margin: 0.5rem;
  background: rgba(207, 207, 207, 0.3);
  border-radius: 0.4rem;
  flex-basis: 1 1 min-content;
  @media (min-width: ${dimens.breakpoints.desktop}px) and (orientation: landscape) {
    color: #ffffff !important;
  }
  & button {
    min-width: min-content;
    font-size: ${(props: { theme: Theme }) => props.theme.textStyle2.size}rem;
    font-weight: normal !important;
    background-color: transparent !important;
    color: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.color};
    @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
      color: #ffffff !important;
      min-height: 4rem;
    }
    &:hover,
    &:focus {
      color: #ffffff !important;
    }
  }

  &:hover,
  &:focus {
    color: #ffffff !important;
    background-color: ${(props) => props.theme.primaryButton.activeBackgroundColor};
  }
`;
