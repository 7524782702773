import { State } from "@blacknut/react-client-core/lib";
import * as React from "react";
import { useSelector } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import FadeAndSlideTransition from "../../transitions/FadeAndSlideTransition";
import NotificationComponent from "../Notification/Notification";
import styles from "./styles.module.scss";

const Notifications = () => {
  const { notifications } = useSelector((state: State) => state.globalState);
  const MAX_TIME = process.env.REACT_APP_TEST_ENV ? 500 : 2000;
  const [tick, setTick] = React.useState(new Date().getTime());
  React.useEffect(() => {
    setTimeout(() => {
      setTick(new Date().getTime());
    }, MAX_TIME);
  }, [notifications, MAX_TIME]);
  return (
    <TransitionGroup className={styles.list} component="div">
      {notifications.map((n) => {
        return (
          <FadeAndSlideTransition
            direction="up_in"
            duration={350}
            key={`${n.message}-${n.time}`}
            inProp={tick - n.time < MAX_TIME}
          >
            <NotificationComponent notification={n} />
          </FadeAndSlideTransition>
        );
      })}
    </TransitionGroup>
  );
};
export default Notifications;
