import { Game } from "@blacknut/react-client-core/lib";
import {
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { RefObject, useEffect } from "react";
import { ModalSubscription } from "../../components/Modals/ModalContext";

const useGameSpatialNavigation = ({
  game,
  videoFullscreen,
  leMagModalSubscription,
}: {
  game?: Game;
  videoFullscreen: boolean;
  leMagModalSubscription?: RefObject<ModalSubscription | undefined | null>;
}) => {
  const { resume: resumeSpatialNav, active: spatialNavActive } = useSpatialNavigation();
  useEffect(() => {
    if (game && !videoFullscreen) {
      setTimeout(() => {
        resumeSpatialNav();
        if (spatialNavActive && !leMagModalSubscription?.current) {
          focusSectionWithPath(`/${game.id}/btns`);
        }
      }, 300);
    }
  }, [
    game,
    leMagModalSubscription,
    resumeSpatialNav,
    spatialNavActive,
    videoFullscreen,
  ]);
};
export default useGameSpatialNavigation;
