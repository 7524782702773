import { logD, logE } from "@blacknut/logging/dist";
import { HasNativeNotif, State, StorageKey } from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  NavigationFailedEvent,
  useForceFocusOnMount,
} from "@blacknut/spatialnav-sdk/dist";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import nativeBridge from "src/utils/NativeBridge";
import { PrimaryButton, TertiaryButton } from "../../components/Button/V2Button";
import { useTheme } from "../../theme/ThemeProvider";
import { LOGGING_TAG } from "../../utils/Utils";
import styles from "./styles.module.scss";
import React from "react";

const NotificationsPage = () => {
  const { theme } = useTheme();
  const history = useHistory();
  const location = useLocation<{ returnUrl?: string }>();
  const { returnUrl } = location.state || {};
  const { t } = useTranslation();
  const { profile } = useSelector((state: State) => state.profilesState);
  const redirectToCatalog = useCallback(() => {
    history.replace(returnUrl ? returnUrl : profile ? "/catalog" : "/profiles");
  }, [history, profile, returnUrl]);

  const onNotifAllowed = useCallback(() => {
    if (nativeBridge.module?.allowNativeNotif) {
      nativeBridge.module
        .allowNativeNotif()
        .then((res) => {
          localStorage.setItem(StorageKey.ALLOW_PUSH_NOTIF, res);
          logD(LOGGING_TAG, "Got allow notif res : %o", res);
          redirectToCatalog();
        })
        .catch((e) => {
          logE("Caught error on hasNativeNotif: %o", e);
        });
    }
  }, [redirectToCatalog]);

  const onNotifNotAllowed = useCallback(() => {
    localStorage.setItem(StorageKey.ALLOW_PUSH_NOTIF, HasNativeNotif.LATER);
    redirectToCatalog();
  }, [redirectToCatalog]);

  const onNavigationFailedScrollTop = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction == "up") {
      document.documentElement.scrollTo({ top: 0 });
    }
  }, []);

  useForceFocusOnMount("/notifications", { preventScroll: true });

  return (
    <FocusableSection className={styles.container} focusKey="notifications">
      <div className={styles.logoContainer}>
        {theme.images.Logo && (
          <theme.images.Logo
            style={{
              width: `${theme.logoSize.width * 1.6}rem`,
              height: `${theme.logoSize.width * 1.6}rem`,
            }}
          />
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{t("notificationsPush.title")}</div>
        <div className={styles.subtitle}>{t("notificationsPush.subtitle")}</div>
        <div className={styles.imageContainer}>
          {theme.images.NotificationsPush && <theme.images.NotificationsPush />}
        </div>
        <PrimaryButton
          className={styles.btnPrimary}
          onClick={onNotifAllowed}
          onNavigationFailed={onNavigationFailedScrollTop}
        >
          <span>{t("notificationsPush.yes")}</span>
        </PrimaryButton>
        <TertiaryButton className={styles.btnSecondary} onClick={onNotifNotAllowed}>
          <span>{t("notificationsPush.no")}</span>
        </TertiaryButton>
      </div>
    </FocusableSection>
  );
};
export default NotificationsPage;
