import { StorageKey } from "@blacknut/react-client-core/lib";
import clsx from "clsx";
import React, { forwardRef, PropsWithChildren, useImperativeHandle } from "react";
import styles from "./styles.module.scss";
export interface RippleStyle {
  radius?:
    | number
    | string
    | {
        topLeft?: number | string;
        topRight?: number | string;
        bottomLeft?: number | string;
        bottomRight?: number | string;
      };
  rippleColor?: string;
}

export declare type RippleType = {
  ripple: (
    e: React.UIEvent<HTMLElement>,
    callback: ((e: React.UIEvent<HTMLElement>) => void) | undefined,
  ) => void;
};

const radiusToString = (radius: number | string | undefined) => {
  return typeof radius === "number"
    ? `${radius}rem`
    : typeof radius === "string"
    ? radius
    : undefined;
};
// eslint-disable-next-line react/display-name
const Ripple = forwardRef<
  RippleType,
  PropsWithChildren<unknown> & {
    tagName?: string;
    rippleStyle?: Partial<RippleStyle>;
    className?: string;
    contentClassName?: string;
  }
>((props, ref) => {
  const isTVLegacy = localStorage.getItem(StorageKey.TV_LEGACY) === "true";
  const { children, rippleStyle, className, contentClassName } = props;

  const [coords, setCoords] = React.useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = React.useState(false);

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    ripple(
      e: React.UIEvent<HTMLElement>,
      callback: (e: React.UIEvent<HTMLElement>) => void,
    ) {
      if (isTVLegacy) {
        callback && callback(e);
        return;
      }

      if (process.env.NODE_ENV === "test") {
        if (callback) {
          callback(e);
        }
        return;
      }
      const rect = (e.target as any).getBoundingClientRect();
      if (e.type === "click") {
        const mouseevent = e as any as MouseEvent;
        if (mouseevent.clientX !== undefined) {
          setCoords({
            x: mouseevent.clientX - rect.left,
            y: mouseevent.clientY - rect.top,
          });
          setIsRippling(true);
          setTimeout(() => {
            setIsRippling(false);
            if (callback) {
              callback(e);
            }
          }, 300);
        }
      } else if (e.type === "sn:enter-up") {
        setCoords({
          x: rect.left,
          y: rect.top,
        });
        setIsRippling(true);
        setTimeout(() => {
          setIsRippling(false);
          if (callback) {
            callback(e);
          }
        }, 300);
      }
    },
  }));

  let radiusStyle: Partial<React.CSSProperties> | undefined;
  if (typeof rippleStyle?.radius === "object") {
    radiusStyle = {
      borderTopLeftRadius: radiusToString(rippleStyle.radius?.topLeft),
      borderTopRightRadius: radiusToString(rippleStyle.radius?.topRight),
      borderBottomLeftRadius: radiusToString(rippleStyle.radius?.bottomLeft),
      borderBottomRightRadius: radiusToString(rippleStyle.radius?.bottomRight),
    };
  } else {
    radiusStyle = {
      borderRadius: radiusToString(rippleStyle?.radius),
    };
  }
  return (
    <div
      className={clsx(styles.container, className)}
      style={{
        ...radiusStyle,
      }}
    >
      <div className={clsx([styles.content], contentClassName)}>{children}</div>
      {isRippling ? (
        <span
          className={styles.ripple}
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
});

export default Ripple;
