import { getAisReceiptUri } from "@blacknut/react-client-core/lib/models/User";
import { State } from "@blacknut/react-client-core/lib";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
const Container = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReceiptPage = () => {
  const { user } = useSelector((state: State) => state.globalState);
  return (
    <Container>
      {user && (
        <webview src={getAisReceiptUri(user)} style={{ width: "100%", flex: 1 }} />
      )}
    </Container>
  );
};

export default ReceiptPage;
