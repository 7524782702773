import { MenuItem, useMenu } from "@blacknut/react-client-core/lib";
import { FocusableSection, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { isM1Build } from "src/utils/Utils";
import styled from "styled-components";
import { ReactComponent as SortNotFilled } from "../../assets/dist/ic_sort.svg";
import { ReactComponent as Sort } from "../../assets/dist/ic_sort_filled.svg";
import { ReactComponent as SortActive } from "../../assets/dist/ic_sort_filled_active.svg";
import IconButton from "../../components/Button/IconButton";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import FeaturedTileView from "./FeaturedTile";
import StandardTileView from "./StandardTile";

const NB_COLS_STANDARD = 3;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${dimens.phone.contentPaddingV}rem ${dimens.phone.contentPaddingH}rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: ${dimens.desktop.contentPaddingV}rem ${dimens.desktop.contentPaddingH}rem;
  }

  flex: 1;
`;

const Content = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

const FeaturedContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${dimens.margins.Green}rem;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StandardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: ${dimens.margins.Green}rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${dimens.margins.DarkRed}rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const MainLoading = styled(CircularProgress)`
  margin: auto;
  align-self: center;
`;

const Header = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${dimens.margins.LightGreen}rem;
  display: none;
  @media screen and (min-width: ${dimens.breakpoints
      .tablet}px) and (orientation: portrait) {
    display: flex;
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    display: flex;
  }
`;

const HeaderButton = styled(IconButton)`
  cursor: pointer;
  display: inline-flex;
  margin-left: auto;
  svg {
    color: ${(props: { theme: Theme }) => props.theme.headerStyle.buttonsTintColor};
    width: 2.4rem;
    height: 2.4rem;
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  @media (hover: hover) {
    svg:last-child {
      display: none;
    }
  }
  &:hover {
    svg:first-child {
      display: none;
    }
    svg:last-child {
      display: block;
    }
  }
`;

const arrange = (data: MenuItem[], sortAZ: boolean) => {
  const res: { featured: MenuItem[]; standard: MenuItem[] } = {
    featured: [],
    standard: [],
  };
  data.map((t) => {
    if (t.imgBackground && t.imgBackground[0]) {
      res.featured.push(t);
    } else {
      res.standard.push(t);
    }
  });
  if (sortAZ) {
    res.standard.sort((a, b) => {
      if (!a.title) return -1;
      if (!b.title) return 1;
      return a.title.localeCompare(b.title);
    });
  }
  return res;
};

const renderFeatureTile = (item: MenuItem) => {
  return <FeaturedTileView item={item} key={item.uuid} />;
};

const FeaturedList = (props: { menuItem: MenuItem[] }) => {
  return <FeaturedContainer>{props.menuItem.map(renderFeatureTile)}</FeaturedContainer>;
};

const renderStandardTile = (item: MenuItem, index: number) => {
  return (
    <StandardTileView
      item={item}
      nbCols={NB_COLS_STANDARD}
      index={index}
      key={item.uuid}
    />
  );
};
const StandardList = (props: { menuItem: MenuItem[] }) => {
  return <StandardContainer>{props.menuItem.map(renderStandardTile)}</StandardContainer>;
};

const BrowsePage = () => {
  const { loading, loadError, dismissError, menu } = useMenu({
    isScreenForeground: true,
  });
  const { t } = useTranslation();
  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();

  const history = useHistory();
  const { theme } = useTheme();
  const [sortAZ, setSortAZ] = useState(false);
  const { featured, standard } = useMemo(
    () => arrange(menu?.pages ?? [], sortAZ),
    [menu?.pages, sortAZ],
  );
  const toggleSort = useCallback(() => {
    setSortAZ((b) => !b);
  }, []);
  React.useEffect(() => {
    setHeaderTitle(t("browse.title"));
    setHeaderLeft(<HeaderBackButton title={t("browse.title")} />);
    setHeaderRight(
      <HeaderButton onClick={toggleSort} theme={theme}>
        <SortNotFilled />
      </HeaderButton>,
    );
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t, theme, toggleSort]);

  useErrorHandling({
    error: loadError,
    clearError: dismissError,
    callback: history.goBack,
  });
  const { resume } = useSpatialNavigation();
  useEffect(() => {
    if (menu?.pages.length) {
      resume();
    }
  }, [menu?.pages.length, resume]);

  return (
    <Container>
      <ScrollRestorer id="main" />
      <Content focusKey="browse">
        <Header>
          {isM1Build() && (
            <HeaderButton onClick={toggleSort} theme={theme}>
              <Sort />
              <SortActive />
            </HeaderButton>
          )}
        </Header>
        {featured && featured.length > 0 && <FeaturedList menuItem={featured} />}
        {standard && standard.length > 0 && <StandardList menuItem={standard} />}
      </Content>
      {loading && <MainLoading size={50} />}
    </Container>
  );
};
export default BrowsePage;
