import {
  AppLayout,
  State,
  useAnalytics,
  usePage,
  usePrevious,
} from "@blacknut/react-client-core/lib";
import React, { useCallback, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import Catalog from "../../components/Catalog/Catalog";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import {
  FocusableSection,
  focusSectionWithPath,
  NavigationFailedEvent,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import { useBatch } from "../../utils/BatchContext";
import { useSelector } from "react-redux";

const Container = styled(FocusableSection)`
  flex: 1;
  display: flex;
`;
const MainLoading = styled(CircularProgress)`
  margin: auto;
  align-self: center;
`;

const CategoryPage = () => {
  const params = useParams<{ id: string }>();
  const { page, loading, dismissLoadingError, loadingError, paginating, paginate } =
    usePage({
      id: params.id,
      limit: 10,
      tilesLimit: 20,
    });
  const { resume: resumeSpatialNav, active: spatialNavActive } = useSpatialNavigation();

  const history = useHistory();

  useErrorHandling({
    error: loadingError,
    clearError: dismissLoadingError,
    callback: history.goBack,
  });

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  const { trackPageView } = useAnalytics();

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      trackPageView(params.id);
    }
  }, [params.id, trackPageView]);

  React.useEffect(() => {
    setHeaderTitle(page ? page.title : "");
    setHeaderRight(undefined);
    setHeaderLeft(<HeaderBackButton title={page ? page.title : ""} />);
  }, [page, params.id, setHeaderLeft, setHeaderRight, setHeaderTitle]);

  const { trackEvent } = useBatch();
  useEffect(() => {
    if (page) {
      trackEvent("visited_category", { id: page.uuid, title: page.title || "" });
    }
  }, [page, trackEvent]);

  const prevPage = usePrevious(page);
  useEffect(() => {
    if (!prevPage && page) {
      setTimeout(() => {
        resumeSpatialNav();
        if (spatialNavActive) {
          focusSectionWithPath(`/${page.uuid}/${page.lists[0].uuid}`);
        }
      }, 300);
    }
  }, [page, prevPage, resumeSpatialNav, spatialNavActive]);

  const onNavigationFailed = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "up") {
      document.documentElement.scroll({ top: 0 });
    } else if (e.detail.direction === "down") {
      document.documentElement.scroll({ top: document.body.scrollHeight });
    }
  }, []);
  const { layout } = useSelector((state: State) => state.globalState);

  return (
    <Container focusKey={page?.uuid || "XX"} onNavigationFailed={onNavigationFailed}>
      {loading && <MainLoading size={50} />}
      {page && (
        <Catalog
          page={page}
          paginating={paginating}
          onPaginate={paginate}
          onGenre={layout === AppLayout.PHONE}
        />
      )}
    </Container>
  );
};

export default CategoryPage;
