import React, { PropsWithChildren, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const scrollPositions = new Map();

const defaultValue = {
  scrollPositions,
  setScrollPosition: (pathName: string, scrollTop: number) => {
    scrollPositions.set(pathName, scrollTop);
  },
  clear: () => {
    scrollPositions.clear();
  },
};

export const ScrollContext = React.createContext<{
  scrollPositions: Map<string, number>;
  setScrollPosition: (pathName: string, scrollTop: number) => void;
  clear: () => void;
}>(defaultValue);

export const ScrollContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <ScrollContext.Provider value={defaultValue}>{children}</ScrollContext.Provider>
  );
};

const ScrollRestorer = (props: { id?: string; scrollKey?: string }) => {
  const context = useContext(ScrollContext);
  const location = useLocation();
  const scrollKey = props.scrollKey || location.pathname;
  useEffect(() => {
    // const e = props.id ? document.getElementById(props.id) : document.getElementById("main");
    // const scrollTop = context.scrollPositions.get(scrollKey);
    // logger.debug("On mount (%o) scroll position is %o, overflowing is %o", scrollKey, scrollTop, e);
    // if (e && e.scrollTo) {
    //     e.scrollTo({ top: scrollTop || 0 });
    // }
    // return () => {
    //     const e = props.id ? document.getElementById(props.id) : document.getElementById("main");
    //     logger.debug(
    //         "On unmount (%o) scroll position is %o, overflowing is %o",
    //         scrollKey,
    //         e ? e.scrollTop : "N/A",
    //         e,
    //     );
    //     if (e) {
    //         context.setScrollPosition(scrollKey, e.scrollTop);
    //     }
    // };
  }, [context, location, props.id, scrollKey]);

  return null;
};

export { ScrollRestorer };
