import {
  useAnalytics,
  useMyList,
  Game,
  List,
  ListDisplayType,
  Tile,
  useLayout,
  AppLayout,
} from "@blacknut/react-client-core/lib";
import { differenceInCalendarDays } from "date-fns/fp";
import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Delete } from "../../assets/dist/ic_delete.svg";
import { ReactComponent as Validate } from "../../assets/dist/ic_done.svg";
import { ReactComponent as Sort } from "../../assets/dist/ic_sort.svg";
import { ReactComponent as FavoritePlaceholder } from "../../assets/dist/placeholder_list_empty.svg";
import IconButton from "../../components/Button/IconButton";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import Checkbox from "../../components/Inputs/Checkbox/Checkbox";
import FullTileList from "../../components/List/FullTileList";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import OptionModal, { Option } from "../../components/Modals/OptionModal";
import dimens, { getNumColumns } from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useOrientation } from "../../utils/OrientationContext";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
import {
  FocusableSection,
  focusSectionWithPath,
  NavigationFailedEvent,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import MyListPageHeader from "./MyListPageHeader";
interface Section {
  title: string;
  items: Tile[];
  dummyList?: List; // Dummy list element to be compatible with full list
}

declare type SortMethod = "name" | "createdat" | "genre";

const dateToLabel = (d: Date, now: Date, t: TFunction) => {
  const diff = differenceInCalendarDays(d, now);
  if (diff === 0) {
    return t("myList.date.today");
  }
  if (diff < 7) {
    return t("myList.date.thisWeek");
  }
  if (diff < 31) {
    return t("myList.date.thisMonth");
  }
  if (diff < 180) {
    return t("myList.date.lessThan6Months");
  }
  if (diff < 365) {
    return t("myList.date.thisYear");
  }
  return t("myList.date.moreThanOneYearAgo");
};

const makeDummyList = (mainList: List, sections: Section[]) => {
  let nbTiles = 0;

  sections.map((section, i) => {
    section.dummyList = {
      uuid: `list-${i}`,
      display: ListDisplayType.FULL,
      tiles: section.items,
      total: i === sections.length - 1 ? mainList.total - nbTiles : section.items.length,
      isPaginated: false,
      title: section.title,
    };
    nbTiles += section.items.length;
  });

  return sections;
};

const makeSections = (list: List, sortMethod: SortMethod, t: TFunction) => {
  switch (sortMethod) {
    case "name": {
      const res: Section[] = [];
      list.tiles.forEach((tile) => {
        let section: Section;

        const firstLetter = (tile.game as Game).name.substring(0, 1).toLocaleUpperCase();
        const isAZ = firstLetter.match("[A-Z]");
        if (res.length > 0) {
          section = res[res.length - 1];
          if (!isAZ || section.title === firstLetter) {
            section.items.push(tile);
          } else if (isAZ) {
            section = {
              title: firstLetter,
              items: [tile],
            };
            res.push(section);
          }
        } else {
          if (isAZ) {
            section = {
              title: firstLetter,
              items: [tile],
            };
          } else {
            section = {
              title: "0-9",
              items: [tile],
            };
          }
          res.push(section);
        }
      });
      return makeDummyList(list, res);
    }
    case "genre": {
      const res: Section[] = [];
      list.tiles.forEach((tile) => {
        let section: Section;
        if (res.length > 0) {
          section = res[res.length - 1];
          if (section.title === (tile.game as Game).genre) {
            section.items.push(tile);
          } else {
            section = {
              title: tile.game?.genre ?? "",
              items: [tile],
            };
            res.push(section);
          }
        } else {
          section = {
            title: tile.game?.genre ?? "",
            items: [tile],
          };
          res.push(section);
        }
      });
      return makeDummyList(list, res);
    }
    case "createdat": {
      const res: Section[] = [];
      const now = new Date();

      list.tiles.forEach((tile) => {
        const date = new Date(tile.game?.createdAt || "");
        const dateLabel = dateToLabel(date, now, t);
        let section: Section;
        if (res.length > 0) {
          section = res[res.length - 1];
          if (section.title === dateLabel) {
            section.items.push(tile);
          } else {
            section = {
              title: dateLabel,
              items: [tile],
            };
            res.push(section);
          }
        } else {
          section = {
            title: dateLabel,
            items: [tile],
          };
          res.push(section);
        }
      });
      return makeDummyList(list, res);
    }
  }
  return [];
};

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: ${dimens.margins.Orange}rem ${dimens.margins.Blue}rem;
  }
`;

const Content = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
`;

const Lists = styled.div`
  padding: ${dimens.margins.LightGreen}rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0;
  }
`;

const StyledMainLoading = styled(CircularProgress)`
  align-self: center;
  margin: auto;
`;

const TileMask = styled(FocusableSection)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: ${(props: { theme: Theme }) => props.theme.cardStyle.radius}rem;
  z-index: 4;
`;

const StyledFullTileList = styled(FullTileList)`
  margin-bottom: ${dimens.margins.Orange}rem;
`;

const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
`;

const EmptyContainer = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${(props: { theme: Theme }) => props.theme.textStyle.color};
  svg {
    width: "80%";
    max-width: 64rem;
    height: auto;
  }
`;

/* padding-left: ${(props: { fixed: boolean }) => (props.fixed ? dimens.desktop.contentPaddingH : 0)}rem;
        padding-right: ${(props: { fixed: boolean }) => (props.fixed ? dimens.desktop.contentPaddingH : 0)}rem;
        padding-top: ${(props: { fixed: boolean }) => (props.fixed ? dimens.margins.LightGreen : 0)}rem;
        padding-bottom: ${(props: { fixed: boolean }) => (props.fixed ? dimens.margins.Orange : 0)}rem; */

const StyledMyListPageHeader = styled(MyListPageHeader)`
  position: fixed;
  right: 0;
  top: ${dimens.phone.headerHeight}rem;
  left: 0;
  height: ${3.6 + 2 * dimens.margins.LightGreen}rem;
  padding: ${dimens.margins.LightGreen}rem;
  z-index: 5;

  @media screen and (orientation: landscape) {
    top: ${dimens.phoneLandscape.headerHeight}rem;
    left: ${dimens.phoneLandscape.navigationBarWidth}rem;
    padding: ${dimens.margins.LightGreen}rem;
    height: initial;
  }

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    top: ${dimens.desktop.headerHeight}rem;
    left: ${dimens.desktop.navigationBarWidth}rem;
    padding: ${dimens.margins.Orange}rem ${dimens.margins.Blue}rem;
    height: initial;
  }
  background: ${(props: { theme: Theme }) => props.theme.backgroundColor};

  ${(props) =>
    props.isTV &&
    `box-shadow: 0px ${-dimens.desktop.headerHeight}rem
    ${props.theme.backgroundColor};`}}
`;

export const LAST_PLAYED_SORT_KEY = "lastPlayedSort";
export const FAVORITES_SORT_KEY = "favoritesSort";

const ButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

const HeaderButton = styled(IconButton)`
  cursor: pointer;
  margin-left: ${dimens.margins.DarkRed}rem;
  display: inline-flex;
  svg {
    color: ${(props: { theme: Theme }) => props.theme.headerStyle.buttonsTintColor};
    width: 2.4rem;
    height: 2.4rem;
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
`;

const EMPTY_LIST: List = {
  uuid: "-1",
  display: ListDisplayType.FULL,
  tiles: [],
  total: 0,
  title: "",
  isPaginated: false,
};

const MyListPageNew = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    lastPlayedList,
    lastPlayedListLoading,
    lastPlayedListPaginating,
    favoritesListLoading,
    favoritesList,
    favoritesListPaginating,
    paginateLastPlayedList,
    paginateFavoritesList,
    getLastPlayedList,
    getFavoritesList,
    removeGamesFromFavorites,
    removeGamesFromLastPlayed,
    lastPlayedSort,
    favoritesListSort,
  } = useMyList({ tilesLimit: 36 });
  const { orientation } = useOrientation();
  const { trackLastPlayed, trackFavorites } = useAnalytics();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [editMode, setEditMode] = React.useState(false);
  const [selectedGames, setSelectedGames] = React.useState<Set<string>>(new Set());
  const [sortMethods, setSortMethods] = React.useState([
    lastPlayedSort ||
      (localStorage.getItem(LAST_PLAYED_SORT_KEY) as SortMethod) ||
      "createdat",
    favoritesListSort ||
      (localStorage.getItem(FAVORITES_SORT_KEY) as SortMethod) ||
      "name",
  ]);

  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  let list = currentIndex === 0 ? lastPlayedList : favoritesList;
  if (!list) {
    list = EMPTY_LIST;
  }

  const sortMethod = sortMethods[currentIndex];

  const sections = React.useMemo(
    () => makeSections(list as List, sortMethod, t),
    [list, sortMethod, t],
  );

  const paginate = currentIndex === 0 ? paginateLastPlayedList : paginateFavoritesList;
  const paginating =
    currentIndex === 0 ? lastPlayedListPaginating : favoritesListPaginating;

  const handleIndexChange = React.useCallback(
    (_currentIndex: number) => {
      if (currentIndex !== _currentIndex) {
        const root = document.getElementById("main");
        if (root) {
          root.scrollTop = 0;
        }
        setCurrentIndex(_currentIndex);
        setSelectedGames(new Set());
        setEditMode(false);

        if (_currentIndex === 0) {
          if (!lastPlayedList) {
            getLastPlayedList(sortMethods[0]);
          }

          trackLastPlayed();
        } else if (_currentIndex === 1) {
          if (!favoritesList) {
            getFavoritesList(sortMethods[1]);
          }
          trackFavorites();
        }
      }
    },
    [
      currentIndex,
      favoritesList,
      getFavoritesList,
      getLastPlayedList,
      lastPlayedList,
      sortMethods,
      trackFavorites,
      trackLastPlayed,
    ],
  );

  const onClickFavorite = React.useCallback(() => {
    handleIndexChange(1);
  }, [handleIndexChange]);
  const onClickLastPlayed = React.useCallback(() => {
    handleIndexChange(0);
  }, [handleIndexChange]);

  const toggleSelect = React.useCallback(
    (game: Game) => {
      if (selectedGames.has(game.id)) {
        const tmp = new Set(selectedGames);
        tmp.delete(game.id);
        setSelectedGames(tmp);
      } else {
        const tmp = new Set(selectedGames);
        tmp.add(game.id);
        setSelectedGames(tmp);
      }
    },
    [selectedGames],
  );
  const decorate = React.useCallback(
    (tile: Tile) => {
      const onPress = () => {
        toggleSelect(tile.game as Game);
      };

      const onClick = (e: React.UIEvent<unknown>) => {
        e.stopPropagation();
        toggleSelect(tile.game as Game);
      };
      return (
        <TileMask
          theme={theme}
          onClick={onClick}
          data-testid="mask"
          focusKey={tile.game!.id}
          disabled={true}
        >
          <StyledCheckbox
            testID="checkbox"
            id={`check-${tile.game!.id}`}
            onCheckChange={onPress}
            checked={selectedGames.has((tile.game as Game).id)}
            checkboxStyle={{
              ...theme.checkboxStyle,
              inactiveTintColor: "#fff",
            }}
          />
        </TileMask>
      );
    },
    [selectedGames, theme, toggleSelect],
  );
  const onToggleEdit = React.useCallback(() => {
    if (editMode) {
      if (selectedGames.size > 0) {
        if (currentIndex === 0) {
          removeGamesFromLastPlayed(selectedGames);
        } else {
          removeGamesFromFavorites(selectedGames);
        }
        setSelectedGames(new Set());
        setEditMode(false);
      } else {
        setEditMode(false);
      }
    } else {
      setEditMode(true);
    }
  }, [
    currentIndex,
    editMode,
    removeGamesFromFavorites,
    removeGamesFromLastPlayed,
    selectedGames,
  ]);

  const closeSortModal = React.useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const onSelectSortOption = React.useCallback(
    (opt: Option) => {
      const _sortMethods = [...sortMethods];
      _sortMethods[currentIndex] = opt.key as SortMethod;
      setSortMethods(_sortMethods);
      if (modalSubscription.current) {
        modalSubscription.current.remove();
        modalSubscription.current = undefined;
      }
      if (currentIndex === 0) {
        getLastPlayedList(opt.key as SortMethod);
        localStorage.setItem(LAST_PLAYED_SORT_KEY, opt.key);
      } else {
        getFavoritesList(opt.key as SortMethod);
        localStorage.setItem(FAVORITES_SORT_KEY, opt.key);
      }
    },
    [currentIndex, getFavoritesList, getLastPlayedList, sortMethods],
  );

  const openSortModal = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title={t("myList.sort.title")}
        onClose={closeSortModal}
        selected={sortMethod}
        options={[
          {
            key: "name",
            label: t("myList.sort.name"),
            icon: theme.images.SortAZ,
            hoveredIcon: theme.images.SortAZDark,
            activeIcon: theme.images.SortAZActive,
          },
          {
            key: "createdat",
            label: t("myList.sort.date"),
            icon: theme.images.SortDate,
            hoveredIcon: theme.images.SortDateDark,
            activeIcon: theme.images.SortDateActive,
          },
          {
            key: "genre",
            label: t("myList.sort.genre"),
            icon: theme.images.SortGenre,
            hoveredIcon: theme.images.SortGenreDark,
            activeIcon: theme.images.SortGenreActive,
          },
        ]}
        onSelectOption={onSelectSortOption}
      />
    ));
  }, [closeSortModal, modalPush, onSelectSortOption, sortMethod, t, theme]);

  const layout = useLayout();

  const { setHeaderRight, setTitle: setHeaderTitle, setHeaderLeft } = useHeader();

  React.useEffect(() => {
    {
      setHeaderLeft(<HeaderBackButton title={t("myList.title")} />);
      setHeaderTitle(t("myList.title"));
      setHeaderRight(
        (list?.total ?? 0) > 0 ? (
          <ButtonsContainer>
            <HeaderButton onClick={openSortModal} theme={theme}>
              <Sort />
            </HeaderButton>

            <HeaderButton onClick={onToggleEdit} theme={theme}>
              {editMode && (
                <>
                  <Validate />
                </>
              )}
              {!editMode && (
                <>
                  <Delete />
                </>
              )}
            </HeaderButton>
          </ButtonsContainer>
        ) : undefined,
      );
    }
  }, [
    editMode,
    list,
    onToggleEdit,
    openSortModal,
    setHeaderLeft,
    setHeaderRight,
    setHeaderTitle,
    t,
    theme,
  ]);

  //On startup fetch page
  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (mounted.current) {
      return;
    }
    mounted.current = true;
    getLastPlayedList(sortMethods[0]);
    trackLastPlayed();
  }, [getLastPlayedList, sortMethods, trackLastPlayed]);

  const { resume, active } = useSpatialNavigation();
  React.useEffect(() => {
    if (list) {
      resume();
      if (active && !modalSubscription.current) {
        setTimeout(() => {
          focusSectionWithPath("/myList/header");
        }, 200);
      }
    }
  }, [active, list, resume]);

  const toggleSelectTile = React.useCallback(
    (t: Tile) => {
      if (t.game) {
        toggleSelect(t.game);
      }
    },
    [toggleSelect],
  );
  const onNavigationFailed = React.useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "up") {
      document.documentElement.scroll({ top: 0 });
      focusSectionWithPath("/myList/header");
    } else if (e.detail.direction === "down") {
      document.documentElement.scroll({ top: document.body.scrollHeight });
    }
  }, []);
  return (
    <Container>
      <ScrollRestorer />
      <Content focusKey="myList" onNavigationFailed={onNavigationFailed}>
        <StyledMyListPageHeader
          onClickFavorite={onClickFavorite}
          currentIndex={currentIndex}
          editMode={editMode}
          list={list}
          theme={theme}
          onClickLastPlayed={onClickLastPlayed}
          onToggleEdit={onToggleEdit}
          openSortModal={openSortModal}
          ref={headerRef}
          isTV={layout === AppLayout.TV}
        />

        {(lastPlayedListLoading || favoritesListLoading) && (
          <StyledMainLoading size={50} />
        )}

        <Lists style={{ marginTop: Math.ceil(headerRef.current?.clientHeight ?? 0) }}>
          {sections &&
            sections.map((section) => {
              return (
                <StyledFullTileList
                  key={section.title}
                  list={section.dummyList as List}
                  decorate={editMode ? decorate : undefined}
                  paginate={paginate}
                  paginating={paginating}
                  onClickTile={editMode ? toggleSelectTile : undefined}
                  numColumns={getNumColumns(layout, orientation)}
                  // config={{ restrict: "self-only" }}
                />
              );
            })}

          {!lastPlayedListLoading &&
            !favoritesListLoading &&
            sections &&
            sections.length === 0 && (
              <EmptyContainer focusKey="empty" theme={theme}>
                <FavoritePlaceholder />
                <span>{t("list.empty")}</span>
              </EmptyContainer>
            )}
        </Lists>
      </Content>
    </Container>
  );
};

export default MyListPageNew;
