import { StorageKey } from "@blacknut/react-client-core/lib";
import React, { useCallback } from "react";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import OptionModal, { Option } from "../../components/Modals/OptionModal";
import BoolOpts from "./BoolOpts";
import ListItem from "./ListItem";
const WindowedMode = () => {
  const windowed = localStorage.getItem(StorageKey.WINDOWED);
  const setWindowed = useCallback((b: boolean) => {
    if (b) {
      localStorage.setItem(StorageKey.WINDOWED, "true");
    } else {
      localStorage.removeItem(StorageKey.WINDOWED);
    }
  }, []);
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();

  const _closeModal = React.useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const _onSelectWindowedMode = React.useCallback(
    (opt: Option) => {
      _closeModal();
      if (opt.key === "on" && !windowed) {
        setWindowed(true);
      } else if (opt.key === "off" && windowed) {
        setWindowed(false);
      }
    },
    [_closeModal, windowed, setWindowed],
  );

  const openModal = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Select windowed mode"
        onSelectOption={_onSelectWindowedMode}
        options={BoolOpts}
        selected={windowed ? "on" : "off"}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, modalPush, _onSelectWindowedMode, windowed]);
  return (
    <ListItem name="Windowed" value={windowed ? "ON" : "OFF"} onClick={openModal} />
  );
};

export default WindowedMode;
