import { leMagService } from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 1s;
  background-color: ${(props: { theme: Theme }) => props.theme.backgroundColor};
`;

const StyledIFrame = styled.iframe`
  border: 0;
  flex: 1;
`;

const LeMagArticlePage = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { theme } = useTheme();
  const { setHeaderLeft, setTitle: setHeaderTitle, setHeaderRight } = useHeader();
  useEffect(() => {
    setHeaderTitle("LeMag");
    setHeaderLeft(<HeaderBackButton title="LeMag" />);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, theme]);

  const { resume: resumeSpatialNav } = useSpatialNavigation();

  const onMessage = useCallback(
    (evt: MessageEvent) => {
      if (
        evt.origin &&
        evt.origin.match("blacknutlemag") &&
        typeof evt.data === "object" &&
        evt.data.deeplink
      ) {
        history.push(evt.data.deeplink.replace("blacknut://", "/"));
      }
    },
    [history],
  );
  useEffect(() => {
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    resumeSpatialNav();
  }, [resumeSpatialNav]);

  const uri = `https://www.blacknutlemag.com/app/${leMagService.locale || "en"}/${
    params.id
  }?webview${theme.name === "dark" ? "&darkmode" : ""}`;

  return (
    <Container theme={theme}>
      <StyledIFrame src={uri} width="100%" allow="web-share" />
    </Container>
  );
};

export default LeMagArticlePage;
