import { RemoteNotification, State, UserProfile } from "@blacknut/react-client-core/lib";
import React, { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import dimens from "src/theme/dimens";
import styled from "styled-components";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { ScrollContext } from "../../utils/scroll/ScrollProvider";
import useAnonymousCheck from "../../utils/useAnonymousCheck";
import AvatarImage from "../AvatarSelectionModal/AvatarImage";
import NavLink from "../NavLink";
import styles from "./styles.module.scss";

export const unreadNotificationsSelector = createSelector(
  (state: State) => state.globalState.remoteNotifications,
  (remoteNotifications: RemoteNotification[]) => {
    return remoteNotifications.filter((notif) => notif.isNew).length;
  },
);

const NewBadge = styled.div<{ theme: Theme }>`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.rippleColor};
  position: absolute;
  right: 2.4rem;
  border: 0.2rem solid #fff;
  top: 0.8rem;
  @media (max-width: 768px) {
    right: 0.8rem;
  }
`;

const AvatarImageStyled = styled(AvatarImage)`
  padding-bottom: 0 !important;
`;
const AvatarDefaultStyled = styled(AvatarDefault)`
  padding-bottom: 0 !important;
`;

interface AccountIconProps {
  activeItem: string;
  profile: UserProfile;
}
const AccountIcon = ({ activeItem, profile }: AccountIconProps) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const unreadNotificationsLen = useSelector(unreadNotificationsSelector);
  const scrollContext = useContext(ScrollContext);
  const { theme } = useTheme();
  const { checkForAnonymous } = useAnonymousCheck();
  const onClick = useCallback(
    (e: React.UIEvent<unknown>) => {
      e.preventDefault();
      if (checkForAnonymous()) {
        scrollContext.setScrollPosition("sideMenu", 0);
        history.push(
          window?.innerWidth < dimens.breakpoints.desktop
            ? "/account/me"
            : "/account/profiles/switch",
        );
      }
    },
    [checkForAnonymous, history, scrollContext],
  );
  return (
    <NavLink
      data-testid="account"
      to={"/account/me"}
      replace={pathname === "/account/me"}
      isActive={() => activeItem === "account"}
      activeClassName={styles.active}
      className={styles.focusable}
      onClick={onClick}
      linkClassName={styles.link}
    >
      {profile.avatarId && <AvatarImageStyled avatarId={profile.avatarId} />}

      {!profile.avatarId && <AvatarDefaultStyled className="svg" />}

      {unreadNotificationsLen > 0 && <NewBadge data-testid="badge" theme={theme} />}
    </NavLink>
  );
};

export default AccountIcon;
