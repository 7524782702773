import React, { useContext } from "react";

export declare type EventName = "willShow" | "willHide";

export interface MenuContextProps {
  showMenu: () => void;
  hideMenu: () => void;
}
export const MenuContext = React.createContext<MenuContextProps>({
  showMenu: () => {
    console.warn("MenuProvider not implemented");
  },
  hideMenu: () => {
    console.warn("MenuProvider not implemented");
  },
});

export function withMenuContext<TOriginalProps extends {}>(
  Component: React.ComponentType<TOriginalProps>,
) {
  return (props: TOriginalProps) => {
    const { showMenu, hideMenu } = useContext(MenuContext);

    return (
      <Component {...props} showMenu={showMenu} hideMenu={hideMenu} />
    ) as React.ReactElement<Omit<TOriginalProps, "showMenu" | "hideMenu">>;
  };
}

export const useMenu = () => {
  return React.useContext(MenuContext);
};
