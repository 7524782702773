import datadogLogs, { setupDatadog as setupDD } from "@blacknut/logging-datadog/dist";
import { State } from "@blacknut/react-client-core/lib";
import { useSelector } from "react-redux";

export const setupDatadog = () => {
  const appVersion = process.env.REACT_APP_DD_VERSION || "0.0.0.NA";
  setupDD({
    service: "Client",
    version: `${appVersion}`,
    env: "prod",
  });
};

export const useDatadog = () => {
  const { platform, user } = useSelector((state: State) => state.globalState);
  if (platform && platform.indexOf("api.blacknut.com") !== -1) {
    datadogLogs.setGlobalContextProperty("env", "prod");
    datadogLogs.setGlobalContextProperty("user", user?.uuid);
  } else if (platform && platform.indexOf("dev") !== -1) {
    // dev
    datadogLogs.setGlobalContextProperty("dev", "prod");
    datadogLogs.setGlobalContextProperty("user", user?.uuid);
  } else if (platform && platform.indexOf("integration") !== -1) {
    datadogLogs.setGlobalContextProperty("env", "integ");
    datadogLogs.setGlobalContextProperty("user", user?.uuid);
  } else {
    if (platform && platform.indexOf("staging") !== -1) {
      datadogLogs.setGlobalContextProperty("env", "staging");
      datadogLogs.setGlobalContextProperty("user", user?.uuid);
    }
  }
};
