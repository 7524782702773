import { logD, logE } from "@blacknut/logging/dist";
import {
  getAisReceiptUri,
  deriveToken,
  RemoteNotification,
  State,
  StorageKey,
} from "@blacknut/react-client-core/lib";
import {
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { firstValueFrom } from "rxjs";
import { TertiaryButton } from "../../components/Button/V2Button";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import LogoutModal from "../../components/Modals/LogoutModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import { useTheme } from "../../theme/ThemeProvider";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
import { isAISBuild, isInStandaloneMode, LOGGING_TAG } from "../../utils/Utils";
import { RemoteNotificationListItem } from "../RemoteNotifications/RemoteNotifications";
import NotificationInstallComponent from "src/components/NotificationsInstall";
import { detectDevice } from "src/services/detectDevice";
import { ViewOrNot } from "src/components/NotificationsInstall/ViewOrNot";

import { ReactComponent as MyAccount } from "../../assets/dist/ic_accounts.svg";
import { ReactComponent as MyAccountActive } from "../../assets/dist/ic_accounts_active.svg";
import {
  AccountListItem,
  BaseListItem,
  MAX_NOTIF_COUNT,
  MyAccountSection,
  MyAccountSectionType,
  ThemeListItem,
} from "./AccountListItem";
import AccountListItemView from "./AccountListItemView";
import {
  Container,
  SectionHeader,
  SectionHeaderTitle,
  SectionList,
  SectionListItem,
  SectionListItemList,
  StyledLogout,
  StyledPrimary,
  StyledProfilesList,
  InstallCard,
  InstallContent,
  InstallButton,
  Version,
} from "./MobileAccountPage.style";
import ThemeListItemView from "./ThemeListItemView";
const MobileAccountPage = () => {
  const {
    remoteNotifications: notifications,
    billingInfo,
    user,
    config,
    organization,
    accountUrl,
    userToken,
    familyToken,
  } = useSelector((state: State) => state.globalState);
  const { profile } = useSelector((state: State) => state.profilesState);
  const { t, i18n } = useTranslation();
  const locale = i18n.language || "en-US";
  const { theme, themes } = useTheme();
  let items: MyAccountSection[] = [];
  const hasNotifications = notifications && notifications.length > 0;
  const history = useHistory();
  if (hasNotifications) {
    const data = notifications.slice(0, MAX_NOTIF_COUNT) as BaseListItem[];
    items.push({
      title: t("account.notifications.title"),
      type: MyAccountSectionType.NOTIFICATION,
      data,
    });
  }
  const [installNotif, setInstallNotif] = useState(
    localStorage.getItem(StorageKey.INSTALL_NOTIF),
  );
  const deviceInfos = detectDevice(navigator.userAgent);
  useEffect(() => {
    window.addEventListener("storage", () => {
      setInstallNotif(localStorage.getItem(StorageKey.INSTALL_NOTIF));
    });
    return () => {
      window.removeEventListener("storage", () => {
        setInstallNotif(ViewOrNot(deviceInfos));
      });
    };
  }, [deviceInfos]);
  const openNotificationInstall = () => {
    localStorage.setItem(StorageKey.INSTALL_NOTIF, "true");
    window.dispatchEvent(new Event("storage"));
  };
  const onPressSubscription = useCallback(() => {
    if (organization?.subscriptionUrl && !isAISBuild()) {
      window.open(organization?.subscriptionUrl);
    } else {
      history.push("/account/subscription");
    }
  }, [history, organization?.subscriptionUrl]);

  const openWario = useCallback(async () => {
    try {
      const derived = await firstValueFrom(deriveToken("profile"));
      const uri = `${accountUrl}?fat=${derived.familyToken.accessToken}&frt=${derived.familyToken.refreshToken}&uat=${derived.userToken?.accessToken}&urt=${derived.userToken?.refreshToken}&theme=${theme.name}&frompwa=true`;
      window.location.replace(uri);
    } catch (e) {
      logE(LOGGING_TAG, "Caught error on token derivation", e);
      const uri = `${accountUrl}?fat=${familyToken?.accessToken}&frt=${familyToken?.refreshToken}&uat=${userToken?.accessToken}&urt=${userToken?.refreshToken}&theme=${theme.name}&frompwa=true`;
      window.location.replace(uri);
    }
  }, [
    accountUrl,
    familyToken?.accessToken,
    familyToken?.refreshToken,
    theme,
    userToken?.accessToken,
    userToken?.refreshToken,
  ]);

  const onNewsletterClick = useCallback(() => {
    history.push("/account/newsletter");
  }, [history]);

  const onReceiptClick = useCallback(() => {
    if (user) window.open(getAisReceiptUri(user), "blank");
  }, [user]);
  const onFaqClick = useCallback(() => {
    if (organization?.faqUrl) {
      window.open(organization?.faqUrl);
    } else {
      window.open(`https://www.blacknut.com/${locale.split("-")[0]}/support`, "_blank");
    }
  }, [locale, organization]);

  const onSupportClick = useCallback(() => {
    if (organization?.supportUrl) {
      window.open(organization?.supportUrl);
    } else {
      history.push("/account/support");
    }
  }, [history, organization?.supportUrl]);

  let accountListItems: AccountListItem[];

  if (profile) {
    if (profile.isMaster) {
      const mySubscription = {
        title: t("buttons.subscription"),
        icon: theme.images.Plans,
        onPress: onPressSubscription,
        id: "subscription",
      };
      const accountManagement = {
        title: t("buttons.accountManagement"),
        icon: MyAccount,
        onPress: openWario,
        id: "accountManagement",
      };

      const newsletter = {
        title: t("account.newsletter"),
        icon: theme.images.Newsletter,

        onPress: onNewsletterClick,
        id: "newsletter",
      };

      const receipt = {
        title: t("buttons.receipt"),
        onPress: onReceiptClick,
        id: "receipt",
        icon: theme.images.Receipt,
      };

      accountListItems = [newsletter, mySubscription, receipt, accountManagement];

      if (accountListItems && accountListItems.length > 0) {
        items.push({
          type: MyAccountSectionType.SETTINGS,
          data: accountListItems,
        });
      }
    }

    // SUPPORT
    items.push({
      type: MyAccountSectionType.SETTINGS,
      data: [
        {
          title: t("buttons.faq"),
          icon: theme.images.Faq,

          onPress: onFaqClick,
          id: "faq",
        } as BaseListItem,
        {
          title: t("buttons.support"),
          icon: theme.images.Support,

          onPress: onSupportClick,
          id: "support",
        } as BaseListItem,
      ],
    });
  }

  items.push({
    type: MyAccountSectionType.SETTINGS,
    data: [
      {
        id: "settings",
        icon: theme.images.Settings,
        title: t("account.advancedSettings"),
        onPress: () => {
          history.push("/account/settings");
        },
      } as BaseListItem,
    ],
  });

  //Filter items based on config
  const features: Partial<Record<string, boolean>> = config?.features || {};
  items = items.map((section) => {
    return {
      ...section,
      data: section.data.filter((item) => {
        const feat = features[item.id];
        if (feat === undefined) {
          logD(LOGGING_TAG, "Feature %o is not configurable", item.id);
          return true;
        }
        if (!feat) {
          logD(LOGGING_TAG, "Feature %o is not supported", item.id);
          return false;
        }
        return true;
      }),
    };
  });

  //Filter empty items
  items = items.filter((section) => {
    return section.data.length > 0;
  });

  const onClickManageProfiles = useCallback(() => {
    history.push("/account/profiles/manage");
  }, [history]);
  const onClickManageProfile = useCallback(() => {
    history.push(`/account/profile/${profile?.id}`);
  }, [history, profile?.id]);

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();
  useEffect(() => {
    setHeaderTitle(t("account.title"));
    setHeaderLeft(<HeaderBackButton title={t("account.title")} />);
    setHeaderRight(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle, t]);

  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const closeLogoutModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const openLogoutModal = useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <LogoutModal {...props} onClose={closeLogoutModal} history={history} />
    ));
  }, [closeLogoutModal, modalPush, history]);

  const onNotifClick = useCallback(() => {
    history.push("/account/notifications");
  }, [history]);

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  useEffect(() => {
    // defer resuming since location change even is propagated afterwards
    setTimeout(() => {
      resumeSpatialNav();
      focusSectionWithPath("/account/content");
    }, 300);
  }, [resumeSpatialNav]);
  return (
    <Container>
      <ScrollRestorer id="main" />

      <StyledProfilesList />

      {profile?.isMaster ? (
        <StyledPrimary theme={theme} onClick={onClickManageProfiles}>
          <span>{t("buttons.editProfiles")}</span>
        </StyledPrimary>
      ) : (
        <StyledPrimary theme={theme} onClick={onClickManageProfile}>
          <span>{t("buttons.editProfile")}</span>
        </StyledPrimary>
      )}

      {installNotif === "true" && !isInStandaloneMode() && (
        <NotificationInstallComponent deviceInfos={deviceInfos} />
      )}

      <SectionList theme={theme}>
        {items.map((section, i) => {
          return (
            <SectionListItem key={`${i}`} theme={theme}>
              <SectionHeader>
                <SectionHeaderTitle>{section.title}</SectionHeaderTitle>
                {section.type === MyAccountSectionType.NOTIFICATION &&
                  notifications.length >= MAX_NOTIF_COUNT && (
                    <TertiaryButton onClick={onNotifClick}>
                      <span>{t("buttons.all")}</span>
                    </TertiaryButton>
                  )}
              </SectionHeader>
              <SectionListItemList theme={theme}>
                {section.data.map((item, index) => {
                  if (section.type === MyAccountSectionType.NOTIFICATION) {
                    const _item = item as RemoteNotification;
                    return (
                      <RemoteNotificationListItem
                        notification={_item}
                        key={item.id}
                        variant="card"
                        isFirst={index === 0}
                        isLast={index === section.data.length - 1}
                      />
                    );
                  }

                  const _item = item as AccountListItem;
                  return (
                    <li key={_item.id}>
                      <AccountListItemView
                        item={_item}
                        section={section}
                        index={index}
                      />
                    </li>
                  );
                })}
              </SectionListItemList>
            </SectionListItem>
          );
        })}
        {ViewOrNot(deviceInfos) === "true" && (
          <InstallCard theme={theme} onClick={openNotificationInstall}>
            <InstallContent>
              <span>{t("notifications.install.accountManagement.text")}</span>
              <InstallButton
                theme={theme}
                onClick={openNotificationInstall}
                testID="openInstallNotification"
              >
                {t("notifications.install.accountManagement.button")}
              </InstallButton>
            </InstallContent>
          </InstallCard>
        )}
      </SectionList>

      <StyledLogout theme={theme} onClick={openLogoutModal}>
        {t("buttons.logout")}
      </StyledLogout>

      <Version>
        {t("inputs.version", { version: process.env.REACT_APP_CLIENT_VERSION })}
      </Version>
    </Container>
  );
};

export default MobileAccountPage;
