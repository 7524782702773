import { logD } from "@blacknut/logging/dist";
import { State } from "@blacknut/react-client-core/lib";
import debounce from "lodash/debounce";
import React, {
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import dimens, { getNumColumns } from "../theme/dimens";
import { useOrientation } from "./OrientationContext";
import { LOGGING_TAG } from "./Utils";

/**
 * Pixel perfect tile width
 */

export const TileWidthContext = React.createContext<{
  standardTileSize: { width: number; height: number };
  featuredTileSize: { width: number; height: number };
}>({
  standardTileSize: { width: 0, height: 0 },
  featuredTileSize: { width: 0, height: 0 },
});

const standardTileRef: RefObject<HTMLDivElement> = React.createRef();
const featuredTileRef: RefObject<HTMLDivElement> = React.createRef();
const StandardTile = styled.div<{ nbCols: number }>`
  position: fixed;
  visibility: hidden;
  width: calc(
    (
        100vw - ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
          ${dimens.phone.contentPaddingH}rem
      ) / ${(props) => props.nbCols}
  );
  height: calc(
    (
        100vw - ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
          ${dimens.phone.contentPaddingH}rem
      ) / ${(props) => props.nbCols}
  );

  @media (orientation: landscape) {
    width: calc(
      (
          100vw - ${dimens.phoneLandscape.navigationBarWidth}rem -
            ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.phoneLandscape.contentPaddingH}rem
        ) / ${(props) => props.nbCols}
    );
    height: calc(
      (
          100vw - ${dimens.phoneLandscape.navigationBarWidth}rem -
            ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.phoneLandscape.contentPaddingH}rem
        ) / ${(props) => props.nbCols}
    );
  }

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: calc(
      (
          100vw - ${dimens.desktop.navigationBarWidth}rem -
            ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.desktop.contentPaddingH}rem
        ) / ${(props) => props.nbCols}
    );
    height: calc(
      (
          100vw - ${dimens.desktop.navigationBarWidth}rem -
            ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.desktop.contentPaddingH}rem
        ) / ${(props) => props.nbCols}
    );
  }
`;

const FeaturedTile = styled.div<{ nbCols: number }>`
  position: fixed;
  visibility: hidden;
  width: calc(
    (
        100vw - ${dimens.phone.navigationBarWidth}rem - ${(props) => props.nbCols - 1} *
          ${dimens.margins.DarkRed}rem - 2 * ${dimens.phone.contentPaddingH}rem
      ) / ${(props) => props.nbCols} * 2 + ${dimens.margins.DarkRed}rem
  );
  height: calc(
    (
        100vw - ${dimens.phone.navigationBarWidth}rem - ${(props) => props.nbCols - 1} *
          ${dimens.margins.DarkRed}rem - 2 * ${dimens.phone.contentPaddingH}rem
      ) / ${(props) => props.nbCols} * 2 + ${dimens.margins.DarkRed}rem
  );
  @media (orientation: landscape) {
    width: calc(
      (
          100vw - ${dimens.phoneLandscape.navigationBarWidth}rem - 2 *
            ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.phoneLandscape.contentPaddingH}rem
        ) / 3
    );
    height: calc(
      (
          100vw - ${dimens.phoneLandscape.navigationBarWidth}rem - 2 *
            ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.phoneLandscape.contentPaddingH}rem
        ) / 3
    );
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: calc(
      (
          100vw - ${dimens.desktop.navigationBarWidth}rem -
            ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.desktop.contentPaddingH}rem
        ) / ${(props) => props.nbCols} * 2 + ${dimens.margins.DarkRed}rem
    );
    height: calc(
      (
          100vw - ${dimens.desktop.navigationBarWidth}rem -
            ${(props) => props.nbCols - 1} * ${dimens.margins.DarkRed}rem - 2 *
            ${dimens.desktop.contentPaddingH}rem
        ) / ${(props) => props.nbCols} * 2 + ${dimens.margins.DarkRed}rem
    );
  }
`;

export const TileWidthContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [standardTileSize, setStandardTileSize] = useState({
    width: 0,
    height: 0,
  });
  const [featuredTileSize, setFeaturedTileSize] = useState({
    width: 0,
    height: 0,
  });
  const { layout } = useSelector((state: State) => state.globalState);
  const { orientation } = useOrientation();
  const [width, setWindowWidth] = useState(window.innerWidth);

  const nbCols = getNumColumns(layout, orientation);
  useEffect(() => {
    if (standardTileRef.current) {
      const rect = standardTileRef.current.getBoundingClientRect();
      if (rect.width != 0) {
        //Fix https://blacknut.atlassian.net/browse/CA-2662
        logD(LOGGING_TAG, "setStandardTileSize %o", rect.width);
        setStandardTileSize({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        });
      }
    }
    if (featuredTileRef.current) {
      const rect = featuredTileRef.current.getBoundingClientRect();
      if (rect.width != 0) {
        //Fix https://blacknut.atlassian.net/browse/CA-2662
        logD(LOGGING_TAG, "setStandardTileSize %o", rect.width);
        setFeaturedTileSize({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        });
      }
    }
  }, [orientation, nbCols, width]);

  const resizeListener = debounce(() => {
    setWindowWidth(window.innerWidth);
  }, 200);
  window.addEventListener("resize", resizeListener);

  return (
    <TileWidthContext.Provider
      value={{
        standardTileSize,
        featuredTileSize,
      }}
    >
      {
        <>
          <StandardTile ref={standardTileRef} nbCols={nbCols}></StandardTile>
          <FeaturedTile ref={featuredTileRef} nbCols={nbCols}></FeaturedTile>
        </>
      }
      {standardTileSize.width > 0 && featuredTileSize.width > 0 && children}
    </TileWidthContext.Provider>
  );
};

export const useTileSize = () => useContext(TileWidthContext);
